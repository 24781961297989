import { TAdvDesignerComponentProps } from "@feature/Designer/types/component-props";
import { IStackItemProps, IStackItemStyles, IStackItemTokens } from "@fluentui/react";
import { IStylesFunctionOrObject, ITokenFunctionOrObject } from "@typings/fluent";
import { combineStylesWithToken, combineTokens } from "@utils/styling";
import React, { ReactNode, useMemo } from "react";

import { getDesignerModeComponentStyle, getSelectedComponentStyle } from "@feature/Designer/utils";
import {
    IsValueBindingTrivial,
    TAdvValueBindingParams,
    useAdvValueBinderNoDataType,
} from "@hooks/dynamic/useAdvValueBinder";
import { EAdvValueDataTypes } from "@utils/data-types";

import { deepCompareJSXProps } from "@utils/deep-compare";
import { defaultStackItemStyles, defaultStackItemTokens } from "./styles";

export type TAdvStackItemTokens = IStackItemTokens; /* do not change */
export type TAdvStackItemStyles = IStackItemStyles; /* do not change */

export type TAdvStackItemProps = Omit<IStackItemProps, "styles"> &
    TAdvDesignerComponentProps &
    TAdvCommonProperties & {
        styles?: IStylesFunctionOrObject<IStackItemProps, TAdvStackItemTokens, TAdvStackItemStyles>;
        tokens?: ITokenFunctionOrObject<IStackItemProps, TAdvStackItemTokens>;
        children?: ReactNode | ReactNode[];

        minWidth?: string;
        maxWidth?: string;
        minHeight?: string;
        maxHeight?: string;

        shrinkBindingParams?: TAdvValueBindingParams;
        growBindingParams?: TAdvValueBindingParams;
        alignBindingParams?: TAdvValueBindingParams;
        basisBindingParams?: TAdvValueBindingParams;
        minWidthBindingParams?: TAdvValueBindingParams;
        maxWidthBindingParams?: TAdvValueBindingParams;
        minHeightBindingParams?: TAdvValueBindingParams;
        maxHeightBindingParams?: TAdvValueBindingParams;
    };

const AdvStackItemImplComp = ({
    styles: propStyles,
    tokens,
    children,
    designerData,
    designerProps,
    minWidth,
    maxWidth,
    minHeight,
    maxHeight,
    ...props
}: TAdvStackItemProps) => {
    useAdvComponent(AdvStackItemImplComp, props);

    const theme = useAdvTheme();

    const styles = useMemo(() => {
        let styles = propStyles;
        if (designerData) {
            if (designerData.isSelected && designerData.renderAsDesigner)
                styles = combineStylesWithToken(styles, {
                    root: getSelectedComponentStyle(theme, true),
                });
            styles = combineStylesWithToken(styles, defaultStackItemStyles.designer);
            if (designerData?.renderAsDesigner ?? false)
                styles = combineStylesWithToken(styles, {
                    root: getDesignerModeComponentStyle(theme),
                });
        }
        return combineStylesWithToken(styles, {
            root: {
                maxWidth: maxWidth != "" ? maxWidth : undefined,
                minWidth: minWidth != "" ? minWidth : undefined,
                maxHeight: maxHeight != "" ? maxHeight : undefined,
                minHeight: minHeight != "" ? minHeight : undefined,
            },
        });
    }, [designerData, maxHeight, maxWidth, minHeight, minWidth, propStyles, theme]);

    const tokensMemo = useMemo(() => {
        const res = combineTokens(defaultStackItemTokens.normal, tokens);
        return res;
    }, [tokens]);

    return (
        <StackItemShim {...props} {...designerProps} styles={styles} tokens={tokensMemo}>
            {children}
        </StackItemShim>
    );
};
const AdvStackItemImpl = React.memo(AdvStackItemImplComp, deepCompareJSXProps);

const AdvStackItemSimple = (props: TAdvStackItemProps) => {
    return <AdvStackItemImpl {...props}></AdvStackItemImpl>;
};

const AdvStackItemComplex = ({
    shrinkBindingParams,
    growBindingParams,
    alignBindingParams,
    minWidthBindingParams,
    maxWidthBindingParams,
    minHeightBindingParams,
    maxHeightBindingParams,
    basisBindingParams,
    shrink,
    grow,
    align,
    minWidth,
    maxWidth,
    minHeight,
    maxHeight,
    basis,
    dataArrayIndex = 0,
    ...props
}: TAdvStackItemProps) => {
    const [doesShrinkValue] = useAdvValueBinderNoDataType(
        shrinkBindingParams,
        shrink,
        EAdvValueDataTypes.Any,
        dataArrayIndex,
    );
    const [doesGrowValue] = useAdvValueBinderNoDataType(
        growBindingParams,
        grow,
        EAdvValueDataTypes.Any,
        dataArrayIndex,
    );
    const [alignValue] = useAdvValueBinderNoDataType(
        alignBindingParams,
        align,
        EAdvValueDataTypes.Any,
        dataArrayIndex,
    );
    const [minWidthValue] = useAdvValueBinderNoDataType(
        minWidthBindingParams,
        minWidth,
        EAdvValueDataTypes.Any,
        dataArrayIndex,
    );
    const [maxWidthValue] = useAdvValueBinderNoDataType(
        maxWidthBindingParams,
        maxWidth,
        EAdvValueDataTypes.Any,
        dataArrayIndex,
    );
    const [minHeightValue] = useAdvValueBinderNoDataType(
        minHeightBindingParams,
        minHeight,
        EAdvValueDataTypes.Any,
        dataArrayIndex,
    );
    const [maxHeightValue] = useAdvValueBinderNoDataType(
        maxHeightBindingParams,
        maxHeight,
        EAdvValueDataTypes.Any,
        dataArrayIndex,
    );
    const [basisValue] = useAdvValueBinderNoDataType(
        basisBindingParams,
        basis,
        EAdvValueDataTypes.Any,
        dataArrayIndex,
    );

    return (
        <AdvStackItemImpl
            {...props}
            shrinkBindingParams={shrinkBindingParams}
            growBindingParams={growBindingParams}
            alignBindingParams={alignBindingParams}
            minWidthBindingParams={minWidthBindingParams}
            maxWidthBindingParams={maxWidthBindingParams}
            minHeightBindingParams={minHeightBindingParams}
            maxHeightBindingParams={maxHeightBindingParams}
            basisBindingParams={basisBindingParams}
            shrink={doesShrinkValue}
            grow={doesGrowValue}
            align={alignValue}
            minWidth={minWidthValue}
            maxWidth={maxWidthValue}
            minHeight={minHeightValue}
            maxHeight={maxHeightValue}
            basis={basisValue}
            dataArrayIndex={dataArrayIndex}
        ></AdvStackItemImpl>
    );
};

/**
 * @summary Wrapper für StackItem.
 * @link https://developer.microsoft.com/en-us/fluentui#/controls/web/stack
 */
const AdvStackItemComp = ({
    shrinkBindingParams,
    growBindingParams,
    alignBindingParams,
    minWidthBindingParams,
    maxWidthBindingParams,
    minHeightBindingParams,
    maxHeightBindingParams,
    basisBindingParams,
    ...props
}: TAdvStackItemProps) => {
    if (
        IsValueBindingTrivial(shrinkBindingParams) &&
        IsValueBindingTrivial(growBindingParams) &&
        IsValueBindingTrivial(alignBindingParams) &&
        IsValueBindingTrivial(minWidthBindingParams) &&
        IsValueBindingTrivial(maxWidthBindingParams) &&
        IsValueBindingTrivial(minHeightBindingParams) &&
        IsValueBindingTrivial(maxHeightBindingParams) &&
        IsValueBindingTrivial(basisBindingParams)
    )
        return (
            <AdvStackItemSimple
                {...props}
                shrinkBindingParams={shrinkBindingParams}
                growBindingParams={growBindingParams}
                alignBindingParams={alignBindingParams}
                minWidthBindingParams={minWidthBindingParams}
                maxWidthBindingParams={maxWidthBindingParams}
                minHeightBindingParams={minHeightBindingParams}
                maxHeightBindingParams={maxHeightBindingParams}
                basisBindingParams={basisBindingParams}
            ></AdvStackItemSimple>
        );
    else
        return (
            <AdvStackItemComplex
                {...props}
                shrinkBindingParams={shrinkBindingParams}
                growBindingParams={growBindingParams}
                alignBindingParams={alignBindingParams}
                minWidthBindingParams={minWidthBindingParams}
                maxWidthBindingParams={maxWidthBindingParams}
                minHeightBindingParams={minHeightBindingParams}
                maxHeightBindingParams={maxHeightBindingParams}
                basisBindingParams={basisBindingParams}
            ></AdvStackItemComplex>
        );
};

const AdvStackItem = React.memo(AdvStackItemComp, deepCompareJSXProps);
export default AdvStackItem;

import { TAdvCommonProperties } from "@components/other/common-properties";
import { StackItemShim } from "@fluentui/react-migration-v8-v9";
import useAdvComponent from "@hooks/useAdvComponent";
import useAdvTheme from "@hooks/useAdvTheme";
import "./designable";

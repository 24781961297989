import { TAdvTextStyles } from "@components/data/text";
import { TAdvButtonStyles } from "@components/inputs/button/button-pure";
import { IModalStyles, ITextProps, ITheme, Theme } from "@fluentui/react";

import { TAdvGridItemStyles } from "../grid/grid-item/types";
import { TAdvGridStyles } from "../grid/types";

const modalStyles: Partial<IModalStyles> = {
    main: {
        // minHeight: '288px',
        minWidth: "172px",
        overflow: "hidden", // Das Modal an sich soll NICHT scrollbar sein (warum auch...)
    },
};

const containerStyles: Partial<TAdvGridStyles> = {};

const headerContainerStyles = (theme: Theme): TAdvGridItemStyles => {
    return {
        root: {
            background: theme.palette.themeSecondary,
            textAlign: "center",
            paddingLeft: 25,
            paddingRight: 25,
            minHeight: 48,
            alignItems: "center",
            position: "relative",
        },
    };
};

const contentContainerStyles: Partial<TAdvGridItemStyles> = {
    root: {
        // Damit MaxHeight und Overflow mit der Virtualization der Liste funktioniert, muss data-is-scrollable-Property gesetzt sein, siehe FluentUI Docs
        overflowY: "auto",
        padding: 4,
        selectors: {
            ".react-grid-item": {
                height: "auto !important" as any,
                position: "inherit !important" as any,
                transform: "inherit !important" as any,
                width: "auto !important" as any,
            },
        },
    },
};

const headlineStyles = (props: ITextProps, theme: ITheme): TAdvTextStyles => {
    return {
        root: {
            color: theme.palette.white,
            display: "inline",
            lineHeight: "48px",
        },
    };
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const closeButtonStyles = (theme: Theme): TAdvButtonStyles => {
    return {
        root: {
            position: "absolute",
            right: 0,
            top: "50%",
            transform: "translate(-50%, -50%)",
            // color: theme.palette.themeDark // --> IconProps
        },
    };
};

export const defaultModalStyles = {
    // Eingebunden in themes/default.ts
    modal: modalStyles,
    container: containerStyles,
    headerContainer: headerContainerStyles,
    contentContainer: contentContainerStyles,
    headline: headlineStyles,
    closeButton: closeButtonStyles,
};

export const enum EActionLogic {
    OpenPage = "open_page",
    TryInitContract = "try_init_contract",
    TryFulfillContract = "try_fulfill_contract",
    CancelContract = "cancel_contract",
    TryCancelContract = "try_cancel_contract",
    // Exec contracts get the input data as init args & as first state
    ExecContract = "exec_contract",
    CallContractFunction = "call_contract_function",
    AddContractEditRecord = "add_contract_edit_record",
    RemContractEditRecord = "rem_contract_edit_record",
    // Go back in browser history by one element
    BrowserBack = "browser_back",
    // Go back in browser history, additionally push a value to the result register
    Return = "return",
}

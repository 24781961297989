import { IIconProps, registerIcons, Stack } from "@fluentui/react";
import * as fa from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon, FontAwesomeIconProps } from "@fortawesome/react-fontawesome";
import { useAdvEffect } from "@hooks/react-overload/useAdvEffect";
import useAdvTheme from "@hooks/useAdvTheme";
import { advcatch } from "@utils/logging";
import { CSSProperties, useState } from "react";

export interface IAdvIcon extends Partial<IIconProps> {
    /** Name des Icons, siehe https://developer.microsoft.com/en-us/fluentui#/styles/web/icons unter "Available Icons". */
    iconName: string;
}

const iconSets = {
    duoTone: import("@fortawesome/pro-duotone-svg-icons"),
    regular: import("@fortawesome/pro-regular-svg-icons"),
    thin: import("@fortawesome/pro-thin-svg-icons"),
    light: import("@fortawesome/pro-light-svg-icons"),
    solid: fa,
};

export const AdvIcons: IAdvIcon[] = [];

export function getAdvIconNames() {
    return AdvIcons.map((icon) => icon.iconName);
}
const AdvFontIcon = ({
    iconName,
    ...props
}: Omit<FontAwesomeIconProps, "icon"> & { iconName: string }) => {
    const theme = useAdvTheme();
    const [iconSet, setIconSet] = useState<any>(iconSets.solid);

    useAdvEffect(() => {
        async function asyncFunction() {
            switch (theme.icon.type) {
                case "duotone":
                    setIconSet(await iconSets.duoTone);
                    return;
                case "regular":
                    setIconSet(await iconSets.regular);
                    return;
                case "thin":
                    setIconSet(await iconSets.thin);
                    return;
                case "light":
                    setIconSet(await iconSets.light);
                    return;
                case "solid":
                case "light":
                    setIconSet(iconSets.solid);
                    return;
            }
        }
        asyncFunction().catch(advcatch);
    }, [theme.icon.type]);

    const iconDef = (iconSet as any)[iconName];
    return (
        <Stack verticalFill styles={{ root: { minWidth: 12, minHeight: 12 } }}>
            <FontAwesomeIcon
                {...props}
                icon={iconDef}
                className={"adv-icon"}
                style={
                    {
                        "--adv-icon-primary-color":
                            "var(--adv-icon-primary-color-override, " +
                            theme.palette[theme.icon.color] +
                            ")",
                        "--adv-icon-secondary-color":
                            "var(--adv-icon-secondary-color-override, " +
                            theme.palette[theme.icon.colorSecondary] +
                            ")",
                        "--adv-icon-primary-color--disabled":
                            "var(--adv-icon-primary-color--disabled-override, " +
                            theme.palette[theme.icon.disabledColor] +
                            ")",
                        "--adv-icon-secondary-color--disabled":
                            "var(--adv-icon-secondary-color--disabled-override, " +
                            theme.palette[theme.icon.disabledColorSecondary] +
                            ")",
                        "--adv-icon-primary-color--hover":
                            "var(--adv-icon-primary-color--hover-override, " +
                            theme.palette[theme.icon.hoverColor] +
                            ")",
                        "--adv-icon-secondary-color--hover":
                            "var(--adv-icon-secondary-color--hover-override, " +
                            theme.palette[theme.icon.hoverColorSecondary] +
                            ")",
                    } as CSSProperties
                }
            />
        </Stack>
    );
};
function getIcons() {
    const obj: Record<string, React.JSX.Element> = {};
    for (const faIcon in fa) {
        if (faIcon.startsWith("fa") && faIcon != "fas") {
            obj[faIcon] = <AdvFontIcon iconName={faIcon} size="4x" display={"flex"} />;
        }
    }
    return obj;
}
function registerIcon(iconOrName: IAdvIcon | string): IAdvIcon {
    if (typeof iconOrName == "string") {
        const icon = { iconName: iconOrName };
        AdvIcons.push(icon);
        return icon;
    } else {
        AdvIcons.push(iconOrName);
        return iconOrName;
    }
}

const registerFAIcons = () => {
    registerIcons({
        icons: getIcons(),
    });

    const allIcons: Record<string, IAdvIcon> = {};
    for (const faIcon in fa) {
        if (faIcon.startsWith("fa") && faIcon != "fas") {
            allIcons[faIcon.replace("fa", "")] = registerIcon(faIcon);
        }
    }
    return allIcons;
};

const AllIcons = registerFAIcons();
export { AllIcons as default };

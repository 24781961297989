import AdvGridItemDesignable from "@components/layout/grid/grid-item/designable";
import AdvStackItemDesignable from "@components/layout/stack/stack-item/designable";
import {
    AdvCommonComponentAttributes,
    AdvThemeProviderProperties,
} from "@components/other/common-properties";
import { LAN } from "@data/language/strings";
import { AdvProperty } from "@feature/Designer/utils";
import { SelectionMode } from "@fluentui/react";
import { toAdvText } from "@hooks/language/useTranslation";

enum EPropertyName {
    Name = "name",
    ProviderKey = "providerKey",
    CanFilter = "canFilter",
    CanSort = "canSort",
    Columns = "customColumnDefinitions",
    CanExcelExport = "canExcelExport",
}

export const legacyEndlessTableProps = [
    AdvProperty.Text.create(
        toAdvText(LAN.NAME),
        EPropertyName.Name,
        toAdvText(LAN.GENERAL),
        toAdvText(LAN.ENDLESS_TABLE_NAME_DESCR),
        "EndlessTable",
    ),
    AdvProperty.Text.createRecoilSelectProvider(
        toAdvText(LAN.PROVIDER),
        EPropertyName.ProviderKey,
        toAdvText(LAN.GENERAL),
        toAdvText(LAN.ENDLESS_TABLE_PROVIDER_KEY_DESCR),
    ),
    AdvProperty.Boolean.create(
        toAdvText(LAN.DO_FILTER),
        EPropertyName.CanFilter,
        toAdvText(LAN.GENERAL),
        toAdvText(LAN.ENDLESS_TABLE_CAN_FILTER_DESCR),
        true,
    ),
    AdvProperty.Boolean.create(
        toAdvText(LAN.SORT),
        EPropertyName.CanSort,
        toAdvText(LAN.GENERAL),
        toAdvText(LAN.ENDLESS_TABLE_CAN_SORT_DESCR),
        true,
    ),
    AdvProperty.Boolean.create(
        toAdvText(LAN.EXCEL_EXPORT),
        EPropertyName.CanExcelExport,
        toAdvText(LAN.GENERAL),
        toAdvText(LAN.ENDLESS_TABLE_EXCEL_EXPORT_DESCR),
        true,
        false,
    ),
    AdvProperty.Object.createColumns(
        toAdvText(LAN.COLUMNS),
        EPropertyName.Columns,
        toAdvText(LAN.GENERAL),
        toAdvText(LAN.ENDLESS_TABLE_CUSTOM_COLUMN_DEFINITION_DESCR),
        [],
    ),
    AdvProperty.Number.createSelect(
        toAdvText(LAN.SELECTION_MODE),
        "selectionMode",
        toAdvText(LAN.SPECIAL),
        toAdvText(LAN.ENDLESS_TABLE_SELECTION_MODE_DESCR),
        0,
        false,
        { text: "single", value: SelectionMode.single },
        { text: "multi", value: SelectionMode.multiple },
        { text: "none", value: SelectionMode.none },
    ),
    AdvProperty.Object.createAutoGrouping(
        toAdvText(LAN.AUTO_GROUPING),
        "autoGrouping",
        toAdvText(LAN.SPECIAL),
        toAdvText(LAN.ENDLESS_TABLE_AUTO_GROUPING_DESCR),
    ),
    ...AdvCommonComponentAttributes,
    ...AdvThemeProviderProperties,
    ...AdvStackItemDesignable.CommonProperties,
    ...AdvGridItemDesignable.CommonProperties,
];

// enum EPropertyCategory {
//     Allgemeines = "Allgemeines",
// }

// enum EPresetName {}

// const registeredComponent = registerDesignableComponent({
//     staticData: {
//         name: LAN.ENDLESS_TABLE.text,
//
//         type: EComponentTypeData.EndlessTable,
//         supportsChildren: true,
//         hideDropAreas: true,
//         category: DefaultComponentCategory.Misc,
//         icon: TableIcon,
//     },
//     properties: endlessTableProps,
//     propertiesBuilders: [],
//     presets: [],
// });

// const AdvEndlessTableDesignable = {
//     PropertyName: EPropertyName,
//     PropertyCategory: EPropertyCategory,
//     PresetName: EPresetName,
//     Registered: registeredComponent,
// };

// export default AdvEndlessTableDesignable;

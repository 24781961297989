import AdvGridItemDesignable from "@components/layout/grid/grid-item/designable";
import AdvStackItemDesignable from "@components/layout/stack/stack-item/designable";
import {
    AdvCommonComponentAttributes,
    AdvThemeProviderProperties,
    TAdvCommonProperties,
} from "@components/other/common-properties";
import { LAN } from "@data/language/strings";
import { DefaultComponentCategory } from "@feature/Designer/types/category";
import { TAdvDesignerComponentProps } from "@feature/Designer/types/component-props";
import { EComponentTypeInput } from "@feature/Designer/types/component-type";
import { TPropertyListItem } from "@feature/Designer/types/property";
import {
    AdvProperty,
    getDesignerModeComponentStyle,
    getSelectedComponentStyle,
    registerDesignableComponent,
} from "@feature/Designer/utils";
import {
    ChoiceGroup,
    IChoiceGroupOption,
    IChoiceGroupProps,
    IChoiceGroupStyleProps,
    IChoiceGroupStyles,
    IStyleFunctionOrObject,
} from "@fluentui/react";
import {
    IsValueBindingTrivial,
    TAdvValueBindingParams,
    useAdvValueBinderAsArrayNoDataType,
    useAdvValueBinderNoDataType,
} from "@hooks/dynamic/useAdvValueBinder";
import { toAdvText } from "@hooks/language/useTranslation";
import { useAdvCallback } from "@hooks/react-overload/useAdvCallback";
import useAdvComponent from "@hooks/useAdvComponent";
import { useAdvMemoWithUpdater } from "@hooks/useAdvMemoWithUpdater";
import useAdvTheme from "@hooks/useAdvTheme";
import { RadioBtnIcon } from "@themes/icons";
import { EAdvValueDataTypes } from "@utils/data-types";
import { deepCompareJSXProps } from "@utils/deep-compare";
import { combineStyles } from "@utils/styling";
import React, { useMemo } from "react";

import { TCommonValueProps } from "..";

export type TAdvRadioGroupOption = IChoiceGroupOption;
export type TAdvRadioGroupStyles = IChoiceGroupStyles; /* Do not change */
export type TAdvRadioGroupStyleProps = IChoiceGroupStyleProps; /* Do not change */

export type TAdvRadioGroupProps = Omit<
    IChoiceGroupProps,
    "options" | "styles" | "label" | "value" | "key" | "selectedKey" | "selectedKeys" | "onChange"
> &
    TAdvDesignerComponentProps &
    TAdvCommonProperties &
    Omit<TCommonValueProps<TAdvRadioGroupOption>, "value"> & {
        styles?: IStyleFunctionOrObject<TAdvRadioGroupStyleProps, TAdvRadioGroupStyles>;
        label: string;
        labelBindingParams?: TAdvValueBindingParams;
        options: TAdvRadioGroupOption[];
        optionsBindingParams?: TAdvValueBindingParams;
        value?: string | TAdvRadioGroupOption | undefined;
        valueBindingParams?: TAdvValueBindingParams;
    };

const AdvRadioGroupImplComp = ({
    styles: propStyles,
    value,
    advhide,
    designerData,
    designerProps,
    ...props
}: TAdvRadioGroupProps & Pick<IChoiceGroupProps, "onChange">) => {
    useAdvComponent(AdvRadioGroupImplComp, props);

    const theme = useAdvTheme();
    const valueAsStrOrInt = useMemo(
        () => (typeof value === "string" ? value : value != undefined ? value.key : ""),
        [value],
    );

    const styles = useMemo(() => {
        let styles = propStyles;
        if ((designerData?.isSelected ?? false) && (designerData?.renderAsDesigner ?? false))
            styles = combineStyles(styles, { root: getSelectedComponentStyle(theme, true) });
        if (designerData?.renderAsDesigner ?? false)
            styles = combineStyles(styles, { root: getDesignerModeComponentStyle(theme) });
        return styles;
    }, [designerData?.isSelected, designerData?.renderAsDesigner, propStyles, theme]);

    if (advhide === true && designerProps === undefined) return <></>;
    return (
        <ChoiceGroup {...props} {...designerProps} styles={styles} selectedKey={valueAsStrOrInt} />
    );
};
const AdvRadioGroupImpl = React.memo(AdvRadioGroupImplComp, deepCompareJSXProps);

const AdvRadioGroupSimple = ({ onValueChanged, value, ...props }: TAdvRadioGroupProps) => {
    const [curVal, , setCurVal] = useAdvMemoWithUpdater(() => value, [value]);

    const handleChange = useAdvCallback(
        (ev?: any, newValue?: TAdvRadioGroupOption) => {
            setCurVal(newValue);
            if (onValueChanged != undefined && typeof newValue != "undefined")
                onValueChanged(newValue, ev);
        },
        [onValueChanged, setCurVal],
    );

    return (
        <AdvRadioGroupImpl {...props} onChange={handleChange} value={curVal}></AdvRadioGroupImpl>
    );
};

const AdvRadioGroupComplex = ({
    valueBindingParams,
    labelBindingParams,
    optionsBindingParams,
    advhide,
    advhideBindingParams,
    value,
    label,
    options,
    onValueChanged,
    dataArrayIndex = 0,
    ...props
}: TAdvRadioGroupProps) => {
    const optionsCached = useMemo(
        () =>
            options.map((val): TPropertyListItem => {
                const data = val.text;
                return { key: val.key.toString(), text: val.text, data: data };
            }),
        [options],
    );

    const valueAsStr = useMemo(
        () => (typeof value === "string" ? value : value != undefined ? value.key.toString() : ""),
        [value],
    );

    const [currentOptions] = useAdvValueBinderAsArrayNoDataType(
        optionsBindingParams,
        optionsCached,
        EAdvValueDataTypes.Any,
    );
    const [currentValue, setCurrentValue] = useAdvValueBinderNoDataType(
        valueBindingParams,
        valueAsStr,
        EAdvValueDataTypes.Any,
        dataArrayIndex,
    );
    const [labelBindingValue] = useAdvValueBinderNoDataType(
        labelBindingParams,
        label,
        EAdvValueDataTypes.Any,
        dataArrayIndex,
    );

    const handleChange = useAdvCallback(
        (ev?: any, newValue?: TAdvRadioGroupOption) => {
            let canSet = true;
            if (newValue != undefined) canSet = setCurrentValue(newValue.key);
            if (canSet && onValueChanged != undefined && typeof newValue != "undefined")
                onValueChanged(newValue, ev);
        },
        [onValueChanged, setCurrentValue],
    );

    const [shouldHide] = useAdvValueBinderNoDataType(
        advhideBindingParams,
        advhide,
        EAdvValueDataTypes.Any,
        dataArrayIndex,
    );

    return (
        <AdvRadioGroupImpl
            {...props}
            valueBindingParams={valueBindingParams}
            labelBindingParams={labelBindingParams}
            optionsBindingParams={optionsBindingParams}
            advhide={shouldHide}
            advhideBindingParams={advhideBindingParams}
            value={currentValue}
            label={labelBindingValue}
            options={currentOptions}
            onChange={handleChange}
            dataArrayIndex={dataArrayIndex}
        ></AdvRadioGroupImpl>
    );
};

/**
 * @summary Wrapper für ``ChoiceGroup``
 * @link https://developer.microsoft.com/en-us/fluentui#/controls/web/choicegroup
 */
const AdvRadioGroupComp = ({
    valueBindingParams,
    labelBindingParams,
    optionsBindingParams,
    advhideBindingParams,
    ...props
}: TAdvRadioGroupProps) => {
    if (
        IsValueBindingTrivial(valueBindingParams) &&
        IsValueBindingTrivial(labelBindingParams) &&
        IsValueBindingTrivial(optionsBindingParams) &&
        IsValueBindingTrivial(advhideBindingParams)
    )
        return (
            <AdvRadioGroupSimple
                {...props}
                valueBindingParams={valueBindingParams}
                labelBindingParams={labelBindingParams}
                optionsBindingParams={optionsBindingParams}
                advhideBindingParams={advhideBindingParams}
            ></AdvRadioGroupSimple>
        );
    else
        return (
            <AdvRadioGroupComplex
                {...props}
                valueBindingParams={valueBindingParams}
                labelBindingParams={labelBindingParams}
                optionsBindingParams={optionsBindingParams}
                advhideBindingParams={advhideBindingParams}
            ></AdvRadioGroupComplex>
        );
};

const AdvRadioGroup = React.memo(AdvRadioGroupComp, deepCompareJSXProps);
export default AdvRadioGroup;

registerDesignableComponent({
    staticData: {
        name: LAN.RADIOBUTTONS.text,

        type: EComponentTypeInput.RadioGroup,
        supportsChildren: false,
        category: DefaultComponentCategory.Input,
        icon: RadioBtnIcon,
    },
    properties: [
        AdvProperty.Text.create(
            toAdvText(LAN.LABEL),
            "label",
            toAdvText(LAN.GENERAL),
            toAdvText(LAN.RADIOGROUP_LABEL_DESCR),
            "RadioGroup-Label",
        ),
        ...AdvCommonComponentAttributes,
        ...AdvThemeProviderProperties,
        ...AdvStackItemDesignable.CommonProperties,
        ...AdvGridItemDesignable.CommonProperties,
    ],
    propertiesBuilders: [],
    presets: [],
});

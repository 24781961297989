import { defaultGroupedListStyles } from "@components/data/list/grouped/styles";
import { defaultLabelStyles } from "@components/data/text/label/styles";
import { defaultTextStyles } from "@components/data/text/styles";
import { defaultCommandBarStyles } from "@components/inputs/action-bar/styles";
import { defaultCheckboxStyles } from "@components/inputs/checkbox/styles";
import { defaultDatePickerStyles } from "@components/inputs/datepicker/styles";
import { defaultDropdownStyles } from "@components/inputs/dropdown/styles";
import { defaultRadioGroupStyles } from "@components/inputs/radio-group/styles";
import { defaultSearchInputStyles } from "@components/inputs/text-input/search-input/styles";
import { defaultTextInputStyles } from "@components/inputs/text-input/styles";
import { defaultToggleStyles } from "@components/inputs/toggle/styles";
import { defaultDialogStyles } from "@components/layout/dialog/styles";
import { defaultModalStyles } from "@components/layout/modal/styles";
import { defaultStackItemStyles } from "@components/layout/stack/stack-item/styles";
import { defaultStackStyles } from "@components/layout/stack/styles";
import { defaultSpinnerStyles } from "@components/other/spinner/styles";
import { ComponentsStyles, createTheme } from "@fluentui/react";

export const globaComponentStyles: ComponentsStyles = {
    Checkbox: { styles: defaultCheckboxStyles },
    Dropdown: { styles: defaultDropdownStyles },
    ChoiceGroup: { styles: defaultRadioGroupStyles },
    TextField: { styles: defaultTextInputStyles },
    CommandBar: { styles: defaultCommandBarStyles },
    GroupedList: { styles: defaultGroupedListStyles },
    Dialog: { styles: defaultDialogStyles },
    Modal: { styles: defaultModalStyles.modal },
    Stack: { styles: defaultStackStyles.normal },
    StackItem: { styles: defaultStackItemStyles.normal },
    Spinner: { styles: defaultSpinnerStyles },
    Toggle: { styles: defaultToggleStyles },
    Text: { styles: defaultTextStyles },
    Label: { styles: defaultLabelStyles },
    SearchBox: { styles: defaultSearchInputStyles },
    DatePicker: { styles: defaultDatePickerStyles },
};

export const lightTheme = createTheme({
    components: globaComponentStyles,
    palette: {
        themePrimary: "#0078d4",
        themeLighterAlt: "#eff6fc",
        themeLighter: "#deecf9",
        themeLight: "#c7e0f4",
        themeTertiary: "#71afe5",
        themeSecondary: "#2b88d8",
        themeDarkAlt: "#106ebe",
        themeDark: "#005a9e",
        themeDarker: "#004578",
        neutralLighterAlt: "#faf9f8",
        neutralLighter: "#f3f2f1",
        neutralLight: "#edebe9",
        neutralQuaternaryAlt: "#e1dfdd",
        neutralQuaternary: "#d0d0d0",
        neutralTertiaryAlt: "#c8c6c4",
        neutralTertiary: "#a19f9d",
        neutralSecondary: "#605e5c",
        neutralPrimaryAlt: "#3b3a39",
        neutralPrimary: "#323130",
        neutralDark: "#201f1e",
        black: "#000000",
        white: "#ffffff",
    },
    effects: {
        elevation4: "0 1.6px 3.6px 0 rgba(0, 0, 0, 0.132), 0 0.3px 0.9px 0 rgba(0, 0, 0, 0.108)",
        elevation8: "0 3.2px 7.2px 0 rgba(0, 0, 0, 0.132), 0 0.6px 1.8px 0 rgba(0, 0, 0, 0.108)",
        elevation16: "0 6.4px 14.4px 0 rgba(0, 0, 0, 0.132), 0 1.2px 3.6px 0 rgba(0, 0, 0, 0.108)",
        elevation64: "0 25.6px 57.6px 0 rgba(0, 0, 0, 0.22), 0 4.8px 14.4px 0 rgba(0, 0, 0, 0.18)",
    },
    isInverted: false,
});

export const darkTheme = createTheme({
    components: globaComponentStyles,
    palette: {
        themePrimary: "#0dbd0d",
        themeLighterAlt: "#016001",
        themeLighter: "#021e02",
        themeLight: "#043904",
        themeTertiary: "#087108",
        themeSecondary: "#0ca60c",
        themeDarkAlt: "#20c320",
        themeDark: "#3ccd3c",
        themeDarker: "#68da68",
        neutralLighterAlt: "#323232",
        neutralLighter: "#515151",
        neutralLight: "#4f4f4f",
        neutralQuaternaryAlt: "#3c3c3c",
        neutralQuaternary: "#2a2a2a",
        neutralTertiaryAlt: "#989898",
        neutralTertiary: "#c8c8c8",
        neutralSecondary: "#d0d0d0",
        neutralPrimaryAlt: "#dadada",
        neutralPrimary: "#ffffff",
        neutralDark: "#f4f4f4",
        black: "#f8f8f8",
        white: "#333333",
    },
    effects: {
        elevation4: "0 1.6px 3.6px 0 rgb(72 72 72 / 3%), 0 0.3px 0.9px 0 rgb(126 126 126)",
        elevation8: "0 3.2px 7.2px 0 rgb(72 72 72 / 3%), 0 0.6px 1.8px 0 rgb(126 126 126)",
        elevation16: "0 6.4px 14.4px 0 rgb(72 72 72 / 3%), 0 1.2px 3.6px 0 rgb(126 126 126)",
        elevation64: "0 25.6px 57.6px 0 rgb(72 72 72 / 3%), 0 4.8px 14.4px 0 rgb(126 126 126)",
    },
    isInverted: true,
});

import { TContractID } from "@components/dynamic/contracts/contracts";
import {
    TDynamicPagePayload,
    TDynamicPagePayloadBase,
} from "@components/dynamic/dynamic-page/types";
import { TAdvDropdownItem } from "@components/inputs/dropdown";
import { TDictionaryValue } from "@data/persist/dictionary-value";
import { recoilPersistServerDictionary } from "@data/persist/server-dictionary";
import { atomFamily, selector } from "recoil";

import { gPageFilePrefix, replacePagePrefix } from "./designer/file";
import { TParameterID } from "./parameters";
import { TSyncDicts } from "./persist/sync-dictionary";
import { CreateRecoilDictionary } from "./utils/recoil-dictionary";

const dictSyncer: TSyncDicts = new Map();
const { valuesEffect, baseValuesEffect } = recoilPersistServerDictionary<
    TDynamicPagePayloadBase,
    TDynamicPagePayload
>("pagestorage", dictSyncer);

// Key: PageName, Value: TDynamicPagePayload
const dynamicPageDictionary = CreateRecoilDictionary<TDynamicPagePayload>("dynamicPages", {
    ValuesEffects: [valuesEffect],
});
const dynamicPageBaseDictionary = CreateRecoilDictionary<TDynamicPagePayloadBase>(
    "basedynamicPages",
    {
        ValuesEffects: [baseValuesEffect],
    },
);

export const recoilDynamicPage = {
    dictionary: dynamicPageDictionary,
    baseDictionary: dynamicPageBaseDictionary,

    Pages: dynamicPageDictionary.values,
    BasePages: dynamicPageBaseDictionary.values,
    PageKeys: dynamicPageDictionary.valueKeys,
    PageNames: dynamicPageBaseDictionary.valueKeys,

    removePage: dynamicPageDictionary.removeItem,
    removeBasePage: dynamicPageBaseDictionary.removeItem,

    addOrSetPage: dynamicPageDictionary.addOrSetItem,
    addOrSetBasePage: dynamicPageBaseDictionary.addOrSetItem,

    replacePage: dynamicPageDictionary.replaceItem,

    pageNamesAsDropdown: selector({
        key: "pageNamesAsDropdown",
        get: ({ get }) => {
            const temp: TAdvDropdownItem[] = [];
            const pageKeyNames = get(dynamicPageBaseDictionary.valueKeys).sort((val1, val2) =>
                val1.toLowerCase().localeCompare(val2.toLowerCase()),
            );
            for (const pageName of pageKeyNames) {
                const page = get(dynamicPageBaseDictionary.values(pageName));
                if (page.IsLoaded())
                    temp.push({
                        key: page.Get().Name,
                        text: page.Get().Name.replace(gPageFilePrefix, ""),
                        data: page.Get().Name,
                    });
            }
            return temp;
        },
    }),

    pageBaseArrayFromNames: selector({
        key: "pageBaseArrayFromNames",
        get: ({ get }) => {
            const temp: TDynamicPagePayloadBase[] = [];
            const pageKeyNames = get(dynamicPageBaseDictionary.valueKeys);
            for (const pageName of pageKeyNames) {
                const page = get(dynamicPageBaseDictionary.values(pageName));
                if (page.IsLoaded()) temp.push(page.Get());
            }
            return temp;
        },
    }),

    basePagesLength: selector({
        key: "basePagesLength",
        get: ({ get }) => {
            const pageKeyNames: string[] = get(dynamicPageBaseDictionary.valueKeys); // Get the list of page names
            return pageKeyNames.length; // Return the total number of loaded base pages
        },
    }),

    pageArrayFromNames: selector({
        key: "pageArrayFromNames",
        get: ({ get }) => {
            const temp: TDynamicPagePayload[] = [];
            const pageKeyNames = get(dynamicPageBaseDictionary.valueKeys);
            for (const pageName of pageKeyNames) {
                const page = get(dynamicPageDictionary.values(pageName));
                if (page.IsLoaded()) temp.push(page.Get());
            }
            return temp;
        },
    }),

    pageBaseArrayFilteredPage: selector<TDictionaryValue<TDynamicPagePayloadBase>[]>({
        key: "pageBaseArrayFilteredPage",
        get: ({ get }) => {
            let temp: TDictionaryValue<TDynamicPagePayloadBase>[] = [];
            const pageKeyNames = get(recoilDynamicPage.PageNames);
            for (const pageName of pageKeyNames) {
                const page = get(recoilDynamicPage.BasePages(pageName));
                if (page.IsLoaded()) temp.push(page);
            }
            temp = temp.sort((val1, val2) =>
                replacePagePrefix(val1.Get().Name).localeCompare(
                    replacePagePrefix(val2.Get().Name),
                ),
            ); // TODO! do we want sorting?
            return temp;
        },
    }),
};

export type TPageState = {
    ParametersLoaded: boolean; // PageParameter geladen?
    ShowRequiredErrors: boolean; // ErrorMessages anzeigen?
};
export const recoilPageState = atomFamily<TPageState, TParameterID>({
    key: "recoilPageState",
    default: { ParametersLoaded: false, ShowRequiredErrors: false },
});
export enum EContractEvent {
    Fulfilled = "Fulfilled",
    Error = "Error",
    Cancel = "Cancel",
}
export type TContractEventKey = {
    page: TParameterID;
    event: EContractEvent;
};
export type TContractEventData = {
    triggered: boolean;
    contract: TContractID | undefined;
    result?: Array<any>;
};
export const recoilContractEvent = atomFamily<TContractEventData, TContractEventKey>({
    key: "recoilContractEvent",
    default: { triggered: false, contract: undefined, result: undefined },
});

import { useAdvEffect } from "@hooks/react-overload/useAdvEffect";
import { deepCompare } from "@utils/deep-compare";
import { DependencyList, useMemo, useRef, useState } from "react";

/**
 * Dieser Hook benutzt JSON.stringify, um Werte zu überprüfen, was den Vorteil hat, dass Objects mit unterschiedlichen Referenzen
 * als gleich behandelt werden
 */
export const useAdvObjMemo = function <TValue>(
    factory: () => TValue,
    deps: DependencyList,
): TValue {
    const oldVal = useRef<TValue | undefined>(undefined);
    const memoVal = useMemo(() => {
        const newVal = factory();
        if (oldVal.current == undefined || !deepCompare(oldVal.current, newVal, true)) {
            oldVal.current = newVal;
        }
        return oldVal.current;
        // only check dependencies
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [...deps]);

    return memoVal;
};

/**
 * Dieser Hook benutzt JSON.stringify, um Werte zu überprüfen, was den Vorteil hat, dass Objects mit unterschiedlichen Referenzen
 * als gleich behandelt werden.
 * Außerdem triggered er state changes nur außerhalb der Render-Funktion
 */
export const useAdvObjMemoRenderless = function <TValue>(
    factory: () => TValue,
    deps: DependencyList,
    initial: () => TValue,
    compareFunc?: (a: TValue, b: TValue) => boolean,
): TValue {
    const [memoVal, setMemoVal] = useState(initial());

    useAdvEffect(() => {
        const newVal = factory();
        setMemoVal((oldVal) => {
            if (
                (compareFunc != undefined && !compareFunc(oldVal, newVal)) ||
                !deepCompare(oldVal, newVal, true)
            )
                return newVal;
            return oldVal;
        });
        // only check dependencies
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [...deps]);

    return memoVal;
};

import { DateToUnix, UnixToDateTime } from "@utils/date";

/* do not change; DateRangeType */
export enum TAdvDatePickerRangeType {
    Day = "day",
    Week = "week",
    Month = "month",
    WorkWeek = "workweek",
}
export enum TAdvDatePickerValueFormat {
    Unix = "unix",
    SQL = "sql",
    Week = "week",
    Month = "month",
}
export enum TAdvDatePickerDisplayFormat {
    LocaleDate = "localeDate",
    Week = "week",
    Month = "month",
    Unix = "unix",
}

/** Konvertiert das ausgewählte Datum in das entsprechende Format */
export function DateToStr(format: TAdvDatePickerValueFormat, date?: Date): string | undefined {
    if (date === undefined) return undefined;
    switch (format) {
        case TAdvDatePickerValueFormat.Unix:
            return DateToUnix(date.clearTime()).toString();
        case TAdvDatePickerValueFormat.SQL:
            return date.toString("yyyy-MM-dd");
        case TAdvDatePickerValueFormat.Week:
            return `${date.getFullYear()}/${date.getWeek().toString().padStart(2, "0")}`;
        case TAdvDatePickerValueFormat.Month:
            // Bei getMonth ist Januar = 0, in AdvanTex ist Januar aber 1, deswegen hier 1 aufschlagen
            return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, "0")}`;
        default:
            throw Error(`DatePickerValueFormat ${String(format)} not implemented!`);
    }
}
/** Versucht den String im angegebenen Format als Datum zu parsen. */
export function StrToDate(
    format: TAdvDatePickerValueFormat,
    str?: string,
    fallbackDate?: Date,
): Date | undefined {
    if (str === undefined || str == "") return fallbackDate;
    switch (format) {
        case TAdvDatePickerValueFormat.Unix:
            if (isNaN(Number(str))) return fallbackDate;
            try {
                return UnixToDateTime(Number(str)).clearTime();
            } catch (ex) {
                console.error(ex);
                return fallbackDate;
            }
        case TAdvDatePickerValueFormat.SQL:
            if (str.indexOf("/") > -1 && str.length == 7) return fallbackDate; // <Jahr>/<Woche> wird auch korrekt als Datum geparsed...
            try {
                return Date.parse(str);
            } catch (ex) {
                console.error(ex);
                return fallbackDate;
            }
        // <Jahr>/<Woche>
        case TAdvDatePickerValueFormat.Week:
            if (str.indexOf("/") == -1 || str.length != 7) return fallbackDate;
            const year = Number(str.substring(0, 4));
            const week = Number(str.substring(5, 7));
            const date = new Date();
            date.setFullYear(year);
            date.setWeek(week);
            return date;
        // <Jahr>-<Monat>
        case TAdvDatePickerValueFormat.Month:
            if (str.indexOf("-") == -1 || str.length != 7) return fallbackDate;
            const yearM = Number(str.substring(0, 4));
            const monthM = Number(str.substring(5, 7));
            const dateM = new Date();
            dateM.setFullYear(yearM);
            // Bei setMonth ist Januar = 0, in AdvanTex ist Januar aber 1, deswegen hier 1 abziehen
            dateM.setMonth(monthM - 1);
            return dateM;
        default:
            throw Error(`DatePickerValueFormat ${String(format)} not implemented!`);
    }
}

import {
    ISpinnerProps,
    ISpinnerStyleProps,
    ISpinnerStyles,
    IStyleFunctionOrObject,
    Spinner,
} from "@fluentui/react";
import { useT } from "@hooks/language/useTranslation";
import { useAdvEffect } from "@hooks/react-overload/useAdvEffect";
import useAdvComponent from "@hooks/useAdvComponent";
import useAdvTheme from "@hooks/useAdvTheme";
import { deepCompareJSXProps } from "@utils/deep-compare";
import { combineStyles } from "@utils/styling";
import React, { useMemo, useRef, useState } from "react";

export type TAdvSpinnerStyles = ISpinnerStyles; /* do not change */
export type TAdvSpinnerStyleProps = ISpinnerStyleProps; /* do not change */
export enum EAdvSpinnerSize {
    xSmall = 0,
    small = 1,
    medium = 2,
    large = 3,
    xLarge = 4,
    xxLarge = 5,
    xxxLarge = 6,
}

export enum EAdvSpinnerThickness {
    xThin,
    thin,
    normal,
    thick,
    xThick,
}

const spinnerSizeToDiameter = (size: EAdvSpinnerSize) => {
    return [16, 16, 20, 28, 36, 44, 52][Number(size)];
};
const spinnerThicknessToWidth = (thickness: EAdvSpinnerThickness) => {
    return [1.0, 1.5, 2.0, 2.5, 3.5][Number(thickness)];
};

export type TAdvSpinnerDotOptions = {
    min: number;
    max: number;
    dotChar: string;
    interval: number;
};

export type TAdvSpinnerProps = Omit<ISpinnerProps, "styles" | "size" | "children"> & {
    styles?: IStyleFunctionOrObject<TAdvSpinnerStyleProps, TAdvSpinnerStyles>;
    /** Größe des Spinners */
    size?: EAdvSpinnerSize;
    /** Dicke des Kreises */
    thickness?: EAdvSpinnerThickness;

    /**
     * Sollen fortlaufende "..." an das Label angefügt werden?
     * @important Löst ein regelmäßiges re-render aus!
     */
    withDots?: boolean | TAdvSpinnerDotOptions;
    // a context specializing the translation used
};
/**
 * Wrapper für einen ``Spinner``
 * @link https://developer.microsoft.com/en-us/fluentui#/controls/web/spinner
 */
const AdvSpinnerComp = ({
    size = EAdvSpinnerSize.medium,
    thickness = EAdvSpinnerThickness.normal,
    styles,
    label = "",

    withDots = false,
    ...props
}: TAdvSpinnerProps) => {
    useAdvComponent(AdvSpinnerComp, props);

    const { t, hasErr } = useT(label);
    const [internalLabel, setInternalLabel] = useState<string | undefined>(t);
    const dotCount = useRef<number>(0);

    useAdvEffect(() => {
        if (withDots != false) {
            const {
                min = 0,
                max = 3,
                dotChar = ".",
                interval: ms = 500,
            } = typeof withDots == "object" ? withDots : {};

            const interval = setInterval(() => {
                if (dotCount.current >= max + 1) dotCount.current = min;
                setInternalLabel(`${t ?? ""}${"".padEnd(dotCount.current, dotChar)}`);
                dotCount.current++;
            }, ms);

            return () => clearInterval(interval);
        } else {
            setInternalLabel(t);
        }
    }, [withDots, t]);

    const customCircleDiameter = spinnerSizeToDiameter(size);
    const customBorderWidth = spinnerThicknessToWidth(thickness);
    const customSizeStyle: ISpinnerStyles = useMemo(() => {
        return {
            circle: {
                width: `${customCircleDiameter}px`,
                height: `${customCircleDiameter}px`,
                borderWidth: `${customBorderWidth}px`,
            },
        };
    }, [customBorderWidth, customCircleDiameter]);

    const theme = useAdvTheme();

    const stylesMemo = useMemo(() => {
        let res = combineStyles(customSizeStyle, styles);
        if (hasErr)
            res = combineStyles(res, {
                root: { ...theme.custom.textNotTranslated },
            });
        return res;
    }, [customSizeStyle, hasErr, styles, theme.custom.textNotTranslated]);
    return <Spinner label={internalLabel} size={size as any} {...props} styles={stylesMemo} />;
};

const AdvSpinner = React.memo(AdvSpinnerComp, deepCompareJSXProps);
export default AdvSpinner;

import { advlog } from "@utils/logging";

// v use the real effect here, so this can be used in the adv variant
// eslint-disable-next-line no-restricted-imports
import { useEffect } from "react";
import { usePrevious } from "./usePrevious";

/**
 * For debuggings changes in useEffect dependencies
 *
 * Is used just like useEffet. Logs changes of the depending values to the console
 * The index in the log is the index of the dependency
 * @link https://stackoverflow.com/questions/55187563/determine-which-dependency-array-variable-caused-useeffect-hook-to-fire
 */

export const useEffectDebugger = (
    logName: string,
    effectHook: any,
    dependencies: any,
    dependencyNames: string[] = [],
) => {
    const previousDeps = usePrevious(dependencies, []);

    const changedDeps = (dependencies ?? []).reduce((accum: any, dependency: any, index: any) => {
        if (dependency !== previousDeps[index]) {
            const keyName = dependencyNames[index] || index;
            let isJSONEq = false;
            try {
                isJSONEq = JSON.stringify(previousDeps[index]) == JSON.stringify(dependency);
            } catch (e) {
                // ignore
            }
            if (isJSONEq) {
                // if the JSONs are equal because both are functions, check if they are annonymous functions or bound functions
                if (
                    typeof previousDeps[index] == "function" &&
                    typeof dependency == "function" &&
                    dependency.adv !== 1
                ) {
                    if (dependency.name == "" || dependency.name == "bound ")
                        console.log(
                            "potential unnecessary re-render because of a bound or annonymous function",
                            dependency,
                            dependency.adv,
                        );
                }
            }
            return {
                ...accum,
                [keyName]: {
                    before: previousDeps[index],
                    after: dependency,
                    JSON_equal: isJSONEq,
                },
            };
        }

        return accum;
    }, {});

    if (Object.keys(changedDeps).length) {
        advlog(logName + " [use-effect-debugger] ", changedDeps);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(effectHook, dependencies);
};

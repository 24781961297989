import { CommandBar } from "@fluentui/react";
import useAdvComponent from "@hooks/useAdvComponent";
import { deepCompareJSXProps } from "@utils/deep-compare";
import React from "react";
import { TAdvActionBarProps } from "./types";

const AdvActionBarPureComp = ({ ...props }: TAdvActionBarProps) => {
    useAdvComponent(AdvActionBarPureComp, props);

    return <CommandBar {...props} />;
};

const AdvActionBarPure = React.memo(AdvActionBarPureComp, deepCompareJSXProps);
export default AdvActionBarPure;

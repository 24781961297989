import { IDropdownStyles } from "@fluentui/react";

// Eingebunden in themes/default.ts
export const defaultDropdownStyles: Partial<IDropdownStyles> = {
    root: {},
    dropdown: {},
    // die default height von fluent ui ist 32, und das erzeugt oft nervige scrollbars
    // evtl. regelmäßig checken ob das gefixed wurde
    caretDownWrapper: { height: 31 },
};

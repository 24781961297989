import { useAdvSocketCallback } from "@hooks/useAdvSocketCallback";
import assert from "assert";
import { AtomEffect, DefaultValue, RecoilState, RecoilValue, WrappedValue } from "recoil";

import { getHook } from "../../utils/react-hooks-outside";
import { TDictionaryValue } from "./dictionary-value";
import { TSyncDicts, TWaiter, addToQueue, removeFamilyName } from "./sync-dictionary";

type TSetItem = {
    Name: string;
    Value: string | null;
    /// options are a way to tell the server about special properties of this item
    /// one example is that the item should be saved in a different database table
    Options: Array<string>;
};

type TSendItemPayload = TSetItem;
type TReceiveItemPayload = { Name: string };

export const gStorageErrItemNotFound: string = "item does not exists";
export const gStorageErrItemNotEnoughPermission: string = "no permission";

// Konstanten legen fest, wie lange Anfragen gesammelt werden sollen bevor sie verschickt
// werden. SEND = SetItems, GET = GetItems
// Werte <= 0 deaktivieren diese Logik.
const QUEUE_SEND_TIMEOUT = 0;
const QUEUE_RECV_TIMEOUT = 0;

/** Entfernt den AtomFamily-Name. Beispeiel: dynamicPages__"NewPage" -> NewPage */

type TValueRecvSendQueue = {
    sendQueue: Array<TSendItemPayload>;
    receiveQueue: Array<TReceiveItemPayload>;
    sendQueueTimeout: number;
    receiveQueueTimeout: number;

    recvEventName: string;
    sendEventName: string;
};

/**
 * Diese Funktion stellt 3 {@link AtomEffect}s bereit:
 * - {@link valuesEffect}: Stellt gebündelt Anfragen an den Server: "getitems" (siehe {@link doSendQueue}, {@link doReceiveQueue})
 * - {@link baseValuesEffect}: Stellt eine einzige Anfrage an den Server: "getbaseitems"
 *
 * Diese Effekte können für ein RecoilDictionary benutzt werden.
 */
export const recoilPersistServerDictionary = <TBaseValue extends object, TValue extends TBaseValue>(
    storageName:
        | "pagestorage"
        | "actionstorage"
        | "datasourcestorage"
        | "keystorage"
        | "keystorage_client"
        | "resourcestorage"
        | "resourcestorage_client"
        | "newsstorage",
    sharedSyncStorage: TSyncDicts,
    ignoreLoginStateFunc?: (aKey: string) => boolean,
) => {
    if (typeof window === "undefined") {
        // Server soll nichts machen
        return {
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            valuesEffect: () => {},
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            baseValuesEffect: () => {},
        };
    }

    function getThisStorage() {
        if (!sharedSyncStorage.has(storageName)) {
            const thisStorage = new Map<string, TWaiter>();
            sharedSyncStorage.set(storageName, thisStorage);
            return thisStorage;
        } else {
            const thisStorage = sharedSyncStorage.get(storageName);
            assert(thisStorage != undefined);
            return thisStorage;
        }
    }
    const thisDictSyncer = getThisStorage();

    function getSyncItem(key: string) {
        if (!thisDictSyncer.has(key)) {
            thisDictSyncer.set(key, { promisesQueue: [], curItem: undefined });
        }
        const item = thisDictSyncer.get(key);
        assert(item != undefined);
        return item;
    }

    const valueQueues: TValueRecvSendQueue = {
        sendQueue: new Array<TSendItemPayload>(),
        receiveQueue: new Array<TReceiveItemPayload>(),

        sendQueueTimeout: -1,
        receiveQueueTimeout: -1,

        recvEventName: "getitems",
        sendEventName: "setitems",
    };

    const baseValueQueues: TValueRecvSendQueue = {
        sendQueue: new Array<TSendItemPayload>(),
        receiveQueue: new Array<TReceiveItemPayload>(),

        sendQueueTimeout: -1,
        receiveQueueTimeout: -1,

        recvEventName: "getbaseitems",
        sendEventName: "",
    };

    let socket: ReturnType<typeof useAdvSocketCallback> | undefined = undefined;

    // Die Hooks nur einmal holen
    const getHooks = async (): Promise<void> => {
        socket = await getHook("useAdvSocketCallback");
    };
    const getHooksPromise = getHooks();

    /** Alle Daten (Values) auf einmal versenden */
    const doSendQueue = async (queues: TValueRecvSendQueue) => {
        await getHooksPromise;
        assert(socket);

        const items = [...queues.sendQueue];
        const payload = { Items: items };

        queues.sendQueue = [];
        clearTimeout(queues.sendQueueTimeout);
        queues.sendQueueTimeout = -1;

        if (queues.sendEventName != "")
            await socket.sendCallbackRequest(storageName, queues.sendEventName, payload);
    };

    /** Alle Daten (Values) auf einmal anforndern */
    const doReceiveQueue = async (queues: TValueRecvSendQueue) => {
        await getHooksPromise;
        assert(socket);

        const recvQueueCopy = [...queues.receiveQueue];
        queues.receiveQueue = [];
        clearTimeout(queues.receiveQueueTimeout);
        queues.receiveQueueTimeout = -1;

        // login state ignored
        {
            const names = recvQueueCopy
                .filter(
                    (val) => ignoreLoginStateFunc != undefined && ignoreLoginStateFunc(val.Name),
                )
                .map((item) => {
                    return { Name: item.Name, Payload: [] };
                });
            const payload = { Names: names };

            if (names.length > 0)
                await socket.sendCallbackRequest(storageName, queues.recvEventName, payload, true);
        }
        // normal keys
        {
            const names = recvQueueCopy
                .filter(
                    (val) => ignoreLoginStateFunc == undefined || !ignoreLoginStateFunc(val.Name),
                )
                .map((item) => {
                    return { Name: item.Name, Payload: [] };
                });
            const payload = { Names: names };

            if (names.length > 0)
                await socket.sendCallbackRequest(storageName, queues.recvEventName, payload);
        }
    };

    const atomFamilyEffectImpl = function <T>(
        queues: TValueRecvSendQueue,
        {
            onSet,
            node,
            trigger,
            resetSelf,
        }: {
            node: RecoilState<TDictionaryValue<TDictionaryValue<T>>>;
            trigger: "set" | "get";

            // Call synchronously to initialize value or async to change it later
            setSelf: (
                param:
                    | TDictionaryValue<T>
                    | DefaultValue
                    | Promise<TDictionaryValue<T> | DefaultValue>
                    | WrappedValue<TDictionaryValue<T>>
                    | ((
                          param: TDictionaryValue<T> | DefaultValue,
                      ) => TDictionaryValue<T> | DefaultValue | WrappedValue<TDictionaryValue<T>>),
            ) => void;
            resetSelf: () => void;

            // Subscribe callbacks to events.
            // Atom effect observers are called before global transaction observers
            onSet: (
                param: (
                    newValue: TDictionaryValue<T>,
                    oldValue: TDictionaryValue<T> | DefaultValue,
                    isReset: boolean,
                ) => void,
            ) => void;
            getPromise: <K>(recoilValue: RecoilValue<K>) => Promise<K>;
        },
    ) {
        const nodeKey = removeFamilyName(node.key);
        const syncItem = getSyncItem(nodeKey);

        if (nodeKey == "" || nodeKey == null || nodeKey == undefined) {
            syncItem.curItem = undefined;
            resetSelf();
            return;
        }

        if (trigger === "get") {
            const loadValuesAsync = async () => {
                await getHooksPromise;
                assert(socket);

                // designers might add this value, an effect is not needed then
                const thisNode = syncItem.curItem;

                if (
                    thisNode != undefined &&
                    (thisNode.IsLoaded() || thisNode.__internalIgnoreLoading)
                )
                    return;

                // console.debug("RECEIVING %s", nodeKey);
                queues.receiveQueue.push({
                    Name: nodeKey,
                });

                if (queues.receiveQueueTimeout == -1) {
                    queues.receiveQueueTimeout = Number(
                        // eslint-disable-next-line @typescript-eslint/no-misused-promises
                        setTimeout(doReceiveQueue.bind(null, queues), QUEUE_RECV_TIMEOUT),
                    );
                }
            };

            addToQueue(nodeKey, syncItem, loadValuesAsync);
        }

        onSet(
            (
                newValue: TDictionaryValue<T>,
                oldValue: TDictionaryValue<T> | DefaultValue,
                isReset: boolean,
            ) => {
                let saveOptions: Array<String> = [];
                let newVal: string | null | undefined;
                if (isReset) assert(false, "don't reset dictionary values. overwrite them instead");
                // newVal = undefined;
                else newVal = newValue.IsLoaded() ? JSON.stringify(newValue.Get()) : undefined;

                if (newValue.IsLoaded()) saveOptions = newValue.__internalSaveOptions;

                // advlog("ONSET", nodeKey, { isReset, newValue, oldValue, equals: newValue == oldValue });
                // FIXME newValue ist immer ungleich oldValue (zwei unterschiedliche Objekte)
                if (
                    (oldValue instanceof DefaultValue ||
                        isReset ||
                        !oldValue.IsLoaded() ||
                        (!newValue.IsLoaded() && oldValue.IsLoaded()) ||
                        newValue.Get() != oldValue.Get()) &&
                    !newValue.__internalIsSilentSet
                ) {
                    const saveValuesAsync = async function () {
                        await getHooksPromise;
                        assert(socket);

                        const sendPayload = {};

                        queues.sendQueue.push({
                            Name: nodeKey,
                            Value: newVal != undefined ? newVal : "",
                            Options: saveOptions,
                            ...sendPayload,
                        } as TSetItem);

                        if (queues.sendQueueTimeout == -1) {
                            queues.sendQueueTimeout = Number(
                                // eslint-disable-next-line @typescript-eslint/no-misused-promises
                                setTimeout(doSendQueue.bind(null, queues), QUEUE_SEND_TIMEOUT),
                            );
                        }
                    };
                    const syncItem = getSyncItem(nodeKey);
                    syncItem.curItem = newValue;
                    addToQueue(nodeKey, syncItem, saveValuesAsync);
                }
            },
        );
    };

    const valuesEffect: AtomEffect<TDictionaryValue<TValue>> = (args: any) => {
        atomFamilyEffectImpl<TValue>(valueQueues, args);
    };

    const baseValuesEffect: AtomEffect<TDictionaryValue<TBaseValue>> = (args: any) => {
        atomFamilyEffectImpl<TBaseValue>(baseValueQueues, args);
    };

    return { valuesEffect, baseValuesEffect };
};

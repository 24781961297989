import { TAdvDropdownItem } from "@components/inputs/dropdown";
import { TDictionaryValue } from "@data/persist/dictionary-value";
import { recoilPersistServerDictionary } from "@data/persist/server-dictionary";
import { EFieldDataType } from "@hooks/useAdvDataProviderFactory";
import { selector, selectorFamily } from "recoil";

import { replaceDatasourcePrefix } from "./designer/file";
import { TSyncDicts } from "./persist/sync-dictionary";
import { CreateRecoilDictionary } from "./utils/recoil-dictionary";

// keep in sync with server
export enum EAdvDatasourceJoinTypes {
    dsInnerJoin = 0,
    dsOuterJoin = 1,
    dsLeftJoin = 2,
    dsRightJoin = 3,
}

export const gDatasourceJoinTypeNames = ["Inner join", "Full join", "Left join", "Right join"];

// keep in sync with server
export enum EAdvDatasourceSQLOperatorTypes {
    dsOperatorEquals = 0,
    dsOperatorNotEquals = 1,
    dsOperatorSmaller = 2,
    dsOperatorGreater = 3,
    dsOperatorSmallerEquals = 4,
    dsOperatorGreaterEquals = 5,
}

export const gDatasourceSQLOperatorNames = ["=", "<>", "<", ">", "<=", ">="];

export type TAdvDatasourceJoinCond = {
    LeftTableColumnName: string;
    RightTableColumnName: string;
    SQLOperator: EAdvDatasourceSQLOperatorTypes;
};

export type TAdvDatasourceJoin = {
    JoinType: EAdvDatasourceJoinTypes;
    TableNameLeft: string;
    TableNameRight: string;
    Conditions: Array<TAdvDatasourceJoinCond>;
};

export type TAdvDatasourceFilterOption = {
    Name: string;
    Cond: string;
    ActiveByDefault: boolean;
    Required?: boolean;
    Early?: boolean; //greift dieser Filter vor Aggregatsfunktionen
};

export type TAdvDatasourceFilterCategory = {
    Name: string;
    Options: Array<TAdvDatasourceFilterOption>;
};

export type TAdvDatasourceOptions = {
    SelectDistinct: boolean;
    LiveSichDisabled: boolean;
    TopAmount: number;
};

export enum EAdvDatasourceFieldMode {
    None = "",
    // select also makes the field available for codition
    Select = "select",
    Condition = "condition",
}

export type TAdvDatasourceField = {
    Name: string;
    DBColumnName: string;
    Mode: EAdvDatasourceFieldMode; // "", "select", "condition"
    IDColumn: boolean;
    Condition: string;
    Aggregation: string;
    Having: string;
    TryLiveSich: boolean;
    /** Alternative Namen für das Feld. Werden als zusätzliche Spalten selected. */
    Aliases: string[];
    DataType: EFieldDataType;
};

export type TAdvDatasourceTable = {
    Name: string;
    DBTableName: string;
    Fields: Array<TAdvDatasourceField>;
};

export type TAdvDatasourceBase = {
    Name: string;
    PageName?: string | undefined;
};

export type TAdvDatasource = TAdvDatasourceBase & {
    Tables: Array<TAdvDatasourceTable>;
    Filters: Array<TAdvDatasourceFilterCategory>;
    Joins: Array<TAdvDatasourceJoin>;
    Options: TAdvDatasourceOptions;
};

const dictSyncer: TSyncDicts = new Map();

const { valuesEffect, baseValuesEffect } = recoilPersistServerDictionary<
    TAdvDatasourceBase,
    TAdvDatasource
>("datasourcestorage", dictSyncer);

// Key: DatasourceName, Value: TDataSource
const datasourceDictionary = CreateRecoilDictionary<TAdvDatasource>("datasources", {
    ValuesEffects: [valuesEffect],
});
const datasourceBaseDictionary = CreateRecoilDictionary<TAdvDatasourceBase>("datasourcesbase", {
    ValuesEffects: [baseValuesEffect],
});

export const recoilDatasource = {
    dictionary: datasourceDictionary,
    baseDictionary: datasourceBaseDictionary,

    Datasources: datasourceDictionary.values,
    DatasourceNames: datasourceBaseDictionary.valueKeys,
    BaseDatasources: datasourceBaseDictionary.values,

    clearAndSetDatasources: datasourceDictionary.setItems,
    addOrSetDatasource: datasourceDictionary.addOrSetItem,
    removeDatasource: datasourceDictionary.removeItem,
    replaceDatasource: datasourceDictionary.replaceItem,

    addOrSetDatasourceBase: datasourceBaseDictionary.addOrSetItem,
    removeDatasourceBase: datasourceBaseDictionary.removeItem,

    // get the filters of a datasource that can map a variable
    mappablefilterListOfDatasource: selectorFamily({
        key: "filterListOfDatasource",
        get:
            (datasourceName: string) =>
            ({ get }) => {
                const ret: TAdvDropdownItem<any>[] = [];
                if (datasourceName != undefined) {
                    const datasource = get(recoilDatasource.Datasources(datasourceName as string));
                    if (datasource.IsLoaded()) {
                        const filters = datasource.Get().Filters;
                        for (const filter of filters) {
                            if (filter.Name == "") {
                                for (const filterVal of filter.Options) {
                                    if (filterVal.Cond.toUpperCase().includes("$VALUE$")) {
                                        ret.push({
                                            key: filterVal.Name,
                                            text: filterVal.Name,
                                            data: filterVal.Name,
                                        });
                                    }
                                }
                            }
                        }
                    }
                }
                return ret;
            },
    }),

    datasourceArrayFromNames: selector({
        key: "datasourceArray",
        get: ({ get }) => {
            const temp: TAdvDatasourceBase[] = [];
            const datasourceKeyNames = get(recoilDatasource.DatasourceNames);
            for (const datasourceName of datasourceKeyNames) {
                const datasource = get(recoilDatasource.BaseDatasources(datasourceName));
                if (datasource.IsLoaded()) temp.push(datasource.Get());
            }
            return temp;
        },
    }),

    datasourceBaseArrayFilteredPage: selectorFamily<
        TDictionaryValue<TAdvDatasourceBase>[],
        string | undefined
    >({
        key: "datasourceBaseArray",
        get:
            (pageName) =>
            ({ get }) => {
                let temp: TDictionaryValue<TAdvDatasourceBase>[] = [];
                const datasourceKeyNames = get(recoilDatasource.DatasourceNames);
                for (const datasourceName of datasourceKeyNames) {
                    const datasource = get(recoilDatasource.BaseDatasources(datasourceName));
                    if (datasource.IsLoaded()) temp.push(datasource);
                }
                temp = temp
                    .filter(
                        (val) =>
                            ((val.Get().PageName == "" || val.Get().PageName == undefined) &&
                                pageName == "") ||
                            val.Get().PageName == pageName ||
                            pageName == undefined,
                    )
                    .sort((val1, val2) =>
                        replaceDatasourcePrefix(val1.Get().Name).localeCompare(
                            replaceDatasourcePrefix(val2.Get().Name),
                        ),
                    ); // TODO! do we want sorting?
                return temp;
            },
    }),

    datasourcesLength: selector({
        key: "datasourcesLength",
        get: ({ get }) => {
            const datasourceKeyNames: string[] = get(recoilDatasource.DatasourceNames);
            return datasourceKeyNames.length;
        },
    }),

    datasourceArrayFilteredPage: selectorFamily<TAdvDatasource[], string | undefined>({
        key: "datasourceArrayFiltered",
        get:
            (pageName) =>
            ({ get }) => {
                let temp: TAdvDatasource[] = [];
                const datasourceKeyNames = get(recoilDatasource.DatasourceNames);
                for (const datasourceName of datasourceKeyNames) {
                    const datasource = get(recoilDatasource.Datasources(datasourceName));
                    if (datasource.IsLoaded()) temp.push(datasource.Get());
                }
                temp = temp.filter(
                    (val) =>
                        ((val.PageName == "" || val.PageName == undefined) && pageName == "") ||
                        val.PageName == pageName ||
                        pageName == undefined,
                );
                return temp;
            },
    }),
};

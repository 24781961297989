import deepCopy from "@utils/deep-copy";

/**
 * Sortiert die Items.
 * Ein *NULL*-Item wird immer an's Ende sortiert.
 * @param items Die zu sortierenden Items
 * @param columnKey Nach welcher Eigenschaft soll sortiert werden?
 * @example
 * items = [ { id: 1, name: "1"} ]
 * sortedItems = _sort(items, "name");
 */
export function _copyAndSort<T>(
    items: T[],
    sortColumns: { columnKey: string; isSortedDescending?: boolean }[],
): T[] {
    let sortedItems = deepCopy(items);
    const sortedCols = deepCopy(sortColumns);
    if (sortedCols.length == 0) return sortedItems;

    const { columnKey, isSortedDescending } = sortedCols[0];

    const key = columnKey as keyof T;
    sortedItems.sort((a: T, b: T) =>
        a == null || b == null || (isSortedDescending ?? false ? a[key] < b[key] : a[key] > b[key])
            ? 1
            : -1,
    );

    if (sortedCols.length > 1) {
        sortedCols.shift();
        const listOfUniqueFields: any[] = [];
        sortedItems.forEach((val) => {
            if (!listOfUniqueFields.includes(val == null ? null : val[key])) {
                listOfUniqueFields.push(val == null ? null : val[key]);
            }
        });
        let newT: T[] = [];
        for (const uniqueField of listOfUniqueFields) {
            const filteredTs = sortedItems.filter(
                (val) =>
                    (val == null && uniqueField == null) ||
                    (val != null && val[key] == uniqueField),
            );
            newT = newT.concat(_copyAndSort(filteredTs, sortedCols));
        }
        sortedItems = newT;
    }

    return sortedItems;
}

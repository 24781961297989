/* eslint-disable react-hooks/exhaustive-deps */
import { TRecoilDictionary } from "@data/utils/recoil-dictionary";
import { useAdvRecoilTransaction } from "@hooks/recoil-overload/useAdvRecoilTransaction";
import { SerializableParam } from "recoil";

/** @see {@link AdvRecoilDictionary} */
function useAdvRecoilDictionary<TValue, TKey extends SerializableParam = string>(
    dictionary: TRecoilDictionary<TValue, TKey>,
    deps?: ReadonlyArray<unknown>,
) {
    return {
        /** @see {@link dictionary.addOrSetItem} */
        addOrSetItem: useAdvRecoilTransaction(
            dictionary.addOrSetItem,
            deps ?? [dictionary.addOrSetItem],
        ),
        /** @see {@link dictionary.addItems} */
        addItems: useAdvRecoilTransaction(dictionary.addItems, deps ?? [dictionary.addItems]),
        /** @see {@link dictionary.removeItem} */
        removeItem: useAdvRecoilTransaction(dictionary.removeItem, deps ?? [dictionary.removeItem]),
        /** @see {@link dictionary.replaceItem} */
        replaceItem: useAdvRecoilTransaction(
            dictionary.replaceItem,
            deps ?? [dictionary.replaceItem],
        ),
        /** @see {@link dictionary.clear} */
        clear: useAdvRecoilTransaction(dictionary.clear, deps ?? [dictionary.clear]),
        /**
         * Only for internal use
         * @see {@link dictionary.addItemsSilentlyInternal}
         * */
        addItemsSilentlyInternal: useAdvRecoilTransaction(
            dictionary.addItemsSilentlyInternal,
            deps ?? [dictionary.addItemsSilentlyInternal],
        ),
        /**
         * Only for internal use
         * @see {@link dictionary.removeItemsSilentlyInternal}
         * */
        removeItemsSilentlyInternal: useAdvRecoilTransaction(
            dictionary.removeItemsSilentlyInternal,
            deps ?? [dictionary.removeItemsSilentlyInternal],
        ),
    };
}

export default useAdvRecoilDictionary;

import { TAdvThemeStorage } from "@feature/settings/theme-types";
import assert from "assert";
import { RecoilValueReadOnly } from "recoil";

import { TAdvNavStorage } from "@feature/nav/types";
import { gNavFilePrefix, gThemeFilePrefix } from "./designer/file";
import { TDictionaryValue } from "./persist/dictionary-value";
import { recoilPersistLocalStorage } from "./persist/localstorage";
import { recoilPersistServerDictionary } from "./persist/server-dictionary";
import { TSyncDicts } from "./persist/sync-dictionary";
import { CreateRecoilDictionary } from "./utils/recoil-dictionary";

export type TKeyStorageBaseItem = {
    // name is mostly unused, except for e.g. themes, parsed by server too (for base values)
    Name: string;
    // additional information about the key storage, e.g. themes have a "disabled" flag
    Additional: string[];
};

export type TKeyStorageItem<T> = TKeyStorageBaseItem & {
    Value: T;
};

const keyCacheFilter = (key: string) => {
    if (key.startsWith(gNavFilePrefix)) return false;
    return true;
};

const keyIgnoreLoginStateFilter = (aKey: string) => {
    return !["user-globalTheme"].includes(aKey) && !aKey.includes(gNavFilePrefix);
};

const dictSyncer: TSyncDicts = new Map();

const { baseValuesEffect, valuesEffect } = recoilPersistServerDictionary<
    TKeyStorageBaseItem,
    TKeyStorageItem<any>
>("keystorage", dictSyncer, keyIgnoreLoginStateFilter);
const { persistAtom: persistAtom } = recoilPersistLocalStorage<string, TKeyStorageItem<any>>(
    "keystorage",
    dictSyncer,
    keyCacheFilter,
);

const dict = CreateRecoilDictionary<TKeyStorageItem<any>>("keys", {
    ValuesEffects: [persistAtom, valuesEffect],
});
const dictBase = CreateRecoilDictionary<TKeyStorageBaseItem>("keysbase", {
    ValuesEffects: [baseValuesEffect],
});

const recoilKeystorageSystem = {
    dictionary: dict,
    baseDictionary: dictBase,

    KeyValues: dict.values,
    Keys: dictBase.valueKeys,

    ThemeValues: (key: string) => {
        assert(
            key == "" || key.startsWith(gThemeFilePrefix),
            "key must start with file prefix for themes but the key was: " + key,
        );
        return dict.values(key) as RecoilValueReadOnly<
            TDictionaryValue<TKeyStorageItem<TAdvThemeStorage>>
        >;
    },

    NavValues: (key: string) => {
        assert(
            key == "" || key.startsWith(gNavFilePrefix),
            "key must start with file prefix for nav",
        );
        return dict.values(key) as RecoilValueReadOnly<
            TDictionaryValue<TKeyStorageItem<TAdvNavStorage>>
        >;
    },
};

const { baseValuesEffect: baseValuesEffectClient, valuesEffect: valuesEffectClient } =
    recoilPersistServerDictionary<TKeyStorageBaseItem, TKeyStorageItem<any>>(
        "keystorage_client",
        dictSyncer,
        keyIgnoreLoginStateFilter,
    );
const { persistAtom: persistAtomClient } = recoilPersistLocalStorage<string, TKeyStorageItem<any>>(
    "keystorage_client",
    dictSyncer,
    keyCacheFilter,
);

const dictClient = CreateRecoilDictionary<TKeyStorageItem<any>>("keys_client", {
    ValuesEffects: [persistAtomClient, valuesEffectClient],
});
const dictBaseClient = CreateRecoilDictionary<TKeyStorageBaseItem>("keysbase_client", {
    ValuesEffects: [baseValuesEffectClient],
});

const recoilKeystorageClient = {
    dictionary: dictClient,
    baseDictionary: dictBaseClient,

    KeyValues: dictClient.values,
    Keys: dictBaseClient.valueKeys,

    ThemeValues: (key: string) => {
        assert(
            key == "" || key.startsWith(gThemeFilePrefix),
            "key must start with file prefix for themes but the key was: " + key,
        );
        return dictClient.values(key) as RecoilValueReadOnly<
            TDictionaryValue<TKeyStorageItem<TAdvThemeStorage>>
        >;
    },

    NavValues: (key: string) => {
        assert(
            key == "" || key.startsWith(gNavFilePrefix),
            "key must start with file prefix for nav",
        );
        return dictClient.values(key) as RecoilValueReadOnly<
            TDictionaryValue<TKeyStorageItem<TAdvNavStorage>>
        >;
    },
};

export const recoilKeystorage = (isClient: boolean) => {
    return isClient ? recoilKeystorageClient : recoilKeystorageSystem;
};

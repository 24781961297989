import { useAdvCallback } from "@hooks/react-overload/useAdvCallback";
import { useDebugValue } from "react";

let isLoggedInState = false;
let isSessionSetState = false;

export function useWaitForLogin() {
    useDebugValue("LoggedIn: " + String(isLoggedInState));

    const setLoggedIn = useAdvCallback(function (value: boolean) {
        isLoggedInState = value;
    }, []);

    const setSessionSet = useAdvCallback(function (value: boolean) {
        isSessionSetState = value;
    }, []);

    const disconnectSession = useAdvCallback(
        function () {
            setSessionSet(false);
        },
        [setSessionSet],
    );

    const isLoggedIn = useAdvCallback(function () {
        return isLoggedInState;
    }, []);

    const isSessionSet = useAdvCallback(function () {
        return isSessionSetState;
    }, []);

    const waitForLogin = useAdvCallback(function () {
        return new Promise<boolean>((resolve) => {
            if (isLoggedInState) {
                resolve(true);
            } else {
                const intv = setInterval(() => {
                    if (isLoggedInState == true && isSessionSetState == true) {
                        clearInterval(intv);
                        resolve(true);
                    }
                }, 10);
            }
        });
    }, []);

    return {
        waitForLogin,
        setLoggedIn,
        setSessionSet,
        disconnectSession,
        isSessionSet,
        isLoggedIn,
    };
}

// v use the real effect here, so this can be used in the adv variant
// eslint-disable-next-line no-restricted-imports
import { useEffect, useRef } from "react";

export const usePrevious = (value: any, initialValue: any) => {
    const ref = useRef(initialValue);
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
};

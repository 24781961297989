import { buildUniqueContractID, gActiveContracts } from "@components/dynamic/contracts/contracts";
import { EAdvActionParameterDataTypes, recoilAction, TAdvActionBase } from "@data/action";
import {
    buildUniqueProviderID,
    dataproviderSelectedKeys,
} from "@data/dataprovider/data-provider-server";
import { recoilPageState } from "@data/dynamic-page";
import {
    buildPageIDForVariableID,
    recoilPageVariables,
    TPageParameterValue,
} from "@data/parameters";
import {
    gStorageErrItemNotEnoughPermission,
    gStorageErrItemNotFound,
} from "@data/persist/server-dictionary";
import { useAdvCallback } from "@hooks/react-overload/useAdvCallback";
import { useAdvEffect } from "@hooks/react-overload/useAdvEffect";
import useAdvRecoilValue from "@hooks/recoil-overload/useAdvRecoilValue";
import useAdvSetRecoilState from "@hooks/recoil-overload/useAdvSetRecoilState";
import { ExecuteWebActions, TActionMappedValues } from "@logic/webactions/actions";
import { EActionLogic } from "@logic/webactions/types";
import { advlog } from "@utils/logging";
import { parseToBoolean } from "@utils/to-bool";
import { useMemo, useState } from "react";

import { gPageParamResultAutoVar } from "@components/dynamic/parameter-mapping/page-parameter";
import { useAdvRouter } from "@hooks/page/useAdvRouter";
import { TPageInfo } from "@pages/dynamic";
import {
    EAdvWebActionType,
    gAdvWebActionParamAsKey,
    gAdvWebActionParamContractContractKey,
    gAdvWebActionParamKey,
    gAdvWebActionParamTypeKey,
    gAdvWebActionParamTypeValueKey,
    TAdvWebActionParams,
} from "./useAdvWebAction.types";

export const gWebActionTypes = [
    // the values of this array is used in the server code. Keep it in sync
    "No web action",
    "Web action",
];

export const AdvWebActionTypeStringToEnum = (webActionStr: string | EAdvWebActionType) => {
    if (typeof webActionStr == "string") {
        const index = gWebActionTypes.indexOf(webActionStr);
        return index == -1 ? EAdvWebActionType.WebActionTypeUser : (index as EAdvWebActionType);
    } else {
        return webActionStr;
    }
};

export const IsWebActionTrivial = (params: TAdvWebActionParams | undefined) => {
    return (
        params == undefined ||
        AdvWebActionTypeStringToEnum(params.actionType) == EAdvWebActionType.WebActionTypeUser
    );
};

export const defaultWebActionParametersPerType = (webActionParam: TAdvWebActionParams) => {
    webActionParam.actionParams = {};
    const webActionType =
        typeof webActionParam.actionType == "string"
            ? AdvWebActionTypeStringToEnum(webActionParam.actionType)
            : webActionParam.actionType;
    switch (webActionType) {
        case EAdvWebActionType.WebActionTypeUser:
        // fallthrough
        case EAdvWebActionType.WebActionTypeNormal:
            break;
    }
};

const actionParameterMappingList = (
    actionParameterKeys: string[],
    actionParams: TAdvWebActionParams,
) => {
    const retMap: {
        MapType: "const" | "provider" | "page" | "passed";
        MapName: string;
        MapAs: string;
        MapValue: string | undefined;
    }[] = [];
    let curParam = 0;
    while (
        actionParameterKeys.includes(gAdvWebActionParamTypeKey + curParam.toString()) &&
        actionParameterKeys.includes(gAdvWebActionParamKey + curParam.toString()) &&
        actionParameterKeys.includes(gAdvWebActionParamAsKey + curParam.toString())
    ) {
        retMap.push({
            MapType: actionParams.actionParams[gAdvWebActionParamTypeKey + curParam.toString()]
                .value as "const" | "provider" | "page" | "passed",
            MapName: actionParams.actionParams[gAdvWebActionParamKey + curParam.toString()].value,
            MapAs: actionParams.actionParams[gAdvWebActionParamAsKey + curParam.toString()].value,
            MapValue: actionParameterKeys.includes(
                gAdvWebActionParamTypeValueKey + curParam.toString(),
            )
                ? actionParams.actionParams[gAdvWebActionParamTypeValueKey + curParam.toString()]
                      .value
                : undefined,
        });
        curParam += 1;
    }
    return retMap;
};

export const executeWebAction = (
    user: string,
    userIndex: number,
    actionParamKeys: string[],
    actionParams: TAdvWebActionParams,
    webActionDef: TAdvActionBase,
    providerRecords: any[][],
    pageParameterValues: TPageParameterValue[],
    passedParameterValues: Array<any>,
    pageInfo: TPageInfo,
) => {
    const actionParamMapping = actionParameterMappingList(actionParamKeys, actionParams);
    let mappingValues: TActionMappedValues = [];
    let passedParamOffset = 0;
    actionParamMapping.forEach((val) => {
        if (val.MapType == "const" && val.MapValue != undefined) {
            mappingValues.push({ Name: val.MapAs, Value: val.MapValue });
        } else if (val.MapType == "page") {
            const foundParam = pageParameterValues.find(
                (valP) => valP.pageParamName == val.MapValue,
            );
            if (foundParam != undefined) {
                for (const param of Array.isArray(foundParam.value)
                    ? foundParam.value
                    : [foundParam.value]) {
                    // for page variables push as many page variables as there are records
                    let maxRecords = Number.MIN_SAFE_INTEGER;
                    providerRecords.forEach((pr) => {
                        if (pr.length > maxRecords) maxRecords = pr.length;
                    });

                    if (maxRecords > 1) {
                        while (maxRecords-- > 0)
                            mappingValues.push({ Name: val.MapAs, Value: param });
                    } else {
                        mappingValues.push({ Name: val.MapAs, Value: param });
                    }
                }
            }
        } else if (val.MapType == "provider") {
            for (const selectedProviderValue of providerRecords) {
                if (selectedProviderValue != undefined && selectedProviderValue.length > 0) {
                    for (const dataFields of selectedProviderValue) {
                        if (dataFields != undefined) {
                            for (const actionParam of Object.keys(dataFields)) {
                                if (val.MapName == actionParam) {
                                    mappingValues.push({
                                        Name: val.MapAs,
                                        Value: dataFields[actionParam],
                                    });
                                }
                            }
                        }
                    }
                }
            }
        } else if (val.MapType == "passed") {
            mappingValues.push({
                Name: val.MapAs,
                Value: passedParameterValues[passedParamOffset++],
            });
        }
    });

    const parameters = webActionDef.Parameters;
    const paramKeys = Object.keys(parameters);
    mappingValues = mappingValues.filter((val) => {
        return (
            paramKeys.find((valP) => parameters[valP].Name == val.Name) != undefined ||
            (webActionDef.LogicName == EActionLogic.Return && val.Name == gPageParamResultAutoVar)
        );
    });

    paramKeys.forEach((key) => {
        const findIndex = mappingValues.findIndex(
            (val) => val.Name == webActionDef.Parameters[key].Name,
        );
        if (
            findIndex == -1 &&
            !webActionDef.Parameters[key].Optional &&
            webActionDef.Parameters[key].DefaultValue != undefined
        ) {
            mappingValues.push({
                Name: webActionDef.Parameters[key].Name,
                Value: webActionDef.Parameters[key].DefaultValue,
            });
        }
    });

    mappingValues.forEach((val) => {
        const valInParameter = paramKeys.find((valP) => parameters[valP].Name == val.Name);
        if (valInParameter != undefined) {
            const param = parameters[valInParameter];
            const dataType = param.DataType;
            const dataTypeValues = Object.values(EAdvActionParameterDataTypes);
            const indexOfDataType = dataTypeValues.findIndex((valI) => valI === dataType);
            let dataTypeEnum = EAdvActionParameterDataTypes.Unknown;
            if (indexOfDataType < dataTypeValues.length / 2) {
                dataTypeEnum = dataTypeValues[
                    indexOfDataType + dataTypeValues.length / 2
                ] as EAdvActionParameterDataTypes;
            }
            switch (dataTypeEnum) {
                case EAdvActionParameterDataTypes.Array:
                    {
                        if (!Array.isArray(val.Value)) {
                            const curVal = val.Value;
                            if (typeof curVal == "string") {
                                const newArr: any[] = [];
                                for (let i = 0; i < curVal.length; ++i)
                                    newArr.push(curVal.charAt(i));
                                val.Value = newArr;
                            } else {
                                throw new Error("Fatal error: value was not of type array");
                            }
                        }
                    }
                    break;
                case EAdvActionParameterDataTypes.Bool:
                    {
                        if (typeof val.Value != "boolean") {
                            const curVal = val.Value;
                            if (typeof curVal == "string") {
                                val.Value = parseToBoolean(curVal);
                            } else {
                                throw new Error("Fatal error: value was not of type boolean");
                            }
                        }
                    }
                    break;
                case EAdvActionParameterDataTypes.Int:
                    {
                        if (
                            (typeof val.Value != "number" || !Number.isInteger(val.Value)) &&
                            typeof val.Value != "bigint"
                        ) {
                            const curVal = val.Value;
                            if (typeof curVal == "string") {
                                val.Value = parseInt(curVal);
                            } else {
                                throw new Error("Fatal error: value was not of type int");
                            }
                        }
                    }
                    break;
                case EAdvActionParameterDataTypes.Float:
                    {
                        if (typeof val.Value != "number") {
                            const curVal = val.Value;
                            if (typeof curVal == "string") {
                                val.Value = parseFloat(curVal);
                            } else {
                                throw new Error("Fatal error: value was not of type float");
                            }
                        }
                    }
                    break;
                case EAdvActionParameterDataTypes.String:
                    {
                        if (typeof val.Value != "string") {
                            const curVal = val.Value;
                            if (typeof curVal == "object") {
                                val.Value = JSON.stringify(curVal);
                            } else if (typeof curVal == "function") {
                                throw new Error("Fatal error: value was not of type string");
                            } else {
                                val.Value = curVal.toString();
                            }
                        }
                    }
                    break;
                case EAdvActionParameterDataTypes.Object:
                    {
                        if (typeof val.Value != "object") {
                            throw new Error("Fatal error: value was not of type object");
                        }
                    }
                    break;
                case EAdvActionParameterDataTypes.Unknown:
                    {
                        if (typeof val.Value == undefined) {
                            throw new Error("Fatal error: value was of type undefined");
                        }
                    }
                    break;
                default:
                    throw new Error("Fatal error: value had no datatype");
                    break;
            }
        }
    });

    advlog(
        "Executing action: " +
            webActionDef.ActionLabel +
            " on selected entry " +
            JSON.stringify(providerRecords),
    );
    ExecuteWebActions(
        webActionDef,
        {
            User: user,
            UserIndex: userIndex,
            Parameters: mappingValues,
        },
        pageInfo,
    );
};

/**
 * Bindet eine Action (eine Funktion) je nach type definiert durch @see EAdvWebActionType an den hook
 * @param params die Parameter, die genutzt werden sollen, oder undefined, wenn nichts benutzt werden soll
 * @returns die Funktion und einen Setter der Funktion
 */
export const useAdvWebAction = (
    user: string,
    userIndex: number,
    params: TAdvWebActionParams | undefined,
): [
    string,
    string | undefined,
    (optionalPassedValues?: Array<any>) => boolean,
    boolean,
    boolean,
    boolean,
] => {
    const isAction = useMemo(
        () =>
            params != undefined
                ? [EAdvWebActionType.WebActionTypeNormal].includes(
                      typeof params.actionType == "string"
                          ? AdvWebActionTypeStringToEnum(params.actionType)
                          : (params.actionType as EAdvWebActionType),
                  ) && params.actionName != ""
                : false,
        [params],
    );
    const shouldUseBoundValue = useMemo(() => (isAction ? true : false), [isAction]);

    const getListOfProvidersInActionParameters = useAdvCallback((): string[] => {
        const providerList: string[] = [];
        if (params) {
            const paramKeys = Object.keys(params.actionParams);
            let curParamIndex = 0;
            while (paramKeys.includes(gAdvWebActionParamTypeKey + curParamIndex.toString())) {
                if (
                    params.actionParams[gAdvWebActionParamTypeKey + curParamIndex.toString()]
                        .value == "provider"
                ) {
                    const providerName =
                        params.actionParams[
                            gAdvWebActionParamTypeValueKey + curParamIndex.toString()
                        ].value;
                    if (!providerList.includes(providerName)) providerList.push(providerName);
                }
                ++curParamIndex;
            }
        }
        return providerList;
    }, [params]);

    const { pageInfo } = useAdvRouter();
    const variableID = useMemo(() => buildPageIDForVariableID(pageInfo), [pageInfo]);
    const setPageState = useAdvSetRecoilState(recoilPageState(variableID));

    const providerIDs = useMemo(
        () =>
            getListOfProvidersInActionParameters().map((val) =>
                buildUniqueProviderID(pageInfo, val),
            ),
        [getListOfProvidersInActionParameters, pageInfo],
    );
    const selectedProviderValues = useAdvRecoilValue(dataproviderSelectedKeys(providerIDs ?? []));
    const currentPageParameterValues = useAdvRecoilValue(recoilPageVariables(variableID));

    const paramKeys = useMemo(() => {
        return params && params.actionParams != undefined ? Object.keys(params.actionParams) : [];
    }, [params]);

    const [actionName, setActionName] = useState(params != undefined ? params.actionName : "");
    const [iconName, setIconName] = useState<string | undefined>(undefined);

    const actionUniqueNameParams = useMemo(
        () => (params != undefined && isAction ? params.actionName : ""),
        [isAction, params],
    );
    const [actionUniqueName, setActionUniqueName] = useState("");
    const actionRecoil = useAdvRecoilValue(recoilAction.BaseActions(actionUniqueNameParams));

    const [hasPermission, setHasPermission] = useState(false);

    const contracts = useAdvRecoilValue(gActiveContracts);
    const isDisabled = useMemo(() => {
        if (
            actionRecoil.IsLoaded() &&
            params != undefined &&
            paramKeys.includes(gAdvWebActionParamContractContractKey)
        ) {
            const doesRequireContractInitialized = [
                EActionLogic.RemContractEditRecord.toString(),
                EActionLogic.AddContractEditRecord.toString(),
                EActionLogic.CallContractFunction.toString(),
                EActionLogic.CancelContract.toString(),
                EActionLogic.TryCancelContract.toString(),
                EActionLogic.TryFulfillContract.toString(),
            ].includes(actionRecoil.Get().LogicName);
            const doesRequireContractNotInitialized = [
                EActionLogic.TryInitContract.toString(),
            ].includes(actionRecoil.Get().LogicName);
            const contractName = params.actionParams[gAdvWebActionParamContractContractKey].value;

            const foundContract = contracts.find((val) => {
                const contractID = buildUniqueContractID(pageInfo, contractName);
                return JSON.stringify(val.contractID) == JSON.stringify(contractID);
            });
            if (doesRequireContractInitialized || doesRequireContractNotInitialized) {
                if (doesRequireContractInitialized && foundContract == undefined) {
                    return true;
                }
                if (doesRequireContractNotInitialized && foundContract != undefined) {
                    return true;
                }
                return false;
            }
        }
        return false;
    }, [actionRecoil, contracts, paramKeys, params, pageInfo]);

    useAdvEffect(() => {
        if (actionUniqueNameParams != "") {
            if (actionUniqueName != actionUniqueNameParams) {
                setActionUniqueName(actionUniqueNameParams);
            }
            let hasPerm = true;
            if (actionRecoil.IsLoaded()) {
                advlog("Loaded action button action: " + actionRecoil.Get().ActionLabel);
                setActionName(actionRecoil.Get().ActionLabel);
                const iconName = actionRecoil.Get().IconName;
                setIconName(iconName != "" && iconName != undefined ? iconName : undefined);
            } else if (actionRecoil.HasError()) {
                if (actionRecoil.GetError() == gStorageErrItemNotFound)
                    setActionName("Missing action: " + actionUniqueNameParams);
                if (actionRecoil.GetError() == gStorageErrItemNotEnoughPermission) hasPerm = false;
                else setActionName("Unknown error");
                setIconName(undefined);
            } else if (actionRecoil.IsLoading()) {
                hasPerm = false;
            }
            setHasPermission(hasPerm);
        }
    }, [actionUniqueNameParams, actionUniqueName, actionRecoil]);

    const onButtonClick = useAdvCallback(
        (optionalPassedValues?: Array<any>) => {
            if (params != undefined) {
                const realBindType =
                    typeof params.actionType == "string"
                        ? AdvWebActionTypeStringToEnum(params.actionType)
                        : (params.actionType as EAdvWebActionType);
                if (
                    realBindType == EAdvWebActionType.WebActionTypeNormal &&
                    actionRecoil.IsLoaded() &&
                    selectedProviderValues != undefined
                ) {
                    const webActionDef = actionRecoil.Get();
                    if (
                        [EActionLogic.TryFulfillContract, EActionLogic.ExecContract].includes(
                            webActionDef.LogicName as EActionLogic,
                        )
                    ) {
                        setPageState((old) => {
                            return { ...old, ShowRequiredErrors: true };
                        });
                    } else if (
                        [EActionLogic.TryCancelContract, EActionLogic.CancelContract].includes(
                            webActionDef.LogicName as EActionLogic,
                        )
                    ) {
                        setPageState((old) => {
                            return { ...old, ShowRequiredErrors: false };
                        });
                    }
                    executeWebAction(
                        user,
                        userIndex,
                        paramKeys,
                        params,
                        webActionDef,
                        selectedProviderValues,
                        currentPageParameterValues,
                        optionalPassedValues ?? [],
                        pageInfo,
                    );
                    return true;
                } else {
                    advlog(
                        "Cannot execute action: " +
                            (!actionRecoil.IsLoaded() ? "action recoil was undefined. " : "") +
                            (selectedProviderValues == undefined
                                ? "selected provider value was undefined. "
                                : ""),
                    );
                }
            }
            return false;
        },
        [
            params,
            actionRecoil,
            selectedProviderValues,
            user,
            userIndex,
            paramKeys,
            currentPageParameterValues,
            pageInfo,
            setPageState,
        ],
    );

    return [actionName, iconName, onButtonClick, shouldUseBoundValue, hasPermission, isDisabled];
};

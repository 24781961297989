import AdvText from "@components/data/text/text";
import AdvGrid from "@components/layout/grid/grid";
import AdvGridItem from "@components/layout/grid/grid-item/grid-item";
import AdvModal from "@components/layout/modal/modal";
import AdvStack from "@components/layout/stack";
import AdvStackItem from "@components/layout/stack/stack-item";
import { useAdvCallback } from "@hooks/react-overload/useAdvCallback";
import useAdvRecoilValue from "@hooks/recoil-overload/useAdvRecoilValue";
import useAdvResetRecoilState from "@hooks/recoil-overload/useAdvResetRecoilState";
import useAdvSetRecoilState from "@hooks/recoil-overload/useAdvSetRecoilState";
import useAdvTheme, { TAdvAppliedTheme } from "@hooks/useAdvTheme";
import { useMemo } from "react";
import { atom } from "recoil";

const atomInfos = atom<TAdvInfo[]>({
    key: "advInfos",
    default: [],
});

export type TAdvInfo = {
    Timestamp: Date;
    Header: string;
    Message: string;
    AdditionalInfo?: string;
    DevInfo?: string;
};

const AdvInfoGridItem = ({
    info,
    index,
    theme,
}: {
    info: TAdvInfo;
    index: number;
    theme: TAdvAppliedTheme;
}) => {
    const { Message, AdditionalInfo } = info;
    return (
        <>
            <AdvGridItem key={`info_m_${index}`} column="1" row={index + 1}>
                <AdvText
                    ignoreTranslation
                    label={`${Message}`}
                    allowMultiline
                    styles={{
                        root: {
                            userSelect: "text",
                        },
                    }}
                />
            </AdvGridItem>
            {/* Zusätzliche Info nur anzeigen wenn vorhanden */}
            {AdditionalInfo === undefined ? null : (
                <AdvGridItem key={`info_a_${index}`} column="2" row={index + 1}>
                    <AdvText
                        ignoreTranslation
                        label={`${AdditionalInfo}`}
                        allowMultiline
                        styles={{
                            root: {
                                color: theme.semanticColors.disabledText,
                            },
                        }}
                    />
                </AdvGridItem>
            )}
        </>
    );
};

const AdvInfoDialog = () => {
    const infos = useAdvRecoilValue(atomInfos);
    const resetInfos = useAdvResetRecoilState(atomInfos);
    const theme = useAdvTheme();

    const modalContent = useMemo(() => {
        return (
            <AdvStack verticalFill>
                <AdvStackItem styles={{ root: { overflowY: "auto" } }}>
                    <AdvGrid
                        columns="auto 1fr"
                        rows=""
                        verticalFill={false}
                        styles={{ root: { padding: 4, width: "auto" } }}
                        colGap="8px"
                        rowGap="0px"
                    >
                        {infos.map((info, index) => (
                            <AdvInfoGridItem
                                key={`info_${info.Timestamp.getTime()}`}
                                info={info}
                                index={index}
                                theme={theme}
                            />
                        ))}
                    </AdvGrid>
                </AdvStackItem>
            </AdvStack>
        );
    }, [infos, theme]);

    return (
        <AdvModal
            isOpen={infos.length > 0}
            headline={infos.at(0)?.Header ?? ""}
            onDismiss={resetInfos}
            styles={{
                header: {
                    root: {
                        color: theme.semanticColors.errorText,
                    },
                },
                headerContainer: {
                    root: {
                        background: theme.semanticColors.errorBackground,
                    },
                },
            }}
        >
            {modalContent}
        </AdvModal>
    );
};
export { AdvInfoDialog };

const useAdvInfo = () => {
    const showInfos = useAdvSetRecoilState(atomInfos);

    const showInfo = useAdvCallback(
        (header: string, message: string, info?: string, dev?: string) => {
            showInfos((oldErrors) => {
                return [
                    {
                        Timestamp: new Date(),
                        Header: header,
                        Message: message,
                        AdditionalInfo: info === undefined ? undefined : info,
                        DevInfo: dev === undefined ? undefined : dev,
                    },
                    ...oldErrors,
                ];
            });
        },
        [showInfos],
    );

    return { showInfo: showInfo };
};

export default useAdvInfo;

import { useAdvCallback } from "@hooks/react-overload/useAdvCallback";
import { useAdvEffect } from "@hooks/react-overload/useAdvEffect";
import { useRef, useState } from "react";

/**
 * This React hook helps to limit that the component is re-rendered too many times.
 *
 * Imagine that you want to execute a function on an event that executes several hundred times per second such as moving the mouse or scrolling.
 *
 * This may cause the application to lag. To prevent this, the debounce uses an internal timer to execute the callback function every xx seconds (2nd parameter).
 * @link https://usehooks-ts.com/react-hook/use-debounce
 */
export function useDebounce<T>(value: T, delay?: number, forcedFlushDelay: number = -1): T {
    const [debouncedValue, setDebouncedValue] = useState<T>(value);
    const curTimerTime = useRef(Date.now());

    useAdvEffect(() => {
        const timeNow = Date.now();
        const elapsed = timeNow - curTimerTime.current;

        const delayTime = delay ?? 500;
        if (forcedFlushDelay != -1 && elapsed > forcedFlushDelay) {
            setDebouncedValue(value);
            curTimerTime.current = timeNow;
        } else {
            const timer = setTimeout(() => {
                setDebouncedValue(value);
                curTimerTime.current = timeNow;
            }, delayTime);

            return () => {
                clearTimeout(timer);
            };
        }
    }, [value, delay, forcedFlushDelay]);

    return debouncedValue;
}

/**
 * This React hook helps to limit that the component is re-rendered too many times by delaying a set function's execution
 */
export function useDebounceSetterFunc<T>(
    setterFunc: (val: T) => void,
    delay?: number,
    forcedFlushDelay: number = -1,
): { setterFunc: (val: T) => void; clearTimer: () => void } {
    const [debouncedValue, setDebouncedValue] = useState<{ val: T } | undefined>();
    const curTimerTime = useRef(Date.now());
    const curTimer = useRef<any>();

    const setterFuncInternal = useAdvCallback((val: T) => {
        setDebouncedValue({ val });
    }, []);

    const clearTimer = useAdvCallback(() => {
        clearTimeout(curTimer.current);
        curTimer.current = undefined;
    }, []);

    useAdvEffect(() => {
        if (debouncedValue == undefined) return;

        const timeNow = Date.now();
        const elapsed = timeNow - curTimerTime.current;

        const delayTime = delay ?? 500;
        if (forcedFlushDelay != -1 && elapsed > forcedFlushDelay) {
            setterFunc(debouncedValue.val);
            curTimerTime.current = timeNow;
        } else {
            curTimer.current = setTimeout(() => {
                setterFunc(debouncedValue.val);
                curTimerTime.current = timeNow;
            }, delayTime);

            return clearTimer;
        }
    }, [clearTimer, debouncedValue, delay, forcedFlushDelay, setterFunc]);

    return { setterFunc: setterFuncInternal, clearTimer };
}

/**
 * This React hook helps to limit that the component is re-rendered too many times by delaying a function's execution
 */
export function useDebounceFunc<T>(
    aFunc: () => T,
    delay?: number,
    forcedFlushDelay: number = -1,
): () => T {
    const [debouncedValue, setDebouncedValue] = useState(() => aFunc);
    const curTimerTime = useRef(Date.now());

    useAdvEffect(() => {
        const timeNow = Date.now();
        const elapsed = timeNow - curTimerTime.current;

        const delayTime = delay ?? 500;
        if (forcedFlushDelay != -1 && elapsed > forcedFlushDelay) {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            setDebouncedValue((old) => aFunc);
            curTimerTime.current = timeNow;
        } else {
            const timer = setTimeout(() => {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                setDebouncedValue((old) => aFunc);
                curTimerTime.current = timeNow;
            }, delayTime);

            return () => {
                clearTimeout(timer);
            };
        }
    }, [aFunc, delay, forcedFlushDelay]);

    return debouncedValue;
}

import AdvText from "@components/data/text/text";
import { AdvContextualMenu } from "@components/layout/contextual-menu/contextual-menu";
import AdvGridItemDesignable from "@components/layout/grid/grid-item/designable";
import AdvModal from "@components/layout/modal/modal";
import AdvStack from "@components/layout/stack/stack";
import AdvStackItemDesignable from "@components/layout/stack/stack-item/designable";
import AdvStackItem from "@components/layout/stack/stack-item/stack-item";
import { LAN } from "@data/language/strings";
import { TResourceStorageKey } from "@data/resource-storage";
import { sessionAddInfosAtom } from "@data/session";
import { DefaultComponentCategory } from "@feature/Designer/types/category";
import { EComponentTypeData } from "@feature/Designer/types/component-type";
import { registerDesignableComponent } from "@feature/Designer/utils";
import { BaseButton, ImageFit, Link, Shimmer } from "@fluentui/react";
import { useAdvRouter } from "@hooks/page/useAdvRouter";
import { useAdvCallback } from "@hooks/react-overload/useAdvCallback";
import { useAdvEffect } from "@hooks/react-overload/useAdvEffect";
import useAdvRecoilValue from "@hooks/recoil-overload/useAdvRecoilValue";
import { TBaseNews, TNews, useAdvDataFactory } from "@hooks/useAdvDataProviderFactory";
import useAdvTheme from "@hooks/useAdvTheme";
import { NewsIcon } from "@themes/icons";
import { ServerStrToLocalDateStr } from "@utils/date";
import { deepCompareJSXProps } from "@utils/deep-compare";
import { advcatch } from "@utils/logging";
import React, { MouseEvent, useRef, useState } from "react";
import { AdvCommonComponentAttributes, AdvThemeProviderProperties } from "../common-properties";
import { AdvComponentThemer } from "../component-themer/component-themer";
import AdvResource from "../resource/resource";

export type TAdvNewsItem = {
    Headline: string;
    Topline: string;
    Shorttext: string;
    Content?: string;
    ExtLink?: string;

    Name: string;
    Media: TResourceStorageKey;
    Date: string;
};

export const AdvNewsSingle = ({ news }: { news: TAdvNewsItem }) => {
    const [isNewsOpen, setIsNewsOpenImpl] = useState(false);
    // if undefined -> not yet loaded
    const [newsItem, setNewsItem] = useState<TNews | undefined>(undefined);

    const session = useAdvRecoilValue(sessionAddInfosAtom);
    const { getNews } = useAdvDataFactory();

    const setIsNewsOpen = useAdvCallback(
        (val: boolean) => {
            if (newsItem == undefined) {
                if (news.Content != undefined && news.ExtLink != undefined) {
                    setNewsItem({
                        Content: news.Content,
                        ExtLink: news.ExtLink,
                    });
                } else {
                    // fetch data
                    getNews(news.Name, session.LanguageID ?? -1)
                        .then((val) => {
                            setNewsItem(val.News);
                        })
                        .catch(advcatch);
                }
            } else if (news.Content != undefined && news.ExtLink != undefined) {
                setNewsItem((old) => {
                    if (news.Content != old?.Content || news.ExtLink != old?.ExtLink)
                        return {
                            Content: news.Content ?? "",
                            ExtLink: news.ExtLink ?? "",
                        };
                    return old;
                });
            }
            setIsNewsOpenImpl(val);
        },
        [getNews, news.Content, news.ExtLink, news.Name, newsItem, session.LanguageID],
    );

    const buttonRef = useRef<any>(null);
    const [isNewsContextMenuHidden, setIsNewsContextMenuHidden] = useState(true);
    const handleContextClick = useAdvCallback(
        (
            e: MouseEvent<
                | HTMLAnchorElement
                | HTMLButtonElement
                | HTMLDivElement
                | BaseButton
                | HTMLSpanElement
            >,
        ) => {
            setIsNewsContextMenuHidden(false);
            buttonRef.current = e.target;
            e.preventDefault();
        },
        [],
    );
    const router = useAdvRouter();

    const theme = useAdvTheme();

    return (
        <>
            <AdvStack
                styles={{
                    root: {
                        padding: 8,
                        cursor: "pointer",
                        backgroundColor: theme.palette.white,
                        border: "1px solid " + theme.palette.neutralLight,
                    },
                }}
                onContextMenu={handleContextClick}
            >
                <AdvStack horizontal onClick={() => setIsNewsOpen(true)}>
                    <AdvStackItem
                        styles={{
                            root: { display: "flex", alignItems: "center", marginRight: 10 },
                        }}
                    >
                        <AdvResource
                            resourceProps={{
                                resourceName: news.Media.Name,
                                resourceDataType: news.Media.DataType,
                            }}
                            resourceHeight="150px"
                            resourceWidth="250px"
                            forceFitType={ImageFit.cover}
                        ></AdvResource>
                    </AdvStackItem>
                    <AdvStackItem shrink>
                        <AdvStack>
                            <AdvStackItem>
                                <AdvComponentThemer
                                    compProps={{
                                        advThemeSwizzle: { neutralPrimary: "neutralSecondary" },
                                    }}
                                >
                                    <AdvText ignoreTranslation variant="xSmall">
                                        {news.Topline}
                                    </AdvText>
                                </AdvComponentThemer>
                            </AdvStackItem>
                            <AdvStackItem>
                                <AdvText ignoreTranslation variant="xLarge">
                                    {news.Headline}
                                </AdvText>
                            </AdvStackItem>
                            <AdvStackItem shrink>
                                <AdvText ignoreTranslation variant="medium" allowMultiline>
                                    {news.Shorttext}
                                </AdvText>
                            </AdvStackItem>
                        </AdvStack>
                    </AdvStackItem>
                </AdvStack>
                <AdvModal
                    isOpen={isNewsOpen}
                    headline={news.Headline}
                    onDismiss={function (): void {
                        setIsNewsOpen(false);
                    }}
                    styles={{
                        root: {
                            main: {
                                width: "90%",
                                height: "90%",
                            },
                        },
                    }}
                    contentAsFlexContainer
                >
                    <AdvStack grow>
                        <AdvStackItem>
                            <AdvResource
                                resourceProps={{
                                    resourceName: news.Media.Name,
                                    resourceDataType: news.Media.DataType,
                                }}
                                resourceHeight="300px"
                                resourceWidth="100%"
                            ></AdvResource>
                        </AdvStackItem>
                        <AdvStackItem
                            styles={{
                                root: { alignSelf: "center", width: "70%", display: "flex" },
                            }}
                            grow
                        >
                            <AdvStack grow>
                                <AdvStackItem>
                                    <AdvStack horizontal grow horizontalAlign="space-between">
                                        <AdvStackItem>
                                            <AdvComponentThemer
                                                compProps={{
                                                    advThemeSwizzle: {
                                                        neutralPrimary: "neutralSecondary",
                                                    },
                                                }}
                                            >
                                                <AdvText ignoreTranslation variant="xSmall">
                                                    {news.Topline}
                                                </AdvText>
                                            </AdvComponentThemer>
                                        </AdvStackItem>
                                        <AdvStackItem>
                                            <AdvText ignoreTranslation variant="xSmall">
                                                {ServerStrToLocalDateStr(news.Date)}
                                            </AdvText>
                                        </AdvStackItem>
                                    </AdvStack>
                                </AdvStackItem>
                                <AdvStackItem>
                                    <AdvText ignoreTranslation variant="xxLargePlus">
                                        {news.Headline}
                                    </AdvText>
                                </AdvStackItem>
                                <AdvStackItem shrink>
                                    <AdvText ignoreTranslation variant="large" allowMultiline>
                                        {news.Shorttext}
                                    </AdvText>
                                </AdvStackItem>
                                {newsItem != undefined ? (
                                    <>
                                        <AdvStackItem shrink>
                                            <AdvText
                                                ignoreTranslation
                                                variant="medium"
                                                allowMultiline
                                            >
                                                {newsItem.Content ?? ""}
                                            </AdvText>
                                        </AdvStackItem>
                                        {newsItem.ExtLink != "" && (
                                            <AdvStackItem shrink align="end">
                                                <Link
                                                    ignoreTranslation
                                                    href={newsItem.ExtLink ?? ""}
                                                    target={"_blank"}
                                                >
                                                    {"Weitere Informationen"}
                                                </Link>
                                            </AdvStackItem>
                                        )}
                                    </>
                                ) : (
                                    <AdvStackItem>
                                        <Shimmer
                                            styles={{
                                                root: { flexGrow: 1, display: "flex" },
                                                shimmerWrapper: { flexGrow: 1, display: "flex" },
                                            }}
                                        ></Shimmer>
                                    </AdvStackItem>
                                )}
                            </AdvStack>
                        </AdvStackItem>
                    </AdvStack>
                </AdvModal>
            </AdvStack>

            <AdvContextualMenu
                items={[
                    {
                        key: "actPermission",
                        text: LAN.ADD_NEWS.text,
                        iconProps: {
                            iconName: NewsIcon.iconName,
                        },
                        onClick: () => {
                            router.push("/designer/news/").catch(advcatch);
                        },
                    },
                ]}
                hidden={isNewsContextMenuHidden}
                target={buttonRef.current}
                onItemClick={() => setIsNewsContextMenuHidden(true)}
                onDismiss={() => setIsNewsContextMenuHidden(true)}
            />
        </>
    );
};

const AdvNewsComp = () => {
    const session = useAdvRecoilValue(sessionAddInfosAtom);

    // undefined -> not yet loaded
    const [baseNews, setBaseNews] = useState<Array<TBaseNews> | undefined>(undefined);

    const { getNewsBase } = useAdvDataFactory();

    useAdvEffect(() => {
        if (baseNews == undefined) {
            getNewsBase(session.LanguageID ?? -1)
                .then((val) => {
                    setBaseNews(val.News);
                })
                .catch(advcatch);
        }
    }, [baseNews, getNewsBase, session.LanguageID]);

    return (
        <AdvStack tokens={{ childrenGap: 10 }}>
            {baseNews?.map((news, newsIndex) => (
                <AdvNewsSingle
                    key={"newsCont_news_" + newsIndex.toString()}
                    news={{
                        ...news,
                    }}
                ></AdvNewsSingle>
            )) ?? <></>}
        </AdvStack>
    );
};

export const AdvNews = React.memo(AdvNewsComp, deepCompareJSXProps);

registerDesignableComponent({
    staticData: {
        name: LAN.NEWS.text,
        type: EComponentTypeData.News,
        supportsChildren: false,
        hideDropAreas: true,
        category: DefaultComponentCategory.Misc,
        icon: NewsIcon,
    },
    properties: [
        ...AdvCommonComponentAttributes,
        ...AdvThemeProviderProperties,
        ...AdvStackItemDesignable.CommonProperties,
        ...AdvGridItemDesignable.CommonProperties,
    ],
    propertiesBuilders: [],
    presets: [],
});

import AdvText from "@components/data/text/text";
import AdvButton from "@components/inputs/button/button";
import AdvGrid from "@components/layout/grid/grid";
import AdvGridItem from "@components/layout/grid/grid-item/grid-item";
import AdvModal from "@components/layout/modal/modal";
import AdvStack from "@components/layout/stack";
import AdvStackItem from "@components/layout/stack/stack-item";
import { LAN } from "@data/language/strings";
import { Separator } from "@fluentui/react";
import { useAdvCallback } from "@hooks/react-overload/useAdvCallback";
import useAdvRecoilValue from "@hooks/recoil-overload/useAdvRecoilValue";
import useAdvResetRecoilState from "@hooks/recoil-overload/useAdvResetRecoilState";
import useAdvSetRecoilState from "@hooks/recoil-overload/useAdvSetRecoilState";
import useAdvTheme, { TAdvAppliedTheme } from "@hooks/useAdvTheme";
import { useMemo } from "react";
import { atom } from "recoil";

const atomErrors = atom<TAdvError[]>({
    key: "advErrors",
    default: [],
});

export type TAdvError = {
    Timestamp: Date;
    Message: string;
    AdditionalInfo?: string;
    DevInfo?: string;
};

const AdvErrorGridItem = ({
    error,
    index,
    errorsCount,
    theme,
}: {
    error: TAdvError;
    index: number;
    errorsCount: number;
    theme: TAdvAppliedTheme;
}) => {
    const { Message, AdditionalInfo, Timestamp, DevInfo } = error;
    return (
        <>
            {/* Zeit nur anzeigen wenn mehr als 1 Fehler */}
            {errorsCount <= 1 ? null : (
                <AdvGridItem key={`err_t_${Timestamp.getTime()}`} column="1" row={index * 2 + 1}>
                    <AdvText
                        ignoreTranslation
                        label={`[${Timestamp.toLocaleString(undefined, {
                            dateStyle: "short",
                            timeStyle: "medium",
                        })}]`}
                        styles={{
                            root: {
                                color: theme.semanticColors.disabledText,
                            },
                        }}
                    />
                </AdvGridItem>
            )}
            <AdvGridItem key={`err_m_${Timestamp.getTime()}`} column="2" row={index * 2 + 1}>
                <AdvText
                    ignoreTranslation
                    label={`${Message}`}
                    allowMultiline
                    styles={{
                        root: {
                            userSelect: "text",
                        },
                    }}
                />
            </AdvGridItem>
            {/* Zusätzliche Info nur anzeigen wenn vorhanden */}
            {AdditionalInfo === undefined ? null : (
                <AdvGridItem key={`err_a_${Timestamp.getTime()}`} column="3" row={index * 2 + 1}>
                    <AdvText
                        ignoreTranslation
                        label={`${AdditionalInfo}`}
                        allowMultiline
                        styles={{
                            root: {
                                color: theme.semanticColors.disabledText,
                            },
                        }}
                    />
                </AdvGridItem>
            )}
            {/* Dev Info nur anzeigen wenn vorhanden und in DEV-Build */}
            {DevInfo === undefined || process.env.NEXT_PUBLIC_ENV != "dev" ? null : (
                <AdvGridItem key={`err_d_${Timestamp.getTime()}`} column="4" row={index * 2 + 1}>
                    <AdvText
                        ignoreTranslation
                        label={`${DevInfo}`}
                        allowMultiline
                        styles={{
                            root: {
                                fontStyle: "italic",
                            },
                        }}
                    />
                </AdvGridItem>
            )}
            {/* Separator nicht beim letzten Error */}
            {index == errorsCount - 1 ? null : (
                <AdvGridItem
                    key={`err_s_${Timestamp.getTime()}`}
                    column="1 / none"
                    row={index * 2 + 2}
                >
                    <Separator />
                </AdvGridItem>
            )}
        </>
    );
};

const AdvErrorDialog = () => {
    const errors = useAdvRecoilValue(atomErrors);
    const resetErrors = useAdvResetRecoilState(atomErrors);
    const theme = useAdvTheme();

    const modalContent = useMemo(() => {
        return (
            <AdvStack verticalFill>
                <AdvStackItem styles={{ root: { height: "calc(100% - 50px)", overflowY: "auto" } }}>
                    <AdvGrid
                        columns="auto 1fr fit-content(15%) fit-content(15%)"
                        rows=""
                        verticalFill={false}
                        styles={{ root: { padding: 4, width: "auto" } }}
                        colGap="8px"
                        rowGap="0px"
                    >
                        {errors.map((error, index, errors) => (
                            <AdvErrorGridItem
                                key={`err_${error.Timestamp.getTime()}`}
                                error={error}
                                index={index}
                                errorsCount={errors.length}
                                theme={theme}
                            />
                        ))}
                    </AdvGrid>
                </AdvStackItem>
                <AdvStackItem>
                    <AdvStack horizontal horizontalAlign="stretch" verticalAlign="center">
                        <AdvButton
                            text="Schließen"
                            primary
                            onClick={resetErrors}
                            styles={{
                                root: {
                                    background: theme.semanticColors.errorText,
                                    borderColor: theme.semanticColors.errorText,
                                    color: theme.semanticColors.errorBackground,
                                    height: "40px",
                                    margin: "5px",
                                },
                            }}
                        />
                    </AdvStack>
                </AdvStackItem>
            </AdvStack>
        );
    }, [errors, resetErrors, theme]);

    return (
        <AdvModal
            isOpen={errors.length > 0}
            headline={LAN.HEADLINE_ERROR.text}
            onDismiss={resetErrors}
            styles={{
                header: {
                    root: {
                        color: theme.semanticColors.errorText,
                    },
                },
                headerContainer: {
                    root: {
                        background: theme.semanticColors.errorBackground,
                    },
                },
            }}
        >
            {modalContent}
        </AdvModal>
    );
};
export { AdvErrorDialog };

const useAdvError = () => {
    const setErrors = useAdvSetRecoilState(atomErrors);

    const showError = useAdvCallback(
        (message: string, info?: string, dev?: string) => {
            setErrors((oldErrors) => {
                return [
                    {
                        Timestamp: new Date(),
                        Message: message,
                        AdditionalInfo: info === undefined ? undefined : info,
                        DevInfo: dev === undefined ? undefined : dev,
                    },
                    ...oldErrors,
                ];
            });
        },
        [setErrors],
    );

    const showPreparedError = useAdvCallback(
        (prepMessage?: string, prepInfo?: string, prepDev?: string) => {
            return (message?: string, info?: string, dev?: string) => {
                return showError(
                    message ?? prepMessage ?? "unknown",
                    info ?? prepInfo,
                    dev ?? prepDev,
                );
            };
        },
        [showError],
    );

    return { showError, showPreparedError };
};

export default useAdvError;

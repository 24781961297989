import { IDesignableStaticData } from "@feature/Designer/types/designable";
import { IDesignerComponent, IDesignerProperty } from "@feature/Designer/types/designer";
import { DefaultValue, selectorFamily } from "recoil";

import { componentsAtomFamily, designerGetUniqueName } from "./atoms";

const staticSelectorFamily = selectorFamily<
    IDesignableStaticData,
    { activeFileName: string; componentKey: string }
>({
    key: "uiDesignerComponentStatic",
    get:
        ({ activeFileName, componentKey }) =>
        ({ get }) => {
            const uniqueName = designerGetUniqueName(activeFileName, componentKey);
            const comp = get(componentsAtomFamily(uniqueName));
            return comp.staticData;
        },
    set:
        ({ activeFileName, componentKey }) =>
        ({ get, set }, newValue) => {
            if (newValue instanceof DefaultValue) return;
            const uniqueName = designerGetUniqueName(activeFileName, componentKey);
            const oldComponent = get(componentsAtomFamily(uniqueName));

            const newComponent: IDesignerComponent = { ...oldComponent, staticData: newValue };
            set(componentsAtomFamily(uniqueName), newComponent);
        },
});

const propertiesSelectorFamily = selectorFamily<
    IDesignerProperty[],
    { activeFileName: string; componentKey: string }
>({
    key: "uiDesignerComponentProperties",
    get:
        ({ activeFileName, componentKey }) =>
        ({ get }) => {
            const uniqueName = designerGetUniqueName(activeFileName, componentKey);
            const props = get(componentsAtomFamily(uniqueName)).properties;
            // const copy = deepCopy(props);
            // copy.sort((a, b) => (a.displayName > b.displayName) ? 1 : ((b.displayName > a.displayName) ? -1 : 0));
            return props;
        },
    set:
        ({ activeFileName, componentKey }) =>
        ({ get, set }, newValue) => {
            if (newValue instanceof DefaultValue) return;
            const uniqueName = designerGetUniqueName(activeFileName, componentKey);
            const oldComponent = get(componentsAtomFamily(uniqueName));

            const newComponent: IDesignerComponent = { ...oldComponent, properties: newValue };
            set(componentsAtomFamily(uniqueName), newComponent);
        },
});

const childrenKeysSelectorFamily = selectorFamily<
    string[],
    { activeFileName: string; componentKey: string }
>({
    key: "uiDesignerComponentChildrenKeys",
    get:
        ({ activeFileName, componentKey }) =>
        ({ get }) => {
            const uniqueName = designerGetUniqueName(activeFileName, componentKey);
            return get(componentsAtomFamily(uniqueName)).childrenKeys;
        },
    set:
        ({ activeFileName, componentKey }) =>
        ({ get, set }, newValue) => {
            if (newValue instanceof DefaultValue) return;
            const uniqueName = designerGetUniqueName(activeFileName, componentKey);
            const oldData: IDesignerComponent = get(componentsAtomFamily(uniqueName));
            const newData: IDesignerComponent = { ...oldData, childrenKeys: newValue };
            set(componentsAtomFamily(uniqueName), newData);
        },
});

export { staticSelectorFamily, propertiesSelectorFamily, childrenKeysSelectorFamily };

import AdvText from "@components/data/text";
import { recoilPageState } from "@data/dynamic-page";
import { buildPageIDForVariableID } from "@data/parameters";
import { TAdvDesignerComponentProps } from "@feature/Designer/types/component-props";
import { TAdvWebActionParams } from "@hooks/dynamic/useAdvWebAction.types";
import { useAdvRouter } from "@hooks/page/useAdvRouter";
import { useAdvCallback } from "@hooks/react-overload/useAdvCallback";
import { useAdvEffect } from "@hooks/react-overload/useAdvEffect";
import useAdvResetRecoilState from "@hooks/recoil-overload/useAdvResetRecoilState";
import useAdvComponent from "@hooks/useAdvComponent";
import { deepCompareJSXProps } from "@utils/deep-compare";
import React, { useMemo } from "react";
import "./designable";

export type TDynamicPageProps = TAdvDesignerComponentProps & {
    children?: React.JSX.Element | React.JSX.Element[];
    onShowEventActionParams?: TAdvWebActionParams;
    onHideEventActionParams?: TAdvWebActionParams;
};
const AdvDynamicPageComp = ({ children, ...props }: TDynamicPageProps) => {
    useAdvComponent(AdvDynamicPageComp, props);

    const router = useAdvRouter();
    const variableID = useMemo(() => buildPageIDForVariableID(router.pageInfo), [router.pageInfo]);

    const resetPageState = useAdvResetRecoilState(recoilPageState(variableID));
    const handleRouteChangeStart = useAdvCallback(() => {
        // PageState zurücksetzen, wenn wir auf eine andere Seite navigieren
        resetPageState();
    }, [resetPageState]);

    useAdvEffect(() => {
        router.events.on("routeChangeStart", handleRouteChangeStart);
        return () => router.events.off("routeChangeStart", handleRouteChangeStart);
    }, [handleRouteChangeStart, router.events, router.asPath]);
    return <>{children ?? <AdvText>Fehler: Keine Komponente?</AdvText>}</>;
};

const AdvDynamicPage = React.memo(AdvDynamicPageComp, deepCompareJSXProps);
export default AdvDynamicPage;

import { useAdvCallback } from "@hooks/react-overload/useAdvCallback";
import { useAdvEffect } from "@hooks/react-overload/useAdvEffect";
import { useState } from "react";

/**
 * Ein Hook, der ein Value + ValueChanged-Callback für ein Controlled Input Component bereitstellt.
 * @important Es müssen folgende Properties beim betroffenen Input Component gesetzt werden: ``value``, ``onValueChange``
 * @param value Standard-Wert oder ein State
 * @param onValueChanged Wird ausgeführt, wenn sich das Value durch den Benutzer ändert
 * @example
 * <TextField value={internalValue} onChange={onInternalChange}/>
 * <Checkbox checked={internalValue} onChange={onInternalChange}/>
 * @see Controlled vs. uncontrolled Component: https://blog.logrocket.com/controlled-vs-uncontrolled-components-in-react/
 */
export function useAdvValue<TValue>(
    value: TValue,
    onValueChanged?: (newValue: TValue, oldValue: TValue) => void,
) {
    const [internalValue, setInternalValue] = useState<TValue>(value);

    const onInternalChange = useAdvCallback(
        (ev: any, newValue: TValue) => {
            const oldValue = internalValue;
            setInternalValue(newValue);

            if (typeof onValueChanged != "undefined") onValueChanged(newValue, oldValue);
        },
        [internalValue, onValueChanged],
    );

    // Wenn sich Value (kommt von der Komponente) ändert, dann auch den internen State entsprechend ändern (sollen gleich sein)
    useAdvEffect(() => {
        setInternalValue(value);
    }, [value]);

    return { internalValue, setInternalValue, onInternalChange };
}

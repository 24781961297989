import AdvText from "@components/data/text";
import ContractsHost from "@components/dynamic/contracts/contracts-host";
import DataProviderHost from "@components/dynamic/data-provider-host";
import AdvDynamicComponent from "@components/dynamic/dynamic-component";
import { TDynamicPage, TDynamicPagePayload } from "@components/dynamic/dynamic-page/types";
import PageParameterHost from "@components/dynamic/parameter-mapping";
import AdvPage from "@components/layout/page";
import AdvLoading from "@components/other/loading";
import { TPageComponentProps } from "@components/page-component";
import { replacePagePrefix } from "@data/designer/file";
import { EPageLoaderMode, useAdvPageLoader } from "@hooks/dynamic/useAdvPageLoader";
import { usePageTitle } from "@hooks/dynamic/usePageTitle";
import { useAdvRouter } from "@hooks/page/useAdvRouter";
import { useAdvCallback } from "@hooks/react-overload/useAdvCallback";
import { useAdvEffect } from "@hooks/react-overload/useAdvEffect";
import {
    TAdvTransactionInterface,
    useAdvRecoilTransaction,
} from "@hooks/recoil-overload/useAdvRecoilTransaction";
import useAdvRecoilValue from "@hooks/recoil-overload/useAdvRecoilValue";
import { useWebActionManager } from "@hooks/useWebActionsManager";
import PageNotFound from "@pages/404";
import { trans_assert } from "@utils/assert-trans";
import { deepCompareJSXProps } from "@utils/deep-compare";
import { buildUniquePageID, gAdvDynPageName, parsePageName } from "@utils/page-parser";
import { nanoid } from "nanoid";
import { NextPage } from "next";
import React, { useContext, useMemo, useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { atomFamily } from "recoil";
import { defaultPageInstanceIndex, pageInstanceContext } from "./instanceIndexContext";

export const dynamicPageAtomName = atomFamily<string, string>({
    key: "dynamicPageName_PageAtom",
    default: "",
});

export type TPageInfo = {
    pathname: string;
    query: Record<string, string | string[] | undefined>;
    pageInstanceIndex: string;
};

export type TPageIdent = {
    Name: string;
    PageIndex: string;
};

const dynamicPageAtom = atomFamily<
    { page: TDynamicPage | undefined; pagename: string; pagecompid: string },
    string
>({
    key: "dynamicPage_PageAtom",
    default: { page: undefined, pagename: "", pagecompid: "" },
});

const dynamicPageUniquenessAtom = atomFamily<string, string>({
    key: "dynamicPageUniqueness_PageAtom",
    default: "",
});

export const gResetDynamicPageTrans = (tb: TAdvTransactionInterface) => (pageID: string) => {
    tb.reset(dynamicPageAtom(pageID));
    tb.reset(dynamicPageUniquenessAtom(pageID));
};

export const useDynamicPage = () => {
    const instance = useContext(pageInstanceContext);
    const dynamicPageName = useAdvRecoilValue(dynamicPageAtomName(instance.index));
    const dynamicPage = useAdvRecoilValue(dynamicPageAtom(dynamicPageName));
    const { initPageTrans, pageName, loadablePage } = useAdvPageLoader(gAdvDynPageName);

    const { pageInfo, isReady } = useAdvRouter();
    /** Wenn verfügbar den Title der Page (anstatt PageName) nutzen */
    const { title, sectitle } = usePageTitle(pageName ?? "");

    const initPageWrapper = useAdvCallback(
        (tb: TAdvTransactionInterface) =>
            (
                pageInfo: TPageInfo,
                pageName: string,
                dynamicPagePayload: TDynamicPagePayload,
                mode: EPageLoaderMode,
            ) => {
                const pageID = buildUniquePageID(pageInfo);
                const uniqueness = tb.get(dynamicPageUniquenessAtom(pageID));
                const pageUniqueness = JSON.stringify(dynamicPagePayload);
                if (uniqueness != pageUniqueness) {
                    initPageTrans(tb)(pageName, dynamicPagePayload, mode, (tb) => (page) => {
                        tb.set(dynamicPageAtomName(instance.index), pageID);
                        tb.set(dynamicPageAtom(pageID), {
                            page: page,
                            pagename: pageName ?? "",
                            pagecompid: nanoid(),
                        });
                        tb.set(dynamicPageUniquenessAtom(pageID), pageUniqueness);
                    });
                } else {
                    tb.set(dynamicPageAtomName(instance.index), pageID);
                }
            },
        [initPageTrans, instance.index],
    );
    const initPage = useAdvRecoilTransaction(initPageWrapper, [initPageWrapper]);

    const checkPageIDTrans = useAdvCallback(
        (tb: TAdvTransactionInterface) => (pageInfo: TPageInfo) => {
            const pageID = buildUniquePageID(pageInfo);
            const oldPageID = tb.get(dynamicPageAtomName(instance.index));
            if (JSON.stringify(pageID) != JSON.stringify(oldPageID))
                tb.set(dynamicPageAtomName(instance.index), pageID);
        },
        [instance.index],
    );
    const checkPageID = useAdvRecoilTransaction(checkPageIDTrans, [checkPageIDTrans]);

    useAdvEffect(() => {
        if (isReady) {
            if (loadablePage.IsLoaded() && parsePageName(pageInfo) == loadablePage.Get().Name) {
                initPage(
                    pageInfo,
                    loadablePage.Get().Name,
                    loadablePage.Get(),
                    EPageLoaderMode.Normal,
                );
            } else {
                checkPageID(pageInfo);
            }
        }
    }, [loadablePage, initPage, isReady, checkPageID, pageInfo]);

    return {
        dynamicPage,
        pageName,
        pageTitle: title ?? "Dynamische Seite (via Page-Loader)",
        secondaryPageTitle: sectitle,
        hasErr: loadablePage.HasError(),
    };
};

export const PageDynamicImpl = ({
    dynamicPage,
    pageName,
    hasErr,
    pageProps,
}: {
    dynamicPage: {
        page: TDynamicPage | undefined;
        pagename: string;
        pagecompid: string;
    };
    pageName: string | undefined;
    hasErr: boolean;
    pageProps: TPageComponentProps;
}) => {
    /** Die Basis-Komponente (AdvDynamicPage) der angezeigten Seite. */
    const dynamicPageComponent = useMemo<React.JSX.Element>(() => {
        if (!hasErr) {
            const dynPage = dynamicPage.page;
            if (dynPage !== undefined && dynamicPage.pagename == pageName) {
                const baseComponent = dynPage.components.find(
                    (comp) => comp.key == dynPage.baseComponentKey,
                );
                trans_assert(
                    baseComponent != undefined,
                    `BaseComponent undefined: ${JSON.stringify(dynamicPage)}`,
                );
                return (
                    <AdvDynamicComponent
                        key={"dc_" + dynamicPage.pagecompid}
                        component={baseComponent}
                        components={dynPage.components}
                        pageProps={pageProps}
                    />
                );
            } else
                return (
                    <AdvLoading
                        isLoading={true}
                        spinnerProps={{
                            label: `Lade ${replacePagePrefix(pageName ?? "") ?? "<Page>"}...`,
                        }}
                    />
                );
        } else {
            return (
                <PageNotFound>
                    <AdvText>Unbekannte Seite</AdvText>
                </PageNotFound>
            );
        }
    }, [dynamicPage, hasErr, pageName, pageProps]);

    return (
        <>
            <DataProviderHost />
            <PageParameterHost />
            <ContractsHost />
            {dynamicPageComponent}
        </>
    );
};

export const PageDynamicImplInstanceWrapper = ({
    pageProps,
    showCloseButton,
}: {
    pageProps: TPageComponentProps;
    showCloseButton: boolean;
}) => {
    useWebActionManager();
    const { dynamicPage, pageName, pageTitle, secondaryPageTitle, hasErr } = useDynamicPage();
    const [pageKey, setPageKey] = useState(getUniqueId());
    useHotkeys(
        "Shift+F5",
        () => {
            // "Soft reload", nur den Content der Page
            setPageKey(getUniqueId());
        },
        { preventDefault: true },
    );
    const { removeInstance, pageInfo } = useAdvRouter();

    const title = pageTitle != "" ? pageTitle : replacePagePrefix(pageName ?? "");
    return (
        <AdvPage title={title} secondaryTitle={secondaryPageTitle}>
            <div
                style={{
                    flexGrow: "1",
                    flexShrink: "1",
                    borderRightStyle: showCloseButton ? "solid" : "none",
                    borderWidth: "1px",
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                }}
            >
                {showCloseButton && (
                    <button
                        onClick={() => {
                            removeInstance(pageInfo.pageInstanceIndex);
                        }}
                    >
                        {"❌" + title}
                    </button>
                )}
                <PageDynamicImpl
                    key={`pagedynamic_${pageKey}_2`}
                    dynamicPage={dynamicPage}
                    pageName={pageName}
                    hasErr={hasErr}
                    pageProps={pageProps}
                ></PageDynamicImpl>
            </div>
        </AdvPage>
    );
};

let uniqueId = 0;
const getUniqueId = () => uniqueId++;

// Aktuell gibt es von jeder Seite höchstens eine Instanz,
// deswegen die pageList hier erstmal mit dem Default erstellen.
// In Zukunft wird die Variable vermutlich dann durch was anderes
// abgelöst oder zumindest dynamisch befüllt
export const pagesList = [defaultPageInstanceIndex];

const PageDynamicComp: NextPage<TPageComponentProps> = (pageProps: TPageComponentProps) => {
    const { pageIndeces } = useAdvRouter();
    console.log("rerender", pageIndeces);
    return (
        <div style={{ display: "flex", gap: "5px", height: "100%" }}>
            {pageIndeces.map((index) => (
                <pageInstanceContext.Provider
                    value={{ index: index }}
                    key={"pageInstanceContext" + index.toString()}
                >
                    <PageDynamicImplInstanceWrapper
                        pageProps={pageProps}
                        showCloseButton={pageIndeces.length > 1}
                    ></PageDynamicImplInstanceWrapper>
                </pageInstanceContext.Provider>
            ))}
        </div>
    );
};

const PageDynamic = React.memo(PageDynamicComp, deepCompareJSXProps);
export default PageDynamic;

import AdvDynamicPageDesignable from "@components/dynamic/dynamic-page/designable";
import { DEFAULT_DESIGNER_BASE_KEY } from "@feature/Designer/const";
import { IDesignerComponent } from "@feature/Designer/types/designer";
import { createDesignerComponent } from "@feature/Designer/utils";
import { atom, atomFamily } from "recoil";

/** Key der aktuell ausgewählten Komponente im Designer. */
export const atomSelectedComponentKey = atomFamily<string, string>({
    key: "uiDesignerSelectedComponentKey",
    default: DEFAULT_DESIGNER_BASE_KEY,
});

/** Key der aktuellen Designer-Base (DynamicPage-Komponente) */
export const atomDesignerBaseComponentKey = atomFamily<string, string>({
    key: "uiDesignerBaseComponentKeyAtom",
    default: DEFAULT_DESIGNER_BASE_KEY,
});

/** Liste mit allen Keys der Komponenten im Designer. */
export const atomComponentKeys = atomFamily<string[], string>({
    key: "uiDesignerComponentKeys",
    default: [DEFAULT_DESIGNER_BASE_KEY],
});

/**
 * "Dictionary" in dem alle DesignerComponents sind.
 * Key: ComponentKey, Value: Komponenten-Daten.
 * @summary
 *  One advantage of using this pattern for separate atoms for each element over trying to store a single atom with a
 *  map of state for all elements is that they all maintain their own individual subscriptions.
 *  So, updating the value for one element will only cause React components that have subscribed to just that atom to update.
 */
export const componentsAtomFamily = atomFamily<IDesignerComponent, string>({
    key: "uiDesignerComponents",
    default: () =>
        createDesignerComponent(
            AdvDynamicPageDesignable.Registered,
            "",
            [],
            DEFAULT_DESIGNER_BASE_KEY,
        ),
});

// the current component, over which the new component is dragged
export const atomDropComponentKey = atom<string[]>({
    key: "uiDesignerDropComponentKey",
    default: [],
});

export function designerGetUniqueName(activeFileName: string, componentKey: string) {
    return (
        activeFileName.length.toString() +
        "_" +
        activeFileName +
        activeFileName.length.toString() +
        (componentKey != undefined
            ? "_" +
              componentKey.length.toString() +
              "_" +
              componentKey +
              componentKey.length.toString()
            : "---")
    );
}

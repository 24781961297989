/**
 * A hook that is useful for certain debugging.
 * It's implemented by all base components.
 * E.g. it can print the component name when it's rendered to find out what error
 * messages caused by react are related to them.
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const useAdvComponent = (component: any, props?: any) => {
    /*useAdvEffect(() => {
        if (process.env.NEXT_PUBLIC_ENV == "dev") {
            advlog("Rendered : ", component.name, props?.key);
        }
    });*/
};

export default useAdvComponent;

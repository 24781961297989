import { useAdvCallback } from "@hooks/react-overload/useAdvCallback";
import { useRef } from "react";
import { Id, ToastOptions, ToastPosition, TypeOptions, toast } from "react-toastify";

export type TAdvToastType = TypeOptions; /* do not change */
export type TAdvToastPosition = ToastPosition; /* do not change */

export type TAdvToastData = {
    message: string;
    type: TAdvToastType;
    position: TAdvToastPosition;
    autoClose?: false | number;
};

export type TAdvToastOptions = Omit<ToastOptions, "type" | "position"> & {
    /** Nur einen Toast gleichzeitig anzeigen? Gilt pro Komponente. Default: TRUE */
    onlyOneToast: boolean;
};

/**
 * Wrapper für ``React-Toastify``
 * @important Standard-Einstellungen können auch im ``ToastContainer`` geändert werden, siehe *_app.tsx*
 * @important Nicht jedes mal ein neues {@link options}-Objekt reingeben -> Endlosschleife
 * @see https://fkhadra.github.io/react-toastify/introduction
 * @param {Partial<TAdvToastData>} [defaults] Default-Daten für das Toast (Nachricht, Typ, Position)
 * @param {Partial<TAdvToastOptions>} [options] Zusätzliche Einstellungen (Optional)
 */
function useAdvToast(defaults?: Partial<TAdvToastData>, options?: Partial<TAdvToastOptions>) {
    const {
        message: defaultMessage,
        type: defaultType,
        position: defaultPosition,
        autoClose: defaultAutoClose,
    } = defaults ?? {};
    // eslint-disable-next-line @typescript-eslint/naming-convention

    const recentToastID = useRef<Id | undefined>(undefined); // Das zuletzt gezeigte Toast (von dieser Komponente!)

    const showToast = useAdvCallback(
        (
            toastMessage?: string,
            toastType?: TAdvToastType,
            toastPosition?: TAdvToastPosition,
            toastAutoClose?: false | number,
        ) => {
            const { onlyOneToast: isOnlyOneToast = true, ...otherOptions } = options ?? {};

            const message = toastMessage ?? defaultMessage;
            const type = toastType ?? defaultType ?? toast.TYPE.DEFAULT;
            const position = toastPosition ?? defaultPosition ?? toast.POSITION.BOTTOM_RIGHT;
            const autoClose = toastAutoClose ?? defaultAutoClose ?? undefined;

            if (typeof message == "undefined") return; // Keine Nachricht? Dann auch nicht anzeigen

            if (isOnlyOneToast) toast.dismiss(recentToastID.current);

            return (recentToastID.current = toast(message, {
                type,
                position,
                autoClose,
                ...otherOptions,
            })); // => ID
        },
        [defaultMessage, defaultPosition, defaultType, options],
    );

    const showInfo = useAdvCallback(
        (
            toastMessage?: string,
            toastPosition?: TAdvToastPosition,
            toastAutoClose?: false | number,
        ) => showToast(toastMessage, "info", toastPosition, toastAutoClose),
        [showToast],
    );
    const showSuccess = useAdvCallback(
        (
            toastMessage?: string,
            toastPosition?: TAdvToastPosition,
            toastAutoClose?: false | number,
        ) => showToast(toastMessage, "success", toastPosition, toastAutoClose),
        [showToast],
    );
    const showWarning = useAdvCallback(
        (
            toastMessage?: string,
            toastPosition?: TAdvToastPosition,
            toastAutoClose?: false | number,
        ) => showToast(toastMessage, "warning", toastPosition, toastAutoClose),
        [showToast],
    );
    const showError = useAdvCallback(
        (
            toastMessage?: string,
            toastPosition?: TAdvToastPosition,
            toastAutoClose: false | number = false,
        ) => showToast(toastMessage, "error", toastPosition, toastAutoClose),
        [showToast],
    );
    const resetToast = useAdvCallback(() => {
        toast.dismiss(recentToastID.current);
        recentToastID.current = undefined;
    }, []);

    return {
        /** Zeigt eine Toast-Notification an */
        showToast,
        /** Zeigt eine Info-Toast-Notification an (Blau) */
        showInfo,
        /** Zeigt eine Erfolg-Toast-Notification an (Grün) */
        showSuccess,
        /** Zeigt eine Warnung-Toast-Notification an (Orange) */
        showWarning,
        /** Zeigt eine Fehler-Toast-Notification an (Rot) */
        showError,
        /** Letzte Toast-Notification verstecken */
        resetToast,
    };
}

export default useAdvToast;

import { EVerbosityLevel, verbosityLevel } from "@hooks/shared";
import { DependencyList, useRef } from "react";
import { TransactionInterface_UNSTABLE, useRecoilTransaction_UNSTABLE } from "recoil";

export type TAdvTransactionInterface = TransactionInterface_UNSTABLE;
export type TAdvTransactionCanExecuteCallback<TArgs extends Array<unknown>> = (
    ...args: TArgs
) => Promise<boolean>;
export type TAdvTransactionCanExecuteCallbackSync<TArgs extends Array<unknown>> = (
    ...args: TArgs
) => boolean;

export function useAdvRecoilTransaction<TArgs extends Array<unknown>>(
    fn: (transactionInterface: TAdvTransactionInterface) => (...args: TArgs) => void,
    deps: DependencyList,
): (...args: TArgs) => void {
    const internalTransaction = useRecoilTransaction_UNSTABLE(
        (ti) =>
            (...args: TArgs) => {
                fn(ti)(...args);
            },
        deps,
    );

    if (verbosityLevel > EVerbosityLevel.None) {
        (internalTransaction as any).adv = 1;
    }

    return internalTransaction;
}

/**
 * Synchrone Variante von {@link useAdvRecoilTransaction}
 * @see {@link useAdvRecoilTransaction}
 */
export function useAdvRecoilTransactionSync<TArgs extends Array<unknown>, TReturnType>(
    fn: (transactionInterface: TAdvTransactionInterface) => (...args: TArgs) => TReturnType,
    deps?: DependencyList,
    canExecuteFunction?: TAdvTransactionCanExecuteCallbackSync<TArgs>,
): (...args: TArgs) => TReturnType | undefined {
    const result = useRef<TReturnType>();
    const internalTransaction = useRecoilTransaction_UNSTABLE(
        (ti) =>
            (...args: TArgs) => {
                const _result = fn(ti)(...args);
                result.current = _result;
            },
        deps ?? [],
    );

    const syncCallback = (...args: TArgs): TReturnType | undefined => {
        result.current = undefined;

        const canExecute = canExecuteFunction === undefined || canExecuteFunction(...args);
        if (canExecute) internalTransaction(...args);

        return result.current;
    };

    return syncCallback;
}

import { LAN } from "@data/language/strings";
import { TAdvThemeSwizzle } from "@data/theme-types";
import { AdvProperty } from "@feature/Designer/utils";
import { TAdvValueBindingParams } from "@hooks/dynamic/useAdvValueBinder";
import { toAdvText } from "@hooks/language/useTranslation";
import { TAdvThemeFontOverwrite } from "./theme-swizzle";

export type TAdvCommonProperties = {
    advhide?: boolean;
    advhideBindingParams?: TAdvValueBindingParams;

    /**
     * the index of the data to be displayed, if the component is bound to a dataprovider
     */
    dataArrayIndex?: number;

    advThemeSwizzle?: TAdvThemeSwizzle;
    advThemeSwizzleBindingParams?: TAdvValueBindingParams;

    advThemeFontOverwrite?: TAdvThemeFontOverwrite;
    advThemeFontOverwriteBindingParams?: TAdvValueBindingParams;

    compType?: string;
    keyRef?: string;
};

export type TAdvFileProperties = {
    dataBase64: string;
    fileType: string;
};

export type TAdvResourceProperties = {
    resourceDataType: string;
    resourceName: string;
};

const commonProperties = [
    AdvProperty.Boolean.create(
        toAdvText(LAN.HIDDEN),
        "advhide",
        toAdvText(LAN.GENERAL),
        toAdvText(LAN.COMP_HIDE),
        false,
    ),
];

export const AdvCommonComponentAttributes = commonProperties;

const commonThemeProviderProperties = [
    AdvProperty.Object.createThemeSwizzle(
        toAdvText(LAN.THEME_SWIZZLE),
        "advThemeSwizzle",
        toAdvText(LAN.THEME_OVERLOAD),
        toAdvText(LAN.COMP_COLOR_SWIZZLE),
    ),
    AdvProperty.Object.createThemeFontOverwrite(
        toAdvText(LAN.FONT_OVERWRITE),
        "advThemeFontOverwrite",
        toAdvText(LAN.THEME_OVERLOAD),
        toAdvText(LAN.COMP_FONT_OVERWRITE),
    ),
];

export const AdvThemeProviderProperties = commonThemeProviderProperties;

import { TAdvDesignerComponentProps } from "@feature/Designer/types/component-props";
import { getDesignerModeComponentStyle, getSelectedComponentStyle } from "@feature/Designer/utils";
import { IStackProps, IStackStyles, IStackTokens, Stack } from "@fluentui/react";
import {
    IsValueBindingTrivial,
    TAdvValueBindingParams,
    useAdvValueBinderNoDataType,
} from "@hooks/dynamic/useAdvValueBinder";
import { nestingBackgroundColors } from "@themes/nesting";
import { IStylesFunctionOrObject, ITokenFunctionOrObject } from "@typings/fluent";
import { EAdvValueDataTypes } from "@utils/data-types";
import { combineStylesWithToken, combineTokens } from "@utils/styling";
import React, { ReactNode, useMemo } from "react";

import { deepCompareJSXProps } from "@utils/deep-compare";
import { defaultStackStyles, defaultStackTokens } from "./styles";

export type TAdvStackTokens = IStackTokens; /* do not change */
export type TAdvStackStyles = IStackStyles; /* do not change */

export type TAdvStackProps = Omit<IStackProps, "styles"> &
    TAdvDesignerComponentProps &
    TAdvCommonProperties & {
        styles?: IStylesFunctionOrObject<IStackProps, TAdvStackTokens, TAdvStackStyles>;
        tokens?: ITokenFunctionOrObject<IStackProps, TAdvStackTokens>;
        children?: ReactNode;

        horizontalBindingParams?: TAdvValueBindingParams;
        wrapBindingParams?: TAdvValueBindingParams;
        verticalFillBindingParams?: TAdvValueBindingParams;
        horizontalAlignBindingParams?: TAdvValueBindingParams;
        verticalAlignBindingParams?: TAdvValueBindingParams;

        childrenGap?: number;
        childrenGapBindingParams?: TAdvValueBindingParams;
    };

const AdvStackImplComp = ({
    styles: propStyles,
    advhide,
    tokens,
    designerData,
    horizontal,
    designerProps,
    childrenGap,
    ...props
}: TAdvStackProps) => {
    useAdvComponent(AdvStackImplComp, props);

    const propsWithoutTokens = useMemo(() => {
        const res = { ...props };
        if ("maxWidth" in res) delete res["maxWidth"];
        if ("maxHeight" in res) delete res["maxHeight"];
        if ("minWidth" in res) delete res["minWidth"];
        if ("minHeight" in res) delete res["minHeight"];
        return res;
    }, [props]);

    const theme = useAdvTheme();

    const styles = useMemo(() => {
        let styles = propStyles;
        if (designerData) {
            styles = combineStylesWithToken(defaultStackStyles.designer, styles);
            if (designerData.renderAsDesigner)
                styles = combineStylesWithToken(styles, {
                    root: {
                        background: nestingBackgroundColors(theme)[designerData?.nestingDepth ?? 0],
                    },
                });
            styles = combineStylesWithToken(
                styles,
                horizontal != undefined && horizontal
                    ? { root: { width: "100%" } }
                    : { root: { height: "100%" } },
            );
        }

        if ((designerData?.isSelected ?? false) && (designerData?.renderAsDesigner ?? false))
            styles = combineStylesWithToken(styles, {
                root: getSelectedComponentStyle(theme, true),
            });
        if (designerData?.renderAsDesigner ?? false)
            styles = combineStylesWithToken(styles, { root: getDesignerModeComponentStyle(theme) });
        return combineStylesWithToken(styles, defaultStackStyles.default(theme));
    }, [designerData, horizontal, propStyles, theme]);

    const tokensMemo = useMemo(() => {
        return combineTokens(
            designerData ? defaultStackTokens.designer : defaultStackTokens.normal,
            childrenGap != undefined ? { ...(tokens ?? {}), childrenGap } : tokens,
        );
    }, [childrenGap, designerData, tokens]);

    if (advhide === true && designerProps === undefined) return <></>;
    return (
        <Stack
            {...propsWithoutTokens}
            {...designerProps}
            styles={styles}
            horizontal={horizontal}
            tokens={tokensMemo}
        />
    );
};
const AdvStackImpl = React.memo(AdvStackImplComp, deepCompareJSXProps);

const AdvStackSimple = (props: TAdvStackProps) => {
    return <AdvStackImpl {...props}></AdvStackImpl>;
};

const AdvStackComplex = ({
    horizontalBindingParams,
    wrapBindingParams,
    verticalFillBindingParams,
    horizontalAlignBindingParams,
    verticalAlignBindingParams,
    advhide,
    advhideBindingParams,
    horizontal,
    wrap,
    verticalFill,
    horizontalAlign,
    verticalAlign,
    childrenGap,
    childrenGapBindingParams,
    dataArrayIndex = 0,
    ...props
}: TAdvStackProps) => {
    const [isHorizontalValue] = useAdvValueBinderNoDataType(
        horizontalBindingParams,
        horizontal,
        EAdvValueDataTypes.Any,
        dataArrayIndex,
    );
    const [isWrapValue] = useAdvValueBinderNoDataType(
        wrapBindingParams,
        wrap,
        EAdvValueDataTypes.Any,
        dataArrayIndex,
    );
    const [isVerticalFillValue] = useAdvValueBinderNoDataType(
        verticalFillBindingParams,
        verticalFill,
        EAdvValueDataTypes.Any,
        dataArrayIndex,
    );
    const [horizontalAlignValue] = useAdvValueBinderNoDataType(
        horizontalAlignBindingParams,
        horizontalAlign,
        EAdvValueDataTypes.Any,
        dataArrayIndex,
    );
    const [verticalAlignValue] = useAdvValueBinderNoDataType(
        verticalAlignBindingParams,
        verticalAlign,
        EAdvValueDataTypes.Any,
        dataArrayIndex,
    );
    const [shouldHide] = useAdvValueBinderNoDataType(
        advhideBindingParams,
        advhide,
        EAdvValueDataTypes.Any,
        dataArrayIndex,
    );
    const [childrenGapValue] = useAdvValueBinderNoDataType(
        childrenGapBindingParams,
        childrenGap,
        EAdvValueDataTypes.Any,
        dataArrayIndex,
    );

    return (
        <AdvStackImpl
            {...props}
            horizontal={isHorizontalValue}
            wrap={isWrapValue}
            verticalFill={isVerticalFillValue}
            horizontalAlign={horizontalAlignValue}
            verticalAlign={verticalAlignValue}
            advhide={shouldHide}
            horizontalBindingParams={horizontalBindingParams}
            wrapBindingParams={wrapBindingParams}
            verticalFillBindingParams={verticalFillBindingParams}
            horizontalAlignBindingParams={horizontalAlignBindingParams}
            verticalAlignBindingParams={verticalAlignBindingParams}
            advhideBindingParams={advhideBindingParams}
            childrenGapBindingParams={childrenGapBindingParams}
            dataArrayIndex={dataArrayIndex}
            childrenGap={childrenGapValue}
        ></AdvStackImpl>
    );
};

/**
 * @summary Wrapper für ``Stack``
 * @important Standardmäßig werden die Elemente UNTEREINANDER angeordnet. Kann mit ``horizontal``-Property geändert werden.
 * @link https://developer.microsoft.com/en-us/fluentui#/controls/web/stack
 */
export const AdvStackComp = ({
    horizontalBindingParams,
    wrapBindingParams,
    verticalFillBindingParams,
    horizontalAlignBindingParams,
    verticalAlignBindingParams,
    advhideBindingParams,
    childrenGapBindingParams,
    ...props
}: TAdvStackProps) => {
    if (
        IsValueBindingTrivial(horizontalBindingParams) &&
        IsValueBindingTrivial(wrapBindingParams) &&
        IsValueBindingTrivial(verticalFillBindingParams) &&
        IsValueBindingTrivial(horizontalAlignBindingParams) &&
        IsValueBindingTrivial(verticalAlignBindingParams) &&
        IsValueBindingTrivial(advhideBindingParams) &&
        IsValueBindingTrivial(childrenGapBindingParams)
    )
        return (
            <AdvStackSimple
                {...props}
                horizontalBindingParams={horizontalBindingParams}
                wrapBindingParams={wrapBindingParams}
                verticalFillBindingParams={verticalFillBindingParams}
                horizontalAlignBindingParams={horizontalAlignBindingParams}
                verticalAlignBindingParams={verticalAlignBindingParams}
                advhideBindingParams={advhideBindingParams}
                childrenGapBindingParams={childrenGapBindingParams}
            ></AdvStackSimple>
        );
    else
        return (
            <AdvStackComplex
                {...props}
                horizontalBindingParams={horizontalBindingParams}
                wrapBindingParams={wrapBindingParams}
                verticalFillBindingParams={verticalFillBindingParams}
                horizontalAlignBindingParams={horizontalAlignBindingParams}
                verticalAlignBindingParams={verticalAlignBindingParams}
                advhideBindingParams={advhideBindingParams}
                childrenGapBindingParams={childrenGapBindingParams}
            ></AdvStackComplex>
        );
};

const AdvStack = React.memo(AdvStackComp, deepCompareJSXProps);
export default AdvStack;

import { TAdvCommonProperties } from "@components/other/common-properties";
import useAdvComponent from "@hooks/useAdvComponent";
import useAdvTheme from "@hooks/useAdvTheme";
import "./designable";

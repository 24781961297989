import { DeepPartial } from "@fluentui/merge-styles";
import { IStyleFunction, IStyleFunctionOrObject, IStyleSet, ITheme } from "@fluentui/react";
import {
    IStylesFunction,
    IStylesFunctionOrObject,
    ITokenFunction,
    ITokenFunctionOrObject,
} from "@typings/fluent";

/**
 * StyleSets sind Objekte, in denen weitere Objekte sind (z.B. root).
 * Deshalb ist ein einfaches { ...a, ...b } zum kombinieren NICHT möglich, dadurch
 * gehen nämlich u.U. alle Daten (z.B. root) von A verloren.
 * Entsprechend müssen wir alle Keys (z.B. root) manuell kombinieren.
 * @summary Kombiniert A mit B (Values & Objekte 1. Ebene)
 * @important B übersteuert A
 * @returns Objekt bestehend aus A und B.
 */
export function mergeObjects<TType extends object>(a: TType, b: TType, debug = false) {
    if (debug) console.debug(a, b);
    if (typeof a == "undefined" && typeof b != "undefined") return b;
    if (typeof a != "undefined" && typeof b == "undefined") return a;
    if (typeof a == "undefined" && typeof b == "undefined") return {} as TType;

    // starting point is object a
    const result: any = { ...a };

    // for all keys in b, check if they exist in a
    for (const key in b) {
        if (typeof result[key] != "undefined") {
            const value = (b as any)[key];
            // if both are objects, also merge them
            if (typeof result[key] == "object" && typeof value == "object")
                result[key] = mergeObjects(result[key], value);
            // else always prefer b's value (because it was merged "later")
            else result[key] = value;
        } else {
            const value = (b as any)[key];
            if (typeof value == "object") result[key] = { ...value };
            else result[key] = value;
        }
    }

    if (debug) console.debug("Merged", result);
    return result;
}

/**
 * Diese Funktion brauchen wir, weil man FluentUI-Styles nicht nur als Objekt angeben kann,
 * sondern auch als Funktion. Ein Beispiel dafür ist die AdvGroupbox.
 * Entsprechend müssen wir alle möglichen Eingaben behandeln und miteinander kombinieren.
 * Die Parameter können Styles-Objekte oder aber Funktionen, die solch ein Styles-Objekt zurückgeben, sein.
 * @important B übersteuert A
 */
export function combineStylesWithToken<TViewProps, TTokens, TStyleSet extends IStyleSet>(
    a?: IStylesFunctionOrObject<TViewProps, TTokens, TStyleSet>,
    b?: IStylesFunctionOrObject<TViewProps, TTokens, TStyleSet>,
    debug?: boolean,
): IStylesFunctionOrObject<TViewProps, TTokens, TStyleSet> {
    if (debug ?? false) console.debug(0, JSON.stringify(a), "\r\n", JSON.stringify(b));

    if (typeof a == "undefined" && typeof b != "undefined") return b;
    if (typeof a != "undefined" && typeof b == "undefined") return a;
    if (typeof a == "undefined" && typeof b == "undefined") return {} as TStyleSet;

    const isFncA = typeof a == "function";
    const isFncB = typeof b == "function";

    if (isFncA && isFncB) {
        const fncA: IStylesFunction<TViewProps, TTokens, TStyleSet> = a as IStylesFunction<
            TViewProps,
            TTokens,
            TStyleSet
        >;
        const fncB: IStylesFunction<TViewProps, TTokens, TStyleSet> = b as IStylesFunction<
            TViewProps,
            TTokens,
            TStyleSet
        >;

        return (props: TViewProps, theme: ITheme, tokens: TTokens) => {
            const stylesA = fncA(props, theme, tokens);
            const stylesB = fncB(props, theme, tokens);
            if (debug ?? false) console.debug(1, JSON.stringify(mergeObjects(stylesA, stylesB)));
            return mergeObjects(stylesA, stylesB);
        };
    } else if (isFncA && !isFncB) {
        const fncA: IStylesFunction<TViewProps, TTokens, TStyleSet> = a as IStylesFunction<
            TViewProps,
            TTokens,
            TStyleSet
        >;
        const stylesB: TStyleSet = b as TStyleSet;

        return (props: TViewProps, theme: ITheme, tokens: TTokens) => {
            const stylesA = fncA(props, theme, tokens);
            if (debug ?? false) console.debug(2, JSON.stringify(mergeObjects(stylesA, stylesB)));
            return mergeObjects(stylesA, stylesB);
        };
    } else if (!isFncA && isFncB) {
        const stylesA: TStyleSet = a as TStyleSet;
        const fncB: IStylesFunction<TViewProps, TTokens, TStyleSet> = b as IStylesFunction<
            TViewProps,
            TTokens,
            TStyleSet
        >;

        return (props: TViewProps, theme: ITheme, tokens: TTokens) => {
            const stylesB = fncB(props, theme, tokens);
            if (debug ?? false) console.debug(3, JSON.stringify(mergeObjects(stylesA, stylesB)));
            if (debug ?? false)
                console.debug(3, JSON.stringify(stylesA), "\r\n", JSON.stringify(stylesB));
            return mergeObjects(stylesA, stylesB);
        };
    } else if (!isFncA && !isFncB) {
        const stylesA: TStyleSet = a as TStyleSet;
        const stylesB: TStyleSet = b as TStyleSet;
        if (debug ?? false) console.debug(4, JSON.stringify(mergeObjects(stylesA, stylesB)));
        return mergeObjects(stylesA, stylesB);
    }
    return {} as TStyleSet;
}

/**
 * Diese Funktion brauchen wir, weil man FluentUI-Styles nicht nur als Objekt angeben kann,
 * sondern auch als Funktion. Ein Beispiel dafür ist die AdvGroupbox.
 * Entsprechend müssen wir alle möglichen Eingaben behandeln und miteinander kombinieren.
 * Die Parameter können Styles-Objekte oder aber Funktionen, die solch ein Styles-Objekt zurückgeben, sein.
 * @important B übersteuert A
 */
export function combineStyles<TViewProps, TStyleSet extends IStyleSet>(
    a?: IStyleFunctionOrObject<TViewProps, TStyleSet>,
    b?: IStyleFunctionOrObject<TViewProps, TStyleSet>,
    debug?: boolean,
): IStyleFunctionOrObject<TViewProps, TStyleSet> {
    if (debug ?? false) console.debug(0, JSON.stringify(a), "\r\n", JSON.stringify(b));

    if (typeof a == "undefined" && typeof b != "undefined") return b;
    if (typeof a != "undefined" && typeof b == "undefined") return a;
    if (typeof a == "undefined" && typeof b == "undefined") return {} as DeepPartial<TStyleSet>;

    const isFncA = typeof a == "function";
    const isFncB = typeof b == "function";

    if (isFncA && isFncB) {
        const fncA: IStyleFunction<TViewProps, TStyleSet> = a as IStyleFunction<
            TViewProps,
            TStyleSet
        >;
        const fncB: IStyleFunction<TViewProps, TStyleSet> = b as IStyleFunction<
            TViewProps,
            TStyleSet
        >;

        return (props: TViewProps) => {
            const stylesA: TStyleSet = fncA(props) as TStyleSet;
            const stylesB: TStyleSet = fncB(props) as TStyleSet;
            if (debug ?? false) console.debug(1, JSON.stringify(mergeObjects(stylesA, stylesB)));
            return mergeObjects(stylesA, stylesB) as DeepPartial<TStyleSet>;
        };
    } else if (isFncA && !isFncB) {
        const fncA: IStyleFunction<TViewProps, TStyleSet> = a as IStyleFunction<
            TViewProps,
            TStyleSet
        >;
        const stylesB: TStyleSet = b as TStyleSet;

        return (props: TViewProps) => {
            const stylesA: TStyleSet = fncA(props) as TStyleSet;
            if (debug ?? false) console.debug(2, JSON.stringify(mergeObjects(stylesA, stylesB)));
            return mergeObjects(stylesA, stylesB) as DeepPartial<TStyleSet>;
        };
    } else if (!isFncA && isFncB) {
        const stylesA: TStyleSet = a as TStyleSet;
        const fncB: IStyleFunction<TViewProps, TStyleSet> = b as IStyleFunction<
            TViewProps,
            TStyleSet
        >;

        return (props: TViewProps) => {
            const stylesB: TStyleSet = fncB(props) as TStyleSet;
            if (debug ?? false) console.debug(3, JSON.stringify(mergeObjects(stylesA, stylesB)));
            return mergeObjects(stylesA, stylesB) as DeepPartial<TStyleSet>;
        };
    } else if (!isFncA && !isFncB) {
        const stylesA: TStyleSet = a as TStyleSet;
        const stylesB: TStyleSet = b as TStyleSet;
        if (debug ?? false) console.debug(4, JSON.stringify(mergeObjects(stylesA, stylesB)));
        return mergeObjects(stylesA, stylesB) as DeepPartial<TStyleSet>;
    }
    return {} as DeepPartial<TStyleSet>;
}

/**
 * Diese Funktion brauchen wir, weil man FluentUI-Tokens nicht nur als Objekt angeben kann,
 * sondern auch als Funktion. Ein Beispiel dafür ist die AdvGroupbox.
 * Entsprechend müssen wir alle möglichen Eingaben behandeln und miteinander kombinieren.
 * Die Parameter können Tokens-Objekte oder aber Funktionen, die solch ein Tokens-Objekt zurückgeben, sein.
 * @important B übersteuert A
 */
export function combineTokens<TViewProps, TTokens extends object>(
    a?: ITokenFunctionOrObject<TViewProps, TTokens>,
    b?: ITokenFunctionOrObject<TViewProps, TTokens>,
    debug?: boolean,
): ITokenFunctionOrObject<TViewProps, TTokens> {
    if (debug ?? false) console.debug(0, JSON.stringify(a), "\r\n", JSON.stringify(b));

    if (typeof a == "undefined" && typeof b != "undefined") return b;
    if (typeof a != "undefined" && typeof b == "undefined") return a;
    if (typeof a == "undefined" && typeof b == "undefined") return {} as TTokens;

    const isFncA = typeof a == "function";
    const isFncB = typeof b == "function";

    if (isFncA && isFncB) {
        const fncA: ITokenFunction<TViewProps, TTokens> = a as ITokenFunction<TViewProps, TTokens>;
        const fncB: ITokenFunction<TViewProps, TTokens> = b as ITokenFunction<TViewProps, TTokens>;

        return (props: TViewProps, theme: ITheme) => {
            const tokensA: TTokens = fncA(props, theme) as TTokens;
            const tokensB: TTokens = fncB(props, theme) as TTokens;
            if (debug ?? false) console.debug(1, JSON.stringify(mergeObjects(tokensA, tokensB)));
            return mergeObjects(tokensA, tokensB) as TTokens;
        };
    } else if (isFncA && !isFncB) {
        const fncA: ITokenFunction<TViewProps, TTokens> = a as ITokenFunction<TViewProps, TTokens>;
        const tokensB: TTokens = b as TTokens;

        return (props: TViewProps, theme: ITheme) => {
            const tokensA: TTokens = fncA(props, theme) as TTokens;
            if (debug ?? false) console.debug(2, JSON.stringify(mergeObjects(tokensA, tokensB)));
            return mergeObjects(tokensA, tokensB) as TTokens;
        };
    } else if (!isFncA && isFncB) {
        const tokensA: TTokens = a as TTokens;
        const fncB: ITokenFunction<TViewProps, TTokens> = b as ITokenFunction<TViewProps, TTokens>;

        return (props: TViewProps, theme: ITheme) => {
            const tokensB: TTokens = fncB(props, theme) as TTokens;
            if (debug ?? false) console.debug(3, JSON.stringify(mergeObjects(tokensA, tokensB)));
            return mergeObjects(tokensA, tokensB) as TTokens;
        };
    } else if (!isFncA && !isFncB) {
        const tokensA: TTokens = a as TTokens;
        const tokensB: TTokens = b as TTokens;
        if (debug ?? false) console.debug(4, JSON.stringify(mergeObjects(tokensA, tokensB)));
        return mergeObjects(tokensA, tokensB) as TTokens;
    }
    return {} as TTokens;
}

import { TAdvAction } from "@data/action";
import { atomFamily } from "recoil";

export const gPermissionNaN = -999;

export const gActionDesigners = atomFamily<TAdvAction, string>({
    key: "_actionDesigners",
    default: {
        ActionHint: "",
        ActionLabel: "",
        Category: "",
        Attributes: {},
        Parameters: {},
        AreYouSureQuestion: "",
        Name: "",
        LogicName: "",
    },
    dangerouslyAllowMutability: true,
});

export const gActionLoadUniqueness = atomFamily<string, string>({
    key: "designer_actionLoadUniqueness",
    default: "",
});

export enum EPageActionsDesignerMode {
    Local = "local",
    Global = "global",
    Both = "both",
}
export type TPageActionsDesignerProps = {
    filterPageName?: string;
    mode?: EPageActionsDesignerMode;
};

// helper string to easily find text that is not part of this list
const tHelp = "";
// prettier-ignore
export const L4N_EXTERNAL = { // L4N damit es nicht beim tippen vorgeschlagen wird
    WELCOME: { text: tHelp + "Startseite" },
    GENERAL: { text: tHelp + "Allgemeines" },
    THEME_OVERLOAD: { text: tHelp + "Theme-Änderung" },
    SPECIAL: { text: tHelp + "Besondere" },
    PROTOTYP: { text: tHelp + "Prototyp" },
    ADVANCED: { text: tHelp + "Fortgeschritten" },
    DESIGNER: { text: tHelp + "Designer" },
    UI_DESIGNER: { text: tHelp + "UI-Designer" },
    DATASOURCES: { text: tHelp + "Datenquellen" },
    WEBACTION: { text: tHelp + "Webaktion" },
    ACTIONS: { text: tHelp + "Aktionen" },
    THEMES: { text: tHelp + "Themes" },
    MISCELLANEOUS: { text: tHelp + "Sonstiges" },
    SETTINGS: { text: tHelp + "Einstellungen" },
    ADMIN_SETTINGS: { text: tHelp + "Admin-Einstellungen" },
    CONTROLS: { text: tHelp + "Bedienung" },

    COPY_VALUE: { text: tHelp + "Wert kopieren" },
    CONFIG_SESSION_PERM: { text: tHelp + "Berechtigungen der Sitzung konfigurieren" },
    ADD_NEWS: { text: tHelp + "Neuigkeiten hinzufügen" },

    // UI-Designer
    UI_DESIGNER_ERR: { text: tHelp + "Es ist ein Fehler aufgetreten!" },

    APPL_MASS: { text: tHelp + "Änderungsmaß" },
    APPL_PLATZ: { text: tHelp + "Platz" },

    NEWS: { text: tHelp + "News" },
    UNKNOWN_ARTGROE: { text: tHelp + "(unbekannt)" },
    WEARER_ARTICLE: { text: tHelp + "Trägerartikel" },
    ADD_MORE_WEARER_ARTICLE: { text: tHelp + "Weiteren Trägerartikel hinzufügen" },
    RELOAD: { text: tHelp + "Neu laden" },

    EXCEL_EXPORT: { text: tHelp + "Excel-Export" },
    RESET_TABLE_VIEW: { text: tHelp + "Darstellung zurücksetzen" },
    FORM_REQUIRED_ERROR: { text: tHelp + "Fehlende Eingabe" },

    // Themes
    DARK_THEME_DEFAULT: { text: tHelp + "Dunkler Modus|[Standard]" },
    LIGHT_THEME_DEFAULT: { text: tHelp + "Heller Modus|[Standard]" },
    COLOR_PREVIEW: { text: tHelp + "Farbvorschau" },
    PRIMARY_COLOR: { text: tHelp + "Primäre Farbe" },
    TEXT_COLOR: { text: tHelp + "Textfarbe" },
    BACKGROUND_COLOR: { text: tHelp + "Hintergrundfarbe" },
    HOVER_COLOR: { text: tHelp + "Hover-Farbe" },
    TOPBAR_NAV_PAGE_MODE: { text: tHelp + "Aufgeteilter Modus" },
    EXTENDED_MODE: { text: tHelp + "Erweiterter Modus" },
    EASY_MODE: { text: tHelp + "Vereinfachter Modus" },
    PAGE_CONTENT_COLOR: { text: tHelp + "Farben des Seiteninhalts" },
    TOPBAR_COLOR: { text: tHelp + "Farben der Kopfzeile" },
    NAV_COLOR: { text: tHelp + "Farben der Navigationsleiste" },
    THEME_PREVIEW: { text: tHelp + "Themevorschau" },
    ENABLE_THEME: { text: tHelp + "Theme aktivieren" },
    DISABLE_THEME: { text: tHelp + "Theme deaktivieren" },
    ICONS_THEME: { text: tHelp + "Icons" },
    ICON_COLOR_AND_TYPE: { text: tHelp + "Icons" },
    RESET_COLORS: { text: tHelp + "Farben zurücksetzen" },
    CHOOSE_FILE: { text: tHelp + "Datei auswählen" },
    DELETE_LOGO: { text: tHelp + "Logo löschen" },
    NAV_GROUP1: { text: tHelp + "Kunde" },
    NAV_GROUP2: { text: tHelp + "Kleidung" },

    LOGO: { text: tHelp + "Logo" },
    LOGO_TOPBAR: { text: tHelp + "Logo der Kopfzeile" },
    LOGO_LOGIN: { text: tHelp + "Logo der Anmeldung" },
    BACKGROUND_LOGIN: { text: tHelp + "Hintergrund der Anmeldung" },

    THEME_INLINE: { text: tHelp + "Theme"},

    ICON_SET_TYPE: { text: tHelp + "Variante" },
    ICON_PRIMARY_COLOR: { text: tHelp + "Primäre Farbe" },
    ICON_SECONDARY_COLOR: { text: tHelp + "Sekundäre Farbe|(nur für Duotone Variante)" },
    ICON_PRIMARY_HOVER_COLOR: { text: tHelp + "Hover|Primäre Farbe" },
    ICON_SECONDARY_HOVER_COLOR: { text: tHelp + "Hover|Sekundäre Farbe|(nur für Duotone Variante)" },
    ICON_PRIMARY_DISABLED_COLOR: { text: tHelp + "Deaktiviert|Primäre Farbe" },
    ICON_SECONDARY_DISABLED_COLOR: { text: tHelp + "Deaktiviert|Sekundäre Farbe|(nur für Duotone Variante)" },

    // News
    HEADLINE: { text: tHelp + "Überschrift" },
    TOPLINE: { text: tHelp + "Kategorie" },
    SHORTTEXT: { text: tHelp + "Zusammenfassung" },
    MEDIA: { text: tHelp + "Medien" },
    MEDIA_INFO: { text: tHelp + "Das Thumbnail ist 250x150 Pixel groß" },
    DATE: { text: tHelp + "Datum" },
    CONTENT: { text: tHelp + "Inhalt" },
    EXTERNAL_LINK_OPT: { text: tHelp + "Externer Link (optional)" },

    // nav
    NAVS: { text: tHelp + "Navigationen"},
    NAV_INLINE: { text: tHelp + "Navigation"},

    NAV_GROUP_COLLAPSED: { text: tHelp + "Gruppe standardmäßig geschlossen" },
    NAV_GROUP_ICON: { text: tHelp + "Icon ändern" },
    NAV_GROUP_ICON_RESET: { text: tHelp + "Icon löschen" },
    NAV_GROUP_ADD: { text: tHelp + "Gruppe hinzufügen" },
    NAV_GROUP_ITEM_ADD: { text: tHelp + "Eintrag hinzufügen" },
    NAV_ITEM_ACTION: { text: tHelp + "Eintrag" },

    // Usersettings
    USERSETTINGS: { text: tHelp + "Benutzerdaten" },
    ANREDE: { text: tHelp + "Anrede" },
    TITEL: { text: tHelp + "Titel" },
    VORNAME: { text: tHelp + "Vorname" },
    NACHNAME: { text: tHelp + "Nachname" },
    EMAIL: { text: tHelp + "E-Mail" },
    CHANGE_PASSWORD_HEADLINE: { text: tHelp + "Passwort ändern" },
    OLD_PASSWORD: { text: tHelp + "Altes Passwort" },
    NEW_PASSWORD_CONFIRM: { text: tHelp + "Neues Passwort bestätigen" },
    CHANGE_PASSWORD: { text: tHelp + "Passwort ändern" },

    // Misc
    NEW: { text: tHelp + "Neu" },
    SAVE: { text: tHelp + "Speichern" },
    CANCEL: { text: tHelp + "Abbrechen" },
    COPY: { text: tHelp + "Kopieren" },
    AUTOSAVED: { text: tHelp + "Automatisch gespeichert" },
    DELETE: { text: tHelp + "Löschen" },
    LOGOUT: { text: tHelp + "Abmelden" },
    UNDO: { text: tHelp + "Rückgängig" },
    REDO: { text: tHelp + "Wiederherstellen" },
    RESET: { text: tHelp + "Zurücksetzen" },
    CONTINUE: { text: tHelp + "Weiter" },
    PAGE_NOT_FOUND: { text: tHelp + "Seite nicht gefunden" },
    EMPTY: { text: tHelp + "Leer" },
    GUIDE: { text: tHelp + "Anleitung" },
    HEADLINE_ERROR: { text: tHelp + "Fehlermeldung" },

    STEP_1: { text: tHelp + "Schritt 1" },
    STEP_2: { text: tHelp + "Schritt 2" },
    STEP_3: { text: tHelp + "Schritt 3" },
    STEP_4: { text: tHelp + "Schritt 4" },

    // Login (2FA, Password Reset)
    LOGIN: { text: tHelp + "Anmelden" },
    LOGIN_HEADER: { text: tHelp + "Anmeldung" },
    STAY_LOGGED_IN: { text: tHelp + "Angemeldet bleiben" },
    USERNAME: { text: tHelp + "Benutzername" },
    PASSWORD: { text: tHelp + "Passwort" },
    FORGOT_PASSWORD: { text: tHelp + "Passwort vergessen?" },
    TWOFACTOR_CONFIGURE: { text: tHelp + "2FA konfigurieren" },
    TWOFACTOR_ACTIVATE: { text: tHelp + "2-Faktor-Authentifizierung aktivieren" },
    ONETIME_CODE: { text: tHelp + "Einmaliger Code" },
    TWOFACTOR_HEADER: { text: tHelp + "2-Faktor-Authentifizierung" },
    RESET_HEADER: { text: tHelp + "Passwort vergessen" },
    AUTHENTICATE: { text: tHelp + "Authentifizieren" },
    USERNAME_OR_EMAIL: { text: tHelp + "Benutzername oder E-Mail" },
    RESET_TOKEN: { text: tHelp + "Reset-Token" },
    NEW_PASSWORD: { text: tHelp + "Neues Passwort" },
    RESET_PASSWORD: { text: tHelp + "Passwort zurücksetzen" },
    RESET_USING_TOKEN: { text: tHelp + "Token nutzen" },
    GOOGLEAUTH_DOWNLOAD: { text: tHelp + "Google Authenticator App herunterladen" },
    GOOGLEAUTH_START: { text: tHelp + "Authenticator starten und das \"+\" Menü" },
    SCAN_QRCODE: { text: tHelp + "QR-Code scannen" },
    ENTER_OTP: { text: tHelp + "Die 6 Zahlen aus der Liste in das Textfeld eingeben" },
    TWOFACTOR_RECOMMENDED: { text: tHelp + "Es wird empfohlen, die 2-Faktor-Authentifizierung zu aktivieren!" },
    TWOFACTOR_THEFT: { text: tHelp + "Die 2-Faktor-Authentifizierung verringert die Wahrscheinlichkeit eines Account-Diebstahls." },
    TWOFACTOR_CHECK_APP: { text: tHelp + "Geben Sie den Code aus der Authenticator App ein." },
    RESET_EMAIL_SENT: { text: tHelp + "E-Mail versendet!" },
    RESET_DONE: { text: tHelp + "Erfolgreich zurückgesetzt!" },
    PASSWORD_GUIDELINE: { text: tHelp + "Passwort-Richtlinien" },
    PASSWORD_GUIDELINE_LEN: { text: tHelp + "Zwischen 8 und 24 Zeichen lang" },
    PASSWORD_GUIDELINE_CASE: { text: tHelp + "Groß- und Kleinschreibung enthalten" },
    PASSWORD_GUIDELINE_NMBER: { text: tHelp + "Zahl enthalten" },
    PASSWORD_GUIDELINE_SPCL: { text: tHelp + "Sonderzeichen enthalten" },

    RESET_PASSWORD_ERROR_NAME: { text: tHelp + "Benutzername oder Passwort falsch"},
    RESET_PASSWORD_ERROR_EMPTY: { text: tHelp + "Bitte füllen Sie alle Eingabefelder"},
    RESET_PASSWORD_ERROR_TOKEN: { text: tHelp + "Ungültiger Token oder zu schwaches Passwort"},
    RESET_PASSWORD_SUCCESS: { text: tHelp + "Erfolgreich zurückgesetzt!"},

    LOGIN_ERR_USERNAME: { text: tHelp + "Benutzername oder Passwort falsch" },
    LOGIN_ERR_NEED_OTP: { text: tHelp + "OTP-Token nötig" },
    LOGIN_ERR_WRONG_OTP: { text: tHelp + "OTP-Token ungültig" },
    LOGIN_ERR_CONF_OTP: { text: tHelp + "OTP-Konfiguration nötig" },
    LOGIN_ERR_NO_PERM: { text: tHelp + "Keine Berechtigung" },
    LOGIN_ERR_INACTIVE: { text: tHelp + "Benutzer ist inaktiv" },

    SEARCH_DOT_DOT_DOT: { text: tHelp + "Suchen..." },

    DEFAULT_THEME: { text: tHelp + "Standard-Theme" },
    CURRENT_THEME: { text: tHelp + "Aktuelles Theme" },
    MARK_MISSING_TRANSLATIONS: { text: tHelp + "Unterstreiche fehlende Übersetzungen" },
    APP_TITLE: { text: tHelp + "Anwendungstitel" },
    APP_ICON: { text: tHelp + "Anwendungssymbol" },
    THEME_AUTO_DETECT_BROWSER: { text: tHelp + "Automatisch durch Browser erkennen" },
    AUTOMATIC: { text: tHelp + "Automatisch" },

    LIST_OF_CHANGED_DATA_COLON: { text: tHelp + "Liste der Daten, welche geändert wurden:" },
    LANGUAGES: { text: tHelp + "Sprachen" },
    SWITCH_LANGUAGE: { text: tHelp + "Sprache wechseln" },
    WEBLINK_LANGUAGE: { text: tHelp + "Weitere Links" },

    PERMISSIONS_CONFIGURE: { text: tHelp + "Berechtigungen konfigurieren" },
    PERMISSIONS_ACTIONS: { text: tHelp + "Aktionen" },
    PERMISSIONS_ADD: { text: tHelp + "Berechtigung hinzufügen" },
    SES_PERMISSIONS_CONFIGURE: { text: tHelp + "Berechtigungen der Sitzung konfigurieren" },

    SELECT_CUSTOMER: { text: tHelp + "Kunden auswählen" },
    LOADING_CUSTOMERS: { text: tHelp + "Lade Kunden..." },
    CONFIRM_CUSTOMERS: { text: tHelp + "Die Seite muss neugeladen werden.|Wirklich fortfahren?" },
    ALL_CUSTOMERS: { text: tHelp + "(alle Kunden)" },

    CUSTOMER: { text: tHelp + "Kunde" },
    CUSTOMER_ID: { text: tHelp + "ID" },
    CUSTOMER_NAME: { text: tHelp + "Name" },
    CUSTOMER_NR: { text: tHelp + "Kunden-Nr." },

    ARTICLE: { text: tHelp + "Artikel" },
    ARTICLE_SIZE: { text: tHelp + "Artikelgröße" },
    ARTICLE_SIZE_SHORT: { text: tHelp + "Gr." },
    ARTICLE_COUNT: { text: tHelp + "Menge" },

    APPLICATION: { text: tHelp + "Applikation" },
    APPLICATIONS: { text: tHelp + "Applikationen" },
    ADD_ARTICLE: { text: tHelp + "Artikel hinzufügen" },

    ALTERATIONTYPE: { text: tHelp + "Änderungsart" },
    ALTERATION_PLACEMENT: { text: tHelp + "Platzierung" },
    ALTERATION_MEASURE: { text: tHelp + "Änderungsmaß" },
    ALTERATION_APPLY: { text: tHelp + "Übernehmen" },

    ARTITYPE_ALTERATION: { text: tHelp + "Änderung" },
    ARTITYPE_NAMETAG: { text: tHelp + "Namenschild" },
    ARTITYPE_EMBLEM: { text: tHelp + "Emblem" },
    ARTITYPE_UNKNOWN: { text: tHelp + "Unbekannter Typ" },

    AUFTRAGSNR_SHORT: { text: tHelp + "Auftrags-Nr." },
    LIEFERDATUM: { text: tHelp + "Lieferdatum" },
    URDATUM: { text: tHelp + "Urdatum" },
    STATUS: { text: tHelp + "Status" },

    KDNR_SHORT: { text: tHelp + "Kd-Nr." },
    VSANR_SHORT: { text: tHelp + "VSA-Nr." },
    BEZ_SHORT: { text: tHelp + "Bez." },
    BEZ: { text: tHelp + "Bezeichnung" },
    ANFORDERUNGEN: { text: tHelp + "Anforderungen" },
    ANF_NIEDR_STATUS: { text: tHelp + "Niedr. Status" },

    ARTIKELNR_SHORT: { text: tHelp + "Artikel-Nr." },
    ARTIKELGROESSE: { text: tHelp + "Artikel-Größe" },
    AVG_COUNT_DAY: { text: tHelp + "Ø-Menge (Tag)" },
    AVG_COUNT: { text: tHelp + "Ø-Menge" },
    VPE_SHORT: { text: tHelp + "VPE" },
    FESTEMENGE: { text: tHelp + "Feste Menge" },
    BESTMENGE: { text: tHelp + "Bestellmenge" },

    WARENKORB_ERROR: { text: tHelp + "Es ist ein Fehler aufgetreten.|Bitte wenden Sie sich an Ihren Kundenservice oder probieren es zu einem späteren Zeitpunkt erneut." },
    WARENKORB_CONFIRM_CANCEL_ALL: { text: tHelp + "Wollen Sie wirklich alle nicht erledigten Aufträge dieses Warenkorbs abbrechen?" },
    WARENKORB_AUFTR_DETAILS: { text: tHelp + "Details zum Web-Auftrag" },
    WARENKORB_HEADLINE: { text: tHelp + "Aufträge im Warenkorb" },
    WARENKORB_EMPTY: { text: tHelp + "Warenkorb leer" },
    WARENKORB_NR: { text: tHelp + "Warenkorb-Nr." },
    WARENKORB_STARTED: { text: tHelp + "Offen seit" },
    WARENKORB_APPROVE: { text: tHelp + "Aufträge erteilen" },
    WARENKORB_CANCEL_ALL: { text: tHelp + "Alle nicht erledigten Aufträge abbrechen" },
    WARENKORB_POS_ERROR: { text: tHelp + "Diese Position ist fehlerhaft" },
    WARENKORB_POS_REQUIRE_APPROVAL: { text: tHelp + "Diese Position benötigt eine Freigabe durch den Kunden-Service" },
    WARENKORB_POS_CANCEL: { text: tHelp + "Position abbrechen" },
    WARENKORB_POS_DETAILS: { text: tHelp + "Details einsehen" },
    WARENKORB_APPROVAL_INFO: { text: tHelp + "Einige Aufträge benötigen eine Freigabe durch den Kunden-Service.|Erst nach der Freigabe sind entsprechende Änderungen ersichtlich." },
    WARENKORB_REQUIRE_APPROVAL: { text: tHelp + "Freigabe nötig" },
    WARENKORB: { text: tHelp + "Warenkorb" },

    ANPROBE_FREIGEBEN_TITLE: { text: tHelp + "Anprobe freigeben" },
    ANPROBE_FREIGEBEN_CONFIRM: { text: tHelp + "Möchten Sie die Anprobe wirklich freigeben?" },
    ANPROBE_FREIGEBEN_SUCCESS: { text: tHelp + "Die Anprobe wurde freigegeben.|Sie erhalten in Kürze eine E-Mail!" },
    ANPROBE_FREIGEBEN_FAILURE: { text: tHelp + "Die Anprobe konnte nicht freigegeben werden.|Grund: " },

    YES: { text: tHelp + "Ja" },
    NO: { text: tHelp + "Nein" },

    MAIL_SUPPORT: { text: tHelp + "Support"},
};

import { L4N_INTERNAL } from "./strings_internal";
const LAN = { ...L4N_INTERNAL, ...L4N_EXTERNAL };
export { LAN };

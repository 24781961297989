import { TAdvValueBindingParams } from "@hooks/dynamic/useAdvValueBinder";
import { nanoid } from "nanoid";

export type TAdvFilterSelection = Record<string, number>;
export type TAdvFilterValues = Record<string, string[]>;

export type TDataProviderConstFilterMapping = Record<string, string>;
export type TDataProviderDynFilterMapping = Record<
    string,
    { filterName: string; filterBinding: TAdvValueBindingParams }
>;

export type TDataProviderFilterSort = {
    name: string;
    desc: boolean;
    // is the sorting const and cannot be changed?
    isConst?: boolean;
};
/**
 * Ein Typ, der alle möglichen Filterungen angibt, die ein Dataprovider unterstützt.
 */
// the name of the type structure of this field is used in the server code, in case of rename, also do it in the server code
export type TDataProviderFilter = {
    // a list of columns the client sees, over which to search over
    textFilterColumns?: string[];
    filteredText?: string;
    sortColumns?: TDataProviderFilterSort[];
    filterSelection?: TAdvFilterSelection;
    filterValues?: TAdvFilterValues;
    customFilteredFields?: { FieldName: string; FieldValue: string }[];
};

export interface IAdvDataProvider {
    providerName: string; // the name of the type structure of this field is used in the server code, in case of rename, also do it in the server code
    datasourceName: string; // the name of the type structure of this field is used in the server code, in case of rename, also do it in the server code
    providerKey: string; // the name of the type structure of this field is used in the server code, in case of rename, also do it in the server code

    constFilterValueMapping: TDataProviderConstFilterMapping;
    dynFilterValueMapping: TDataProviderDynFilterMapping;

    defaultFilter: TDataProviderFilter;

    // load all data at once and select them all
    loadAndSelectAll: boolean;
    // like loadAndSelectAll just without selecting
    loadAll?: boolean;
    isLive?: boolean;
}

// check if all fields exists. Useful if attributes change or are added
export const checkDataProviderCorrectness = (provider: IAdvDataProvider) => {
    const provKeys = Object.keys(provider);
    let retProv = JSON.parse(JSON.stringify(provider));
    if (!provKeys.includes("providerKey"))
        retProv = Object.assign({}, { ...retProv, ["providerKey"]: nanoid() });
    if (!provKeys.includes("providerName"))
        retProv = Object.assign({}, { ...retProv, ["providerName"]: "unknown" + nanoid() });
    if (!provKeys.includes("datasourceName"))
        retProv = Object.assign({}, { ...retProv, ["datasourceName"]: "unknown" + nanoid() });
    if (!provKeys.includes("constFilterValueMapping"))
        retProv = Object.assign({}, { ...retProv, ["constFilterValueMapping"]: {} });
    if (!provKeys.includes("dynFilterValueMapping"))
        retProv = Object.assign({}, { ...retProv, ["dynFilterValueMapping"]: {} });
    if (!provKeys.includes("defaultFilter"))
        retProv = Object.assign({}, { ...retProv, ["defaultFilter"]: {} });
    return retProv;
};

export type IAdvDataProviderPayload = IAdvDataProvider;

export interface IDataField {
    Values: Array<unknown>; // Vereinfacht
}
export type IDataFields = Record<string /* FeldName */, IDataField>;

export enum EFieldSettingsFieldTypes {
    default = "",
    memo = "memo",
    date = "date",
    datetime = "datetime",
    boolean = "boolean",
    currency = "currency",
    string = "string",
    number = "number",
}

export enum EFieldSettingsFieldDomain {
    unknown = "",
    percent = "PERCENT",
}

export interface IFieldSettings {
    IsMultiLangField: boolean;
    FieldType: EFieldSettingsFieldTypes;
    FieldDomain: EFieldSettingsFieldDomain;
    Len: number; // length of the field. 0 means unknown or not used
}

export interface IDataStructureField {
    IsLinkToTable?: boolean; // TODO: Ist immer TRUE, sollte es aber nicht?
    LinkIcon?: string;
    LinkPage?: string;
    LinkTableName?: string;
    LinkTableAlias?: string;
    ColumnIndex: number;
    Settings: IFieldSettings;
    Properties: TFieldRuntimeProperties;
}
export type IDataStructureFields = Record<string /* FeldName */, IDataStructureField>;

export type TBlockSorting = {
    Field: string;
    IsDesc: boolean;
};

export interface IDataProviderRecordResponseData {
    Fields: IDataFields;
    MaxSequence: number;
    maxSeqIsEoF: boolean;
    Sorting: Array<TBlockSorting>;
    Filters: Record<string, number>;
}

export interface IDataProviderRecordResponse {
    dataJSON: string; // refers to IDataProviderRecordResponseData
}

export interface IPublicFilterOption {
    Name: string;
    ActiveByDefault: boolean;
}

export interface IPublicFilterCategory {
    Name: string;
    Options: Array<IPublicFilterOption>;
}

export interface IDataProviderSocketGetDataStructureResponse {
    Fields: IDataStructureFields;
    PossibleFilter: Array<IPublicFilterCategory>;
    Errs?: Array<string>;
}

export type TFieldRuntimeProperties = {
    ForceHidden: boolean;
};

export type TDataProviderSocketGetStructureAndDataResponse = {
    Structure: IDataProviderSocketGetDataStructureResponse;
    Data: IDataProviderRecordResponse;
};

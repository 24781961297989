import AdvTable from "@components/data/table-new";
import { endlessTableProps } from "@components/data/table-new/endless/designable";
import {
    TAdvTableColumn,
    TAdvTableProps,
    createAdvTableColumn,
} from "@components/data/table-new/types";
import AdvText from "@components/data/text";
import { TAdvCommonProperties } from "@components/other/common-properties";
import { LAN } from "@data/language/strings";
import { DefaultComponentCategory } from "@feature/Designer/types/category";
import { TAdvDesignerComponentProps } from "@feature/Designer/types/component-props";
import { EComponentTypeCustom } from "@feature/Designer/types/component-type";
import { AdvProperty, registerDesignableComponent } from "@feature/Designer/utils";
import { TableColumnSizingOptions } from "@fluentui/react-components";
import {
    EAdvValueBinderLogicType,
    EAdvValueBinderType,
    TAdvValueBindingParams,
    useAdvValueBinderAsArrayNoDataType,
    useAdvValueBinderNoDataType,
} from "@hooks/dynamic/useAdvValueBinder";
import { toAdvText } from "@hooks/language/useTranslation";
import useAdvComponent from "@hooks/useAdvComponent";
import { useAdvObjMemo } from "@hooks/useAdvObjMemo";
import { OrderVSAIcon } from "@themes/icons";
import { EAdvValueDataTypes } from "@utils/data-types";
import { deepCompareJSXProps } from "@utils/deep-compare";
import React, { useMemo } from "react";
import { ORDER_PAGE_BINDINGS, ORDER_PAGE_VARS, TOrderVSA } from "../const";

require("datejs");

const HeaderText = ({ children }: { children: string }) => (
    <AdvText
        styles={{ root: { textOverflow: "ellipsis", wordWrap: "break-word", overflowX: "hidden" } }}
    >
        {children}
    </AdvText>
);

const columns: TAdvTableColumn<TOrderVSA>[] = [
    createAdvTableColumn<TOrderVSA>({
        columnId: "kdNr",
        renderHeaderCell: () => <HeaderText>{LAN.KDNR_SHORT.text}</HeaderText>,
        renderCell: ({ KdNr }) => String(KdNr),
        contains: ({ KdNr }, text) => String(KdNr).toLowerCase().includes(text.toLowerCase()),
        compare: ({ KdNr: a }, { KdNr: b }) => {
            return a == b ? 0 : a > b ? 1 : -1;
        },
    }),
    createAdvTableColumn<TOrderVSA>({
        columnId: "vsaNr",
        renderHeaderCell: () => <HeaderText>{LAN.VSANR_SHORT.text}</HeaderText>,
        renderCell: ({ VsaNr }) => String(VsaNr),
        contains: ({ VsaNr }, text) => String(VsaNr).toLowerCase().includes(text.toLowerCase()),
        compare: ({ VsaNr: a }, { VsaNr: b }) => {
            return a == b ? 0 : a > b ? 1 : -1;
        },
    }),
    createAdvTableColumn<TOrderVSA>({
        columnId: "vsaBez",
        renderHeaderCell: () => <HeaderText>{LAN.BEZ_SHORT.text}</HeaderText>,
        renderCell: ({ VsaBez }) => VsaBez,
        contains: ({ VsaBez }, text) => VsaBez.toLowerCase().includes(text.toLowerCase()),
        compare: ({ VsaBez: a }, { VsaBez: b }) => {
            return a.localeCompare(b);
        },
    }),
    createAdvTableColumn<TOrderVSA>({
        columnId: "anfAnz",
        renderHeaderCell: () => <HeaderText>{LAN.ANFORDERUNGEN.text}</HeaderText>,
        renderCell: ({ AnzAnfKo }) => `${AnzAnfKo}`,
        contains: ({ AnzAnfKo }, text) =>
            String(AnzAnfKo).toLowerCase().includes(text.toLowerCase()),
        compare: ({ AnzAnfKo: a }, { AnzAnfKo: b }) => {
            return a == b ? 0 : a > b ? 1 : -1;
        },
    }),
    createAdvTableColumn<TOrderVSA>({
        columnId: "anfStatus",
        renderHeaderCell: () => <HeaderText>{LAN.ANF_NIEDR_STATUS.text}</HeaderText>,
        renderCell: ({ MinAnfKoStatusBez }) => MinAnfKoStatusBez,
        contains: ({ MinAnfKoStatusBez }, text) =>
            MinAnfKoStatusBez.toLowerCase().includes(text.toLowerCase()),
        compare: ({ MinAnfKoStatusBez: a }, { MinAnfKoStatusBez: b }) => {
            return a.localeCompare(b);
        },
    }),
];

const columnSizingOptions: TableColumnSizingOptions = {
    kdNr: {
        minWidth: 80,
        idealWidth: 100,
    },
    vsaNr: {
        minWidth: 80,
        idealWidth: 100,
    },
    vsaBez: {
        minWidth: 1,
        idealWidth: 1000,
    },
    anfAnz: {
        minWidth: 70,
    },
    anfStatus: {
        minWidth: 70,
    },
};

export type TAdvOrderEndlessVSAStyles = {}; /* do not change */

export type TAdvOrderEndlessVSAProps = TAdvTableProps<TOrderVSA> &
    TAdvCommonProperties &
    TAdvDesignerComponentProps & {
        providerData?: string;
        providerDataBindingParams?: TAdvValueBindingParams;
    };

const AdvOrderEndlessVSAComp = ({
    providerDataBindingParams,
    advhide,
    advhideBindingParams,
    designerProps,
    designerData,
    ...props
}: TAdvOrderEndlessVSAProps) => {
    useAdvComponent(AdvOrderEndlessVSAComp, props);

    const [providerData] = useAdvValueBinderAsArrayNoDataType(
        providerDataBindingParams,
        [],
        EAdvValueDataTypes.Any,
    );

    const [shouldHide] = useAdvValueBinderNoDataType(
        advhideBindingParams,
        advhide,
        EAdvValueDataTypes.Any,
        0,
    );

    // Quasi das onChanged-Event. Jeder Wert ist ein eigenes, hartkodiertes Binding, sodass wir alle Werte anzeigen könnten wenn wir wollten.
    const [, setSelectedVsaID] = useAdvValueBinderNoDataType(
        ORDER_PAGE_BINDINGS.VSAList,
        -1,
        EAdvValueDataTypes.Any,
        0,
    );
    const [, setSelectedVsaBez] = useAdvValueBinderNoDataType(
        {
            bindingLogic: EAdvValueBinderLogicType.Value,
            bindingLogicValue: "",
            bindingTypeName: EAdvValueBinderType.BinderTypePageVariable,
            bindingParams: {
                getPageVar: { value: ORDER_PAGE_VARS.VSA.Bez, options: {} },
            },
        },
        "",
        EAdvValueDataTypes.Any,
        0,
    );

    const parsedProviderData = useAdvObjMemo(() => {
        const parsed = (providerData ?? []).map((data) => JSON.parse(data)) as TOrderVSA[];
        return parsed;
    }, [providerData]);

    const items = useMemo(() => {
        return parsedProviderData;
    }, [parsedProviderData]);

    if (shouldHide === true && designerProps === undefined) return <></>;
    return (
        <AdvTable<TOrderVSA>
            {...props}
            items={items}
            columns={columns}
            onSelectionChange={(items) => {
                if (items.length > 0) {
                    if (designerData !== undefined) return; // Im Designer nichts an den Server schicken

                    const { VsaID, VsaBez } = items[0] as TOrderVSA;
                    setSelectedVsaID(VsaID);
                    setSelectedVsaBez(VsaBez);
                }
            }}
            selectionMode="single"
            columnSizingOptions={columnSizingOptions}
            canFilter
            sortable
            defaultSortState={{ sortColumn: "kdNr", sortDirection: "ascending" }}
        ></AdvTable>
    );
};

const AdvOrderEndlessVSA = React.memo(AdvOrderEndlessVSAComp, deepCompareJSXProps);
export default AdvOrderEndlessVSA;

const OrderEndlessVSAProps = [
    AdvProperty.Text.create(
        toAdvText(LAN.PROVIDER_ORDERS),
        "providerData",
        toAdvText(LAN.GENERAL),
        toAdvText(LAN.ORDERS_ENDLESS_TABLE_PROVIDER_DATA_DESCR),
        "",
    ),
    ...endlessTableProps,
];

registerDesignableComponent({
    staticData: {
        name: LAN.ENDLESS_TABLE_VSA.text,

        type: EComponentTypeCustom.OrderEndlessVSA,
        supportsChildren: false,
        category: DefaultComponentCategory.Misc,
        icon: OrderVSAIcon,
    },
    properties: OrderEndlessVSAProps,
    propertiesBuilders: [],
    presets: [],
});

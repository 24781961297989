import { useMount, useUnmount } from "@fluentui/react-hooks";
import { useRef } from "react";

/** Wrapper für useUnmount von FluentUI. Ignoriert den ersten Render im Dev-Build */
export const useAdvUnmount = (callback: () => void) => {
    const isFirstDebugUnmount = useRef<boolean>(process.env.NEXT_PUBLIC_ENV == "dev");
    useUnmount(() => {
        if (isFirstDebugUnmount.current) {
            isFirstDebugUnmount.current = false;
            return;
        }
        callback();
    });
};

/** Wrapper für useMmount von FluentUI. Ignoriert den ersten Render im Dev-Build */
export const useAdvMount = (callback: () => void) => {
    const isFirstDebugMount = useRef<boolean>(process.env.NEXT_PUBLIC_ENV == "dev");
    useMount(() => {
        if (isFirstDebugMount.current) {
            isFirstDebugMount.current = false;
            return;
        }
        callback();
    });
};

import { IStackProps, IStackStyles, IStackTokens, ITheme } from "@fluentui/react";

// TODO: children gaps machen oft Probleme, weil Fluent UI oft -1 margins benutzt... Nervige bugs :/
const stackTokens: IStackTokens = {
    childrenGap: 4,
};

const designerStackTokens: IStackTokens = {
    childrenGap: 4,
};

const stackStyles: Partial<IStackStyles> = {
    inner: { marginRight: "0" },
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const stackDefaultStyle = (theme: ITheme): Partial<IStackStyles> => {
    return {};
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const stackDesignerStyles = (props: IStackProps, theme: ITheme): IStackStyles => {
    return {
        root: {
            // border: "2px solid " + theme.palette.neutralPrimary,
            padding: "2px",
        },
    };
};

export const defaultStackTokens = {
    normal: stackTokens,
    designer: designerStackTokens,
};

export const defaultStackStyles = {
    // Eingebunden in themes/default.ts
    normal: stackStyles,
    designer: stackDesignerStyles,
    default: stackDefaultStyle,
};

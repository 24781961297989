// eslint-disable-next-line no-restricted-imports
import { useRecoilValue, useRecoilValue_TRANSITION_SUPPORT_UNSTABLE } from "recoil";

type TRecoilValueParams<T> = Parameters<typeof useRecoilValue<T>>;

const useAdvRecoilValue = <T>(recoilState: TRecoilValueParams<T>[0]) => {
    return useRecoilValue_TRANSITION_SUPPORT_UNSTABLE(recoilState);
};

export default useAdvRecoilValue;

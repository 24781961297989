import AdvGrid from "@components/layout/grid";
import AdvGridItem from "@components/layout/grid/grid-item";
import { TAdvGridItemStyles } from "@components/layout/grid/grid-item/types";
import AdvStack from "@components/layout/stack";
import { EPageComponentSizeType } from "@components/page-component";
import { Theme, ThemeProvider } from "@fluentui/react";
import { useAdvMemoWithUpdater } from "@hooks/useAdvMemoWithUpdater";
import useAdvTheme from "@hooks/useAdvTheme";
import React, { useMemo } from "react";
import { IAdvNavItemGroup } from "./nav";
import { NavElIconizedNav, NavElNav } from "./nav-el-nav";
import { NavElTopBar } from "./nav-el-topbar";

export type TAdvNavElProps = {
    persona: React.JSX.Element | false;
    navHistory: React.JSX.Element | false;
    navGroup: IAdvNavItemGroup;
    titleText: React.JSX.Element | false;
    customerDropdown: React.JSX.Element | false;
    webAuftragButton: React.JSX.Element | false;
    mailButton: React.JSX.Element | false;

    themeTopBar: Theme;
    themeNav: Theme;

    hideNav?: boolean;
    children?: React.JSX.Element | false;

    elRef?: React.LegacyRef<HTMLDivElement> | undefined;
    pageLayout?: EPageComponentSizeType;

    customerLinkSearch?: React.JSX.Element | false;
};

/**
 * Navigation-Bar, bestehend aus der Navigation und allem was sich darunter / darüber befindet.
 */
export const AdvNavEl = ({
    persona,
    navHistory,
    navGroup,
    titleText,
    customerDropdown,
    webAuftragButton,
    mailButton,
    children,
    hideNav = false,
    themeTopBar,
    themeNav,
    elRef,
    pageLayout,
    customerLinkSearch,
}: TAdvNavElProps) => {
    const [menuMode, , setMenuMode] = useAdvMemoWithUpdater<"iconnized" | "open" | "hidden">(
        () =>
            (pageLayout ?? EPageComponentSizeType.DesktopWide) <= EPageComponentSizeType.Mobile
                ? "hidden"
                : "open",
        [pageLayout],
    );
    const theme = useAdvTheme();

    const navGridStyle = useMemo<TAdvGridItemStyles>(() => {
        return {
            root: {
                overflowX: "hidden",
                padding: 0,
                boxSizing: "border-box",
                borderRight:
                    menuMode == "hidden" ? undefined : "1px solid " + theme.palette.neutralLight,
                height: "100%",
                position: "relative",
                display: "flex",
                justifyContent: "center",
            },
        };
    }, [menuMode, theme.palette.neutralLight]);

    const navContentStyle = useMemo<TAdvGridItemStyles>(() => {
        return {
            root: {
                overflowY: "auto",
                height: "100%",
                position: "relative",
            },
        };
    }, []);

    if (hideNav) {
        return (
            <>
                {children}
                {customerLinkSearch}
            </>
        );
    }

    return (
        <AdvGrid
            rows="[header] auto [navcontent] 1fr"
            columns="1fr"
            styles={{ root: { gridGap: "0px" } }}
            verticalFill
            divProps={{ ref: elRef }}
        >
            {customerLinkSearch}
            <AdvGridItem column={1} row="header">
                <ThemeProvider theme={themeTopBar} as={React.Fragment}>
                    <NavElTopBar
                        parentTheme={theme}
                        setMenuMode={setMenuMode}
                        navHistory={navHistory}
                        persona={persona}
                        titleText={titleText}
                        pageLayout={pageLayout}
                        customerDropdown={customerDropdown}
                        webAuftragButton={webAuftragButton}
                        mailButton={mailButton}
                    ></NavElTopBar>
                </ThemeProvider>
            </AdvGridItem>
            <AdvGridItem column={1} row="navcontent">
                <AdvGrid
                    rows="[navcontent] 1fr"
                    columns={
                        "minmax(" +
                        (menuMode == "open" ? "230px" : menuMode == "iconnized" ? "50px" : "0px") +
                        ", auto) 1fr"
                    }
                    styles={{ root: { gridGap: "0" } }}
                >
                    <AdvGridItem column={1} row="navcontent" styles={navGridStyle}>
                        {menuMode == "open" ? (
                            <NavElNav
                                navGroup={navGroup}
                                themeNav={themeNav}
                                pageLayout={pageLayout}
                                setMenuMode={setMenuMode}
                            ></NavElNav>
                        ) : menuMode == "iconnized" ? (
                            <NavElIconizedNav
                                navGroup={navGroup}
                                themeNav={themeNav}
                            ></NavElIconizedNav>
                        ) : (
                            <></>
                        )}
                    </AdvGridItem>
                    <AdvGridItem column={2} row="navcontent" styles={navContentStyle}>
                        <AdvStack
                            verticalFill
                            styles={{
                                root: {
                                    position: "absolute",
                                    width: "100%",
                                    overflow: "auto",
                                    backgroundColor: theme.palette.alternativeWhite,
                                },
                            }}
                        >
                            {children}
                        </AdvStack>
                    </AdvGridItem>
                </AdvGrid>
            </AdvGridItem>
        </AdvGrid>
    );
};

import { ISpinnerStyles } from "@fluentui/react";

// Eingebunden in themes/default.ts
export const defaultLoadingStyles: Partial<ISpinnerStyles> = {
    root: {
        // Circle ist in der Mitte vom Element, also machen wir das Element (root) einfach so groß wie möglich,
        // sodass der Circle in der Mitte ist
        height: "100%",
        width: "100%",
    },
    circle: {},
};

export const loadingOverlayStyles: Partial<ISpinnerStyles> = {
    root: {
        width: "100%",
        height: "100%",

        position: "absolute",
        top: 0,
        left: 0,
        pointerEvents: "none", // Nicht klickbar

        background: "rgb(0 0 0 / 8%)",
        zIndex: 100,
    },
    label: {
        fontWeight: "600",
    },
    circle: {
        background: "rgb(0 0 0 / 2%)",
        boxShadow: "rgb(0 0 0 / 16%) 0px 10px 36px 0px, rgb(0 0 0 / 6%) 0px 0px 0px 1px",
    },
};

import AdvGridItemDesignable from "@components/layout/grid/grid-item/designable";
import AdvStackItemDesignable from "@components/layout/stack/stack-item/designable";
import {
    AdvCommonComponentAttributes,
    AdvThemeProviderProperties,
} from "@components/other/common-properties";
import { LAN } from "@data/language/strings";
import { DefaultComponentCategory } from "@feature/Designer/types/category";
import { EComponentTypeInput } from "@feature/Designer/types/component-type";
import { AdvProperty, registerDesignableComponent } from "@feature/Designer/utils";
import { toAdvText } from "@hooks/language/useTranslation";
import { DropdownIcon } from "@themes/icons";

enum EPropertyName {
    Label = "label",
    Value = "value",
    OptionsText = "optionsText",
    OptionsData = "optionsData",
}

enum EPropertyCategory {
    Allgemeines = "Allgemeines",
}

enum EPresetName {}

const registeredComponent = registerDesignableComponent({
    staticData: {
        name: LAN.DROPDOWN.text,
        type: EComponentTypeInput.Dropdown,
        supportsChildren: false,
        category: DefaultComponentCategory.Input,
        icon: DropdownIcon,
    },
    properties: [
        AdvProperty.Text.createTranslatable(
            toAdvText(LAN.LABEL),
            "translatableTextLabel",
            toAdvText(LAN.GENERAL),
            toAdvText(LAN.DROPDOWN_LABEL_DESCR),
            toAdvText("Dropdown-Label", false),
        ),
        AdvProperty.Text.createTranslatable(
            toAdvText(LAN.TEXTINPUT_DESCRIPTION),
            "translatableTextDescription",
            toAdvText(LAN.GENERAL),
            toAdvText(LAN.TEXTINPUT_DESCRIPTION_DESCR),
            toAdvText("", false),
        ),
        AdvProperty.Text.createTranslatable(
            toAdvText(LAN.PLACEHOLDER),
            "translatableTextPlaceholder",
            toAdvText(LAN.GENERAL),
            toAdvText(LAN.PLACEHOLDER_DESCR),
            toAdvText("", false),
        ),
        AdvProperty.Text.create(
            toAdvText(LAN.VALUE),
            EPropertyName.Value,
            toAdvText(LAN.GENERAL),
            toAdvText(LAN.DROPDOWN_VALUE_DESCR),
            "TestValue",
            undefined,
            undefined,
            true,
        ),
        AdvProperty.Boolean.create(
            toAdvText(LAN.MULTI_SELECT),
            "multiSelect",
            toAdvText(LAN.GENERAL),
            toAdvText(LAN.DROPDOWN_MULTI_SELECT_DESCR),
            false,
        ),
        AdvProperty.List.create(
            toAdvText(LAN.DROPDOWN_TEXT_ITEMS),
            toAdvText(LAN.TEXT),
            EPropertyName.OptionsText,
            toAdvText(LAN.GENERAL),
            toAdvText(LAN.DROPDOWN_TEXT_ITEMS_DESCR),
            [],
        ),
        AdvProperty.List.create(
            toAdvText(LAN.DROPDOWN_DATA_ITEMS),
            toAdvText(LAN.DATA),
            EPropertyName.OptionsData,
            toAdvText(LAN.GENERAL),
            toAdvText(LAN.DROPDOWN_DATA_ITEMS_DESCR),
            [],
        ),
        AdvProperty.Boolean.create(
            toAdvText(LAN.FORM_REQUIRED),
            "required",
            toAdvText(LAN.GENERAL),
            toAdvText(LAN.FORM_REQUIRED_DESCR),
            false,
        ),
        AdvProperty.Boolean.create(
            toAdvText(LAN.DISABLED),
            "disabled",
            toAdvText(LAN.GENERAL),
            toAdvText(LAN.TEXT_INPUT_DISABLED_DESCR),
            false,
        ),
        AdvProperty.Boolean.create(
            toAdvText(LAN.HIDE_WHEN_EMPTY),
            "hideWhenEmpty",
            toAdvText(LAN.GENERAL),
            toAdvText(LAN.HIDE_WHEN_EMPTY_DESCR),
            false,
            false,
        ),
        AdvProperty.Number.create(
            toAdvText(LAN.HIDE_WHEN_BELOW_RECORDS),
            "hideWhenBelowRecords",
            toAdvText(LAN.GENERAL),
            toAdvText(LAN.HIDE_WHEN_EMPTY_DESCR),
            0,
        ),
        AdvProperty.Boolean.create(
            toAdvText(LAN.DISABLE_WHEN_EMPTY),
            "disabledWhenEmpty",
            toAdvText(LAN.GENERAL),
            toAdvText(LAN.DISABLED_WHEN_EMPTY_DESCR),
            false,
            false,
        ),
        AdvProperty.Object.createConst("options", []),
        AdvProperty.Text.createConst("dropdownWidth", "auto"),
        AdvProperty.Action.create(
            toAdvText(LAN.EVENTS_CHANGED),
            "onChangedEventActionParams",
            toAdvText(LAN.EVENTS),
            toAdvText(LAN.EVENTS_CHANGED_DESCR),
        ),
        ...AdvCommonComponentAttributes,
        ...AdvThemeProviderProperties,
        ...AdvStackItemDesignable.CommonProperties,
        ...AdvGridItemDesignable.CommonProperties,
    ],
    propertiesBuilders: [],
    presets: [],
});

const AdvDropdownDesignable = {
    PropertyName: EPropertyName,
    PropertyCategory: EPropertyCategory,
    PresetName: EPresetName,
    Registered: registeredComponent,
};

export default AdvDropdownDesignable;

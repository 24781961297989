/**
 * A Collection of some page related functions
 */

import { TPageInfo } from "@pages/dynamic";

// TODO: move this somewhere else
export const gAdvDynPageName = "_adv_dynpname";
export const gAdvPageAccessKey = "_advaeskey";
export const gAdvBreadcrumbIgnoreKey = "_advbcignore";
export const gAdvDesignerPageName = "_f";

export const gDefaultIgnoreParamKeys = [
    gAdvDynPageName,
    gAdvPageAccessKey,
    gAdvBreadcrumbIgnoreKey,
];

export const pageIsDynamic = (pathname: string) => {
    const url = pathname;

    const pagename = url.substring(1);
    return pagename == "dynamic";
};

export const parsePageName = (pageInfo: TPageInfo) => {
    const url = pageInfo.pathname;
    const params = pageInfo.query;

    let pagename = url.substring(1);

    if (pagename.length == 0) return "";

    if (pagename == "dynamic") {
        if (Object.keys(params).includes(gAdvDynPageName))
            pagename = params[gAdvDynPageName] as string;
        else return "";
    }
    return pagename;
};

export type TPageParserParam = { MappingName: string; MappingVal: string };

export const buildQueryAsStr = (params: TPageParserParam[]) => {
    let queryStr = "?";
    params.map((value) => {
        queryStr +=
            encodeURIComponent(value.MappingName) +
            "=" +
            encodeURIComponent(value.MappingVal) +
            "&";
    });
    if (queryStr == "?") queryStr = "";
    if (queryStr.endsWith("&")) queryStr = queryStr.substring(0, queryStr.length - 1);
    return queryStr;
};

export const getQueryValue = (
    query: Record<string, string | string[] | undefined>,
    find: string,
) => {
    if (Object.keys(query).includes(find)) {
        const q = query[find];
        if (typeof q == "string") {
            return q;
        } else if (typeof q != "undefined") {
            return q[0];
        }
    }
    return "";
};

export const buildPageParserParam = (
    query: Record<string, string | string[] | undefined>,
    exclude: string[],
) => {
    const queryList: TPageParserParam[] = [];
    Object.keys(query).forEach((val) => {
        if (!exclude.includes(val)) {
            const q = query[val];
            if (typeof q == "string") {
                queryList.push({ MappingName: val, MappingVal: q });
            } else if (typeof q != "undefined") {
                q.forEach((qval) => queryList.push({ MappingName: val, MappingVal: qval }));
            }
        }
    });
    return queryList;
};

export const buildUniquePageID = (pageInfo: TPageInfo) => {
    const queryUnique = buildQueryAsStr(
        buildPageParserParam(pageInfo.query, gDefaultIgnoreParamKeys),
    );
    const pageUnique = parsePageName(pageInfo);
    return (
        pageUnique.length.toString() +
        "_" +
        pageUnique +
        "_" +
        pageUnique.length.toString() +
        "__" +
        queryUnique.length.toString() +
        "_" +
        queryUnique +
        "_" +
        queryUnique.length.toString() +
        "__" +
        pageInfo.pageInstanceIndex.toString() +
        "__"
    );
};

// modified https://stackoverflow.com/a/51359101
export const parseQueryString = (query: string): Record<string, string | string[] | undefined> => {
    return query
        ? (/^[?#]/.test(query) ? query.slice(1) : query).split("&").reduce((params: any, param) => {
              const [key, value] = param.split("=");
              const newVal =
                  value != undefined ? decodeURIComponent(value.replace(/\+/g, " ")) : "";
              if (Object.keys(params).includes(newVal)) {
                  params[key] = Array.isArray(params[key])
                      ? [...params[key], newVal]
                      : [params[key], newVal];
              } else {
                  params[key] = newVal;
              }
              return params;
          }, {})
        : {};
};

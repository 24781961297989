import { advlog } from "@utils/logging";
import { atomFamily } from "recoil";

export class CUndoAction {
    ActionFuncName = "";
    DoActionFuncArgs = new Array<any>();
    UndoActionFuncArgs = new Array<any>();
    DoActionLabel = "";
    UndoActionLabel = "";

    DoHintNoChange = false;
    UndoHintNoChange = false;
}

export class CUndoActionGroup {
    UndoActions = new Array<CUndoAction>();
    Name = "";

    RedoActionGroup(callDoFunc: (registerName: string, ...theArgs: any[]) => boolean) {
        for (const undoAction of this.UndoActions) {
            advlog(
                "[UndoManager]" +
                    (this.Name != "" ? " [" + this.Name + "]" : "") +
                    " [Redo] " +
                    undoAction.DoActionLabel,
            );
            if (!callDoFunc(undoAction.ActionFuncName, ...undoAction.DoActionFuncArgs))
                return false;
        }
        return true;
    }

    UndoActionGroup(callUndoFunc: (registerName: string, ...theArgs: any[]) => boolean) {
        for (let i = this.UndoActions.length; i > 0; --i) {
            advlog(
                "[UndoManager]" +
                    (this.Name != "" ? " [" + this.Name + "]" : "") +
                    " [Undo] " +
                    this.UndoActions[i - 1].UndoActionLabel,
            );
            if (
                !callUndoFunc(
                    this.UndoActions[i - 1].ActionFuncName,
                    ...this.UndoActions[i - 1].UndoActionFuncArgs,
                )
            )
                return false;
        }
        return true;
    }
}

export type TUndoAction = (Args: any[]) => void;

export class CUndoManager {
    UndoActionsGroups = new Array<CUndoActionGroup>();
    CurrentActionIndex = -1;

    RegisteredFunctions = new Map<string, { UndoFunc: TUndoAction; DoFunc: TUndoAction }>();

    DoActionImpl(groupName: string, undoAction: CUndoAction) {
        // drop overwritten undo events
        while (this.UndoActionsGroups.length > this.CurrentActionIndex + 1) {
            this.UndoActionsGroups.pop();
        }

        advlog(
            "[UndoManager]" +
                (groupName != "" ? " [" + groupName + "]" : "") +
                " [Do] " +
                undoAction.DoActionLabel,
        );
        this.CallDo(undoAction.ActionFuncName, ...undoAction.DoActionFuncArgs);
        const didAddNewGroup =
            groupName == "" ||
            this.UndoActionsGroups.length == 0 ||
            this.UndoActionsGroups[this.UndoActionsGroups.length - 1].Name != groupName;
        if (didAddNewGroup) {
            this.UndoActionsGroups.push(new CUndoActionGroup());
            this.UndoActionsGroups[this.UndoActionsGroups.length - 1].Name = groupName;
        }
        this.UndoActionsGroups[this.UndoActionsGroups.length - 1].UndoActions.push(undoAction);
        if (didAddNewGroup) ++this.CurrentActionIndex;
    }

    DoAction(undoAction: CUndoAction) {
        this.DoActionImpl("", undoAction);
        return !undoAction.DoHintNoChange;
    }

    DoGroupAction(groupName: string, undoAction: CUndoAction) {
        this.DoActionImpl(groupName, undoAction);
        return !undoAction.DoHintNoChange;
    }

    CanUndo() {
        return this.CurrentActionIndex >= 0;
    }

    undoText() {
        if (this.CanUndo()) {
            return this.UndoActionsGroups[this.CurrentActionIndex].UndoActions[0].UndoActionLabel;
        }
        return "";
    }

    UndoAction() {
        // undo the whole event group
        if (this.CanUndo()) {
            const canNotSave =
                this.UndoActionsGroups[this.CurrentActionIndex].UndoActions[0].UndoHintNoChange;
            this.UndoActionsGroups[this.CurrentActionIndex].UndoActionGroup(
                this.CallUndo.bind(this),
            );
            --this.CurrentActionIndex;

            return !canNotSave;
        }
        return false;
    }

    CanRedo() {
        return this.CurrentActionIndex + 1 < this.UndoActionsGroups.length;
    }

    redoText() {
        if (this.CanRedo()) {
            return this.UndoActionsGroups[this.CurrentActionIndex + 1].UndoActions[0].DoActionLabel;
        }
        return "";
    }

    RedoAction() {
        // redo the whole event group
        if (this.CanRedo()) {
            const canNotSave =
                this.UndoActionsGroups[this.CurrentActionIndex + 1].UndoActions[0].DoHintNoChange;
            this.UndoActionsGroups[this.CurrentActionIndex + 1].RedoActionGroup(
                this.CallDo.bind(this),
            );
            ++this.CurrentActionIndex;

            return !canNotSave;
        }
        return false;
    }

    Register(registerName: string, undoFunc: TUndoAction, doFunc: TUndoAction) {
        const aFunc = this.RegisteredFunctions.get(registerName);
        if (aFunc != null)
            advlog("Registering an already registered undo function: " + registerName);
        this.RegisteredFunctions.set(registerName, { UndoFunc: undoFunc, DoFunc: doFunc });
    }

    Unregister(registerName: string) {
        const aFunc = this.RegisteredFunctions.get(registerName);
        if (aFunc == null)
            advlog("Unregistering an already unregistered undo function: " + registerName);
        this.RegisteredFunctions.delete(registerName);
    }

    CallUndo(registerName: string, ...theArgs: any[]) {
        const aFunc = this.RegisteredFunctions.get(registerName);
        if (aFunc) {
            aFunc.UndoFunc(theArgs);
            return true;
        } else {
            advlog("Calling undo of function that is not registered: " + registerName);
            return false;
        }
    }

    CallDo(registerName: string, ...theArgs: any[]) {
        const aFunc = this.RegisteredFunctions.get(registerName);
        if (aFunc) {
            aFunc.DoFunc(theArgs);
            return true;
        } else {
            advlog("Calling do of function that is not registered: " + registerName);
            return false;
        }
    }
}

export const recoilUndoManagersState = atomFamily<
    {
        canRedo: boolean;
        canUndo: boolean;
        shouldSave: boolean;
        redoText: string;
        undoText: string;
    },
    string
>({
    key: "designerUndoManagersState",
    default: {
        canRedo: false,
        canUndo: false,
        shouldSave: false,
        redoText: "",
        undoText: "",
    },
});

export const recoilUndoManagers = atomFamily<CUndoManager, string>({
    key: "designerUndoManagers",
    default: () => new CUndoManager(),
    dangerouslyAllowMutability: true,
});

export const gDesignerFileListUndoManagerKey = "designerGlobalFileListUndoManager";
export const gDesignerUndoManagerKey = "designerGlobalDesignerUndoManager";

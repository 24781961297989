import { useEffectDebugger } from "@hooks/misc/useEffectDebugger";
import { EVerbosityLevel, verbosityLevel } from "@hooks/shared";
// eslint-disable-next-line no-restricted-imports
import { useLayoutEffect } from "react";

type TEffectParams = Parameters<typeof useLayoutEffect>;

// allow unused since this hook as runtime overhead it must be explicitly enabled
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const useAdvLayoutEffect =
    verbosityLevel > EVerbosityLevel.None
        ? (effect: TEffectParams[0], deps?: TEffectParams[1]) => {
              // eslint-disable-next-line react-hooks/exhaustive-deps
              useLayoutEffect(effect, deps);
              useEffectDebugger("verbose-useAdvLayoutEffect-" + effect.name, () => {}, deps);
          }
        : (effect: TEffectParams[0], deps?: TEffectParams[1]) => {
              // eslint-disable-next-line react-hooks/exhaustive-deps
              useLayoutEffect(effect, deps);
          };

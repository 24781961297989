import { classNamesFunction, styled } from "@fluentui/react";

import { TAdvGridProps, TAdvGridStyleProps, TAdvGridStyles } from "./types";

import { getDesignerModeComponentStyle, getSelectedComponentStyle } from "@feature/Designer/utils";
import {
    IsValueBindingTrivial,
    useAdvValueBinderNoDataType,
} from "@hooks/dynamic/useAdvValueBinder";
import { EAdvValueDataTypes } from "@utils/data-types";
import { deepCompareJSXProps } from "@utils/deep-compare";
import { combineStyles } from "@utils/styling";
import React, { useMemo } from "react";

const getClassNames = classNamesFunction<TAdvGridStyleProps, TAdvGridStyles>();

type TAdvGridPropsClean = Omit<
    TAdvGridProps,
    | "columnsBindingParams"
    | "rowsBindingParams"
    | "verticalFillBindingParams"
    | "horizontalFillBindingParams"
>;

const AdvGridBaseImplComp = ({
    children,
    advhide,
    designerData,
    styles: propStyles,
    divProps,
    columns,
    rows,
    verticalFill,
    horizontalFill,
    designerProps,
    alignItems,
    ...props
}: TAdvGridProps) => {
    useAdvComponent(AdvGridBaseImplComp, props);

    const theme = useAdvTheme();

    const styles = useMemo(() => {
        let ret = propStyles;
        if ((designerData?.isSelected ?? false) && (designerData?.renderAsDesigner ?? false))
            ret = combineStyles(ret, { root: getSelectedComponentStyle(theme, true) });
        if (designerData?.renderAsDesigner ?? false)
            ret = combineStyles(ret, { root: getDesignerModeComponentStyle(theme) });
        if ((alignItems ?? "") != "") ret = combineStyles(ret, { root: { alignItems } });
        return ret;
    }, [alignItems, designerData?.isSelected, designerData?.renderAsDesigner, propStyles, theme]);

    const newProps: TAdvGridPropsClean = {
        ...props,
        columns,
        rows,
        verticalFill,
        horizontalFill,
        children,
    };

    const classNames = getClassNames(styles, newProps);

    if (advhide === true && designerProps === undefined) return <></>;
    // https://css-tricks.com/snippets/css/complete-guide-grid/
    return (
        <div {...divProps} {...designerProps} className={`${classNames.root ?? ""} adv-grid`}>
            {children}
        </div>
    );
};
const AdvGridBaseImpl = React.memo(AdvGridBaseImplComp, deepCompareJSXProps);

const AdvGridBaseSimple = (props: TAdvGridProps) => {
    return <AdvGridBaseImpl {...props}></AdvGridBaseImpl>;
};

const AdvGridBaseComplex = ({
    columnsBindingParams,
    rowsBindingParams,
    verticalFillBindingParams,
    horizontalFillBindingParams,
    advhide,
    advhideBindingParams,
    columns,
    rows,
    verticalFill,
    horizontalFill,
    dataArrayIndex = 0,
    ...props
}: TAdvGridProps) => {
    const [columnsValue] = useAdvValueBinderNoDataType(
        columnsBindingParams,
        columns,
        EAdvValueDataTypes.Any,
        dataArrayIndex,
    );
    const [rowsValue] = useAdvValueBinderNoDataType(
        rowsBindingParams,
        rows,
        EAdvValueDataTypes.Any,
        dataArrayIndex,
    );
    const [isVerticalFillValue] = useAdvValueBinderNoDataType(
        verticalFillBindingParams,
        verticalFill,
        EAdvValueDataTypes.Any,
        dataArrayIndex,
    );
    const [isHorizontalFillValue] = useAdvValueBinderNoDataType(
        horizontalFillBindingParams,
        horizontalFill,
        EAdvValueDataTypes.Any,
        dataArrayIndex,
    );
    const [shouldHide] = useAdvValueBinderNoDataType(
        advhideBindingParams,
        advhide,
        EAdvValueDataTypes.Any,
        dataArrayIndex,
    );

    return (
        <AdvGridBaseImpl
            {...props}
            columns={columnsValue}
            rows={rowsValue}
            verticalFill={isVerticalFillValue}
            horizontalFill={isHorizontalFillValue}
            advhide={shouldHide}
            columnsBindingParams={columnsBindingParams}
            rowsBindingParams={rowsBindingParams}
            verticalFillBindingParams={verticalFillBindingParams}
            horizontalFillBindingParams={horizontalFillBindingParams}
            advhideBindingParams={advhideBindingParams}
        ></AdvGridBaseImpl>
    );
};

const AdvGridBase = ({
    columnsBindingParams,
    rowsBindingParams,
    verticalFillBindingParams,
    horizontalFillBindingParams,
    advhideBindingParams,
    ...props
}: TAdvGridProps) => {
    if (
        IsValueBindingTrivial(columnsBindingParams) &&
        IsValueBindingTrivial(rowsBindingParams) &&
        IsValueBindingTrivial(verticalFillBindingParams) &&
        IsValueBindingTrivial(horizontalFillBindingParams) &&
        IsValueBindingTrivial(advhideBindingParams)
    )
        return (
            <AdvGridBaseSimple
                {...props}
                columnsBindingParams={columnsBindingParams}
                rowsBindingParams={rowsBindingParams}
                verticalFillBindingParams={verticalFillBindingParams}
                horizontalFillBindingParams={horizontalFillBindingParams}
                advhideBindingParams={advhideBindingParams}
            ></AdvGridBaseSimple>
        );
    else
        return (
            <AdvGridBaseComplex
                {...props}
                columnsBindingParams={columnsBindingParams}
                rowsBindingParams={rowsBindingParams}
                verticalFillBindingParams={verticalFillBindingParams}
                horizontalFillBindingParams={horizontalFillBindingParams}
                advhideBindingParams={advhideBindingParams}
            ></AdvGridBaseComplex>
        );
};

const getStyle = ({
    columns,
    rows,
    verticalFill = true,
    horizontalFill = true,
    rowGap,
    colGap,
}: TAdvGridStyleProps): TAdvGridStyles => {
    return {
        root: {
            display: "grid",
            gridTemplateRows: rows,
            gridTemplateColumns: columns,
            height: verticalFill ? "100%" : "auto",
            width: horizontalFill ? "100%" : "auto",
            gridRowGap: rowGap ?? "4px",
            gridColumnGap: colGap ?? "4px",
        },
    };
};

const AdvGridComp: React.FunctionComponent<TAdvGridProps> = styled<
    TAdvGridProps,
    TAdvGridStyleProps,
    TAdvGridStyles
>(AdvGridBase, getStyle, undefined, { scope: "AdvGrid" });

const AdvGrid = React.memo(AdvGridComp, deepCompareJSXProps);
export default AdvGrid;

import useAdvComponent from "@hooks/useAdvComponent";
import useAdvTheme from "@hooks/useAdvTheme";
import "./designable";

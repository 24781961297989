import { useAdvCallback } from "@hooks/react-overload/useAdvCallback";

import {
    TAdvTransactionInterface,
    useAdvRecoilTransaction,
} from "./recoil-overload/useAdvRecoilTransaction";
import { useAdvEvent } from "./useAdvEvent";
import { useAdvSocket } from "./useAdvSocket";

let callbackCounter = 0;

interface ICallbackData {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    __callbackID: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    __bereich: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    __name: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    __payload: string;
}

export type TSuccessClass = {
    Success: boolean;
};

export type TSuccessWithMsgClass = {
    Success: boolean;
    Msg: string;
};

export type TDelphiPair<K, V> = {
    Key: K;
    Value: V;
};

export type TSuccessWithMsgsClass = {
    Success: boolean;
    Msgs: Array<TDelphiPair<string, Array<any>>>;
};

const callbacks: Record<number, (AData: any) => void> = {};

const eventCallbackGlobal = function (aData: any, aExtraDataInfo: string) {
    let id: number = aData["__callbackID"];
    if (aExtraDataInfo != "") {
        id = parseInt(aExtraDataInfo);
    }
    if (typeof id != "number" || isNaN(id)) {
        throw "Callback-ID missing";
    } else {
        const handler = callbacks[id];

        if (typeof handler == "undefined") {
            throw (
                "handler for Callback (" +
                id.toString() +
                ") unknown (data: " +
                JSON.stringify(aData) +
                ")"
            );
        } else {
            const parsedPayload = JSON.parse(aData.__payload);

            handler(parsedPayload);
            delete callbacks[id];
        }
    }
};

/**
 * Registriert ein Callback für Socket-Events. Sollte der User Daten senden, wird hält hier ein Promise the Callback-Daten
 * @returns Funktionen zum Senden von Daten, die die Callback-Daten im Promise zurück geben. Den State der Socket-Connection
 */
export function useAdvSocketCallback() {
    const { sendEventDataTrans } = useAdvSocket();

    const { registerGlobalEventHandler } = useAdvEvent();

    const getNextCallbackEventID = useAdvCallback(function (): number {
        return callbackCounter++;
    }, []);

    registerGlobalEventHandler<ICallbackData>("callbackEvent", "receive", eventCallbackGlobal);
    const sendCallbackRequestTrans = useAdvCallback(
        (tb: TAdvTransactionInterface) =>
            function (
                resolve: (dataOut: any) => void,
                aBereich: string,
                aName: string,
                aData: any,
                aIgnoreLoginState = false,
            ) {
                const _id = getNextCallbackEventID();
                callbacks[_id] = resolve;

                const payload: ICallbackData = {
                    __callbackID: _id,
                    __bereich: aBereich,
                    __name: aName,
                    __payload: JSON.stringify(aData),
                };

                sendEventDataTrans(tb)("callbackEvent", "receive", payload, aIgnoreLoginState);
            },
        [getNextCallbackEventID, sendEventDataTrans],
    );

    const sendCallbackRequestTransInternal = useAdvRecoilTransaction(sendCallbackRequestTrans, [
        sendCallbackRequestTrans,
    ]);

    const sendCallbackRequest = useAdvCallback(
        function <TIn, TOut>(
            aBereich: string,
            aName: string,
            aData: TIn,
            aIgnoreLoginState = false,
        ): Promise<TOut> {
            const outDataObj: { outData: any; resolve: any } = {
                outData: undefined,
                resolve: undefined,
            };
            const resolveInner = function (aDataObj: typeof outDataObj, aDataInner: TOut) {
                if (aDataObj.resolve != undefined) aDataObj.resolve(aDataInner);
                else aDataObj.outData = aDataInner;
            }.bind(null, outDataObj);

            sendCallbackRequestTransInternal(
                resolveInner,
                aBereich,
                aName,
                aData,
                aIgnoreLoginState,
            );

            return new Promise<TOut>(
                function (
                    aDataObj: typeof outDataObj,
                    resolve: (value: TOut | PromiseLike<TOut>) => void,
                ) {
                    if (aDataObj.outData != undefined) {
                        resolve(aDataObj.outData);
                    } else {
                        aDataObj.resolve = resolve;
                    }
                }.bind(null, outDataObj),
            );
        },
        [sendCallbackRequestTransInternal],
    );

    return { sendCallbackRequest, sendCallbackRequestTrans };
}

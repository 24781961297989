import { useAdvCallback } from "@hooks/react-overload/useAdvCallback";
// eslint-disable-next-line no-restricted-imports
import { useResetRecoilState } from "recoil";

type TResetRecoilStateParams = Parameters<typeof useResetRecoilState>;

const useAdvResetRecoilState = (recoilState: TResetRecoilStateParams[0]) => {
    const setter = useResetRecoilState(recoilState);
    const setterWrapper = useAdvCallback<typeof setter>(() => {
        setTimeout(() => setter(), 0);
    }, [setter]);
    return setterWrapper;
};

export default useAdvResetRecoilState;

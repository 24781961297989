import { TAdvTextStyles } from "@components/data/text";
import {
    IButtonStyles,
    IStackItemProps,
    IStyle,
    IStyleFunctionOrObject,
    Theme,
} from "@fluentui/react";

import { TAdvAppliedTheme } from "@hooks/useAdvTheme";
import { TAdvGridItemStyles } from "../grid/grid-item/types";
import { TAdvStackItemStyles } from "../stack/stack-item";

const headingStyle: IStyle = {
    background: "transparent",
    borderWidth: 0,
    paddingLeft: 4,
    width: "100%",
};

const headingButtonStyle: IButtonStyles = {
    root: { ...headingStyle, textAlign: "left" },
    textContainer: { marginLeft: "4px" },
};

const headingTextStyle = (theme: TAdvAppliedTheme): TAdvTextStyles => {
    return {
        root: {
            ...headingStyle,
            marginTop: "4px",
            marginLeft: "4px",
            color: theme.palette.neutralSecondary,
        },
    };
};
// Mit folgendem Code ändert sich der Hintergrund aber ebenfalls (je nach Theme):
const groupboxContainerStyle: (
    theme: Theme,
    withBorder: boolean,
) => IStyleFunctionOrObject<IStackItemProps, TAdvStackItemStyles> = (
    theme: Theme,
    withBorder = true,
) => {
    if (withBorder)
        return {
            root: {
                border: "1px solid " + theme.palette.neutralLight,
                boxSizing: "border-box",
                padding: 4,
                margin: 0,
                backgroundColor: theme.palette.white,
            },
        };
    else
        return {
            root: {
                padding: 3,
                margin: 0,
                backgroundColor: theme.palette.white,
            },
        };
};

const headContainerStyle: TAdvGridItemStyles = {
    root: {
        overflowX: "hidden",
        whiteSpace: "normal",
        textOverflow: "ellipsis",
    },
};

const contentContainerStyle: TAdvGridItemStyles = {
    root: {
        padding: 5,
        boxSizing: "border-box",
        overflowY: "auto",
        // height: "100%"
    },
};

const contentContainerDesignerStyle: TAdvGridItemStyles = {
    root: {
        padding: 5,
        boxSizing: "border-box",
        overflowY: "auto",
        minHeight: 24,
    },
};

export const defaultGroupboxStyles = {
    headingButton: headingButtonStyle,
    headingText: headingTextStyle,

    headContainer: headContainerStyle,
    contentContainer: contentContainerStyle,
    contentContainerDesigner: contentContainerDesignerStyle,
    groupboxContainer: groupboxContainerStyle,
};

import { IDataStructureField } from "@components/dynamic/data-provider/types";
import { AdvValueBindingDefaultValue } from "@hooks/dynamic/useAdvValueBinder";
import deepCopy from "@utils/deep-copy";
import { nanoid } from "nanoid";
import { COLUMN_MAX_WIDTH, COLUMN_MIN_WIDTH } from "./const";
import { TAdvCustomColumnsDefinition } from "./types";

function removePrefix(source: string, delimiter: string = "_"): string {
    const index = source.indexOf(delimiter);
    if (index == -1 || index == source.length - 1) return source;
    else return source.substring(index + 1);
}

export type TDefaultTableColumn = {
    key?: string;
    name?: string;
    fieldName?: string;
};

const singleNumberWidth = 8;
const extraWidthIcons = 16;

const estFontSize = 15;
export function estimateFontSize(text?: string, fontSize = estFontSize): number {
    if (text == undefined || text == "") return 0;
    const widths = [
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0.278125, 0.278125, 0.35625, 0.55625, 0.55625, 0.890625, 0.6671875, 0.1921875, 0.334375,
        0.334375, 0.390625, 0.584375, 0.278125, 0.334375, 0.278125, 0.303125, 0.55625, 0.55625,
        0.55625, 0.55625, 0.55625, 0.55625, 0.55625, 0.55625, 0.55625, 0.55625, 0.278125, 0.278125,
        0.5859375, 0.584375, 0.5859375, 0.55625, 1.015625, 0.6671875, 0.6671875, 0.7234375,
        0.7234375, 0.6671875, 0.6109375, 0.778125, 0.7234375, 0.278125, 0.5, 0.6671875, 0.55625,
        0.834375, 0.7234375, 0.778125, 0.6671875, 0.778125, 0.7234375, 0.6671875, 0.6109375,
        0.7234375, 0.6671875, 0.9453125, 0.6671875, 0.6671875, 0.6109375, 0.278125, 0.35625,
        0.278125, 0.478125, 0.55625, 0.334375, 0.55625, 0.55625, 0.5, 0.55625, 0.55625, 0.278125,
        0.55625, 0.55625, 0.2234375, 0.2421875, 0.5, 0.2234375, 0.834375, 0.55625, 0.55625, 0.55625,
        0.55625, 0.334375, 0.5, 0.278125, 0.55625, 0.5, 0.7234375, 0.5, 0.5, 0.5, 0.35625,
        0.2609375, 0.3546875, 0.590625,
    ];
    const avg = 0.5293256578947368;
    return (
        ((text as string) + "")
            .split("")
            .map((c) => (c.charCodeAt(0) < widths.length ? widths[c.charCodeAt(0)] : avg))
            .reduce((cur, acc) => {
                return acc + cur;
            }) * fontSize
    );
}

export function EndlessTableMinMaxWidth(displayName: string, isInteger: boolean) {
    const minWidth = Math.max(estimateFontSize(displayName) + extraWidthIcons, COLUMN_MIN_WIDTH);
    const maxWidth = Math.min(
        isInteger == true
            ? Math.max(singleNumberWidth * 10, estimateFontSize(displayName) + extraWidthIcons)
            : COLUMN_MAX_WIDTH,
        COLUMN_MAX_WIDTH,
    );

    return { minWidth, maxWidth };
}

/**
 *
 * @param columnFieldName Column.FieldName
 * @param columnName Optional: Column.Name (default: columnFieldName)
 * @param columnKey Optional: Column.Key (default: nanoID)
 * @returns
 */
export function DefaultEndlessTableColumn(
    columnFieldName: string,
    field: IDataStructureField | null,
    columnName?: string,
): TAdvCustomColumnsDefinition {
    const fieldName = columnFieldName;
    const name = removePrefix(columnName ?? columnFieldName, "_"); // KUNDE_ID => ID

    return {
        definition: {
            name,
            fieldName,
            idealWidth: 1,
            key: nanoid(),
        },
        isHiddenBindingParams: deepCopy(AdvValueBindingDefaultValue),
        isResizableBindingParams: deepCopy(AdvValueBindingDefaultValue),
        maxWidthBindingParams: deepCopy(AdvValueBindingDefaultValue),
        minWidthBindingParams: deepCopy(AdvValueBindingDefaultValue),
        nameBindingParams: deepCopy(AdvValueBindingDefaultValue),
        field: field,
    };
}

import { IObjectWithKey } from "@fluentui/react";
import {
    EAdvValueBinderLogicType,
    EAdvValueBinderType,
    TAdvValueBindingParams,
} from "@hooks/dynamic/useAdvValueBinder";

/** Hartkodierte Page-Variablen, die VON HAND angelegt werden müssen! */
export const ORDER_PAGE_VARS = {
    UnixDate: "UnixDate",
    PrettyDate: "PrettyDate",
    SelectedDate: "SelectedDate",
    ArtikelList: "LIST_Artikel",
    VSA: {
        ID: "SEL_VSA_ID",
        Bez: "SEL_VSA_BEZ",
    },
    ANFKO: {
        ID: "SEL_ANFKO_ID",
        NR: "SEL_ANFKO_NR",
    },
};

export const ORDER_PAGE_BINDINGS = {
    ArticleList: {
        bindingLogic: EAdvValueBinderLogicType.Value,
        bindingLogicValue: "",
        bindingTypeName: EAdvValueBinderType.BinderTypePageVariable,
        bindingParams: {
            getPageVar: { value: ORDER_PAGE_VARS.ArtikelList, options: {} },
        },
    } as TAdvValueBindingParams,
    VSAList: {
        bindingLogic: EAdvValueBinderLogicType.Value,
        bindingLogicValue: "",
        bindingTypeName: EAdvValueBinderType.BinderTypePageVariable,
        bindingParams: {
            getPageVar: { value: ORDER_PAGE_VARS.VSA.ID, options: {} },
        },
    } as TAdvValueBindingParams,
    SelectedAnfKoID: {
        bindingLogic: EAdvValueBinderLogicType.Value,
        bindingLogicValue: "",
        bindingTypeName: EAdvValueBinderType.BinderTypePageVariable,
        bindingParams: {
            getPageVar: { value: ORDER_PAGE_VARS.ANFKO.ID, options: {} },
        },
    },
    SelectedAnfKoNr: {
        bindingLogic: EAdvValueBinderLogicType.Value,
        bindingLogicValue: "",
        bindingTypeName: EAdvValueBinderType.BinderTypePageVariable,
        bindingParams: {
            getPageVar: { value: ORDER_PAGE_VARS.ANFKO.NR, options: {} },
        },
    },
};

/** ANALOG ZUM SERVER; DATEN AUS DEM SQL */
export type TOrderVSA = {
    /** VsaID */
    VsaID: number;
    AbteilID: number;
    Wochentag: number; // ???

    VsaNr: number;
    VsaBez: string;
    KdNr: number;

    /** Wieviele Anforderungen existieren für diese VSA (an dem Datum?) */
    AnzAnfKo: number;
    /** Niedrigster Status der bestehenden Anforderungen. "?" falls AnzAnfKo = 0 */
    MinAnfKoStatus: string;
    /** Niedrigster StatusBez der bestehenden Anforderungen. "?" falls AnzAnfKo = 0 */
    MinAnfKoStatusBez: string;
};

/** ANALOG ZUM SERVER; DATEN AUS DEM SQL */
export type TOrderAnforderung = {
    AnfKoID: number;

    Status: string;
    StatusBez: string;

    LieferDatum: string;
    UrDatum: string;
    AuftragsNr: string;

    TotalAngefordert: number;
    TotalGeliefert: number;
};

/** ANALOG ZUM SERVER; DATEN AUS DEM SQL; Nur diese Daten werden an den Contract geschickt */
export type TOrderArtikelBase = {
    VsaAnfID: number;
    KdArtiID: number;
    ArtGroeID: number;
    AnfPoID: number; // Falls nicht vorhanden: -999
    Menge: number; // AnfPo.Angefordert
};

/** ANALOG ZUM SERVER; DATEN AUS DEM SQL */
export type TOrderArtikel = IObjectWithKey &
    TOrderArtikelBase & {
        Bereich: string;
        ArtikelNr: string;
        ArtikelBez: string;
        Variante: string;
        VariantBez: string;
        Groesse: string; // ArtGroe
        Gruppe: string; // ArtGru
        VPE: string; // <Packmenge> <Mengeneinheit>

        Durchschnitt: number; // VsaAnf
        DurchschnittTag: number; // VsaAnf
        MaxBestellMenge: number; // VsaAnf, Negativ wenn überschritten werden darf

        FesteMenge: number;
        PackMenge: number;
    };

import { advcatch } from "@utils/logging";
import { nanoid } from "nanoid";
import { TDictionaryValue } from "./dictionary-value";

export type TWaiterItem = {
    promiseGetter: () => Promise<void>;
    promise: Promise<void> | undefined;
    key: string;
};
export type TWaiter = {
    promisesQueue: Array<TWaiterItem>;
    curItem: TDictionaryValue<any> | undefined;
};
export type TWaiterQueue = Map<string, TWaiter>;
export type TSyncDicts = Map<string, TWaiterQueue>;

export function removeFamilyName(key: string) {
    const index = key.indexOf("__");
    if (index >= 0) {
        let quotedKey = key.substring(index + 2);
        if (quotedKey[0] == '"')
            quotedKey = quotedKey.substring(
                1,
                quotedKey[quotedKey.length - 1] == '"' ? quotedKey.length - 1 : undefined,
            );
        return quotedKey;
    }
    return key;
}

export const addToQueue = (
    nodeKey: string,
    syncItem: TWaiter,
    promiseGetter: () => Promise<void>,
) => {
    const promKey = nanoid();
    syncItem.promisesQueue.push({
        key: promKey,
        promiseGetter: promiseGetter,
        promise: undefined,
    });
    const getNextItem = async () => {
        for (const waitItem of syncItem.promisesQueue) {
            if (waitItem.promise == undefined) {
                waitItem.promise = waitItem.promiseGetter();
            }
            if (waitItem.promise != undefined) {
                await waitItem.promise;
            }
            if (promKey == waitItem.key) {
                const indexOfWaitItem = syncItem.promisesQueue.indexOf(waitItem);
                if (indexOfWaitItem != -1) {
                    syncItem.promisesQueue.splice(indexOfWaitItem, 1);
                }
                return;
            }
        }
        await getNextItem();
    };
    getNextItem().catch(advcatch);
};

/* eslint-disable react/display-name */
/* eslint-disable react-hooks/rules-of-hooks */
import useSsr from "@hooks/misc/useSsr";
import { useAdvCallback } from "@hooks/react-overload/useAdvCallback";
import { useAdvEffect } from "@hooks/react-overload/useAdvEffect";
import deepCopy from "@utils/deep-copy";
import assert from "assert";
import React, { useRef, useState } from "react";

type TSize = { width: number; height: number };

/**
 * Enthält den WidthProvider ({@link https://github.com/react-grid-layout/react-grid-layout/blob/master/lib/components/WidthProvider.jsx})
 * sowie unsere eigene Logik für die Höhe. Die Höhe der Layoutss wird hier dynamisch an die Höhe der Seite angepasst.
 * Funktioniert bisher nur für simple Layouts, die keine Layouts untereinander besitzen.
 */
const WidthAndHeightProvider =
    // eslint-disable-next-line @typescript-eslint/naming-convention
    <T extends ReactGridLayout.ReactGridLayoutProps>(WrappedComponent: React.ComponentType<T>) => {
        return (props: T) => {
            const defaultMargin = [10, 10]; // Default-Werte aus ReactGridLayout-Quellcode
            const { rowHeight = 150, layout: layouts, style } = props;

            const isMounted = useRef<boolean>(false);

            const [myLayout, setMyLayout] = useState<ReactGridLayout.Layout[] | undefined>(layouts);
            const [size, setSize] = useState<TSize | undefined>(undefined);
            const elementRef: React.Ref<HTMLDivElement> = React.createRef();

            const onSizeChanged = (entries: ResizeObserverEntry[]) => {
                if (!isMounted.current) return;
                setSize({
                    height: entries[0].contentRect.height,
                    width: entries[0].contentRect.width,
                });
            };

            // ResizeObserver ist nur im Browser verfügbar
            const { isServer } = useSsr();
            if (isServer) return null;

            const resizeObserver = useRef<ResizeObserver>(new ResizeObserver(onSizeChanged));

            const setInitialSize = useAdvCallback(() => {
                if (!isMounted.current) return;

                const node = elementRef.current;
                if (node instanceof HTMLElement) {
                    setSize({ height: node.offsetHeight, width: node.offsetWidth });
                }
            }, [elementRef, isMounted]);

            useAdvEffect(() => {
                isMounted.current = true;

                setInitialSize();
                if (elementRef.current && elementRef.current.parentElement) {
                    resizeObserver.current.observe(elementRef.current.parentElement);

                    const _observer = resizeObserver.current;
                    const _element = elementRef.current.parentElement;
                    return () => {
                        _observer.unobserve(_element);
                    };
                } else console.warn("HeightProvider: Could not observe Element");
                // eslint-disable-next-line react-hooks/exhaustive-deps
            }, []);

            useAdvEffect(() => {
                if (isMounted.current) {
                    if (layouts && size) {
                        if (layouts.length == 1) {
                            // Wenn wir nur ein Layout haben, dann können wir das ja einfach
                            // auf die maximale Höhe bringen
                            setMyLayout([maximizeHeight(layouts[0])]);
                        } else {
                            // Wenn mehrere Layouts vorhanden sind, sollten wir diese nur auf die maximale Höhe bringen,
                            // wenn sie NICHT überlappen
                            let isLayoutOK = false;

                            // Fall 1: Alle Layouts starten bei unterschiedlichen X-Koordinaten (=> Überlappen wahrscheinlich nicht)
                            isLayoutOK =
                                Array.from(new Set(layouts.map((layout) => layout.x))).length ==
                                layouts.length;

                            if (isLayoutOK) {
                                const newLayouts = layouts.map(maximizeHeight);
                                setMyLayout(newLayouts);
                            }
                        }
                    }
                }
                // exclude layouts
                // eslint-disable-next-line react-hooks/exhaustive-deps
            }, [size?.width, size?.height]);

            function maximizeHeight(layout: ReactGridLayout.Layout): ReactGridLayout.Layout {
                assert(size);

                const { height } = size;
                const newLayout = deepCopy(layout);
                const margin = (props.margin ?? defaultMargin)[1];
                const containerPadding = props.containerPadding
                    ? props.containerPadding[1]
                    : margin;

                // Berechnung @ReactGridLayout:
                // (siehe https://github.com/react-grid-layout/react-grid-layout/blob/2e1387bcf598c98bffd3eb149cd670c7e5990e96/lib/ReactGridLayout.jsx#L221)
                // Height  = Rows * RowHeight + (Rows - 1) * Margin + (ContainerPadding * 2)
                // Height ~= Rows * (RowHeight + Margin) + (ContainerPadding * 2)
                // => Rows = (Height - (ContainerPadding * 2)) / (RowHeight + Margin)
                const newH =
                    Math.floor((height - containerPadding * 2) / (rowHeight + margin)) -
                    layout.y -
                    1;

                newLayout.h = newH;

                // Neue Höhe darf nicht die Grenzen sprengen
                if (newLayout.minH != undefined && newH < newLayout.minH) newLayout.minH = newH;
                if (newLayout.maxH != undefined && newH > newLayout.maxH) newLayout.maxH = newH;

                // advlog("Maximize", { layout, newLayout, height, rowHeight, margin, containerPadding });
                return newLayout;
            }

            return (
                <WrappedComponent
                    {...props}
                    layout={myLayout}
                    style={{ ...style, height: "100%" }}
                    innerRef={elementRef}
                    width={size?.width}
                ></WrappedComponent>
            );
        };
    };

export default WidthAndHeightProvider;

import { AdvContextualMenu } from "@components/layout/contextual-menu/contextual-menu";
import { PermissionConfig } from "@data/actions/permission-config";
import { LAN } from "@data/language/strings";
import { IStyle } from "@fluentui/react";
import { useT } from "@hooks/language/useTranslation";
import { useAdvCallback } from "@hooks/react-overload/useAdvCallback";
import useAdvTheme from "@hooks/useAdvTheme";
import { SettingsIcon } from "@themes/icons";
import { useMemo, useState } from "react";
import { TAdvButtonContextMenuProps } from "./types";

export const AdvButtonContextMenu = ({
    buttonRef,
    isContextMenuHidden,
    setIsContextMenuHidden,
    webActionName,
}: TAdvButtonContextMenuProps) => {
    const [isPermissionConfigHidden, setIsPermissionConfigHidden] = useState(true);
    const handleContextItemMenuClick = useAdvCallback(() => {
        setIsPermissionConfigHidden(false);
    }, []);

    const { text: translatedText, hasErr } = useT(LAN.PERMISSIONS_CONFIGURE.text);
    const theme = useAdvTheme();

    const labelStyles = useMemo(() => {
        if (hasErr == false) return undefined;
        return theme.custom.textNotTranslated as IStyle;
    }, [hasErr, theme.custom.textNotTranslated]);

    const onDismiss = useAdvCallback(() => setIsPermissionConfigHidden(true), []);

    return (
        <>
            <AdvContextualMenu
                items={[
                    {
                        key: "actPermission",
                        text: translatedText,
                        itemProps: {
                            styles: { label: labelStyles },
                        },
                        iconProps: {
                            iconName: SettingsIcon.iconName,
                        },
                        onClick: handleContextItemMenuClick,
                    },
                ]}
                hidden={isContextMenuHidden}
                target={buttonRef.current}
                onItemClick={() => setIsContextMenuHidden(true)}
                onDismiss={() => setIsContextMenuHidden(true)}
            />
            <PermissionConfig
                isOpen={!isPermissionConfigHidden}
                onDismiss={onDismiss}
                webActionName={webActionName}
            ></PermissionConfig>
        </>
    );
};

import { TAdvContract } from "@components/dynamic/contracts/types";
import { TAdvDropdownItem } from "@components/inputs/dropdown";
import recoilSettings from "@data/settings";
import { gContractInterfaceAtom } from "@hooks/useAdvDataProviderFactory";
import { atomFamily, selectorFamily } from "recoil";

import {
    atomComponentKeys,
    atomDesignerBaseComponentKey,
    atomDropComponentKey,
    atomSelectedComponentKey,
    componentsAtomFamily,
    designerGetUniqueName,
} from "./atoms";
import {
    childrenKeysSelectorFamily,
    propertiesSelectorFamily,
    staticSelectorFamily,
} from "./withComponent";
import { selectedComponentSelector } from "./withSelectedComponent";

const recoilDesigner = {
    /** Aktuell ausgewählte Komponente */
    selected: {
        componentKey: atomSelectedComponentKey,
        component: selectedComponentSelector,
    },
    /** Daten zu einer spezifischen Komponente */
    component: function (activeFileName: string, componentKey: string) {
        const uniqueName = designerGetUniqueName(activeFileName, componentKey);
        return {
            childrenKeys: childrenKeysSelectorFamily({ activeFileName, componentKey }),
            properties: propertiesSelectorFamily({ activeFileName, componentKey }),
            static: staticSelectorFamily({ activeFileName, componentKey }),
            self: componentsAtomFamily(uniqueName),
        };
    },
    /** Daten über alle Komponenten */
    components: {
        keys: atomComponentKeys,
        baseKey: atomDesignerBaseComponentKey,
    },
    /** Ui-Designer Einstellungen */
    settings: {
        dragOverCompKeys: atomDropComponentKey,
        alwaysShowDropAreas: recoilSettings.Designer.alwaysShowDropAreas,
        renderAsDesigner: recoilSettings.Designer.renderAsDesigner,
    },

    contracts: atomFamily<TAdvContract[], string>({
        key: "uiDesigner_Contracts",
        default: [],
    }),

    uniqueness: atomFamily<string, string>({
        key: "uiDesignerUniqueness",
        default: "",
    }),
};

export default recoilDesigner;

export const selectContractsAsDropdown = selectorFamily<TAdvDropdownItem[], string>({
    key: "selectContractsAsDropdown_Selector",
    get:
        (activeFileName) =>
        ({ get }) => {
            return get(recoilDesigner.contracts(activeFileName)).map<TAdvDropdownItem>((val) => {
                return { key: val.Name, text: val.Name, data: val.Name, ignoreTranslation: true };
            });
        },
});

export const selectContractFieldsAsDropdown = selectorFamily<
    TAdvDropdownItem[],
    { fileName: string; contractName: string }
>({
    key: "selectContractFieldsAsDropdown_Selector",
    get:
        (params) =>
        ({ get }) => {
            const contractVal = get(recoilDesigner.contracts(params.fileName)).find(
                (c) => c.Name == params.contractName,
            );
            const contractServerName = contractVal?.ContractName ?? "";
            const contractInterface = get(gContractInterfaceAtom(contractServerName));
            if (contractVal != undefined && contractInterface != undefined) {
                return contractInterface.ContractInterface.Fields.map<TAdvDropdownItem>((val) => {
                    return {
                        key: val.Name,
                        text: val.Name,
                        data: val.Name,
                        ignoreTranslation: true,
                    };
                });
            }
            return [];
        },
});

/*
    Datenhaltung (für den Designer) wie folgt:

    Alle DesignerComponents liegt in einem "Dictionary" (atomFamilyComponents) und haben jeweils einen unique identifier (Key).
    Dieser Key ist ebenfalls in dem Key-Array (atomComponentKeys), sodass man das "Dictionary" auch durchlaufen kann (anders nicht möglich).
    Die aktuell ausgewählte DesignerComponent im Ui-Designer befindet sich in atomSelectedComponentKey (bzw. dessen Key).

    Neben der tatsächlichen Datenhaltung gibt es noch einige Nice-To-Have Selektoren, um direkt an bestimmte Daten zu kommen.
    Um beispielsweise an die Properties der ausgewählten DesignerComponent zu kommen, müsste man über die atomFamilyComponents an die
*/

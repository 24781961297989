export enum EComponentTypeLayout {
    Stack = "stack",
    StackItem = "stackItem",
    ReactGrid = "grid",
    // TODO: grid -> reactGrid, advgrid => grid
    Grid = "advgrid",
    GridItem = "advgridItem",
    TabControl = "tabControl",
    TabItem = "tabItem",
    Groupbox = "groupbox",
    DynamicPage = "dynamicPage",
}

export enum EComponentTypeInput {
    Button = "button",
    TextInput = "textInput",
    Checkbox = "checkbox",
    Combobox = "combobox",
    Dropdown = "dropdown",
    RadioGroup = "radioGroup",
    Calendar = "calender",
    DatePicker = "datePicker",
}

export enum EComponentTypeData {
    Label = "label",
    Text = "text",
    Chart = "chart",
    Icon = "icon",
    FontIcon = "fonticon",
    EndlessTable = "endlessTable",
    CopyCat = "copyCat",
    Resource = "resource",
    News = "news",
    Progressbar = "progressbar",
}

export enum EComponentTypeCustom {
    OrderCalendar = "orderCalendar",
    OrderEndlessArticles = "orderEndlessArticles",
    OrderEndlessVSA = "orderEndlessVSA",
    OrderEndlessAnf = "orderEndlessAnf",
    Benchmark = "benchmark",
    TraeArtiList = "traeArtiList",
    ContractActions = "contractActions",
    TraeAnprArtList = "traeAnprArtList",
    TraeArtiAnlageList = "traeArtiAnlageList",
}

/** Arten von Komponenten. Unterteilt in:
 * - ``EComponentTypeLayout``
 * - ``EComponentTypeInput``
 * - ``EComponentTypeData``
 * - ``EComponentTypeCustom``
 **/
export type EComponentType =
    | EComponentTypeLayout
    | EComponentTypeInput
    | EComponentTypeData
    | EComponentTypeCustom;

import { TAdvCommonProperties } from "@components/other/common-properties";
import { providersOfPageLoaded } from "@data/dataprovider/data-provider-server";
import { LAN } from "@data/language/strings";
import { DefaultComponentCategory } from "@feature/Designer/types/category";
import { TAdvDesignerComponentProps } from "@feature/Designer/types/component-props";
import { EComponentTypeCustom } from "@feature/Designer/types/component-type";
import { AdvProperty, registerDesignableComponent } from "@feature/Designer/utils";
import { useAdvWebAction } from "@hooks/dynamic/useAdvWebAction";
import { TAdvWebActionParams } from "@hooks/dynamic/useAdvWebAction.types";
import { toAdvText } from "@hooks/language/useTranslation";
import { useAdvRouter } from "@hooks/page/useAdvRouter";
import { useAdvEffect } from "@hooks/react-overload/useAdvEffect";
import { useAdvLayoutEffect } from "@hooks/react-overload/useAdvLayoutEffect";
import useAdvRecoilValue from "@hooks/recoil-overload/useAdvRecoilValue";
import useAdvComponent from "@hooks/useAdvComponent";
import { BenchmarkIcon } from "@themes/icons";
import { deepCompareJSXProps } from "@utils/deep-compare";
import React, { useRef } from "react";

const AdvBenchmarkComp = ({
    keyRef,
    afterBenchmarkAction,
    afterBenchmarkTimeout = 0,
    designerProps,
    ...props
}: {
    afterBenchmarkAction?: TAdvWebActionParams;
    afterBenchmarkTimeout?: number;
    keyRef?: string;
} & TAdvCommonProperties &
    TAdvDesignerComponentProps) => {
    useAdvComponent(AdvBenchmarkComp, props);

    const [, , actionFunc] = useAdvWebAction(keyRef ?? "", 0, afterBenchmarkAction);
    const router = useAdvRouter();

    const isEveryProviderLoaded = useAdvRecoilValue(
        providersOfPageLoaded({ pageInfo: router.pageInfo }),
    );

    const benchStart = useRef<number>(0);

    useAdvLayoutEffect(() => {
        benchStart.current = Date.now();
    }, []);

    const actionWasExecuted = useRef<{}>();
    useAdvEffect(() => {
        if (isEveryProviderLoaded && designerProps == undefined) {
            console.warn(
                "[[Benchmark]] - Time until all components were rendered: " +
                    (Date.now() - benchStart.current).toString() +
                    "ms",
            );
            if (actionWasExecuted.current == undefined) {
                actionWasExecuted.current = {};
                if (afterBenchmarkTimeout > 0) {
                    setTimeout(() => actionFunc(), afterBenchmarkTimeout);
                } else actionFunc();
            }
        }
    }, [actionFunc, isEveryProviderLoaded, designerProps, afterBenchmarkTimeout]);

    return <div style={{ height: 100 }}></div>;
};

const AdvBenchmark = React.memo(AdvBenchmarkComp, deepCompareJSXProps);
export default AdvBenchmark;

const benchmarkProps = [
    AdvProperty.Action.create(
        toAdvText(LAN.AFTER_BENCHMARK_ACTION),
        "afterBenchmarkAction",
        toAdvText(LAN.GENERAL),
        toAdvText(LAN.AFTER_BENCHMARK_ACTION_DESCR),
    ),
    AdvProperty.Number.create(
        toAdvText(LAN.AFTER_BENCHMARK_TIMEOUT),
        "afterBenchmarkTimeout",
        toAdvText(LAN.GENERAL),
        toAdvText(LAN.AFTER_BENCHMARK_TIMEOUT_DESCR),
        0,
    ),
];

registerDesignableComponent({
    staticData: {
        name: LAN.BENCHMARK.text,

        type: EComponentTypeCustom.Benchmark,
        supportsChildren: false,
        category: DefaultComponentCategory.Misc,
        icon: BenchmarkIcon,
    },
    properties: benchmarkProps,
    propertiesBuilders: [],
    presets: [],
});

import { trans_assert } from "./assert-trans";
import { EAdvValueDataTypes } from "./data-types";
import { parseToBoolean } from "./to-bool";

export function convertToTypeOfVal<TTarget>(
    convertVal: any,
    targetType: EAdvValueDataTypes,
): TTarget {
    switch (targetType) {
        case EAdvValueDataTypes.String: {
            if (typeof convertVal == "undefined") return "" as unknown as TTarget;
            if (typeof convertVal == "object") {
                return JSON.stringify(convertVal) as unknown as TTarget;
            }
            return convertVal.toString() as unknown as TTarget;
        }

        case EAdvValueDataTypes.Boolean: {
            return parseToBoolean(convertVal) as unknown as TTarget;
        }
        case EAdvValueDataTypes.Number: {
            return parseInt(convertVal) as unknown as TTarget;
        }
        case EAdvValueDataTypes.Object: {
            if (typeof convertVal == "string") return JSON.parse(convertVal) as unknown as TTarget;
            else if (typeof convertVal == "object") return convertVal;
            return {} as unknown as TTarget;
        }
        case EAdvValueDataTypes.ObjectAsJSON: {
            return JSON.stringify(convertVal) as unknown as TTarget;
        }
        case EAdvValueDataTypes.Array: {
            if (Array.isArray(convertVal)) return convertVal as unknown as TTarget;
            else return [convertVal] as unknown as TTarget;
        }
        case EAdvValueDataTypes.ArrayAsCommaSeperatedString: {
            if (typeof convertVal == "undefined") return "" as TTarget;
            else if (Array.isArray(convertVal))
                return convertVal.map((v) => v.toString()).join(",") as unknown as TTarget;
            else return convertVal.toString() as unknown as TTarget;
        }
        case EAdvValueDataTypes.Any:
            return convertVal as unknown as TTarget;
    }
}

/**
 * Helper function that converts object JSONs or comma seperated string arrays back to object/arrays
 */
export function convertToOriginalType<TTarget>(
    convertVal: any,
    targetType: EAdvValueDataTypes,
): TTarget {
    switch (targetType) {
        case EAdvValueDataTypes.ObjectAsJSON: {
            return JSON.parse(convertVal) as unknown as TTarget;
        }
        case EAdvValueDataTypes.ArrayAsCommaSeperatedString: {
            trans_assert(typeof convertVal == "string", "Value was not of type string");
            return convertVal.split(",") as unknown as TTarget;
        }
        default:
            return convertVal as unknown as TTarget;
    }
}

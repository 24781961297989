import { MessageBar, MessageBarType } from "@fluentui/react";
import useAdvComponent from "@hooks/useAdvComponent";
import { deepCompareJSXProps } from "@utils/deep-compare";
import React from "react";

export type TAdvMessageBarProps = {
    /** Inhalt des Banner */
    children: React.ReactNode | React.ReactNode[];
    type: MessageBarType;
    isMultiLine?: boolean;
};

const AdvMessageBarComp = ({
    children,
    type,
    isMultiLine = false,
    ...props
}: TAdvMessageBarProps) => {
    useAdvComponent(AdvMessageBarComp, props);

    return (
        <MessageBar
            messageBarType={type}
            isMultiline={isMultiLine}
            //  onDismiss={p.resetChoice}
            //  dismissButtonAriaLabel="Close"
            styles={{
                content: { display: "flex", alignItems: "center" },
                icon: { alignItems: "center", textAlign: "center" },
            }}
            {...props}
        >
            {children}
        </MessageBar>
    );
};

const AdvMessageBar = React.memo(AdvMessageBarComp, deepCompareJSXProps);
export default AdvMessageBar;

import { gTranslationsAtom, TTranslationItem } from "@data/language/language";
import useAdvRecoilValue from "@hooks/recoil-overload/useAdvRecoilValue";
import { useMemo, useRef } from "react";
import { RecoilValue, waitForAll } from "recoil";
import { LAN_GERMAN, useLanguage } from "./useLanguages";

export type TAdvTranslation = {
    /**
     * Ursprung des Texts:
     * CurrentLanguage-Cache > Server-Anfrage in CurrentLanguage > FallbackLanguage-Cache
     * > Server-Anfrage FallbackLanguage > DefaultItem (textToTranslate)
     */
    text: string | undefined;
    t: string | undefined; // alias
    /** Rot unterstreichen? */
    hasErr: boolean;
};

/** Übersetzt die {@link textsToTranslate} */
const useTranslationArray = (
    textsToTranslate: (string | undefined)[],
    dontTranslate: boolean = false,
): TAdvTranslation[] => {
    const currentLanguage = useLanguage();
    const translationItems = useAdvRecoilValue(
        waitForAll(
            textsToTranslate.map(
                (text) =>
                    gTranslationsAtom({
                        langId: currentLanguage.ID,
                        sourceText: dontTranslate ? "" : text,
                    }) as RecoilValue<TTranslationItem>,
            ),
        ),
    );

    const previousTranslationRef = useRef<TAdvTranslation[] | undefined>();
    const translatedTexts = useMemo(
        () =>
            dontTranslate
                ? textsToTranslate
                : translationItems.map((item, index) => {
                      if (textsToTranslate[index] === undefined) return undefined;
                      if (dontTranslate) return textsToTranslate[index];
                      if (currentLanguage.ID == LAN_GERMAN) return textsToTranslate[index];
                      if (item.success) return item.text;

                      // Vorherige Übersetzung anzeigen solange translationItems noch nicht geladen ist
                      if (
                          previousTranslationRef.current !== undefined &&
                          previousTranslationRef.current[index].hasErr == false
                      )
                          return previousTranslationRef.current[index].text;
                      return textsToTranslate[index];
                  }),
        [currentLanguage.ID, dontTranslate, translationItems, textsToTranslate],
    );

    const translationErrors = useMemo(
        () =>
            translationItems.map(
                (item, index) =>
                    dontTranslate == false &&
                    (item.success == false ||
                        (item.text === undefined && (textsToTranslate[index] ?? "") == "")),
            ),
        [dontTranslate, textsToTranslate, translationItems],
    );

    const advTranslations = useMemo(() => {
        // Sobald sich die Sprache ändert lädt das translationItems-Atom neu und alle Texte sind
        // kurzzeitig fehlerhaft (solange geladen wird).
        // Deshalb solange noch die vorherigen Übersetzungen (falls vorhanden) anzeigen
        if (
            translationErrors.some((t) => t == false) == false &&
            previousTranslationRef.current !== undefined
        ) {
            return previousTranslationRef.current;
        }

        const ret: TAdvTranslation[] = [];
        for (let i = 0; i < textsToTranslate.length; i++) {
            ret.push({
                text: translatedTexts[i],
                t: translatedTexts[i],
                hasErr: translationErrors[i],
            });
        }

        previousTranslationRef.current = ret;
        return ret;
    }, [textsToTranslate.length, translatedTexts, translationErrors]);

    return advTranslations;
};

export default useTranslationArray;

export enum EAdvValueDataTypes {
    String = 0,
    Number,
    Boolean,
    Array,
    ArrayAsCommaSeperatedString,
    Object,
    ObjectAsJSON,
    // leave as is (except if the binding logic converts it)
    Any,
}

export const AdvValueDataTypeAuto = <T>(v: T) => {
    if (typeof v == "string") return EAdvValueDataTypes.String;
    else if (typeof v == "number" || typeof v == "bigint") return EAdvValueDataTypes.Number;
    else if (typeof v == "boolean") return EAdvValueDataTypes.Boolean;
    else if (Array.isArray(v)) return EAdvValueDataTypes.Array;
    else if (typeof v == "object") return EAdvValueDataTypes.Object;
    return EAdvValueDataTypes.Any;
};

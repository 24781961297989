import AdvGrid from "@components/layout/grid/grid";
import AdvGridItem from "@components/layout/grid/grid-item/grid-item";
import AdvModal from "@components/layout/modal/modal";
import AdvIcon from "@components/other/icon/icon";
import useAdvTheme from "@hooks/useAdvTheme";
import { CircleInfoIcon } from "@themes/icons";
import { useMemo, useState } from "react";
import AdvCellValue from "./cellValue/cellValue";
import { TAdvEndlessTableColumnDefinitions } from "./types";

export const AdvEndlessTableMobileModal = ({
    itemData,
    columns,
}: {
    itemData: Record<string, any> | null;
    columns: TAdvEndlessTableColumnDefinitions | undefined;
}) => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const filteredColums = useMemo(() => {
        return (columns ?? []).filter((c) => {
            return (
                typeof (itemData ?? {})[c.definition.fieldName ?? ""] != "undefined" &&
                c.field != null
            );
        });
    }, [columns, itemData]);

    const theme = useAdvTheme();

    return (
        <>
            <AdvIcon
                iconName={CircleInfoIcon.iconName}
                styles={{ root: { cursor: "pointer" } }}
                onClick={() => {
                    setIsModalOpen(true);
                }}
            ></AdvIcon>
            <AdvModal
                isOpen={isModalOpen}
                headline={""}
                onDismiss={() => {
                    setIsModalOpen(false);
                }}
            >
                <AdvGrid
                    rows={"auto " + filteredColums.map(() => "auto").join(" ")}
                    columns={"minmax(40%, auto) 1fr"}
                    verticalFill
                >
                    <AdvGridItem
                        row={1}
                        column={1}
                        styles={{
                            root: { backgroundColor: theme.palette.alternativeWhite, padding: 5 },
                        }}
                    >
                        {"Name"}
                    </AdvGridItem>
                    <AdvGridItem
                        row={1}
                        column={2}
                        styles={{
                            root: { backgroundColor: theme.palette.alternativeWhite, padding: 5 },
                        }}
                    >
                        {"Wert"}
                    </AdvGridItem>
                    {filteredColums.map((c, index) => {
                        const item = (itemData ?? {})[c.definition.fieldName];

                        if (item != undefined && c.field != null) {
                            return (
                                <>
                                    <AdvGridItem
                                        row={2 + index}
                                        column={1}
                                        styles={{
                                            root: {
                                                backgroundColor: theme.palette.alternativeWhite,
                                                padding: 5,
                                            },
                                        }}
                                    >
                                        {c.definition.name}
                                    </AdvGridItem>
                                    <AdvGridItem
                                        row={2 + index}
                                        column={2}
                                        styles={{
                                            root: {
                                                backgroundColor: theme.palette.alternativeWhite,
                                                padding: 5,
                                            },
                                        }}
                                    >
                                        <AdvCellValue
                                            type={c.field?.Settings.FieldType}
                                            domain={c.field?.Settings.FieldDomain}
                                            value={item}
                                        />
                                    </AdvGridItem>
                                </>
                            );
                        }

                        return <></>;
                    })}
                </AdvGrid>
            </AdvModal>
        </>
    );
};

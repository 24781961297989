import { IButtonStyles, INavStyles } from "@fluentui/react";

export const navStyles = (): Partial<INavStyles> => {
    return {
        groupContent: {
            marginBottom: 0, // Keinen übermäßig großen Abstand zwischen den Gruppen
            animation: "none", // Animation bugs in production build
        },
        root: {
            height: "100%",
        },
        group: {
            overflowY: "auto",
        },
        chevronButton: { display: "none" },
    };
};

export const navIconButtonStyles: IButtonStyles = {
    root: {
        placeSelf: "flex-end",
    },
};

export function ServerStrToDate(str: string) {
    return new Date(str);
}

export function DateTimeToLocalStr(date: Date) {
    return new Intl.DateTimeFormat(undefined, { dateStyle: "short", timeStyle: "medium" }).format(
        date,
    );
}

export function ServerStrToLocalDateTimeStr(str: string) {
    if (str == "") return "";
    try {
        return DateTimeToLocalStr(ServerStrToDate(str));
    } catch {
        return "Invalid date: " + str;
    }
}

export function DateToLocalStr(date: Date) {
    return new Intl.DateTimeFormat(undefined, {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
    }).format(date);
}

export function ServerStrToLocalDateStr(str: string) {
    if (str == "") return "";
    return DateToLocalStr(ServerStrToDate(str));
}

function GetLeadingNull(testNumber: number): string {
    if (testNumber < 10) return "0" + testNumber.toString();
    else return testNumber.toString();
}

export function ServerStrToDateTimeStrFluent(str: string) {
    if (str == "") return "";
    const date = ServerStrToDate(str);
    return (
        date.getFullYear().toString() +
        "-" +
        GetLeadingNull(date.getMonth() + 1) +
        "-" +
        GetLeadingNull(date.getDate()) +
        " " +
        GetLeadingNull(date.getHours()) +
        ":" +
        GetLeadingNull(date.getMinutes()) +
        ":" +
        GetLeadingNull(date.getSeconds())
    );
}

export function DateTimeStrFluentToServerStr(str: string) {
    if (str == "") return "";
    const date = ServerStrToDate(str);
    return (
        date.getFullYear().toString() +
        "-" +
        GetLeadingNull(date.getMonth() + 1) +
        "-" +
        GetLeadingNull(date.getDate()) +
        " " +
        GetLeadingNull(date.getHours()) +
        ":" +
        GetLeadingNull(date.getMinutes()) +
        ":" +
        GetLeadingNull(date.getSeconds())
    );
}

export function ServerStrToDateStrFluent(str: string) {
    if (str == "") return "";
    const date = ServerStrToDate(str);
    return (
        date.getFullYear().toString() +
        "-" +
        GetLeadingNull(date.getMonth() + 1) +
        "-" +
        GetLeadingNull(date.getDate())
    );
}

export function DateStrFluentToServerStr(str: string) {
    if (str == "") return "";
    const date = ServerStrToDate(str);
    return (
        date.getFullYear().toString() +
        "-" +
        GetLeadingNull(date.getMonth() + 1) +
        "-" +
        GetLeadingNull(date.getDate())
    );
}

export type TDelphiDate = number;
export function DelphiDateToJSDate(dateToConvert: TDelphiDate): Date {
    const unixDateDelta = 25569.0;
    const secsPerDay = 86400;
    const miliSecsPerSec = 1000;
    const dateWithoutDelta = dateToConvert - unixDateDelta;
    const unixSeconds = dateWithoutDelta * secsPerDay;
    const unixTime = unixSeconds * miliSecsPerSec;
    const resultTime = new Date(unixTime);
    resultTime.clearTime();
    return resultTime;
}
export function JsDateToDelphiDate(dateToConvert: Date): TDelphiDate {
    dateToConvert.clearTime();
    const unixDateDelta = 25569.0;
    const secsPerDay = 86400;
    const miliSecsPerSec = 1000;
    const unixSeconds = dateToConvert.getTime() / miliSecsPerSec; // 1563984000
    const secsToAdd = unixSeconds / secsPerDay; // 18101.666666666668
    return unixDateDelta + secsToAdd;
}

/** Das Equivalent zu Delphi. Konvertiert das Datum in Unixtime UTC. */
export function DateTimeToUnix(date: Date): number {
    return (
        Date.UTC(
            date.getUTCFullYear(),
            date.getUTCMonth(),
            date.getUTCDate(),
            date.getUTCHours(),
            date.getUTCMinutes(),
            date.getUTCSeconds(),
        ) / 1000
    );
}

/** Gibt den aktuellen Tag in UnixTime zurück, unabhängig von der TimeZone. */
export function DateToUnix(date: Date): number {
    return Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()) / 1000;
}

/** Das Equivalent zu Delphi. Konvertiert die Unixtime (UTC) in Date */
export function UnixToDateTime(unixtime: number) {
    return new Date(unixtime * 1000);
}

export function CompareDatesUTC(dateA: Date, dateB: Date) {
    return (
        dateA.getUTCFullYear() == dateB.getUTCFullYear() &&
        dateA.getUTCMonth() == dateB.getUTCMonth() &&
        dateA.getUTCDate() == dateB.getUTCDate()
    );
}

/** Liefert einen Array zurück, der alle Werte von <start> bis <ende> (inklusiv) enthält. */
export function NumberRange(start: number, end: number): number[] {
    return Array.from(new Array(Math.abs(end - start) + 1), (x, i) => i + start);
}

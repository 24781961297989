import { IButtonStyles } from "@fluentui/react";

// Dieser Style ist für die Links in der NavBar (AdvNav).
// Diese Links (tatsächlich sind das Buttons) besitzen ein Icon, das links-zentriert ist.
// Der Text (a-Element) soll den restlichen Platz nehmen (ist bereits zentriert)
export const navButtonStyles: IButtonStyles = {
    flexContainer: {
        justifyContent: "stretch",
    },
    root: {
        ["a"]: {
            width: "calc(100% - 5px)",
            paddingLeft: 5,
        },
    },
};

import { AdvTable } from "@components/data/table-new";
import { endlessTableProps } from "@components/data/table-new/endless/designable";
import {
    TAdvTableColumn,
    TAdvTableProps,
    createAdvTableColumn,
} from "@components/data/table-new/types";
import { TAdvCommonProperties } from "@components/other/common-properties";
import { LAN } from "@data/language/strings";
import { DefaultComponentCategory } from "@feature/Designer/types/category";
import { TAdvDesignerComponentProps } from "@feature/Designer/types/component-props";
import { EComponentTypeCustom } from "@feature/Designer/types/component-type";
import { AdvProperty, registerDesignableComponent } from "@feature/Designer/utils";
import {
    TAdvValueBindingParams,
    useAdvValueBinderAsArrayNoDataType,
    useAdvValueBinderNoDataType,
} from "@hooks/dynamic/useAdvValueBinder";
import { toAdvText } from "@hooks/language/useTranslation";
import useAdvComponent from "@hooks/useAdvComponent";
import { useAdvObjMemo } from "@hooks/useAdvObjMemo";
import { OrderAnfIcon } from "@themes/icons";
import { EAdvValueDataTypes } from "@utils/data-types";
import { deepCompareJSXProps } from "@utils/deep-compare";
import React, { useMemo } from "react";
import { ORDER_PAGE_BINDINGS, TOrderAnforderung } from "../const";

require("datejs");

const columns: TAdvTableColumn<TOrderAnforderung>[] = [
    createAdvTableColumn<TOrderAnforderung>({
        columnId: "auftragsNr",
        renderHeaderCell: () => LAN.AUFTRAGSNR_SHORT.text,
        renderCell: ({ AuftragsNr }) => AuftragsNr,
        contains: ({ AuftragsNr }, text) => AuftragsNr.toLowerCase().includes(text.toLowerCase()),
        compare: ({ AuftragsNr: a }, { AuftragsNr: b }) => {
            return a.localeCompare(b);
        },
    }),
    createAdvTableColumn<TOrderAnforderung>({
        columnId: "lieferDatum",
        renderHeaderCell: () => LAN.LIEFERDATUM.text,
        renderCell: ({ LieferDatum }) => LieferDatum,
        contains: ({ LieferDatum }, text) => LieferDatum.toLowerCase().includes(text.toLowerCase()),
        compare: ({ LieferDatum: a }, { LieferDatum: b }) => {
            return a.localeCompare(b);
        },
    }),
    createAdvTableColumn<TOrderAnforderung>({
        columnId: "urDatum",
        renderHeaderCell: () => LAN.URDATUM.text,
        renderCell: ({ UrDatum }) => UrDatum,
        contains: ({ UrDatum }, text) => UrDatum.toLowerCase().includes(text.toLowerCase()),
        compare: ({ UrDatum: a }, { UrDatum: b }) => {
            return a.localeCompare(b);
        },
    }),
    createAdvTableColumn<TOrderAnforderung>({
        columnId: "anfStatus",
        renderHeaderCell: () => LAN.STATUS.text,
        renderCell: ({ StatusBez }) => StatusBez,
        contains: ({ StatusBez }, text) => StatusBez.toLowerCase().includes(text.toLowerCase()),
        compare: ({ StatusBez: a }, { StatusBez: b }) => {
            return a.localeCompare(b);
        },
    }),
];

export type TAdvOrderEndlessAnfStyles = {}; /* do not change */

export type TAdvOrderEndlessAnfProps = TAdvTableProps<TOrderAnforderung> &
    TAdvCommonProperties &
    TAdvDesignerComponentProps & {
        providerData?: string;
        providerDataBindingParams?: TAdvValueBindingParams;
    };

const AdvOrderEndlessAnfComp = ({
    providerDataBindingParams,
    advhide,
    advhideBindingParams,
    designerProps,
    designerData,
    ...props
}: TAdvOrderEndlessAnfProps) => {
    useAdvComponent(AdvOrderEndlessAnfComp, props);

    const [providerData] = useAdvValueBinderAsArrayNoDataType(
        providerDataBindingParams,
        [],
        EAdvValueDataTypes.Any,
    );

    const [shouldHide] = useAdvValueBinderNoDataType(
        advhideBindingParams,
        advhide,
        EAdvValueDataTypes.Any,
        0,
    );

    // Quasi das onChanged-Event. Jeder Wert ist ein eigenes, hartkodiertes Binding, sodass wir alle Werte anzeigen könnten wenn wir wollten.
    const [, setSelectedAnfKoID] = useAdvValueBinderNoDataType(
        ORDER_PAGE_BINDINGS.SelectedAnfKoID,
        -1,
        EAdvValueDataTypes.Any,
        0,
    );
    const [, setSelectedAnfKoNr] = useAdvValueBinderNoDataType(
        ORDER_PAGE_BINDINGS.SelectedAnfKoNr,
        "",
        EAdvValueDataTypes.Any,
        0,
    );

    const parsedProviderData = useAdvObjMemo(() => {
        return (providerData ?? []).map((data) => JSON.parse(data)) as TOrderAnforderung[];
    }, [providerData]);

    const items = useMemo(() => {
        return parsedProviderData;
    }, [parsedProviderData]);

    if (shouldHide === true && designerProps === undefined) return <></>;
    return (
        <AdvTable<TOrderAnforderung>
            {...props}
            items={items}
            columns={columns}
            selectionMode="single"
            onSelectionChange={(items) => {
                if (items.length > 0) {
                    if (designerData !== undefined) return; // Im Designer nichts an den Server schicken

                    const { AnfKoID, AuftragsNr } = items[0] as TOrderAnforderung;
                    setSelectedAnfKoID(AnfKoID);
                    setSelectedAnfKoNr(AuftragsNr);
                }
            }}
            sortable
            canFilter
        ></AdvTable>
    );
};

const AdvOrderEndlessAnf = React.memo(AdvOrderEndlessAnfComp, deepCompareJSXProps);
export default AdvOrderEndlessAnf;

const OrderEndlessAnfProps = [
    AdvProperty.Text.create(
        toAdvText(LAN.PROVIDER_ORDERS),
        "providerData",
        toAdvText(LAN.GENERAL),
        toAdvText(LAN.ORDERS_ENDLESS_TABLE_PROVIDER_DATA_DESCR),
        "",
    ),
    ...endlessTableProps,
];

registerDesignableComponent({
    staticData: {
        name: LAN.ENDLESS_TABLE_ANF.text,

        type: EComponentTypeCustom.OrderEndlessAnf,
        supportsChildren: false,
        category: DefaultComponentCategory.Misc,
        icon: OrderAnfIcon,
    },
    properties: OrderEndlessAnfProps,
    propertiesBuilders: [],
    presets: [],
});

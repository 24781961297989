export const gAdvParameterMappingKey = "__MappedParam__ADV";

export interface IAdvParameterMapping {
    params: Record<
        string /* gAdvParameterMappingKey + Index */,
        { value: string; options: Record<string, any> }
    >;
}

export type IAdvParameterMappingPayload = IAdvParameterMapping;

/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/naming-convention */
import { ssrCompletedState } from "@hooks/misc/useSsrCompletedState";
import { advcatch } from "@utils/logging";
import { AtomEffect } from "recoil";

// Source: https://github.com/polemius/recoil-persist
// Ergänzt um NextJS-Fix: https://github.com/polemius/recoil-persist/issues/43

export interface PersistStorage {
    setItem(key: string, value: string): void | Promise<void>;
    mergeItem?(key: string, value: string): Promise<void>;
    getItem(key: string): null | string | Promise<string>;
}

export interface PersistConfiguration {
    key?: string;
    storage?: PersistStorage;
}

/**
 * Recoil module to persist state to storage
 *
 * @param config Optional configuration object
 * @param config.key Used as key in local storage, defaults to `recoil-persist`
 * @param config.storage Local storage to use, defaults to `localStorage`
 */
export const recoilPersistLocalStorage = <TType = any>(
    config: PersistConfiguration = {},
): { persistAtom: AtomEffect<TType> } => {
    if (typeof window === "undefined") {
        // Server soll nichts machen
        return {
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            persistAtom: () => {},
        };
    }

    const { key = "recoil-persist", storage = localStorage } = config;

    const persistAtom: AtomEffect<TType> = ({ onSet, node, trigger, setSelf }) => {
        if (trigger === "get") {
            const state = getState();
            if (typeof state.then === "function") {
                state.then((s: any) => {
                    if (s.hasOwnProperty(node.key)) {
                        setSelf(s[node.key]);
                    }
                });
            }
            if (state.hasOwnProperty(node.key)) {
                setSelf(state[node.key]);
            }
        }

        onSet((newValue, _, isReset) => {
            const state = getState();
            if (typeof state.then === "function") {
                state.then((s: any) => updateState(newValue, s, node.key, isReset));
            } else {
                updateState(newValue, state, node.key, isReset);
            }
        });
    };

    const updateState = (newValue: any, state: any, key: string, isReset: boolean) => {
        if (isReset) {
            delete state[key];
        } else {
            state[key] = newValue;
        }

        setState(state);
    };

    const getState = (): any => {
        const toParse = storage.getItem(key);
        if (toParse === null || toParse === undefined) {
            return {};
        }
        if (typeof toParse === "string") {
            return parseState(toParse);
        }
        if (typeof toParse.then === "function") {
            return toParse.then(parseState);
        }

        return {};
    };

    const parseState = (state: string) => {
        if (state === undefined) {
            return {};
        }
        try {
            return JSON.parse(state);
        } catch (e) {
            console.error(e);
            return {};
        }
    };

    const setState = (state: any): void => {
        try {
            if (typeof storage.mergeItem === "function") {
                storage.mergeItem(key, JSON.stringify(state));
            } else {
                void storage.setItem(key, JSON.stringify(state));
            }
        } catch (e) {
            console.error(e);
        }
    };

    // Fix für NextJS in Kombination mit recoil-persist
    // https://github.com/polemius/recoil-persist/issues/43
    // https://stackoverflow.com/a/70459889
    const nextJsEffect: AtomEffect<TType> = (param: Parameters<AtomEffect<TType>>[0]) => {
        param
            .getPromise(ssrCompletedState)
            .then(() => persistAtom(param))
            .catch(advcatch);
    };

    return { persistAtom: nextJsEffect };
};

import { gThemeFilePrefix } from "@data/designer/file";
import { recoilKeystorage, TKeyStorageItem } from "@data/key-storage";
import recoilSettings from "@data/settings";
import {
    DefaultThemeIcon,
    DefaultThemeLogo,
    TAdvPalette,
    TAdvTheme,
    TAdvThemeIcon,
    TAdvThemeStorage,
} from "@feature/settings/theme-types";
import { createTheme, ISpacing, Theme } from "@fluentui/react";
import { TAdvAppliedTheme } from "@hooks/useAdvTheme";
import { darkTheme, lightTheme } from "@themes/default";
import { getContrast, multiRGBValues } from "@utils/colors";
import deepCopy from "@utils/deep-copy";
import { selector, selectorFamily } from "recoil";

export const gCheckThemeCorrectness = (
    theme: TKeyStorageItem<TAdvThemeStorage>,
): TKeyStorageItem<TAdvThemeStorage> => {
    const res = deepCopy(theme);
    if (res.Additional == undefined) res.Additional = [];
    if (res.Name == undefined) res.Name = "";
    if (res.Value == undefined) {
        res.Value = {
            logo: deepCopy(DefaultThemeLogo),
            main: {
                palette: {
                    ...deepCopy(darkTheme.palette),
                    alternativeWhite: darkTheme.palette.white,
                },
                spacing: deepCopy(darkTheme.spacing),
                isInverted: darkTheme.isInverted,
                icon: deepCopy(DefaultThemeIcon),
            },
            topBar: {
                palette: {
                    ...deepCopy(darkTheme.palette),
                    alternativeWhite: darkTheme.palette.white,
                },
                spacing: deepCopy(darkTheme.spacing),
                isInverted: darkTheme.isInverted,
                icon: deepCopy(DefaultThemeIcon),
            },
            nav: {
                palette: {
                    ...deepCopy(darkTheme.palette),
                    alternativeWhite: darkTheme.palette.white,
                },
                spacing: deepCopy(darkTheme.spacing),
                isInverted: darkTheme.isInverted,
                icon: deepCopy(DefaultThemeIcon),
            },
        };
    }
    if (res.Value.logo == undefined || typeof res.Value.logo != "object")
        res.Value.logo = deepCopy(DefaultThemeLogo);
    if (
        res.Value.logo.backgroundLogin == undefined ||
        res.Value.logo.logoLogin == undefined ||
        res.Value.logo.logoTopBar == undefined
    ) {
        res.Value.logo.backgroundLogin =
            res.Value.logo.backgroundLogin ?? deepCopy(DefaultThemeLogo.backgroundLogin);
        res.Value.logo.logoLogin = res.Value.logo.logoLogin ?? deepCopy(DefaultThemeLogo.logoLogin);
        res.Value.logo.logoTopBar =
            res.Value.logo.logoTopBar ?? deepCopy(DefaultThemeLogo.logoTopBar);
    }
    if (res.Value.main == undefined) {
        res.Value.main = {
            palette: { ...deepCopy(darkTheme.palette), alternativeWhite: darkTheme.palette.white },
            spacing: deepCopy(darkTheme.spacing),
            isInverted: darkTheme.isInverted,
            icon: deepCopy(DefaultThemeIcon),
        };
    }
    if (res.Value.topBar == undefined) {
        res.Value.topBar = {
            palette: { ...deepCopy(darkTheme.palette), alternativeWhite: darkTheme.palette.white },
            spacing: deepCopy(darkTheme.spacing),
            isInverted: darkTheme.isInverted,
            icon: deepCopy(DefaultThemeIcon),
        };
    }
    if (res.Value.nav == undefined) {
        res.Value.nav = {
            palette: { ...deepCopy(darkTheme.palette), alternativeWhite: darkTheme.palette.white },
            spacing: deepCopy(darkTheme.spacing),
            isInverted: darkTheme.isInverted,
            icon: deepCopy(DefaultThemeIcon),
        };
    }
    const checkTheme = (val: TAdvTheme) => {
        if (val.palette == undefined) {
            val.palette = {
                ...deepCopy(darkTheme.palette),
                alternativeWhite: darkTheme.palette.white,
            };
        }
        if (val.palette.alternativeWhite == undefined)
            val.palette.alternativeWhite = val.palette.white;
        if (val.spacing == undefined) {
            val.spacing = deepCopy(darkTheme.spacing);
        }
        if (val.isInverted == undefined) {
            val.isInverted = darkTheme.isInverted;
        }
        if (val.icon == undefined) {
            val.icon = deepCopy(DefaultThemeIcon);
        }
    };
    checkTheme(res.Value.main);
    checkTheme(res.Value.topBar);
    checkTheme(res.Value.nav);

    return res;
};

function prefersDarkTheme(): boolean {
    let doesPrefersDark = false;
    if (typeof window != "undefined") {
        doesPrefersDark = window.matchMedia("(prefers-color-scheme: dark)").matches;
    }
    return doesPrefersDark;
}

export const themeAuto = selector<TKeyStorageItem<TAdvThemeStorage> | undefined>({
    key: "app_themeAutoSelector",
    get: ({ get }) => {
        // try user selected theme first
        {
            const themeName = get(recoilSettings.Local.globalTheme);
            if (themeName.IsLoaded()) {
                const themeVal = get(
                    recoilKeystorage(true).ThemeValues(gThemeFilePrefix + themeName.Get().Value),
                );
                if (themeVal.IsLoaded() && !themeVal.Get().Additional.includes("disabled")) {
                    return gCheckThemeCorrectness(themeVal.Get());
                } else if (
                    !themeVal.IsLoaded() &&
                    ["dark", "light"].includes(themeName.Get().Value)
                ) {
                    const doesPrefersDark = themeName.Get().Value == "dark";
                    return gBuildThemeFromDefault(doesPrefersDark ? "dark" : "light");
                }
            }
        }

        // try the app's default theme
        const themeName = get(recoilSettings.Global.appTheme);
        if (themeName.IsLoaded() && themeName.Get().Value != "") {
            const themeVal = get(
                recoilKeystorage(true).ThemeValues(gThemeFilePrefix + themeName.Get().Value),
            );
            if (themeVal.IsLoaded() && !themeVal.Get().Additional.includes("disabled")) {
                return gCheckThemeCorrectness(themeVal.Get());
            } else if (!themeVal.IsLoaded() && ["dark", "light"].includes(themeName.Get().Value)) {
                const doesPrefersDark = themeName.Get().Value == "dark";
                return gBuildThemeFromDefault(doesPrefersDark ? "dark" : "light");
            }
        }

        // detect browser default
        const doesPrefersDark = prefersDarkTheme();
        const themeVal = get(
            recoilKeystorage(true).ThemeValues(
                gThemeFilePrefix + (doesPrefersDark ? "dark" : "light"),
            ),
        );
        if (themeVal.IsLoaded() && !themeVal.Get().Additional.includes("disabled")) {
            return gCheckThemeCorrectness(themeVal.Get());
        }

        return gBuildThemeFromDefault(doesPrefersDark ? "dark" : "light");
    },
});

export const gBuildThemeFromDefinition = (
    basedOnTheme: Theme,
    palette: TAdvPalette,
    spacing: ISpacing,
    icon: TAdvThemeIcon,
) => {
    const res = deepCopy(basedOnTheme);

    res.palette = { ...res.palette, ...palette };
    res.spacing = { ...res.spacing, ...spacing };

    const whiteContrast = getContrast(res.palette.white, "#ffffff");
    const blackContrast = getContrast(res.palette.white, "#000000");

    const isInverted = whiteContrast > blackContrast;

    const resTheme = createTheme({
        palette: res.palette,
        spacing: res.spacing,
        isInverted,
    });
    const calcAltWhite = () => {
        return multiRGBValues(resTheme.palette.white, 0.9);
    };
    const alternativeWhite =
        palette.alternativeWhite != undefined ? palette.alternativeWhite : calcAltWhite();
    return {
        ...resTheme,
        palette: { ...resTheme.palette, alternativeWhite: alternativeWhite },
        custom: { textNotTranslated: undefined },
        icon: deepCopy(icon),
    } as TAdvAppliedTheme;
};

export const gBuildThemeFromDefault = (from: "dark" | "light") => {
    const themeFrom = from == "dark" ? darkTheme : lightTheme;
    return {
        Name: gThemeFilePrefix + from,
        Additional: [],
        Value: {
            main: {
                palette: {
                    ...deepCopy(themeFrom.palette),
                    alternativeWhite: themeFrom.palette.white,
                },
                spacing: deepCopy(themeFrom.spacing),
                isInverted: themeFrom.isInverted,
                icon: deepCopy(DefaultThemeIcon),
            },
            topBar: {
                palette: {
                    ...deepCopy(themeFrom.palette),
                    alternativeWhite: themeFrom.palette.white,
                },
                spacing: deepCopy(themeFrom.spacing),
                isInverted: themeFrom.isInverted,
                icon: deepCopy(DefaultThemeIcon),
            },
            nav: {
                palette: {
                    ...deepCopy(themeFrom.palette),
                    alternativeWhite: themeFrom.palette.white,
                },
                spacing: deepCopy(themeFrom.spacing),
                isInverted: themeFrom.isInverted,
                icon: deepCopy(DefaultThemeIcon),
            },
            logo: deepCopy(DefaultThemeLogo),
        },
    } as TKeyStorageItem<TAdvThemeStorage>;
};

const gBuildThemeFromStorageItem = (
    themeVal: TKeyStorageItem<TAdvThemeStorage>,
    themeName: "main" | "topBar" | "nav",
) => {
    return gBuildThemeFromDefinition(
        themeVal.Value[themeName].isInverted ? darkTheme : lightTheme,
        themeVal.Value[themeName].palette,
        themeVal.Value[themeName].spacing,
        themeVal.Value[themeName].icon,
    );
};

export const themeSelectorImpl = selectorFamily<
    { theme: Theme },
    { themeName: "main" | "topBar" | "nav" }
>({
    key: "app_themeSelectorImpl",
    get:
        ({ themeName }) =>
        ({ get }) => {
            const themeVal = get(themeAuto);
            if (themeVal != undefined) {
                return {
                    theme: gBuildThemeFromStorageItem(themeVal, themeName),
                };
            }
            return { theme: lightTheme };
        },
});

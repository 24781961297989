import { createContext } from "react";
import { atom } from "recoil";

export const defaultPageInstanceIndex = "1";
export const pageInstanceContext = createContext<{
    index: string;
}>({ index: defaultPageInstanceIndex });

export const pageIndecesAtom = atom<Array<string>>({ key: "pageIndeces", default: [""] });
export const nextPageIndexAtom = atom<number>({ key: "nextPageIndexAtom", default: 2 });

export default pageInstanceContext;

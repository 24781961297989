import { selector } from "recoil";
import { replaceNewsPrefix } from "./designer/file";
import { TDictionaryValue } from "./persist/dictionary-value";
import { recoilPersistServerDictionary } from "./persist/server-dictionary";
import { TSyncDicts } from "./persist/sync-dictionary";
import { TResourceStorageKey } from "./resource-storage";
import { CreateRecoilDictionary } from "./utils/recoil-dictionary";

export type TBaseItemLangBase = {
    Headline: string;
    Topline: string;
    Shorttext: string;
};

export type TBaseItemLang = {
    Content: string;
};

export type TNewsStorageBaseItem = {
    Name: string;
    LangBase: Record<string, TBaseItemLangBase>;
    Media: TResourceStorageKey;
    Date: string;
};

export type TNewsStorageItem = TNewsStorageBaseItem & {
    Lang: Record<string, TBaseItemLang>;
    ExtLink: string;
};

const dictSyncer: TSyncDicts = new Map();
const { baseValuesEffect, valuesEffect } = recoilPersistServerDictionary<
    TNewsStorageBaseItem,
    TNewsStorageItem
>("newsstorage", dictSyncer);

const dict = CreateRecoilDictionary<TNewsStorageItem, string>("news", {
    ValuesEffects: [valuesEffect],
});
const dictBase = CreateRecoilDictionary<TNewsStorageBaseItem, string>("newsbase", {
    ValuesEffects: [baseValuesEffect],
});

export const recoilNewsStorage = {
    dictionary: dict,
    baseDictionary: dictBase,

    NewsValues: dict.values,
    News: dict.valueKeys,

    BaseNewsValues: dictBase.values,
    BaseNews: dictBase.valueKeys,
};

export const newsBaseArray = selector<TDictionaryValue<TNewsStorageBaseItem>[]>({
    key: "newsBaseArray",
    get: ({ get }) => {
        let temp: TDictionaryValue<TNewsStorageBaseItem>[] = [];
        const newsKeyNames = get(recoilNewsStorage.BaseNews);
        for (const newsName of newsKeyNames) {
            const news = get(recoilNewsStorage.BaseNewsValues(newsName));
            if (news.IsLoaded()) temp.push(news);
        }
        temp = temp.sort((val1, val2) =>
            replaceNewsPrefix(val1.Get().Name).localeCompare(replaceNewsPrefix(val2.Get().Name)),
        ); // TODO! do we want sorting?
        return temp;
    },
});

import { useAdvCallback } from "@hooks/react-overload/useAdvCallback";
import { useAdvEffect } from "@hooks/react-overload/useAdvEffect";
import deepCopy from "@utils/deep-copy";
import { advlog } from "@utils/logging";
import { useRef } from "react";
import { isFirefox } from "react-device-detect";
import { useRecoilSnapshot } from "recoil";

type TLogItem = {
    key: string;
    state: string;
    contents: any;
};

/**
 * Protokolliert alle Recoil-Änderungen in der Konsole.
 * @link https://recoiljs.org/docs/guides/dev-tools
 */
const DebugObserverImpl = () => {
    const snapshot = useRecoilSnapshot();
    const logTimeout = useRef<NodeJS.Timeout | undefined>(undefined);
    const logItems = useRef<TLogItem[]>([]);

    const printLog = useAdvCallback(() => {
        if (logTimeout.current != undefined) {
            clearTimeout(logTimeout.current);
            logTimeout.current = undefined;
        }

        console.groupCollapsed(
            `%cATOMs CHANGED (${logItems.current.length})`,
            "background: white; color: gray;",
        );

        const itemsToLog = deepCopy(logItems.current, true);
        const keyArray: string[] = [];

        const colorArray: string[] = [
            "aqua",
            "black",
            "blue",
            "fuchsia	",
            "gray",
            "green",
            "lime",
            "maroon",
            "navy",
            "olive",
            "purple",
            "red",
            "silver",
            "teal",
            "white",
            "yellow",
            // "black", "white", "gray", "silver", "maroon", "red", "purple",
            // "fushsia", "green", "lime", "olive", "yellow", "navy", "blue", "teal", "aqua"
        ];

        for (const { key, state, contents } of itemsToLog) {
            // Jeder Key hat einen eindeutigen Index im KeyArray,
            // sodass man auf einem Blick erkennen kann ob sich der selbe State öfter geändert hat (anhand der Farben in den eckigen Klammern)
            let index = keyArray.indexOf(key);
            if (index == -1) {
                keyArray.push(key);
                index = keyArray.length - 1;
            }

            advlog(
                "[%c %s %c] %c %s %c %s %c %o",
                `background: ${colorArray[Math.min(index, colorArray.length - 1)]};`,
                "   ",
                "color: inherit; ",
                "color: orange;",
                key,
                state == "hasValue" ? "color: gray;" : "color: red;",
                state,
                contents === undefined ? "color: red;" : "color: inherit;",
                contents,
            );
        }

        console.groupEnd();
        logItems.current = [];
    }, []);

    const addLog = useAdvCallback(
        (key: string, state: string, contents: any) => {
            // Direkt ausgeben, aber auch in die das LogItems-Array schreiben
            console.debug(
                "%c%s%c %s %c %s %c %o",
                "background: lightgray; color: gray;",
                "ATOM Changed",
                "color: orange;",
                key,
                state == "hasValue" ? "color: gray;" : "color: red;",
                state,
                contents === undefined ? "color: red;" : "color: inherit;",
                contents,
            );

            logItems.current.push({ key, state, contents });
            if (logTimeout.current === undefined) {
                // Nicht direkt ausgeben, sondern 500ms sammeln und dann gesammelt in der Konsole ausgeben
                logTimeout.current = setTimeout(printLog, 500);
            }
        },
        [printLog],
    );

    useAdvEffect(() => {
        // console.debug('The following atoms were modified:');
        const nodes = Array.from(snapshot.getNodes_UNSTABLE({ isModified: true }));
        for (const node of nodes) {
            const loadable = snapshot.getLoadable(node);

            const key = node.key.replace("__", " --> ").padEnd(64, " ");
            const state = loadable.state;

            addLog(key, state, loadable.contents);
        }
    }, [addLog, snapshot]);

    return null;
};

const DebugObserverDummy = () => {
    return <></>;
};

const DebugObserver = () => {
    if (isFirefox) return <DebugObserverDummy></DebugObserverDummy>;
    return <DebugObserverImpl></DebugObserverImpl>;
};

export default DebugObserver;

import { selectorFamily } from "recoil";
import { TDictionaryValue } from "./persist/dictionary-value";
import { recoilPersistLocalStorage } from "./persist/localstorage";
import { recoilPersistServerDictionary } from "./persist/server-dictionary";
import { TSyncDicts } from "./persist/sync-dictionary";
import { CreateRecoilDictionary } from "./utils/recoil-dictionary";

export type TResourceStorageKey = {
    DataType: string;
    Name: string;
};

export const ResourceStorageDefault: TResourceStorageKey = {
    DataType: "",
    Name: "",
};

export type TResourceStorageBaseItem = {
    Name: TResourceStorageKey;
};

export type TResourceStorageItem = TResourceStorageBaseItem & {
    Value: string; // base64
};

const dictSyncer: TSyncDicts = new Map();

const { baseValuesEffect, valuesEffect } = recoilPersistServerDictionary<
    TResourceStorageBaseItem,
    TResourceStorageItem
>("resourcestorage", dictSyncer, () => {
    return true;
});
const { persistAtom } = recoilPersistLocalStorage<TResourceStorageKey, TResourceStorageItem>(
    "resourcestorage",
    dictSyncer,
);

const dict = CreateRecoilDictionary<TResourceStorageItem, TResourceStorageKey>("resources", {
    ValuesEffects: [persistAtom, valuesEffect],
});
const dictBase = CreateRecoilDictionary<TResourceStorageBaseItem, TResourceStorageKey>(
    "resourcesbase",
    {
        ValuesEffects: [baseValuesEffect],
    },
);

const recoilResourceStorage = {
    dictionary: dict,
    baseDictionary: dictBase,

    ResourceValues: dict.values,
    Resources: dict.valueKeys,

    BaseResourceValues: dictBase.values,
    BaseResources: dictBase.valueKeys,
};

// client resource storage
const { baseValuesEffect: baseValuesEffectClient, valuesEffect: valuesEffectClient } =
    recoilPersistServerDictionary<TResourceStorageBaseItem, TResourceStorageItem>(
        "resourcestorage_client",
        dictSyncer,
        () => {
            return true;
        },
    );
const { persistAtom: persistAtomClient } = recoilPersistLocalStorage<
    TResourceStorageKey,
    TResourceStorageItem
>("resourcestorage_client", dictSyncer);

const dictClient = CreateRecoilDictionary<TResourceStorageItem, TResourceStorageKey>(
    "resources_client",
    {
        ValuesEffects: [persistAtomClient, valuesEffectClient],
    },
);
const dictBaseClient = CreateRecoilDictionary<TResourceStorageBaseItem, TResourceStorageKey>(
    "resourcesbase_client",
    {
        ValuesEffects: [baseValuesEffectClient],
    },
);

const recoilResourceStorageClient = {
    dictionary: dictClient,
    baseDictionary: dictBaseClient,

    ResourceValues: dictClient.values,
    Resources: dictClient.valueKeys,

    BaseResourceValues: dictBaseClient.values,
    BaseResources: dictBaseClient.valueKeys,
};

export const getRecoilResourceStorage = (isClient: boolean) => {
    return isClient ? recoilResourceStorageClient : recoilResourceStorage;
};

/**
 * This selector automatically decides what resource to pick
 * It always tries to find a client's resource first
 * But uses the non client's one as fallback
 */
export const selectResource = selectorFamily<
    TDictionaryValue<TResourceStorageItem>,
    { key: TResourceStorageKey; mode: "client" | "normal" | "client_first_then_normal" }
>({
    key: "resourceStorage_SelectorSingleItemAuto",
    get:
        (param) =>
        ({ get }) => {
            const res = get(recoilResourceStorageClient.ResourceValues(param.key));
            if (
                param.mode == "normal" ||
                (res.HasError() && param.mode == "client_first_then_normal")
            ) {
                return get(recoilResourceStorage.ResourceValues(param.key));
            }

            return res;
        },
});

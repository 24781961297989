import { TResourceStorageKey } from "@data/resource-storage";
import { useAdvCallback } from "@hooks/react-overload/useAdvCallback";
import { advcatch } from "@utils/logging";
import { getHook } from "@utils/react-hooks-outside";
import { atom, atomFamily } from "recoil";

import { TSuccessClass, useAdvSocketCallback } from "./useAdvSocketCallback";

export enum EFieldDataType {
    unknown = "",
    integer = "i",
    biginteger = "I",
    varchar = "s",
    nvarchar = "n",
    currency = "C",
    boolean = "B",
    date = "D",
    datetime = "d",
    numeric = "f",
    float = "F",
    memo = "M",
    widememo = "W",
    time = "T",
}

export type TAdvDBTableField = {
    Name: string;
    Bez: string;
    TableRef: string;
    FieldRef: string;
    DataType: EFieldDataType;
    GroupPath: string;
    Expression: string;
};

export type TAdvDBTable = {
    Name: string;
    Bez: string;
    IsVirtual: boolean;
    Fields: Array<TAdvDBTableField>;
};

export type TAdvVirtualDBTableField = {
    Name: string;
    TableRef?: string;
    FieldRef?: string;
    DataType: EFieldDataType;
};

export type TAdvVirtualDBTable = {
    Name: string; //wird auch zur Identifizierung genutzt
    Descr: string;
    Fields: Array<TAdvVirtualDBTableField>;
    SqlPre: string;
    SqlSelect: string;
    SqlPost: string;
};

export type TAdvDBTableCB = {
    Tables: Array<TAdvDBTable>;
};

export type TAdvDBVirtTableCB = {
    Tables: Array<TAdvVirtualDBTable>;
};

export type TAdvPermission = {
    ID: number;
    Bez: string;
};

export type TAdvUserGroup = {
    ID: number;
    Bez: string;
    Permissions: Array<TAdvPermission>;
};

export type TAdvDBUserGroupsDB = {
    UserGroups: Array<TAdvUserGroup>;
};

export type TAdvWebActionModule = {
    RightID: number;
    ModID: number;
};

export type TAdvWebActionPermission = {
    Rights: Array<TAdvWebActionModule>;
    PageName: string;
    FormID: number;
};

type TGetWebActionPermission = {
    ActionName: string;
};

type TGetWebActionPermissionRes = {
    Success: boolean;
    Permission: TAdvWebActionPermission;
};

type TSetWebActionPermission = {
    ActionName: string;
    Permission: TAdvWebActionPermission;
};

type TGetPermissionsResult = {
    Permissions: Array<TAdvPermission>;
};

type TSetPermissionsRequest = {
    IsReset: boolean;
    Permissions: Array<TAdvPermission>;
};

export type TGetContracts = {
    Names: Array<string>;
};

export type TContractInterfaceInitEntry = {
    Name: string;
    DataType: string;
    IsOptional: boolean;
    Description: string;
};

export type TContractInterfaceEntry = {
    Name: string;
    DataType: string;
    IsOptional: boolean;
    Description: string;
    ExpectsProviderName: boolean;
    ExpectsValueRange: boolean;
    IsParameter: boolean;
};

export type TContractFieldEntry = {
    Name: string;
    DataType: string;
    Description: string;
    // this value is used similar to a value binding
    // if the value changes, notify the contract about this change
    NotifyOnInterfaceEntryValueChange: string;
};

export type TContractInterfaceFunctionParameterEntry = {
    Name: string;
    DataType: string; // see dt constants
    // whether it's an optional parameter
    IsOptional: boolean;
    Description: string;
};

export type TContractInterfaceFunctionEntry = {
    // name of the exported function
    Name: string;
    Parameters: Array<TContractInterfaceFunctionParameterEntry>;
    Description: string;
};

export type TContractInterface = {
    InitArgs: Array<TContractInterfaceInitEntry>;
    Entries: Array<TContractInterfaceEntry>;

    Functions: Array<TContractInterfaceFunctionEntry>;
    Fields: Array<TContractFieldEntry>;
};

export type TGetContractInterface = {
    ContractInterface: TContractInterface;
};

export type TLanguageItem = {
    Name: string;
    ID: string;
    FieldIndex: string;
};

export type TGetSysLanguages = {
    Languages: Array<TLanguageItem>;
};

export type TBaseNews = {
    Headline: string;
    Topline: string;
    Shorttext: string;

    Name: string;
    Media: TResourceStorageKey;
    Date: string;
};

export type TNews = {
    Content: string;
    ExtLink: string;
};

export type TGetNewsBaseRequest = {
    LanguageID: number;
};

export type TGetNewsBaseResponse = {
    News: Array<TBaseNews>;
};

export type TGetNewsRequest = {
    Name: string;
    LanguageID: number;
};

export type TGetNewsResponse = {
    News: TNews;
};

export const gDBTablesAtom = atom<TAdvDBTableCB | undefined>({
    key: "sql_getdbTablesAtom",
    default: undefined,
    effects: [
        ({ trigger, setSelf, onSet }) => {
            const loadDbTables = async () => {
                const socket: ReturnType<typeof useAdvSocketCallback> =
                    await getHook("useAdvSocketCallback");
                if (socket != undefined) {
                    const tables = (await socket.sendCallbackRequest(
                        "DataproviderFactory",
                        "GetDbTables",
                        {},
                    )) as TAdvDBTableCB;
                    setSelf(tables);
                }
            };

            if (trigger == "get") {
                loadDbTables().catch((r) => advcatch("Could not fetch db tables", r));
            }

            onSet((newVal, oldVal, isReset) => {
                if (isReset) {
                    loadDbTables().catch((r) => advcatch("Could not fetch db tables", r));
                }
            });
        },
    ],
});

function removeFamilyName(key: string) {
    const index = key.indexOf("__");
    if (index >= 0) {
        let quotedKey = key.substring(index + 2);
        if (quotedKey[0] == '"')
            quotedKey = quotedKey.substring(
                1,
                quotedKey[quotedKey.length - 1] == '"' ? quotedKey.length - 1 : undefined,
            );
        return quotedKey;
    }
    return key;
}

export const gContractInterfaceAtom = atomFamily<TGetContractInterface | undefined, string>({
    key: "global_ContractInterfaceAtom",
    default: undefined,
    effects: [
        ({ trigger, setSelf, node }) => {
            const contractName = removeFamilyName(node.key);
            const loadContractInterface = async () => {
                const socket: ReturnType<typeof useAdvSocketCallback> =
                    await getHook("useAdvSocketCallback");
                if (socket != undefined) {
                    const contractInterface = (await socket.sendCallbackRequest(
                        "contract",
                        "interface",
                        {
                            ContractName: contractName,
                        },
                    )) as TGetContractInterface;
                    setSelf(contractInterface);
                }
            };

            if (trigger == "get") {
                loadContractInterface().catch((r) =>
                    advcatch("Could not fetch contract interface", r),
                );
            }
        },
    ],
});

export const gSysLanguagesAtom = atom<TGetSysLanguages | undefined>({
    key: "global_SysLanguagesAtom",
    default: undefined,
    effects: [
        ({ trigger, setSelf }) => {
            const loadSysLanguages = async () => {
                const socket: ReturnType<typeof useAdvSocketCallback> =
                    await getHook("useAdvSocketCallback");
                if (socket != undefined) {
                    const contractInterface = (await socket.sendCallbackRequest(
                        "newsstorage",
                        "getlanguages",
                        {},
                    )) as TGetSysLanguages;
                    setSelf(contractInterface);
                }
            };

            if (trigger == "get") {
                loadSysLanguages().catch((r) => advcatch("Could not fetch contract interface", r));
            }
        },
    ],
});

export function useAdvDataFactory() {
    const { sendCallbackRequest } = useAdvSocketCallback();

    const getDBUserGroups = useAdvCallback(
        async function () {
            const newTables = (await sendCallbackRequest(
                "Permissions",
                "GetUserGroups",
                {},
            )) as TAdvDBUserGroupsDB;
            return newTables;
        },
        [sendCallbackRequest],
    );

    const getWebActionPermissions = useAdvCallback(
        async function (actionName: string) {
            const inp: TGetWebActionPermission = { ActionName: actionName };
            const res = (await sendCallbackRequest(
                "Permissions",
                "GetWebActionPermission",
                inp,
            )) as TGetWebActionPermissionRes;
            return res;
        },
        [sendCallbackRequest],
    );

    const setWebActionPermissions = useAdvCallback(
        async function (inp: TSetWebActionPermission) {
            const res = (await sendCallbackRequest(
                "Permissions",
                "SetWebActionPermission",
                inp,
            )) as TSuccessClass;
            return res;
        },
        [sendCallbackRequest],
    );

    const getSessionPermissions = useAdvCallback(
        async function () {
            const res = (await sendCallbackRequest(
                "Permissions",
                "GetSessionPermission",
                {},
            )) as TGetPermissionsResult;
            return res;
        },
        [sendCallbackRequest],
    );

    const setSessionPermissions = useAdvCallback(
        async function (inp: TSetPermissionsRequest) {
            const res = (await sendCallbackRequest(
                "Permissions",
                "SetSessionPermission",
                inp,
            )) as TSuccessClass;
            return res;
        },
        [sendCallbackRequest],
    );

    const getContractList = useAdvCallback(
        async function () {
            const res = (await sendCallbackRequest("contract", "list", {})) as TGetContracts;
            return res;
        },
        [sendCallbackRequest],
    );

    const getContractInterface = useAdvCallback(
        async function (contractName: string) {
            const res = (await sendCallbackRequest("contract", "interface", {
                ContractName: contractName,
            })) as TGetContractInterface;
            return res;
        },
        [sendCallbackRequest],
    );

    const getNewsBase = useAdvCallback(
        async function (langID: number) {
            const res = (await sendCallbackRequest("newsstorage", "getnewsbase", {
                LanguageID: langID,
            } as TGetNewsBaseRequest)) as TGetNewsBaseResponse;
            return res;
        },
        [sendCallbackRequest],
    );

    const getNews = useAdvCallback(
        async function (newsName: string, langID: number) {
            const res = (await sendCallbackRequest("newsstorage", "getnews", {
                LanguageID: langID,
                Name: newsName,
            } as TGetNewsRequest)) as TGetNewsResponse;
            return res;
        },
        [sendCallbackRequest],
    );

    return {
        getDBUserGroups,
        getWebActionPermissions,
        setWebActionPermissions,
        getSessionPermissions,
        setSessionPermissions,
        // contracts
        getContractList,
        getContractInterface,
        // news
        getNewsBase,
        getNews,
    };
}

import { useEffectDebugger } from "@hooks/misc/useEffectDebugger";
import { EVerbosityLevel, verbosityLevel } from "@hooks/shared";
// eslint-disable-next-line no-restricted-imports
import { useEffect, useRef } from "react";

type TEffectParams = Parameters<typeof useEffect>;

// allow unused since this hook as runtime overhead it must be explicitly enabled
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const useAdvEffect =
    verbosityLevel > EVerbosityLevel.None
        ? (effect: TEffectParams[0], deps?: TEffectParams[1]) => {
              useEffectDebugger("verbose-useAdvEffect-" + effect.name, effect, deps);
          }
        : (effect: TEffectParams[0], deps?: TEffectParams[1]) => {
              // eslint-disable-next-line react-hooks/exhaustive-deps
              useEffect(effect, deps);
          };

/** Stellt sicher, dass der Effekt nur 1x beim Mount ausgeführt wird! Nur nutzen falls wirklich nötig */
export const useAdvEffectOnce = (effect: TEffectParams[0]) => {
    const effectRan = useRef(false);

    useAdvEffect(() => {
        if (effectRan.current) return;

        const cleanup = effect();
        return () => {
            if (typeof cleanup === "function") cleanup();
            effectRan.current = true;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
};

import { IDesignerComponent, IDesignerProperty } from "@feature/Designer/types/designer";
import { getDesignableComponentByType } from "@feature/Designer/utils";
import deepCopy from "@utils/deep-copy";
import { advlog } from "@utils/logging";

import { IDesignerComponentPayload, IDesignerPropertyPayload } from "./dynamic-component/types";
import { TDynamicPage, TDynamicPagePayload } from "./dynamic-page/types";

export function findDynamicPageComponent(page: TDynamicPage) {
    return page.components.find((comp) => comp.key == page.baseComponentKey);
}

/**
 * Erzeugt aus dem {@link DynamicPage} eine {@link DynamicPagePayload}.
 *
 * Das Payload enthält nur essentielle Daten.
 */
export function pageToPayload({
    components,
    dataproviders,
    contracts,
    ...page
}: TDynamicPage): TDynamicPagePayload {
    const componentPayloads: IDesignerComponentPayload[] = [];

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    components.forEach(({ properties, staticData, presets, ...component }: IDesignerComponent) => {
        const propertyPayloads: IDesignerPropertyPayload[] = [];
        properties.forEach(({ key, name, value, bindingParams }) => {
            propertyPayloads.push({ key, name, value, bindingParams });
        });
        componentPayloads.push({
            properties: propertyPayloads,
            type: staticData.type,
            ...component,
        });
    });

    // Data & Fields nicht abspeichern, werden ja sowieso ermittelt
    const dataprovidersCopy = deepCopy(dataproviders);
    const contractsCopy = deepCopy(contracts);

    const payload = {
        components: componentPayloads,
        dataproviders: dataprovidersCopy,
        contracts: contractsCopy,
        ...page,
    };
    return payload;
}

export function payloadToComponents(componentPayloads: IDesignerComponentPayload[]) {
    const components: IDesignerComponent[] = [];

    componentPayloads.forEach(
        ({ properties: propertyPayloads, type, ...payload }: IDesignerComponentPayload) => {
            const designableComponent = getDesignableComponentByType(type);

            const designableProperties = [...designableComponent.properties];
            const properties: IDesignerProperty[] = [];

            // Alle Properties der DesignableComponent durchlaufen und nach gegenstücken in der DesignerComponent suchen
            designableProperties.forEach((dc) => {
                const propertyPayload = propertyPayloads.find((pp) => pp.name == dc.name);

                if (propertyPayload === undefined) {
                    // Die Property ist nicht im DesignerComponent (möglicherweise eine neue Property)
                    // Das ist aber nicht schlimm, da wir im useAdvPageLoader fehlende Properties ergänzen (addMissingPropertiesInternal)
                    advlog(
                        `${designableComponent.staticData.type} PropertyPayload undefined, Property '${dc.name}' missing`,
                    );
                } else
                    properties.push({
                        ...dc,
                        ...propertyPayload,
                    });
            });

            const staticData = designableComponent.staticData;
            const presets = designableComponent.presets;
            components.push({
                ...designableComponent,
                properties,
                staticData,
                presets,
                ...payload,
            });
        },
    );
    return components;
}

/**
 * Erzeugt aus dem {@link DynamicPagePayload} eine {@link DynamicPage}.
 *
 * Ermittelt Sachen wie z.B. StaticData selbstständig.
 */
export function payloadToPage({
    components: componentPayloads,
    dataproviders: pageDataProviders,
    ...payload
}: TDynamicPagePayload): TDynamicPage {
    const components = payloadToComponents(componentPayloads);

    const dataproviders = pageDataProviders ?? []; // Falls keine vorhanden: Leeres Array
    if (pageDataProviders === undefined) console.warn("DataProvider undefined!", payload);

    const page = { components, dataproviders, ...payload };
    return page;
}

/** Default-Key der Base-Komponente im Designer. */
export const DEFAULT_DESIGNER_BASE_KEY = "000000000000000000000";

/**
 * Name der DesignableComponent, die gerade in den Designer gezogen wurde.
 * Es wird eine DesignerComponent aus der DesignableComponent erzeugt und in das DropTarget eingefügt.
 */
export const ADDITIONAL_TYPE = "component-name";

/**
 * ComponentKey der DesignerComponent, die gerade in ein anderes Element gezogen wurde.
 */
export const ADDITIONAL_KEY = "component-key";

/**
 * Name des Presets das angewendet werden soll nachdem die Komponente in den Designer gezogen wurde
 */
export const ADDITIONAL_PRESET = "component-preset-name";

import {
    EFieldSettingsFieldDomain,
    EFieldSettingsFieldTypes,
} from "@components/dynamic/data-provider/types";
import AdvCheckbox from "@components/inputs/checkbox";
import { LAN } from "@data/language/strings";
import { normalizeISO, useLanguage } from "@hooks/language/useLanguages";
import { useAdvCallback } from "@hooks/react-overload/useAdvCallback";
import useAdvComponent from "@hooks/useAdvComponent";
import { ValueAbsoluteIcon } from "@themes/icons";
import { ServerStrToLocalDateStr, ServerStrToLocalDateTimeStr } from "@utils/date";
import { deepCompareJSXProps } from "@utils/deep-compare";
import { advcatch } from "@utils/logging";
import React, { useMemo, useRef, useState } from "react";

import { AdvContextualMenu } from "@components/layout/contextual-menu/contextual-menu";
import { numberStyle } from "./styles";

export type TCellValueProps<TValue = any> = React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLSpanElement>,
    HTMLSpanElement
> & {
    type?: EFieldSettingsFieldTypes;
    domain?: EFieldSettingsFieldDomain;
    value: TValue;
    curSearchText?: string;
};

const StringToSpans = ({
    text,
    searchField,
    ...props
}: React.HTMLAttributes<HTMLSpanElement> & { text: string; searchField?: string }) => {
    if (searchField != undefined && searchField != "") {
        const allText = text.split(new RegExp(searchField, "i"));
        const allSearch: string[] = [];
        let m = null;
        const searchReg = new RegExp(searchField, "gi");
        do {
            m = searchReg.exec(text);
            if (m) allSearch.push(m[0]);
        } while (m);
        const isMapReversed = (props.style?.float ?? "") == "right";
        return (
            <span style={{ float: isMapReversed ? "right" : undefined }}>
                {allText.map((t, tIndex) => {
                    if (tIndex < allText.length - 1) {
                        return (
                            <>
                                <span
                                    {...props}
                                    style={{
                                        ...(props.style ?? {}),
                                        float: "left",
                                        whiteSpace: "pre",
                                    }}
                                    key={tIndex.toString() + "_tablespanfield"}
                                >
                                    {t}
                                </span>
                                <mark
                                    {...props}
                                    style={{
                                        ...(props.style ?? {}),
                                        float: "left",
                                        whiteSpace: "pre",
                                    }}
                                    key={tIndex.toString() + "_tablespanfield2"}
                                >
                                    {allSearch[tIndex]}
                                </mark>
                            </>
                        );
                    }
                    return (
                        <span
                            {...props}
                            style={{ ...(props.style ?? {}), float: "left" }}
                            key={tIndex.toString() + "_tablespanfield"}
                        >
                            {t}
                        </span>
                    );
                })}
            </span>
        );
    }
    return <span {...props}>{text}</span>;
};

/** Value enthält u.U. €, $, ... */
const AdvCellValueCurrency = ({ value, curSearchText }: TCellValueProps<string>) => {
    const hasNumberNotZero = value.search(/[1-9]+/) != -1;
    return (
        <StringToSpans
            style={numberStyle}
            text={hasNumberNotZero ? value : "-"}
            searchField={curSearchText}
        ></StringToSpans>
    );
};

const AdvCellValueNumber = ({
    value,
    onContextMenu,
    curSearchText,
}: TCellValueProps<number | string>) => {
    const currentLanguage = useLanguage();
    const currentLocales = normalizeISO(currentLanguage.ISO); // => de-de, en-gb, nl-nl
    const formatter = new Intl.NumberFormat(currentLocales);
    const numberValue = typeof value == "number" ? value : Number(value.replace(",", "."));

    return (
        <>
            <StringToSpans
                onContextMenu={onContextMenu}
                style={numberStyle}
                text={numberValue.toString()}
                searchField={curSearchText}
            ></StringToSpans>
        </>
    );
};

/** Value ist z.B. "0,25" (25%) */
const AdvCellValuePercentage = ({
    value,
    onContextMenu,
    curSearchText,
}: TCellValueProps<string>) => {
    const currentLanguage = useLanguage();
    const currentLocales = normalizeISO(currentLanguage.ISO);
    const formatter = new Intl.NumberFormat(currentLocales, {
        style: "percent",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });
    const numberValue = typeof value === "number" ? value : Number(value.replace(",", "."));

    return (
        <StringToSpans
            onContextMenu={onContextMenu}
            style={numberStyle}
            text={formatter.format(numberValue)}
            searchField={curSearchText}
        ></StringToSpans>
    );
};

const AdvCellValueBool = ({ value }: TCellValueProps<boolean>) => {
    return <AdvCheckbox key="field_checkbox" label="" disabled={true} value={value}></AdvCheckbox>;
};

const AdvCellValueDatetime = ({ value, type, onContextMenu, curSearchText }: TCellValueProps) => {
    if (type == EFieldSettingsFieldTypes.datetime)
        return (
            <StringToSpans
                onContextMenu={onContextMenu}
                text={ServerStrToLocalDateTimeStr(value)}
                searchField={curSearchText}
            ></StringToSpans>
        );
    else
        return (
            <StringToSpans
                onContextMenu={onContextMenu}
                text={ServerStrToLocalDateStr(value)}
                searchField={curSearchText}
            ></StringToSpans>
        );
};

const AdvCellValueBasic = ({ value, onContextMenu, curSearchText }: TCellValueProps) => {
    return (
        <StringToSpans
            title={value}
            onContextMenu={onContextMenu}
            text={value.toString()}
            searchField={curSearchText}
        ></StringToSpans>
    );
};
const AdvCellValueMemo = ({ value, onContextMenu, curSearchText }: TCellValueProps) => {
    return (
        <StringToSpans
            title={value}
            onContextMenu={onContextMenu}
            text={value}
            searchField={curSearchText}
            style={{ textOverflow: "ellipsis" }}
        ></StringToSpans>
    );
};
export const columnIsNumberType = (type: EFieldSettingsFieldTypes | undefined) => {
    return type == EFieldSettingsFieldTypes.currency || type == EFieldSettingsFieldTypes.number;
};

/**
 * @summary Wrapper für ``Checkbox``
 * @link https://developer.microsoft.com/en-us/fluentui#/controls/web/checkbox
 */
const AdvCellValueComp = ({ type, value, domain, ...props }: TCellValueProps) => {
    useAdvComponent(AdvCellValueComp, props);

    const [isContextOpen, setIsContextOpen] = useState(false);
    const ref = useRef<any>();

    const onContextMenu = useAdvCallback((e: any) => {
        ref.current = e.target;
        setIsContextOpen(true);
        e.preventDefault();
    }, []);

    const childComp = useMemo(() => {
        switch (type) {
            case EFieldSettingsFieldTypes.boolean:
                return (
                    <AdvCellValueBool
                        value={value}
                        {...props}
                        onContextMenu={onContextMenu}
                    ></AdvCellValueBool>
                );
            case EFieldSettingsFieldTypes.currency:
                return (
                    <AdvCellValueCurrency
                        value={value}
                        {...props}
                        onContextMenu={onContextMenu}
                    ></AdvCellValueCurrency>
                );
            case EFieldSettingsFieldTypes.number:
                if (domain == EFieldSettingsFieldDomain.percent)
                    return (
                        <AdvCellValuePercentage
                            value={value}
                            {...props}
                            onContextMenu={onContextMenu}
                        ></AdvCellValuePercentage>
                    );
                else
                    return (
                        <AdvCellValueNumber
                            value={value}
                            {...props}
                            onContextMenu={onContextMenu}
                        ></AdvCellValueNumber>
                    );
            case EFieldSettingsFieldTypes.date:
            case EFieldSettingsFieldTypes.datetime:
                return (
                    <AdvCellValueDatetime
                        value={value}
                        type={type}
                        {...props}
                        onContextMenu={onContextMenu}
                    ></AdvCellValueDatetime>
                );
            case EFieldSettingsFieldTypes.memo:
                return (
                    <AdvCellValueMemo
                        value={value}
                        {...props}
                        onContextMenu={onContextMenu}
                    ></AdvCellValueMemo>
                );
            default:
                return (
                    <AdvCellValueBasic
                        value={value}
                        {...props}
                        onContextMenu={onContextMenu}
                    ></AdvCellValueBasic>
                );
        }
    }, [domain, onContextMenu, props, type, value]);

    return (
        <>
            {childComp}
            <AdvContextualMenu
                items={[
                    {
                        key: "copyVal",
                        text: LAN.COPY_VALUE.text,
                        iconProps: {
                            iconName: ValueAbsoluteIcon.iconName,
                        },
                        onClick: () => {
                            navigator.clipboard.writeText(value.toString()).catch(advcatch);
                        },
                    },
                ]}
                hidden={!isContextOpen}
                target={ref.current}
                onItemClick={() => setIsContextOpen(false)}
                onDismiss={() => setIsContextOpen(false)}
            />
        </>
    );
};

const AdvCellValue = React.memo(AdvCellValueComp, deepCompareJSXProps);
export default AdvCellValue;

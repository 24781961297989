import AdvDynamicPageDesignable from "@components/dynamic/dynamic-page/designable";
import { recoilDynamicPage } from "@data/dynamic-page";
import { EComponentTypeLayout } from "@feature/Designer/types/component-type";
import useAdvRecoilValue from "@hooks/recoil-overload/useAdvRecoilValue";
import { useMemo } from "react";
import { EAdvValueDataTypes } from "../../utils/data-types";
import { TAdvValueBindingParams, useAdvValueBinderNoDataType } from "./useAdvValueBinder";

export const usePageTitle = (dynPageName: string) => {
    const dynPage = useAdvRecoilValue(recoilDynamicPage.Pages(dynPageName));
    const dynPageBindingParams = useMemo<{
        bindings: TAdvValueBindingParams | undefined;
        title: string | undefined;
        secbindings: TAdvValueBindingParams | undefined;
        sectitle: string | undefined;
    }>(() => {
        if (dynPage.IsLoaded()) {
            const foundComp = dynPage
                .Get()
                .components.find((valC) => valC.type == EComponentTypeLayout.DynamicPage);
            if (foundComp != undefined) {
                const foundTitle = foundComp.properties.find(
                    (valP) => valP.name == AdvDynamicPageDesignable.PropertyName.Title,
                );
                const foundSecTitle = foundComp.properties.find(
                    (valP) => valP.name == AdvDynamicPageDesignable.PropertyName.SecondaryTitle,
                );
                return {
                    bindings: foundTitle?.bindingParams,
                    title: foundTitle?.value as string,
                    secbindings: foundSecTitle?.bindingParams,
                    sectitle: foundSecTitle?.value as string,
                };
            }
        }
        return {
            bindings: undefined,
            title: undefined,
            secbindings: undefined,
            sectitle: undefined,
        };
    }, [dynPage]);

    const [title] = useAdvValueBinderNoDataType(
        dynPageBindingParams.bindings,
        dynPageBindingParams.title,
        EAdvValueDataTypes.Any,
        0,
    );
    const [sectitle, , attributes] = useAdvValueBinderNoDataType(
        dynPageBindingParams.secbindings,
        dynPageBindingParams.sectitle,
        EAdvValueDataTypes.Any,
        0,
    );

    return {
        title,
        sectitle: attributes.isLoaded || attributes.isLoading ? sectitle : "",
    };
};

import { useAdvCallback } from "@hooks/react-overload/useAdvCallback";
import { advlog } from "@utils/logging";
import useEventListener from "./misc/useEventListener";
import { useAdvSocketCallback } from "./useAdvSocketCallback";

/**
 * Native JS-Fehler über den "error" Eventhandler abfangen. Dies sind Fehler, die bspw. in Event-Handlern oder
 * asynchronen Code (siehe Limitierungen von Errorboundary) auftreten.
 *
 * BEACHTE: In DEV gibt NextJS einige Fehler in der Konsole aus (console.error), die KEINE tatsächlichen Fehler sind.
 * Sowas wie "Component needs unique key" oä. kommt in PROD garnicht vor. Dementsprechend können wir solche Sachen auch nicht abfangen.
 */
export const useNativeErrorHandler = () => {
    const { sendCallbackRequest } = useAdvSocketCallback();
    const sendErrorToServer = useAdvCallback(
        (error: { name: string; message: string; stack: string; cause?: string }) => {
            const payload = {
                Name: error.name,
                Msg: error.message,
                Stack: error.stack,
                Cause: error.cause ?? "<no cause>",
            };
            if (process.env.NEXT_PUBLIC_ENV != "dev") {
                sendCallbackRequest("BugReportInterface", "CreateBugReport", payload).catch(() =>
                    advlog("Could not create bug report."),
                );
            } else {
                console.info("Bugreport would have been sent: ", payload); // In DEV keinen Bugreport schicken
            }
        },
        [sendCallbackRequest],
    );
    const handleError = useAdvCallback(
        (errorEvent: ErrorEvent) => {
            if (errorEvent.filename.indexOf("react-dom.development") > 0) return; // React wirft jeden Fehler nochmal (DEV) -> ignorieren
            sendErrorToServer({
                name: errorEvent.message,
                message: errorEvent.error?.message ?? "",
                stack: errorEvent.error?.stack ?? "",
                cause: "native",
            });
        },
        [sendErrorToServer],
    );
    useEventListener("error", handleError);
    return [];
};

// /**
//  * Hook zur Fehlerbehandlung.
//  * Gibt den Fehler an das ErrorBoundary weiter, sodass dieses den Fehler behandeln kann.
//  * Im DEV-Stand werden die Fehler nicht weitergereicht sondern im "normalen" React Fenster angezeigt.
//  */
// const useAdvError = () => {
//     const { showBoundary } = useErrorBoundary();
//     /* */
//     const handleError = useAdvCallback(
//         (error: any) => {
//             if (process.env.NEXT_PUBLIC_ENV == "dev") {
//                 // [throw error] React Error Fenster öffnet sich.
//                 // [console.error] React Fenster öffnet sich nicht (Logik wie früher)
//                 throw error;
//             } else showBoundary(error);
//         },
//         [showBoundary]
//     );
//     return { handleError };
// };
// export default useAdvError;

import { RecoilState } from "recoil";

import { recoilKeystorage, TKeyStorageItem } from "./key-storage";
import { TDictionaryValue } from "./persist/dictionary-value";

export const GLOBAL_THEME_NAME = "user-globalTheme";

export const DESIGNER_UI_ALWAYS_SHOW_DROP_AREAS = "designer-UI-alwaysShowDropAreas";
export const DESIGNER_UI_RENDER_AS_DESIGNER = "designer-UI-renderAsDesigner";

export const APP_TITLE = "app-Title";
export const APP_ICON = "app-Icon";
export const APP_THEME = "app-Theme";

export const UNDERLINE_UNTRANSLATED = "user-underlineUntranslated";

// the key names should be synced to server
const recoilSettings = {
    /** Einstellugen aus dem User-KeyStorage */
    Local: {
        globalTheme: recoilKeystorage(true).KeyValues(GLOBAL_THEME_NAME) as RecoilState<
            TDictionaryValue<TKeyStorageItem<string>>
        >,
        underlineUntranslated: recoilKeystorage(true).KeyValues(
            UNDERLINE_UNTRANSLATED,
        ) as RecoilState<TDictionaryValue<TKeyStorageItem<boolean>>>,
    },
    /** Einstellugen aus dem Designer(User)-KeyStorage */
    Designer: {
        autoSaveIntervalTimeInMS: recoilKeystorage(true).KeyValues(
            "designer-autoSaveIntervalTimeInMS",
        ) as RecoilState<TDictionaryValue<TKeyStorageItem<number>>>,
        alwaysShowDropAreas: recoilKeystorage(true).KeyValues(
            DESIGNER_UI_ALWAYS_SHOW_DROP_AREAS,
        ) as RecoilState<TDictionaryValue<TKeyStorageItem<boolean>>>,
        renderAsDesigner: recoilKeystorage(true).KeyValues(
            DESIGNER_UI_RENDER_AS_DESIGNER,
        ) as RecoilState<TDictionaryValue<TKeyStorageItem<boolean>>>,
    },
    /** Einstellugen aus dem globalen KeyStorage */
    Global: {
        appTitle: recoilKeystorage(true).KeyValues(APP_TITLE) as RecoilState<
            TDictionaryValue<TKeyStorageItem<string>>
        >,
        appIcon: recoilKeystorage(true).KeyValues(APP_ICON) as RecoilState<
            TDictionaryValue<TKeyStorageItem<{ base64Str: string; type: string }>>
        >,
        appTheme: recoilKeystorage(true).KeyValues(APP_THEME) as RecoilState<
            TDictionaryValue<TKeyStorageItem<string>>
        >,
    },
};

export default recoilSettings;

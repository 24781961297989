import { remContractsTrans } from "@components/dynamic/contracts/contracts";
import { resetPageParameters } from "@components/dynamic/parameter-mapping/page-parameter";
import { recoilDataProvider } from "@data/dataprovider";
import { useProvider } from "@data/dataprovider/data-provider-server";
import { gPageFilePrefix } from "@data/designer/file";
import { buildPageIDForVariableID, recoilParameters } from "@data/parameters";
import { defaultStartPage, sessionAddInfosAtom } from "@data/session";
import useAdvError from "@hooks/dialogs/useAdvError";
import { useAdvCallback } from "@hooks/react-overload/useAdvCallback";
import {
    TAdvTransactionInterface,
    useAdvRecoilTransaction,
} from "@hooks/recoil-overload/useAdvRecoilTransaction";
import useAdvRecoilValue from "@hooks/recoil-overload/useAdvRecoilValue";
import { useAdvEvent } from "@hooks/useAdvEvent";
import { gResetDynamicPageTrans, TPageInfo } from "@pages/dynamic";
import { defaultPageInstanceIndex } from "@pages/dynamic/instanceIndexContext";
import { buildUniquePageID, gAdvDynPageName, parseQueryString } from "@utils/page-parser";
import { useAdvRouter } from "./useAdvRouter";

type TCloseEvent = {
    Page: string;
    QueryStr: string;
};

type TErrAndWarnLog = {
    Logs: Array<string>;
};

export const usePageFunctions = () => {
    const router = useAdvRouter();

    const { reloadAllDataprovider } = useProvider();

    const reloadPageData = useAdvCallback(() => {
        reloadAllDataprovider(router.pageInfo);
    }, [reloadAllDataprovider, router.pageInfo]);

    return { reloadPageData };
};

export const usePageListener = () => {
    const { registerGlobalEventHandler } = useAdvEvent();

    const { showError } = useAdvError();

    const resetTrans = recoilDataProvider.resetAndRemoveAllDataprovider;
    const resetAllDataprovider = useAdvRecoilTransaction(resetTrans, [resetTrans]);

    const resetParametersTrans = useAdvCallback(
        (tb: TAdvTransactionInterface) => (pageInfo: TPageInfo) => {
            const variableID = buildPageIDForVariableID(pageInfo);
            tb.reset(recoilParameters(variableID));
            resetPageParameters(tb)(variableID);
        },
        [],
    );
    const resetParameters = useAdvRecoilTransaction(resetParametersTrans, [resetParametersTrans]);

    const resetContractsTrans = useAdvCallback(
        (tb: TAdvTransactionInterface) => (pageInfo: TPageInfo) => {
            remContractsTrans(tb)(pageInfo);
        },
        [],
    );
    const resetContracts = useAdvRecoilTransaction(resetContractsTrans, [resetContractsTrans]);

    const resetPageUniqueness = useAdvRecoilTransaction(gResetDynamicPageTrans, [
        gResetDynamicPageTrans,
    ]);

    const session = useAdvRecoilValue(sessionAddInfosAtom);

    registerGlobalEventHandler<TCloseEvent>("page", "close", (data) => {
        const queryFromStr = parseQueryString(data.QueryStr);
        // TODO: REITER: Hier muss vermutlich der Server noch angepasst werden,
        // damit die richtige Instanz der Seite geschlossen wird

        // close dataprovider and other stuff related to that page
        if (
            data.Page.startsWith(gPageFilePrefix) ||
            data.Page == (session.Startpage != undefined ? session.Startpage : defaultStartPage)
        ) {
            const pageInfo: TPageInfo = {
                pageInstanceIndex: defaultPageInstanceIndex,
                pathname: "/dynamic",
                query: { ...queryFromStr, [gAdvDynPageName]: data.Page },
            };

            resetAllDataprovider(pageInfo);
            resetParameters(pageInfo);
            const pageID = buildUniquePageID(pageInfo);
            resetPageUniqueness(pageID);
            resetContracts(pageInfo);
        } else if (data.Page.startsWith("designer/")) {
            // TODO: should designer pages ever be closed? what would be an advantage of it
            // but check if another session changes a page if it will reinitialize
            // all page stuff on the current session
        } else {
            const pageInfo: TPageInfo = {
                pageInstanceIndex: defaultPageInstanceIndex,
                pathname: data.Page,
                query: queryFromStr,
            };
            resetAllDataprovider(pageInfo);
            resetParameters(pageInfo);
            resetContracts(pageInfo);
        }
    });

    registerGlobalEventHandler<TErrAndWarnLog>("connection", "log", (data) => {
        if (data.Logs.length > 0) {
            showError(data.Logs.join("\n"));
        }
    });
};

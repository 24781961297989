import AdvGridItemDesignable from "@components/layout/grid/grid-item/designable";
import AdvStackItemDesignable from "@components/layout/stack/stack-item/designable";
import { LAN } from "@data/language/strings";
import { selectResource } from "@data/resource-storage";
import { DefaultComponentCategory } from "@feature/Designer/types/category";
import { TAdvDesignerComponentProps } from "@feature/Designer/types/component-props";
import { EComponentTypeData } from "@feature/Designer/types/component-type";
import {
    AdvProperty,
    getDesignerModeComponentStyle,
    getSelectedComponentStyle,
    registerDesignableComponent,
} from "@feature/Designer/utils";
import { Image, ImageFit, Label, Shimmer } from "@fluentui/react";
import {
    IsValueBindingTrivial,
    TAdvValueBindingParams,
    useAdvValueBinderNoDataType,
} from "@hooks/dynamic/useAdvValueBinder";
import { toAdvText } from "@hooks/language/useTranslation";
import useAdvRecoilValue from "@hooks/recoil-overload/useAdvRecoilValue";
import useAdvComponent from "@hooks/useAdvComponent";
import useAdvTheme from "@hooks/useAdvTheme";
import { ImageIcon } from "@themes/icons";
import { EAdvValueDataTypes } from "@utils/data-types";
import { deepCompareJSXProps } from "@utils/deep-compare";
import { combineStyles } from "@utils/styling";
import React, { useMemo } from "react";
import {
    AdvCommonComponentAttributes,
    AdvThemeProviderProperties,
    TAdvCommonProperties,
    TAdvResourceProperties,
} from "../common-properties";

export type TAdvResourceCompProps = TAdvDesignerComponentProps &
    TAdvCommonProperties & {
        resourceProps?: TAdvResourceProperties;
        resourcePropsBindingParams?: TAdvValueBindingParams;

        resourceHeight?: string;
        resourceMaxHeight?: string;
        resourceWidth?: string;
        resourceMaxWidth?: string;

        forceResourceTab?: "client" | "normal" | "client_first_then_normal";
        forceDataBase64?: string;
        forceIsLoading?: boolean;

        forceFitType?: ImageFit;
    };

const AdvResourceCompImpl = ({
    designerProps,
    designerData,
    resourceProps,
    resourceHeight,
    resourceMaxHeight,
    resourceWidth,
    resourceMaxWidth,
    advhide = false,
    forceResourceTab,
    forceDataBase64,
    forceIsLoading = false,
    forceFitType = ImageFit.contain,
    ...props
}: TAdvResourceCompProps) => {
    useAdvComponent(AdvResourceCompImpl, props);

    const theme = useAdvTheme();
    const style = useMemo(() => {
        let style = {
            width: resourceWidth != undefined ? resourceWidth : "100%",
            height: resourceHeight != undefined && resourceHeight != "" ? resourceHeight : "100%",
            maxHeight: resourceMaxHeight,
            maxWidth: resourceMaxWidth,
        } as any;

        if ((designerData?.isSelected ?? false) && (designerData?.renderAsDesigner ?? false))
            style = combineStyles<any, any>(style, getSelectedComponentStyle(theme, true));
        if (designerData?.renderAsDesigner ?? false)
            style = combineStyles<any, any>(style, getDesignerModeComponentStyle(theme) as any);
        return style;
    }, [
        designerData?.isSelected,
        designerData?.renderAsDesigner,
        resourceHeight,
        resourceMaxHeight,
        resourceMaxWidth,
        resourceWidth,
        theme,
    ]);
    const styles = useMemo(() => {
        let style = {
            image: {
                width: resourceWidth,
                height:
                    resourceHeight != undefined && resourceHeight != "" ? resourceHeight : "100%",
                maxHeight: resourceMaxHeight,
                maxWidth: resourceMaxWidth,
            },
        } as any;

        if ((designerData?.isSelected ?? false) && (designerData?.renderAsDesigner ?? false))
            style = combineStyles<any, any>(style, {
                root: getSelectedComponentStyle(theme, true),
            });
        if (designerData?.renderAsDesigner ?? false)
            style = combineStyles<any, any>(style, { root: getDesignerModeComponentStyle(theme) });
        return style;
    }, [
        designerData?.isSelected,
        designerData?.renderAsDesigner,
        resourceHeight,
        resourceMaxHeight,
        resourceMaxWidth,
        resourceWidth,
        theme,
    ]);

    const resource = useAdvRecoilValue(
        selectResource({
            key: {
                DataType: resourceProps?.resourceDataType ?? "",
                Name: resourceProps?.resourceName ?? "",
            },
            mode: forceResourceTab ?? "client_first_then_normal",
        }),
    );

    const fileType = useMemo(() => {
        return resource.IsLoaded() && forceDataBase64 != undefined
            ? resource.Get().Name.DataType
            : resourceProps?.resourceDataType ?? undefined;
    }, [forceDataBase64, resource, resourceProps?.resourceDataType]);
    const fileData = useMemo(() => {
        return forceDataBase64 ?? (resource.IsLoaded() ? resource.Get().Value : undefined);
    }, [forceDataBase64, resource]);

    if (!forceIsLoading && fileData != undefined && fileType != undefined && !advhide) {
        if (fileType.startsWith("image/")) {
            return (
                <Image
                    src={"data:" + fileType + ";base64," + fileData}
                    alt={"loading..."}
                    styles={styles}
                    imageFit={forceFitType}
                ></Image>
            );
        } else if (fileType.startsWith("application/")) {
            return (
                <object
                    data={"data:" + fileType + ";base64," + fileData}
                    type={fileType}
                    style={style}
                ></object>
            );
        } else if (fileType.startsWith("video/")) {
            return (
                <video
                    src={"data:" + fileType + ";base64," + fileData}
                    style={style}
                    controls
                ></video>
            );
        } else {
            return <Label>{"Unknown resource of type: " + fileType}</Label>;
        }
    } else if (resource.IsLoading() || forceIsLoading) {
        return (
            <Shimmer
                styles={{
                    shimmerWrapper: {
                        height:
                            resourceHeight != undefined && resourceHeight != ""
                                ? resourceHeight
                                : 100,
                    },
                }}
            ></Shimmer>
        );
    } else if (!advhide || designerProps != undefined) {
        return <Image alt={"---"} styles={styles}></Image>;
    }
    return <></>;
};

const AdvResourceCompSimple = ({ ...props }: TAdvResourceCompProps) => {
    return <AdvResourceCompImpl {...props} />;
};

const AdvResourceCompComplex = ({
    advhide: _advhide = false,
    advhideBindingParams,
    dataArrayIndex = 0,
    resourcePropsBindingParams,
    ...props
}: TAdvResourceCompProps) => {
    useAdvComponent(AdvResourceCompComplex, props);

    const [shouldAdvhide] = useAdvValueBinderNoDataType(
        advhideBindingParams,
        _advhide,
        EAdvValueDataTypes.Boolean,
        dataArrayIndex,
    );
    const [resourcePropsValue] = useAdvValueBinderNoDataType(
        resourcePropsBindingParams,
        // this type comes from the contract definition on the server
        // for binary downloads
        // TODO: maybe use other names instead
        {
            FileName: "",
            BinaryData: new Uint8Array(),
        },
        EAdvValueDataTypes.Object,
        dataArrayIndex,
    );

    const resourceBase64 = useMemo(() => {
        return Buffer.from(resourcePropsValue.BinaryData).toString("base64");
    }, [resourcePropsValue.BinaryData]);

    const fakeResourceProps = useMemo<TAdvResourceProperties>(() => {
        return {
            resourceName: resourcePropsValue.FileName,
            // TODO: the contract used the file name ending as type declaration, not nice but ok for now
            resourceDataType: resourcePropsValue.FileName.endsWith(".pdf") ? "application/pdf" : "",
        };
    }, [resourcePropsValue.FileName]);

    return (
        <AdvResourceCompImpl
            {...props}
            advhide={shouldAdvhide}
            resourceProps={fakeResourceProps}
            forceDataBase64={resourceBase64}
            forceIsLoading={
                fakeResourceProps.resourceName == "" || fakeResourceProps.resourceDataType == ""
            }
        />
    );
};

const AdvResourceComp = ({
    advhideBindingParams,
    resourcePropsBindingParams,
    ...props
}: TAdvResourceCompProps) => {
    if (
        IsValueBindingTrivial(advhideBindingParams) &&
        IsValueBindingTrivial(resourcePropsBindingParams)
    ) {
        return (
            <AdvResourceCompSimple
                {...props}
                advhideBindingParams={advhideBindingParams}
                resourcePropsBindingParams={resourcePropsBindingParams}
            />
        );
    } else {
        return (
            <AdvResourceCompComplex
                {...props}
                advhideBindingParams={advhideBindingParams}
                resourcePropsBindingParams={resourcePropsBindingParams}
            />
        );
    }
};

const AdvResource = React.memo(AdvResourceComp, deepCompareJSXProps);
export default AdvResource;

registerDesignableComponent({
    staticData: {
        name: LAN.RESOURCE.text,

        type: EComponentTypeData.Resource,
        supportsChildren: false,
        category: DefaultComponentCategory.Display,
        icon: ImageIcon,
    },
    properties: [
        AdvProperty.Object.createResource(
            toAdvText(LAN.RESOURCE_FILE),
            "resourceProps",
            toAdvText(LAN.GENERAL),
            toAdvText(LAN.RESOURCE_FILE_DESCR),
            undefined,
        ),
        AdvProperty.Text.create(
            toAdvText(LAN.RESOURCE_HEIGHT),
            "resourceHeight",
            toAdvText(LAN.GENERAL),
            toAdvText(LAN.RESOURCE_HEIGHT_DESCR),
            "",
        ),
        ...AdvCommonComponentAttributes,
        ...AdvThemeProviderProperties,
        ...AdvStackItemDesignable.CommonProperties,
        ...AdvGridItemDesignable.CommonProperties,
    ],
    propertiesBuilders: [],
    presets: [],
});

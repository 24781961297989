/* eslint-disable @typescript-eslint/naming-convention */
import { useAdvCallback } from "@hooks/react-overload/useAdvCallback";
import { useAdvSocketCallback } from "@hooks/useAdvSocketCallback";
import { advlog } from "@utils/logging";
import { ReactNode } from "react";
import { ErrorBoundary, FallbackProps } from "react-error-boundary";
import { install } from "source-map-support";

if (process.env.NEXT_PUBLIC_ENV != "dev") {
    // Ohne dieses Package bekommen wir keinen vernünftigen Stacktrace im PROD,
    // da error.stack standardmäßig KEINE sourcemaps berücksichtigt.
    install({ environment: "browser" });
}

// ErrorBoundary wird (normalerweise) nicht im Development angezeigt oder angestoßen, stattdessen wird das standardmäßige Fenster von React angezeigt.
// Dementsprechend schicken wir auch keinen Bugreport im Development (was ja nicht schlecht ist).
// Zusatz: Teilweise wird das ErrorBoundary auch im Development angezeigt, deshalb vorm Bugreport prüfen ob DEV oder PROD.

// (Limitierung von ErrorBoundaries, s. Dokumentation)
// Error boundaries do not catch errors for:
// - Event handlers
// - Asynchronous code (e.g. setTimeout or requestAnimationFrame callbacks)
// - (Server side rendering)
// --> "Native" (s. oben) JavaScript-Fehler werden durch den "useNativeErrorHandler" behandelt, siehe useAdvError.ts

const AdvFallbackComponent = ({ resetErrorBoundary, error }: FallbackProps) => {
    return (
        <div>
            <h1>Sorry.. there was an error</h1>
            <h3>{error.message}</h3>
            <br />
            <button type="button" onClick={resetErrorBoundary}>
                Try again?
            </button>
        </div>
    );
};

export type ErrorBoundaryBaseProps = {
    children: ReactNode;
};
const ErrorBoundaryBase = ({ children }: ErrorBoundaryBaseProps) => {
    const { sendCallbackRequest } = useAdvSocketCallback();
    const sendErrorToServer = useAdvCallback(
        (error: Error) => {
            const payload = {
                Name: error.name,
                Msg: error.message,
                Stack: error.stack,
                Cause: error.cause ?? "<no cause>",
            };
            if (process.env.NEXT_PUBLIC_ENV != "dev") {
                sendCallbackRequest("BugReportInterface", "CreateBugReport", payload).catch(() =>
                    advlog("Could not create bug report."),
                );
            } else console.info("Bugreport would have been sent: ", payload);
        },
        [sendCallbackRequest],
    );

    return (
        <ErrorBoundary FallbackComponent={AdvFallbackComponent} onError={sendErrorToServer}>
            {children}
        </ErrorBoundary>
    );
};

const AdvErrorBoundary = ErrorBoundaryBase;
export default AdvErrorBoundary;

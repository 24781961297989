// eslint-disable-next-line no-restricted-imports
import { useRecoilState, useRecoilState_TRANSITION_SUPPORT_UNSTABLE } from "recoil";

type TRecoilStateParams<T> = Parameters<typeof useRecoilState<T>>;

const useAdvRecoilState = <T>(
    recoilState: TRecoilStateParams<T>[0],
): ReturnType<typeof useRecoilState<T>> => {
    const [val, setter] = useRecoilState_TRANSITION_SUPPORT_UNSTABLE(recoilState);
    /*const setterWrapper = useAdvCallback<typeof setter>(
        (param: Parameters<typeof setter>[0]) => {
            setTimeout(() => setter(param), 0);
        },
        [setter],
    );*/
    return [val, setter];
};

export default useAdvRecoilState;

import assert from "assert";

export class TDictionaryValue<TValue> {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    constructor({
        aVal,
        aIsLoading,
        aCannotLoad,
        __internalSilentSet,
        __internalLifeTime,
        __internalIgnoreLoading,
        __internalSaveOptions,
    }: {
        aVal?: TValue;
        aIsLoading?: boolean;
        aCannotLoad?: string;
        // eslint-disable-next-line @typescript-eslint/naming-convention
        __internalSilentSet?: boolean;
        // eslint-disable-next-line @typescript-eslint/naming-convention
        __internalLifeTime?: number;
        // eslint-disable-next-line @typescript-eslint/naming-convention
        __internalIgnoreLoading?: boolean;
        // eslint-disable-next-line @typescript-eslint/naming-convention
        __internalSaveOptions?: Array<string>;
    }) {
        this.cannotLoad = aCannotLoad ?? "";
        this.isLoading = aIsLoading ?? (aVal == undefined && this.cannotLoad == "" ? true : false);
        this.val = aVal;
        this.Get = () => {
            assert(
                this.val != undefined,
                "have you checked if the data is actually loaded (IsLoaded)",
            );
            return this.val;
        };
        this.IsLoaded = () => this.val != undefined;
        this.IsLoading = () => this.isLoading;
        this.HasError = () => this.cannotLoad != "";
        this.GetError = () => this.cannotLoad;

        this.__internalIsSilentSet = __internalSilentSet ?? false;
        this.__internalIsSaving = false;
        this.__internalLifeTime = __internalLifeTime ?? 0;
        this.__internalIgnoreLoading = __internalIgnoreLoading ?? false;
        this.__internalSaveOptions = __internalSaveOptions ?? [];
    }
    private val: TValue | undefined;
    private isLoading: boolean;
    private cannotLoad: string;
    Get: () => TValue;
    IsLoaded: () => boolean;
    IsLoading: () => boolean;
    HasError: () => boolean;
    GetError: () => string;

    // eslint-disable-next-line @typescript-eslint/naming-convention
    __internalIsSilentSet: boolean;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    __internalIsSaving: boolean;
    // this is only useful for storages like localstorage, the lifetime is basically
    // the lifetime in the cache
    // eslint-disable-next-line @typescript-eslint/naming-convention
    __internalLifeTime: number;
    // this signals that the item was already loaded by another storage system,
    // and any other loading should be ignored
    // eslint-disable-next-line @typescript-eslint/naming-convention
    __internalIgnoreLoading: boolean;
    // this allows an item to specify options for the server to
    // handle the item in a specific way, e.g. saving the item in a
    // different database table. It is only used for saving(setting) items
    // and can be ignored anywhere else
    // eslint-disable-next-line @typescript-eslint/naming-convention
    __internalSaveOptions: Array<String>;
}

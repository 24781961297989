import { getDesignerModeComponentStyle, getSelectedComponentStyle } from "@feature/Designer/utils";
import { classNamesFunction, styled } from "@fluentui/react";
import {
    IsValueBindingTrivial,
    useAdvValueBinderNoDataType,
} from "@hooks/dynamic/useAdvValueBinder";
import { EAdvValueDataTypes } from "@utils/data-types";
import { deepCompareJSXProps } from "@utils/deep-compare";
import { combineStyles } from "@utils/styling";
import React, { useMemo } from "react";

import { TAdvGridItemProps, TAdvGridItemStyleProps, TAdvGridItemStyles } from "./types";

const getClassNames = classNamesFunction<TAdvGridItemStyleProps, TAdvGridItemStyles>();

type TAdvGridItemPropsClean = Omit<TAdvGridItemProps, "columnBindingParams" | "rowBindingParams">;

const AdvGridItemBaseImplComp = ({
    children,
    designerData,
    styles: propStyles,
    divProps,
    row,
    column,
    asFlexContainer = false,
    designerProps,
    ...props
}: TAdvGridItemProps) => {
    useAdvComponent(AdvGridItemBaseImplComp, props);

    const theme = useAdvTheme();

    const styles = useMemo(() => {
        let styles = propStyles;
        if ((designerData?.isSelected ?? false) && (designerData?.renderAsDesigner ?? false))
            styles = combineStyles(styles, { root: getSelectedComponentStyle(theme, true) });
        if (designerData?.renderAsDesigner ?? false)
            styles = combineStyles(styles, { root: getDesignerModeComponentStyle(theme) });
        if (asFlexContainer) styles = combineStyles(styles, { root: { display: "flex" } });
        return styles;
    }, [
        asFlexContainer,
        designerData?.isSelected,
        designerData?.renderAsDesigner,
        propStyles,
        theme,
    ]);

    const newProps: TAdvGridItemPropsClean = { ...props, row, column };
    const classNames = getClassNames(styles, newProps);

    return (
        <div {...divProps} {...designerProps} className={`${classNames.root ?? ""} adv-griditem`}>
            {children}
        </div>
    );
};
const AdvGridItemBaseImpl = React.memo(AdvGridItemBaseImplComp, deepCompareJSXProps);

const AdvGridItemBaseSimple = (props: TAdvGridItemProps) => {
    return <AdvGridItemBaseImpl {...props}></AdvGridItemBaseImpl>;
};

const AdvGridItemBaseComplex = ({
    rowBindingParams,
    columnBindingParams,
    row,
    column,
    dataArrayIndex = 0,
    ...props
}: TAdvGridItemProps) => {
    const [rowValue] = useAdvValueBinderNoDataType(
        rowBindingParams,
        row,
        EAdvValueDataTypes.Any,
        dataArrayIndex,
    );
    const [columnValue] = useAdvValueBinderNoDataType(
        columnBindingParams,
        column,
        EAdvValueDataTypes.Any,
        dataArrayIndex,
    );
    return (
        <AdvGridItemBaseImpl
            {...props}
            column={columnValue}
            row={rowValue}
            rowBindingParams={rowBindingParams}
            columnBindingParams={columnBindingParams}
            dataArrayIndex={dataArrayIndex}
        ></AdvGridItemBaseImpl>
    );
};

const AdvGridItemBase = ({
    rowBindingParams,
    columnBindingParams,
    ...props
}: TAdvGridItemProps) => {
    if (IsValueBindingTrivial(rowBindingParams) && IsValueBindingTrivial(columnBindingParams))
        return (
            <AdvGridItemBaseSimple
                {...props}
                rowBindingParams={rowBindingParams}
                columnBindingParams={columnBindingParams}
            ></AdvGridItemBaseSimple>
        );
    else
        return (
            <AdvGridItemBaseComplex
                {...props}
                rowBindingParams={rowBindingParams}
                columnBindingParams={columnBindingParams}
            ></AdvGridItemBaseComplex>
        );
};

function positionToString(rowOrColumn: string | string[] | number | number[]) {
    if (typeof rowOrColumn == "string") return rowOrColumn;
    if (typeof rowOrColumn == "number") return String(rowOrColumn); // 1 => "1"
    if (typeof rowOrColumn == "object" && "length" in rowOrColumn) return rowOrColumn.join(" / "); // [1,2] => "1 / 2"
    return undefined;
}

const getStyle = ({ column, row, ...props }: TAdvGridItemStyleProps): TAdvGridItemStyles => {
    // https://css-tricks.com/snippets/css/complete-guide-grid/
    return {
        root: {
            gridColumn: positionToString(column),
            gridRow: positionToString(row),
            ...props,
        },
    };
};

const AdvGridItemComp: React.FunctionComponent<TAdvGridItemProps> = styled<
    TAdvGridItemProps,
    TAdvGridItemStyleProps,
    TAdvGridItemStyles
>(AdvGridItemBase, getStyle, undefined, { scope: "AdvGridItem" });

const AdvGridItem = React.memo(AdvGridItemComp, deepCompareJSXProps);
export default AdvGridItem;

import useAdvComponent from "@hooks/useAdvComponent";
import useAdvTheme from "@hooks/useAdvTheme";
import "./designable";

import { advcatch } from "@utils/logging";
import localforage from "localforage";

type TStorageItemWrapper<T> = {
    item: T;
    creationDate: number;
    lifetime: number;
};

export const cacheRemove = async (key: string) => {
    await localforage.removeItem(key).catch(advcatch);
};

export const cacheGet = async <T,>(key: string) => {
    let remainingLifetime = 0;
    let item: T | null = null;

    if (
        typeof window !== "undefined" &&
        window.document != undefined &&
        window.document.documentElement !== undefined
    ) {
        const cacheItem = await localforage.getItem<string>(key);
        if (cacheItem != null) {
            const itemWrapped: TStorageItemWrapper<T> = JSON.parse(cacheItem);
            const timeNow = Date.now();
            if (
                itemWrapped.lifetime == 0 ||
                timeNow - itemWrapped.creationDate < itemWrapped.lifetime
            ) {
                item = itemWrapped.item;
                remainingLifetime = timeNow - itemWrapped.creationDate;
            } else {
                await cacheRemove(key);
            }
        }
    } else {
        console.warn(`cacheGet on Server-Side: ${key}`);
    }
    return { item, remainingLifetime };
};

export const cacheSet = <T,>(key: string, value: T, lifeTime: number) => {
    const itemWrapped: TStorageItemWrapper<T> = {
        item: value,
        creationDate: Date.now(),
        lifetime: lifeTime,
    };
    return localforage.setItem(key, JSON.stringify(itemWrapped));
};

/**
 * Asserts for recoil transactions, because a normal assert does not throw
 */
// eslint-disable-next-line @typescript-eslint/naming-convention
export function trans_assert(cond: boolean, text?: string): asserts cond {
    if (!cond) {
        console.error("[assert] " + (text ?? ""));
        debugger;
        throw new Error(text ?? "");
    }
}

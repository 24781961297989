/**
 * Quickly know where your code will be executed;
 *
 * In the server (Server-Side-Rendering) or
 * In the client, the navigator
 * This hook doesn't cause an extra render, it just returns the value directly, at the mount time, and it didn't re-trigger if the value changes.
 *
 * Otherwise, If you want to be notified when the value changes to react to it, you can use useIsClient() instead.
 *
 * @author https://usehooks-ts.com/react-hook/use-ssr
 */
function useSsr() {
    const isDOM =
        typeof window !== "undefined" &&
        window.document != undefined &&
        window.document.documentElement;

    return {
        isBrowser: isDOM,
        isServer: isDOM === false,
    };
}

export default useSsr;

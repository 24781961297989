/* eslint-disable @typescript-eslint/naming-convention */
import { cacheGet, cacheRemove, cacheSet } from "@data/cache/cache";
import { TSetItems } from "@hooks/storage/useStorageListener";
import { useAdvEvent } from "@hooks/useAdvEvent";
import { advcatch } from "@utils/logging";
import { getHook } from "@utils/react-hooks-outside";
import assert from "assert";
import { AtomEffect, SerializableParam } from "recoil";

import { TDictionaryValue } from "./dictionary-value";
import { TSyncDicts, TWaiter, addToQueue, removeFamilyName } from "./sync-dictionary";

/**
 * Recoil module to persist state to storage
 *
 * @param key name in local storage
 */
export const recoilPersistLocalStorage = <K extends SerializableParam, TType extends { Name: K }>(
    storageName: "keystorage" | "keystorage_client" | "resourcestorage" | "resourcestorage_client",
    sharedSyncStorage: TSyncDicts,
    keyUsesCache: (key: K) => boolean = () => true,
): {
    persistAtom: AtomEffect<TDictionaryValue<TType>>;
} => {
    if (typeof window === "undefined") {
        // Server soll nichts machen
        return {
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            persistAtom: () => {},
        };
    }

    function getThisStorage() {
        if (!sharedSyncStorage.has(storageName)) {
            const thisStorage = new Map<string, TWaiter>();
            sharedSyncStorage.set(storageName, thisStorage);
            return thisStorage;
        } else {
            const thisStorage = sharedSyncStorage.get(storageName);
            assert(thisStorage != undefined);
            return thisStorage;
        }
    }

    const thisDictSyncer = getThisStorage();

    function getSyncItem(key: string) {
        if (!thisDictSyncer.has(key)) {
            thisDictSyncer.set(key, { promisesQueue: [], curItem: undefined });
        }
        const item = thisDictSyncer.get(key);
        assert(item != undefined);
        return item;
    }

    let events: ReturnType<typeof useAdvEvent> | undefined = undefined;
    // Die Hooks nur einmal holen
    const getHooks = async (): Promise<void> => {
        events = await getHook("useAdvEvent");
    };
    const getHooksPromise = getHooks();

    const persistAtom: AtomEffect<TDictionaryValue<TType>> = ({
        onSet,
        node,
        trigger,
        setSelf,
    }) => {
        const nodeKey = removeFamilyName(node.key);
        if (trigger === "get") {
            const syncItem = getSyncItem(nodeKey);
            const getItem = async () => {
                const { item, remainingLifetime } = await cacheGet<TType>(node.key);
                if (item != null && keyUsesCache(item.Name)) {
                    const newItem = new TDictionaryValue({
                        aVal: item,
                        __internalSilentSet: true /* localstorage sets silent */,
                        __internalLifeTime: remainingLifetime /* remaining time */,
                        __internalIgnoreLoading: true,
                    });
                    syncItem.curItem = newItem;
                    // temporary set the value silently, so the client can directly use it
                    setSelf(newItem);
                    // then also add it to the dictionary over the storage listener
                    const triggerEventAsync = async function () {
                        await getHooksPromise;
                        assert(events != undefined);
                        // trigger key storage events
                        const data: TSetItems<string> = {
                            Values: [
                                {
                                    Err: "",
                                    Name:
                                        typeof item.Name == "string"
                                            ? item.Name
                                            : JSON.stringify(item.Name),
                                    Value: JSON.stringify(item),
                                },
                            ],
                        };
                        events.executeEvent(storageName, "setitems", data, "");
                    };
                    await triggerEventAsync();
                }
            };
            addToQueue(nodeKey, syncItem, getItem);
        }

        onSet((newValue, _, isReset) => {
            const syncItem = getSyncItem(nodeKey);
            if (isReset || !newValue.IsLoaded()) {
                syncItem.curItem = undefined;
                cacheRemove(node.key).catch(advcatch);
            } else if (newValue.IsLoaded() && keyUsesCache(newValue.Get().Name)) {
                syncItem.curItem = newValue;
                addToQueue(nodeKey, syncItem, () =>
                    cacheSet(node.key, newValue.Get(), newValue.__internalLifeTime).then(() => {}),
                );
            }
        });
    };

    return { persistAtom };
};

import { TAdvThemeSwizzle } from "@data/theme-types";
import { createTheme, IFontStyles, IPalette, ThemeProvider } from "@fluentui/react";
import useAdvTheme from "@hooks/useAdvTheme";
import deepCopy from "@utils/deep-copy";
import { mergeObjects } from "@utils/styling";
import React, { useMemo } from "react";
import { TAdvThemeFontOverwrite } from "../theme-swizzle";

export const AdvComponentThemer = ({
    children,
    compProps,
}: {
    children?: React.JSX.Element;
    compProps: Record<string, any>;
}) => {
    const theme = useAdvTheme();

    const usefulProps = useMemo(() => {
        // only return properties that are useful for the component themer
        const themeSwizzle =
            "advThemeSwizzle" in compProps ? compProps["advThemeSwizzle"] : undefined;
        const themeFontOverwrite =
            "advThemeFontOverwrite" in compProps ? compProps["advThemeFontOverwrite"] : undefined;
        const res: {
            advThemeSwizzle: TAdvThemeSwizzle | undefined;
            advThemeFontOverwrite: TAdvThemeFontOverwrite | undefined;
        } = {
            advThemeSwizzle: themeSwizzle,
            advThemeFontOverwrite: themeFontOverwrite,
        };
        return res;
    }, [compProps]);

    const appliedTheme = useMemo(() => {
        if (
            usefulProps != undefined &&
            (usefulProps.advThemeSwizzle != undefined ||
                usefulProps.advThemeFontOverwrite != undefined)
        ) {
            const res = deepCopy(theme);
            if (usefulProps.advThemeSwizzle != undefined) {
                for (const key in usefulProps.advThemeSwizzle) {
                    if (key in res.palette) {
                        const keyPalette =
                            usefulProps.advThemeSwizzle[key as keyof TAdvThemeSwizzle];
                        if (keyPalette != undefined)
                            res.palette[key as keyof IPalette] = theme.palette[keyPalette];
                    }
                }
            }
            if (usefulProps.advThemeFontOverwrite != undefined) {
                for (const key in usefulProps.advThemeFontOverwrite) {
                    if (key in res.fonts) {
                        const keyFont =
                            usefulProps.advThemeFontOverwrite[key as keyof TAdvThemeFontOverwrite];
                        if (keyFont != undefined)
                            res.fonts[key as keyof IFontStyles] = mergeObjects(
                                res.fonts[key as keyof IFontStyles],
                                keyFont,
                            );
                    }
                }
            }
            return createTheme({ palette: res.palette, fonts: res.fonts });
        }
        return theme;
    }, [theme, usefulProps]);

    return (
        <ThemeProvider theme={appliedTheme} as={React.Fragment}>
            {children}
        </ThemeProvider>
    );
};

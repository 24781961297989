import { LAN } from "@data/language/strings";

export interface ICategory {
    name: string;
}

export const DefaultComponentCategory: Record<string, ICategory> = {
    Layout: { name: LAN.LAYOUT.text },
    Input: { name: LAN.INPUT.text },
    Display: { name: LAN.PRESENTATION.text },
    Misc: { name: LAN.MISCELLANEOUS.text },
};

export interface IDesignableComponentCategory extends ICategory {}
export interface IDynamicPageCategory extends ICategory {}
export interface IPropertyCategory extends ICategory {}

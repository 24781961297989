export function capitalize(value: string): string {
    if (value == "" || value == undefined) return "";
    return value[0].toUpperCase() + value.substring(1);
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export function str_format(value: string, ...theArgs: string[]) {
    let replIndex = 0;
    return value.replaceAll("{}", () => {
        return theArgs[replIndex++];
    });
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export function str_times(value: string, count: number, delimiter: string = "") {
    let str = "";
    for (let index = 0; index < count; index++) {
        str += value;
        if (index != count - 1) str += delimiter;
    }
    return str;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export function str_get_trim_len(value: string | undefined): {
    StartTrimLen: number;
    EndTrimLen: number;
} {
    if (value === undefined) return { StartTrimLen: 0, EndTrimLen: 0 };

    let trimmedText = value;
    let oldLength = trimmedText.length;
    trimmedText = trimmedText.trimStart();
    const startTrimLen = Math.abs(trimmedText.length - oldLength);
    oldLength = trimmedText.length;
    trimmedText = trimmedText.trimEnd();
    const endTrimLen = Math.abs(trimmedText.length - oldLength);

    return { StartTrimLen: startTrimLen, EndTrimLen: endTrimLen };
}

/** Überträgt die Whitespaces (Anfang+Ende) von Source auf Target.
 * Beispiel:
 * Source = " Benutzername "
 * Target = "Username"
 * => " Username "
 */
// eslint-disable-next-line @typescript-eslint/naming-convention
export function str_apply_trim(
    source: string | undefined,
    target: string | undefined,
): string | undefined {
    if (target === undefined) return undefined;
    const { StartTrimLen, EndTrimLen } = str_get_trim_len(source);
    if (StartTrimLen == 0 && EndTrimLen == 0) return target;

    if (StartTrimLen > 0) target = str_times(" ", StartTrimLen) + target;
    if (EndTrimLen > 0) target = target + str_times(" ", EndTrimLen);
    return target;
}

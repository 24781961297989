import { TAdvLegacyEndlessTableAutoGrouping } from "@components/data/table/endless/types";
import { IDesignerComponentPayload } from "@components/dynamic/dynamic-component/types";
import { TAdvResourceProperties } from "@components/other/common-properties";
import { TAdvThemeFontOverwrite } from "@components/other/theme-swizzle";
import { LAN } from "@data/language/strings";
import { TAdvThemeSwizzle } from "@data/theme-types";
import { IRawStyle, IStyle, ITheme } from "@fluentui/react";
import {
    AdvValueBindingDefaultValue,
    TAdvValueBindingParams,
} from "@hooks/dynamic/useAdvValueBinder";
import {
    TAdvWebActionParams,
    gAdvWebActionDefaultValue,
} from "@hooks/dynamic/useAdvWebAction.types";
import { TAdvTranslationText } from "@hooks/language/useTranslation";
import assert from "assert";
import { nanoid } from "nanoid";

import {
    TAdvEndlessTableColumnDefinitions,
    TAdvEndlessTableDataPositioning,
    TAdvEndlessTableHeader,
    TAdvEndlessTableHeaderFilters,
} from "@components/data/table-new/endless/types";
import { TAdvProgressBarDataDefinitions } from "@components/progress";
import { IPropertyCategory } from "./types/category";
import { EComponentType, EComponentTypeCustom } from "./types/component-type";
import { IDesignableComponent, IDesignableProperty } from "./types/designable";
import { IDesignerComponent, IDesignerProperty } from "./types/designer";
import { IDesignableComponentPreset } from "./types/preset";
import {
    EPropertyInputType,
    EPropertyType,
    IPropertyAction,
    IPropertyActionLogicOnly,
    IPropertyActionSimple,
    IPropertyBindings,
    IPropertyBoolean,
    IPropertyColumns,
    IPropertyFile,
    IPropertyIcons,
    IPropertyList,
    IPropertyNumber,
    IPropertyObject,
    IPropertyProgressData,
    IPropertyText,
    IPropertyWithRecoilCombo,
    IPropertyWithRecoilSelect,
    IPropertyWithSelect,
    IPropertyWithToggle,
    TPropertyList,
} from "./types/property";

// let globalComponentID = 10;
export function getGlobalComponentID(): string {
    return nanoid();
} // { return ++globalComponentID; }
export function getGlobalPropertyID(): string {
    return nanoid();
} // { return ++globalComponentID; }

// #region(collapsed) Hilfsfunktionen um DesignableProperties zu erstellen

function createProperty<TValue, TType extends EPropertyType>(
    displayName: TAdvTranslationText,
    propertyName: string,
    propCategory: TAdvTranslationText,
    defaultValue: TValue,
    type: TType,
    // a hint for e.g. bindings to filter const page variables
    valueWriteable: boolean,
    description: TAdvTranslationText,
    invisible?: boolean,
    allowsBindings: boolean = true,
    defaultBindingParams: TAdvValueBindingParams = AdvValueBindingDefaultValue,
): IDesignableProperty<TType, TValue> {
    const category = {
        name: propCategory.text,
    } as IPropertyCategory;
    return {
        name: propertyName,
        allowsBindings: allowsBindings,
        bindingParams: defaultBindingParams,
        displayName,
        category,
        description: description,
        value: defaultValue,
        type,
        hidden: invisible ?? false,
        valueWriteable: valueWriteable,
    };
}

function createToggleProperty<TValue, TType extends EPropertyType>(
    displayName: TAdvTranslationText,
    propertyName: string,
    category: TAdvTranslationText,
    description: TAdvTranslationText,
    defaultValue: boolean,
    type: TType,
    trueDisplay: string,
    falseDisplay: string,
    trueValue: TValue,
    falseValue: TValue,
): IDesignableProperty<TType, TValue> & IPropertyWithToggle<TValue> {
    const value = defaultValue ? trueValue : falseValue;
    return {
        ...createProperty(displayName, propertyName, category, value, type, false, description),
        trueValue,
        falseValue,
        trueDisplay,
        falseDisplay,
        inputType: EPropertyInputType.Toggle,
    };
}

function createSelectProperty<TValue, TType extends EPropertyType>(
    displayName: TAdvTranslationText,
    propertyName: string,
    category: TAdvTranslationText,
    description: TAdvTranslationText,
    defaultIndex: number,
    type: TType,
    allowsBindings: boolean = true,
    ...values: { text: string; value: TValue }[]
): IDesignableProperty<TType, TValue> & IPropertyWithSelect<TValue> {
    const value = values[defaultIndex];
    return {
        ...createProperty(
            displayName,
            propertyName,
            category,
            value.value,
            type,
            false,
            description,
            undefined,
            allowsBindings,
        ),
        values,
        inputType: EPropertyInputType.Select,
    };
}

function createTextProperty(
    displayName: TAdvTranslationText,
    propertyName: string,
    category: TAdvTranslationText,
    description: TAdvTranslationText,
    defaultValue: string,
    allowsBindings: boolean = true,
    defaultBindingVal: TAdvValueBindingParams = AdvValueBindingDefaultValue,
    valueWriteable: boolean = false,
): IDesignableProperty<EPropertyType.Text, string> & IPropertyText {
    return {
        ...createProperty(
            displayName,
            propertyName,
            category,
            defaultValue,
            EPropertyType.Text,
            valueWriteable,
            description,
            false,
            allowsBindings,
            defaultBindingVal,
        ),
    };
}

function createTextPropertyWithSuggestion(
    displayName: TAdvTranslationText,
    propertyName: string,
    category: TAdvTranslationText,
    description: TAdvTranslationText,
    defaultValue: string,
    allowsBindings: boolean = true,
    defaultBindingVal: TAdvValueBindingParams = AdvValueBindingDefaultValue,
): IDesignableProperty<EPropertyType.Text, string> & IPropertyText {
    return {
        ...createProperty(
            displayName,
            propertyName,
            category,
            defaultValue,
            EPropertyType.Text,
            false,
            description,
            false,
            allowsBindings,
            defaultBindingVal,
        ),
        inputType: EPropertyInputType.Suggestions,
    };
}

function createTextPropertyConst(
    propertyName: string,
    defaultValue: string,
): IDesignableProperty<EPropertyType.Text, string> & IPropertyText {
    return {
        ...createProperty(
            { text: "const" },
            propertyName,
            { text: "const" },
            defaultValue,
            EPropertyType.Text,
            false,
            { text: "", ignoreTranslation: true },
            true,
        ),
    };
}

function createHotkeyTextProperty(
    displayName: TAdvTranslationText,
    propertyName: string,
    category: TAdvTranslationText,
    description: TAdvTranslationText,
): IDesignableProperty<EPropertyType.Text, string> & IPropertyText {
    return {
        ...createProperty(
            displayName,
            propertyName,
            category,
            "",
            EPropertyType.Text,
            false,
            description,
            false,
            false,
            AdvValueBindingDefaultValue,
        ),
        inputType: EPropertyInputType.Hotkeys,
    };
}

function createTextPropertyToggle(
    displayName: TAdvTranslationText,
    propertyName: string,
    category: TAdvTranslationText,
    description: TAdvTranslationText,
    defaultValue: boolean,
    trueDisplay: string,
    falseDisplay: string,
    trueValue: string,
    falseValue: string,
): IDesignableProperty<EPropertyType.Text, string> & IPropertyText & IPropertyWithToggle<string> {
    return {
        ...createToggleProperty(
            displayName,
            propertyName,
            category,
            description,
            defaultValue,
            EPropertyType.Text,
            trueValue,
            falseValue,
            trueDisplay,
            falseDisplay,
        ),
    };
}

function createTextPropertySelect(
    displayName: TAdvTranslationText,
    propertyName: string,
    category: TAdvTranslationText,
    description: TAdvTranslationText,
    defaultIndex: number,
    allowsBindings: boolean = true,
    ...values: string[]
): IDesignableProperty<EPropertyType.Text, string> & IPropertyText & IPropertyWithSelect<string> {
    return {
        ...createSelectProperty(
            displayName,
            propertyName,
            category,
            description,
            defaultIndex,
            EPropertyType.Text,
            allowsBindings,
            ...values.map((val) => {
                return { text: val, value: val };
            }),
        ),
    };
}

function createTextPropertyRecoilSelect(
    displayName: TAdvTranslationText,
    propertyName: string,
    category: TAdvTranslationText,
    description: TAdvTranslationText,
): IDesignableProperty<EPropertyType.Text, string> & IPropertyText & IPropertyWithRecoilSelect {
    return {
        ...createProperty(
            displayName,
            propertyName,
            category,
            undefined,
            EPropertyType.Text,
            false,
            description,
        ),
        recoilStateKey: "",
        inputType: EPropertyInputType.Select,
    };
}

function createTextPropertyRecoilCombo(
    displayName: TAdvTranslationText,
    propertyName: string,
    category: TAdvTranslationText,
    description: TAdvTranslationText,
    defaultValue: string,
    recoilStateKey: string,
): IDesignableProperty<EPropertyType.Text, string> & IPropertyText & IPropertyWithRecoilCombo {
    return {
        ...createProperty(
            displayName,
            propertyName,
            category,
            Object.assign(defaultValue, AdvValueBindingDefaultValue),
            EPropertyType.Text,
            false,
            description,
        ),
        recoilStateKey,
        inputType: EPropertyInputType.Combobox,
    };
}

function createBooleanProperty(
    displayName: TAdvTranslationText,
    propertyName: string,
    category: TAdvTranslationText,
    description: TAdvTranslationText,
    defaultValue: boolean,
    allowsBindings: boolean = true,
): IDesignableProperty<EPropertyType.Boolean, boolean> & IPropertyBoolean {
    return {
        ...createProperty(
            displayName,
            propertyName,
            category,
            defaultValue,
            EPropertyType.Boolean,
            false,
            description,
            undefined,
            allowsBindings,
        ),
    };
}
function createBooleanToggleProperty(
    displayName: TAdvTranslationText,
    propertyName: string,
    category: TAdvTranslationText,
    description: TAdvTranslationText,
    defaultValue: boolean,
    trueDisplay: string,
    falseDisplay: string,
    trueValue: boolean = true,
    falseValue: boolean = false,
): IDesignableProperty<EPropertyType.Boolean, boolean> & IPropertyWithToggle<boolean> {
    return {
        ...createToggleProperty(
            displayName,
            propertyName,
            category,
            description,
            defaultValue,
            EPropertyType.Boolean,
            trueDisplay,
            falseDisplay,
            trueValue,
            falseValue,
        ),
    };
}

function createNumberProperty(
    displayName: TAdvTranslationText,
    propertyName: string,
    category: TAdvTranslationText,
    description: TAdvTranslationText,
    defaultValue: number,
    minValue?: number,
    maxValue?: number,
    allowsBindings: boolean = true,
): IDesignableProperty<EPropertyType.Number, number> & IPropertyNumber {
    return {
        ...createProperty(
            displayName,
            propertyName,
            category,
            defaultValue,
            EPropertyType.Number,
            false,
            description,
            undefined,
            allowsBindings,
        ),
        minValue,
        maxValue,
    };
}
function createNumberPropertySelect(
    displayName: TAdvTranslationText,
    propertyName: string,
    category: TAdvTranslationText,
    description: TAdvTranslationText,
    defaultIndex: number,
    allowsBindings: boolean = true,
    ...values: { text: string; value: number }[]
): IDesignableProperty<EPropertyType.Number, number> &
    IPropertyNumber &
    IPropertyWithSelect<number> {
    return {
        ...createSelectProperty(
            displayName,
            propertyName,
            category,
            description,
            defaultIndex,
            EPropertyType.Number,
            allowsBindings,
            ...values,
        ),
    };
}

function createActionProperty(
    displayName: TAdvTranslationText,
    propertyName: string,
    category: TAdvTranslationText,
    description: TAdvTranslationText,
    defaultValue: TAdvWebActionParams = gAdvWebActionDefaultValue,
): IDesignableProperty<EPropertyType.Action, TAdvWebActionParams> & IPropertyAction {
    return {
        ...createProperty(
            displayName,
            propertyName,
            category,
            defaultValue,
            EPropertyType.Action,
            false,
            description,
        ),
    };
}

function createSimplifiedActionProperty(
    displayName: TAdvTranslationText,
    propertyName: string,
    category: TAdvTranslationText,
    description: TAdvTranslationText,
): IDesignableProperty<EPropertyType.Action, string> & IPropertyActionSimple {
    return {
        ...createProperty(
            displayName,
            propertyName,
            category,
            undefined,
            EPropertyType.Action,
            false,
            description,
        ),
        inputType: EPropertyInputType.Simplified,
    };
}

function createLogicOnlyActionProperty(
    displayName: TAdvTranslationText,
    propertyName: string,
    category: TAdvTranslationText,
    description: TAdvTranslationText,
): IDesignableProperty<EPropertyType.Action, string> & IPropertyActionLogicOnly {
    return {
        ...createProperty(
            displayName,
            propertyName,
            category,
            undefined,
            EPropertyType.Action,
            false,
            description,
        ),
        inputType: EPropertyInputType.LogicOnly,
    };
}

function createBindingsProperty(
    displayName: TAdvTranslationText,
    propertyName: string,
    category: TAdvTranslationText,
    description: TAdvTranslationText,
    defaultValue: TAdvValueBindingParams = AdvValueBindingDefaultValue,
): IDesignableProperty<EPropertyType.Bindings, TAdvValueBindingParams> & IPropertyBindings {
    return {
        ...createProperty(
            displayName,
            propertyName,
            category,
            defaultValue,
            EPropertyType.Bindings,
            false,
            description,
        ),
    };
}

function createListProperty(
    displayName: TAdvTranslationText,
    displayTextDataInput: TAdvTranslationText,
    propertyName: string,
    category: TAdvTranslationText,
    description: TAdvTranslationText,
    defaultValue: TPropertyList,
    defaultBindingParams: TAdvValueBindingParams = AdvValueBindingDefaultValue,
): IDesignableProperty<EPropertyType.List, TPropertyList> & IPropertyList {
    return {
        ...createProperty(
            displayName,
            propertyName,
            category,
            defaultValue,
            EPropertyType.List,
            false,
            description,
            undefined,
            undefined,
            defaultBindingParams,
        ),
        displayTextDataInput,
    };
}

function createChartListProperty(
    displayName: TAdvTranslationText,
    displayTextDataInput: TAdvTranslationText,
    propertyName: string,
    category: TAdvTranslationText,
    description: TAdvTranslationText,
    defaultValue: TPropertyList,
    defaultBindingParams: TAdvValueBindingParams = AdvValueBindingDefaultValue,
): IDesignableProperty<EPropertyType.List, TPropertyList> & IPropertyList {
    return {
        ...createProperty(
            displayName,
            propertyName,
            category,
            defaultValue,
            EPropertyType.List,
            false,
            description,
            undefined,
            undefined,
            defaultBindingParams,
        ),
        inputType: EPropertyInputType.Chart,
        displayTextDataInput,
    };
}

function createObjectProperty(
    displayName: TAdvTranslationText,
    propertyName: string,
    category: TAdvTranslationText,
    description: TAdvTranslationText,
    defaultValue: object,
): IDesignableProperty<EPropertyType.Object, object> & IPropertyObject {
    return {
        ...createProperty(
            displayName,
            propertyName,
            category,
            defaultValue,
            EPropertyType.Object,
            false,
            description,
        ),
    };
}

function createConstObjectProperty(
    propertyName: string,
    defaultValue: object,
): IDesignableProperty<EPropertyType.Object, object> & IPropertyObject {
    return {
        ...createProperty(
            { text: "const", ignoreTranslation: true },
            propertyName,
            { text: "", ignoreTranslation: true },
            defaultValue,
            EPropertyType.Object,
            false,
            { text: "", ignoreTranslation: true },
            true,
            false,
        ),
    };
}

function createColumnsProperty(
    displayName: TAdvTranslationText,
    propertyName: string,
    category: TAdvTranslationText,
    description: TAdvTranslationText,
    defaultValue: TAdvEndlessTableColumnDefinitions,
): IDesignableProperty<EPropertyType.Columns, TAdvEndlessTableColumnDefinitions> &
    IPropertyColumns {
    return {
        ...createProperty(
            displayName,
            propertyName,
            category,
            defaultValue,
            EPropertyType.Columns,
            false,
            description,
        ),
    };
}

function createObjResourceProperty(
    displayName: TAdvTranslationText,
    propertyName: string,
    category: TAdvTranslationText,
    description: TAdvTranslationText,
    defaultValue?: TAdvResourceProperties,
): IDesignableProperty<EPropertyType.Object, TAdvResourceProperties> & IPropertyFile {
    return {
        ...createProperty(
            displayName,
            propertyName,
            category,
            defaultValue,
            EPropertyType.Object,
            false,
            description,
        ),
        inputType: EPropertyInputType.Resource,
    };
}

function createThemeSwizzle(
    displayName: TAdvTranslationText,
    propertyName: string,
    category: TAdvTranslationText,
    description: TAdvTranslationText,
    defaultValue?: TAdvThemeSwizzle,
): IDesignableProperty<EPropertyType.Object, TAdvThemeSwizzle> & IPropertyFile {
    return {
        ...createProperty(
            displayName,
            propertyName,
            category,
            defaultValue,
            EPropertyType.Object,
            false,
            description,
        ),
        inputType: EPropertyInputType.ThemeSwizzle,
    };
}

function createProgressData(
    displayName: TAdvTranslationText,
    propertyName: string,
    category: TAdvTranslationText,
    description: TAdvTranslationText,
    defaultValue?: TAdvProgressBarDataDefinitions,
): IDesignableProperty<EPropertyType.Object, TAdvProgressBarDataDefinitions> &
    IPropertyProgressData {
    return {
        ...createProperty(
            displayName,
            propertyName,
            category,
            defaultValue,
            EPropertyType.Object,
            false,
            description,
        ),
        inputType: EPropertyInputType.ProgressData,
        allowsBindings: true,
    };
}

function createThemeFontOverwrite(
    displayName: TAdvTranslationText,
    propertyName: string,
    category: TAdvTranslationText,
    description: TAdvTranslationText,
    defaultValue?: TAdvThemeFontOverwrite,
): IDesignableProperty<EPropertyType.Object, TAdvThemeFontOverwrite> & IPropertyFile {
    return {
        ...createProperty(
            displayName,
            propertyName,
            category,
            defaultValue,
            EPropertyType.Object,
            false,
            description,
        ),
        inputType: EPropertyInputType.ThemeFontOverwrite,
    };
}

function createAutoGrouping(
    displayName: TAdvTranslationText,
    propertyName: string,
    category: TAdvTranslationText,
    description: TAdvTranslationText,
    defaultValue?: TAdvLegacyEndlessTableAutoGrouping,
): IDesignableProperty<EPropertyType.Object, TAdvLegacyEndlessTableAutoGrouping> & IPropertyFile {
    return {
        ...createProperty(
            displayName,
            propertyName,
            category,
            defaultValue,
            EPropertyType.Object,
            false,
            description,
        ),
        inputType: EPropertyInputType.AutoGrouping,
    };
}

function createTableHeader(
    displayName: TAdvTranslationText,
    propertyName: string,
    category: TAdvTranslationText,
    description: TAdvTranslationText,
    defaultValue?: TAdvEndlessTableHeader,
): IDesignableProperty<EPropertyType.Object, TAdvEndlessTableHeader> & IPropertyFile {
    return {
        ...createProperty(
            displayName,
            propertyName,
            category,
            defaultValue,
            EPropertyType.Object,
            false,
            description,
        ),
        inputType: EPropertyInputType.TableHeader,
    };
}

function createTableFilters(
    displayName: TAdvTranslationText,
    propertyName: string,
    category: TAdvTranslationText,
    description: TAdvTranslationText,
    defaultValue?: TAdvEndlessTableHeaderFilters,
): IDesignableProperty<EPropertyType.Object, TAdvEndlessTableHeaderFilters> & IPropertyFile {
    return {
        ...createProperty(
            displayName,
            propertyName,
            category,
            defaultValue,
            EPropertyType.Object,
            false,
            description,
        ),
        inputType: EPropertyInputType.TableFilters,
    };
}

function createDataPositioning(
    displayName: TAdvTranslationText,
    propertyName: string,
    category: TAdvTranslationText,
    description: TAdvTranslationText,
    defaultValue?: TAdvEndlessTableDataPositioning,
): IDesignableProperty<EPropertyType.Object, TAdvEndlessTableDataPositioning> & IPropertyFile {
    return {
        ...createProperty(
            displayName,
            propertyName,
            category,
            defaultValue,
            EPropertyType.Object,
            false,
            description,
            false,
            false,
        ),
        inputType: EPropertyInputType.DataPositioning,
    };
}

function createIconSelectProperty(
    displayName: TAdvTranslationText,
    propertyName: string,
    category: TAdvTranslationText,
    description: TAdvTranslationText,
    defaultIconName: string,
    ...icons: string[]
): IDesignableProperty<EPropertyType.Text, string> & IPropertyIcons {
    return {
        ...createTextProperty(displayName, propertyName, category, description, defaultIconName),
        inputType: EPropertyInputType.Select,
        icons,
    };
}

function createTranslatableTextProperty(
    displayName: TAdvTranslationText,
    propertyName: string,
    category: TAdvTranslationText,
    description: TAdvTranslationText,
    defaultValue: TAdvTranslationText,
    allowsBindings: boolean = true,
    defaultBindingVal: TAdvValueBindingParams = AdvValueBindingDefaultValue,
): IDesignableProperty<
    EPropertyType.Object,
    TAdvTranslationText //add more here?
> {
    return {
        ...createProperty(
            displayName,
            propertyName,
            category,
            defaultValue,
            EPropertyType.Object,
            false, //valueWritable True?
            description,
            false,
            allowsBindings,
            defaultBindingVal,
        ),
        inputType: EPropertyInputType.TranslatableText,
        value: defaultValue,
    };
}
// #endregion

/**
 * @summary Zentrale Stelle zum Erzeugen von DesignableProperties.
 */
export const AdvProperty = {
    /** Text-Property (string) */
    Text: {
        create: createTextProperty,
        createSuggestion: createTextPropertyWithSuggestion,
        createToggle: createTextPropertyToggle,
        createSelect: createTextPropertySelect,
        createRecoilSelectProvider: createTextPropertyRecoilSelect,
        createRecoilCombo: createTextPropertyRecoilCombo,
        createConst: createTextPropertyConst,
        createHotkey: createHotkeyTextProperty,
        createTranslatable: createTranslatableTextProperty,
    },
    /** Boolean-Property */
    Boolean: {
        create: createBooleanProperty,
        createToggle: createBooleanToggleProperty,
    },
    /** Number-Property */
    Number: {
        create: createNumberProperty,
        createSelect: createNumberPropertySelect,
    },
    Action: {
        create: createActionProperty,
        createSimplified: createSimplifiedActionProperty,
        createLogicOnly: createLogicOnlyActionProperty,
    },
    Bindings: {
        create: createBindingsProperty,
    },
    List: {
        create: createListProperty,
        createChartDataset: createChartListProperty,
    },
    Object: {
        create: createObjectProperty,
        createConst: createConstObjectProperty,
        /** Spalten-Property für EndlessTable */
        createColumns: createColumnsProperty,
        createResource: createObjResourceProperty,
        createThemeSwizzle: createThemeSwizzle,
        createThemeFontOverwrite: createThemeFontOverwrite,
        createAutoGrouping: createAutoGrouping,
        createTableHeader: createTableHeader,
        createTableFilters: createTableFilters,
        createDataPositioning: createDataPositioning,
        createProgressData: createProgressData,
    },
    Special: {
        /** Icon-Property für Dynamic-Page */
        createIconSelect: createIconSelectProperty,
    },
};

/** Alle im Ui-Designer auswählbaren Komponenten */
export const designableComponents: IDesignableComponent[] = [];
export const designableComponentsMap = new Map<EComponentType, IDesignableComponent>();

/**
 * Fügt die Komponente zu den {@link designableComponents} hinzu und stellt sicher, dass...
 * - ... eine versteckte CompType-Property existiert
 * - ... ein Default-Preset existiert
 * @see {@link createDefaultPreset}
 * @returns Die Komponente (u.U. mit einer weiteren Property + Preset)
 */
export function registerDesignableComponent(
    designableComponent: IDesignableComponent,
): IDesignableComponent {
    const hasComp = designableComponentsMap.has(designableComponent.staticData.type);
    if (process.env.NEXT_PUBLIC_ENV != "dev") {
        // Nur im DEV darf eine Komponente öfters registriert werden (nötig wegen Fast Refresh)
        assert(
            !hasComp,
            `DesignableComponent wurde bereits registriert: ${designableComponent.staticData.type}`,
        );
    }

    designableComponent.properties.push(
        AdvProperty.Text.createConst("compType", designableComponent.staticData.type),
    );

    if (
        designableComponent.presets === undefined ||
        designableComponent.presets.length == 0 ||
        designableComponent.presets.findIndex((preset) => preset.default) < 0
    )
        designableComponent.presets = [
            createDefaultPreset(designableComponent),
            ...designableComponent.presets,
        ];

    // Jede DesignableComponent darf nur 1x registriert werden, beim Entwickeln geschieht das aber dank "Fast Refresh" u.U. öfters.
    if (!hasComp) {
        designableComponents.push(designableComponent);
        designableComponentsMap.set(designableComponent.staticData.type, designableComponent);
    } else {
        designableComponents[
            designableComponents.findIndex(
                (c) => c.staticData.type == designableComponent.staticData.type,
            )
        ] = designableComponent;
        designableComponentsMap.set(designableComponent.staticData.type, designableComponent);
    }

    return designableComponent;
}

/** Erzeugt das Standard-/Default-Preset */
function createDefaultPreset({}: IDesignableComponent): IDesignableComponentPreset {
    // Default ${staticData.name} Preset
    return {
        name: LAN.DEFAULT_PRESET.text,
        default: true,
    };
}

export function getSelectedComponentStyle(theme: ITheme, asOutline?: boolean): IRawStyle {
    if (asOutline === true)
        return { outline: `solid 2px ${theme.palette.red}`, outlineOffset: "-2px" };
    else return { border: `solid 2px ${theme.palette.red}` };
}

export function getDesignerModeComponentStyle(
    theme: ITheme,
    type: "unknown" | "button" = "unknown",
): IStyle {
    const extraMarginLeft = 5;
    const extraMarginTop = 10;
    switch (type) {
        case "button":
            return { marginTop: extraMarginTop };
        case "unknown":
            return {
                borderLeft: "solid " + extraMarginLeft.toString() + "px rgba(0,0,0,0)",
                borderTop: "solid " + extraMarginTop.toString() + "px rgba(0,0,0,0)",
                maxWidth: "calc(100% - " + extraMarginLeft.toString() + "px)",
                maxHeight: "calc(100% - " + extraMarginTop.toString() + "px)",
                boxSizing: "content-size",
            };
    }
}

/**
 * @returns DesignableComponent vom entsprechenden Type
 * @throws AssertionError wenn nicht gefunden
 */
export function getDesignableComponentByType(type: EComponentType) {
    let dc = designableComponentsMap.get(type);
    if (dc === undefined && String(type) == "calenderExtra")
        dc = designableComponentsMap.get(EComponentTypeCustom.OrderCalendar);
    if (dc === undefined && String(type) == "orderEndless")
        dc = designableComponentsMap.get(EComponentTypeCustom.OrderEndlessVSA);
    if (dc === undefined && String(type) == "orderArticles")
        dc = designableComponentsMap.get(EComponentTypeCustom.OrderEndlessArticles);
    assert(dc, `DesignableComponentType not found: '${type}'`);
    return dc;
}

/**
 * @returns DesignableComponent mit entsprechendem Namen
 * @throws AssertionError wenn nicht gefunden
 */
export function getDesignableComponentByName(name: string) {
    const dc = designableComponents.find(
        (comp) => comp.staticData.name.toUpperCase() == name.toUpperCase(),
    );
    assert(dc, `DesignableComponentName not found: '${name}'`);
    return dc;
}

export function tryGetPropertyValue<T>(
    componentOrDynamicPage: IDesignerComponent | IDesignerComponentPayload,
    propertyName: string,
): T | undefined {
    if (componentOrDynamicPage === undefined || propertyName === undefined) return undefined;

    const property = componentOrDynamicPage.properties.find((prop) => prop.name == propertyName);
    if (property) {
        return property.value as T;
    }

    return undefined;
}

/**
 * @summary Erzeugt eine *frische* DesignerComponent aus einem DesignableComponent.
 * @important Kümmert sich NUR um Objekte, nicht um States / Atoms!
 */
export function createDesignerComponent(
    designableComponent: IDesignableComponent,
    parentKey: string,
    childrenKeys: string[] = [],
    key?: string,
): IDesignerComponent {
    if (typeof key == "undefined") key = getGlobalComponentID();

    const { properties, ...everythingelse } = designableComponent;

    // Wichtig: Properties müssen von DesignableProperties zu DesignerProperties werden
    const designerProperties: IDesignerProperty[] = [];
    if (typeof properties != "undefined") {
        for (let i = 0; i < properties.length; i++) {
            // ... jede DesignerProperty besitzt einen (eindeutigen) key, ähnlich wie der Key jeder DesignerComponent
            designerProperties[i] = { ...properties[i], key: getGlobalPropertyID() };
        }
    }

    return {
        key: key,
        properties: designerProperties,
        childrenKeys: childrenKeys,
        parentKey,
        ...everythingelse,
    };
}

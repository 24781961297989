import { Callout, ICalloutContentStyles, ICalloutProps, mergeStyleSets } from "@fluentui/react";
import useAdvComponent from "@hooks/useAdvComponent";
import { deepCompareJSXProps } from "@utils/deep-compare";
import React from "react";

export type TAdvCalloutContentStyles = ICalloutContentStyles; /* do not change */

export type TAdvCalloutProps = ICalloutProps;

/**
 * @summary Wrapper für ``Callout``
 * @link https://developer.microsoft.com/en-us/fluentui#/controls/web/callout
 */
export const AdvCalloutComp = ({ ...props }: TAdvCalloutProps) => {
    useAdvComponent(AdvCalloutComp, props);

    return (
        <Callout
            {...props}
            styles={mergeStyleSets(props.styles, { root: { transform: "unset !important" } })}
        >
            {props.children}
        </Callout>
    );
};

const AdvCallout = React.memo(AdvCalloutComp, deepCompareJSXProps);
export default AdvCallout;

import { useAdvCallback } from "@hooks/react-overload/useAdvCallback";

declare const config: {
    dataServerUrl: string;
};

export function useSystemConfig() {
    const getDataServerUrl = useAdvCallback(function () {
        return new Promise<string>((resolve) => {
            resolve(config.dataServerUrl);
        });
    }, []);

    return {
        getDataServerUrl: getDataServerUrl,
    };
}

import AdvGridItemDesignable from "@components/layout/grid/grid-item/designable";
import AdvGridItem from "@components/layout/grid/grid-item/grid-item";
import AdvStackItem from "@components/layout/stack/stack-item";
import AdvStackItemDesignable from "@components/layout/stack/stack-item/designable";
import AdvPivotOnRenderWithBinds from "@components/layout/tab-control/tab-item/tab-item";
import { AdvComponentThemer } from "@components/other/component-themer/component-themer";
import { keyToComponentMap } from "@feature/Designer/map";
import { EComponentTypeData, EComponentTypeLayout } from "@feature/Designer/types/component-type";
import { PivotItem } from "@fluentui/react";
import { useAdvCallback } from "@hooks/react-overload/useAdvCallback";
import { useAdvEffect } from "@hooks/react-overload/useAdvEffect";
import useAdvComponent from "@hooks/useAdvComponent";
import { deepCompareJSXProps } from "@utils/deep-compare";
import deepCopy from "@utils/deep-copy";
import assert from "assert";
import React, { useMemo } from "react";
import AdvCopyCat from "../copycat/copycat";
import { TAdvDynamicComponentProps } from "./types";

/**
 *
 */
const AdvDynamicComponentComp = ({
    component,
    components,
    pageProps,
    ...props
}: TAdvDynamicComponentProps) => {
    useAdvComponent(AdvDynamicComponentComp, props);

    const {
        staticData: myStaticData,
        properties: myProperties,
        childrenKeys: myChildrenKeys,
        key: myComponentKey,
        parentKey,
    } = component;

    const getProps = useAdvCallback(
        (aProps: typeof myProperties, aCompKey: typeof myComponentKey) => {
            const properties: Record<string, any> = {};
            aProps?.forEach((prop) => {
                // Wenn ein "." im PropName ist, dann ist die Property eine Property eines Nested Objects,
                // also z.B. "layout.x" bzw. "layout.y" würden properties.layout.x / properties.layout.y sein,
                // wobei "layout" ein Objekt im Properties-Objekt ist.
                if (prop.name.indexOf(".") >= 0) {
                    // Nested Object
                    const nestedName = prop.name.substring(0, prop.name.indexOf("."));
                    const propName = prop.name.substring(prop.name.indexOf(".") + 1);

                    if (typeof properties[nestedName] == "undefined") properties[nestedName] = {};
                    properties[nestedName][propName] = prop.value;
                    properties[nestedName + "_" + propName + "BindingParams"] = prop.bindingParams;
                } else {
                    properties[String(prop.name)] = prop.value;
                    properties[String(prop.name) + "BindingParams"] = prop.bindingParams;
                }
            });
            properties["key"] = aCompKey;
            // in case a component needs to know its own key
            properties["keyRef"] = aCompKey;
            // all components also get the page props
            properties["pageProps"] = deepCopy(pageProps);
            return properties;
        },
        [pageProps],
    );

    const getChildren = useAdvCallback(
        (aStaticData: typeof myStaticData, aChildrenKeys: typeof myChildrenKeys) => {
            const tempChildren: React.JSX.Element[] = [];
            if (aChildrenKeys != undefined && aChildrenKeys.length > 0) {
                aChildrenKeys.forEach((key) => {
                    const childrenComponent = components.find((comp) => comp.key == key);
                    assert(
                        childrenComponent,
                        "ChildrenComponent undefined (parent data was):" +
                            JSON.stringify(aStaticData),
                    );

                    const dc = (
                        <AdvDynamicComponent
                            key={"dc_" + key}
                            component={childrenComponent}
                            components={components}
                            pageProps={pageProps}
                        ></AdvDynamicComponent>
                    );
                    if (aStaticData.type == EComponentTypeData.CopyCat) {
                        // copy cat has no children
                    } else if (aStaticData.type == EComponentTypeLayout.TabControl) {
                        const {
                            staticData: childData,
                            properties: childProp,
                            childrenKeys: childChildrenKeys,
                            key: childKey,
                        } = childrenComponent;

                        const tabItemProps: Record<string, any> = getProps(childProp, childKey);
                        const dcP = getChildren(childData, childChildrenKeys);
                        if (childData.type == EComponentTypeLayout.TabItem) {
                            tempChildren.push(
                                <PivotItem
                                    {...tabItemProps}
                                    onRenderItemLink={AdvPivotOnRenderWithBinds.bind(null, {})}
                                >
                                    {dcP}
                                </PivotItem>,
                            );
                        }
                    } else tempChildren.push(dc);
                });
            }
            return tempChildren;
        },
        [components, getProps, pageProps],
    );

    // TODO: Aus render und renderDesignerComponent eine einzige Funktion machen?
    /** @see ``ElementContainer`` */
    const renderedComponent = useMemo<React.JSX.Element>(() => {
        if (typeof keyToComponentMap[myStaticData.type] !== "undefined") {
            // Alle verfügbaren Properties zusammenfügen (DesignerComponent & AdditionalProperties)
            const properties: Record<string, any> = getProps(myProperties, myComponentKey);

            const compType = keyToComponentMap[myStaticData.type];
            const compProps = properties;
            let compChildren: React.JSX.Element[] | React.JSX.Element | undefined = undefined;

            if (myStaticData.supportsChildren) {
                compChildren = getChildren(myStaticData, myChildrenKeys);
            } else {
                if (typeof properties["children"] != "undefined")
                    compChildren = properties["children"];
            }

            const getCopyCat = () => {
                if (myChildrenKeys.length == 0) return <></>;
                const foundChild = components.find((c) => c.key == myChildrenKeys[0]);
                if (foundChild == undefined) return <></>;
                const childCopy = deepCopy(foundChild);
                childCopy.parentKey = parentKey;
                return (
                    <AdvCopyCat
                        component={childCopy}
                        components={components}
                        dynComp={AdvDynamicComponent}
                        pageProps={pageProps}
                        {...properties}
                    ></AdvCopyCat>
                );
            };
            // never render tab items no directly
            assert(compType != EComponentTypeLayout.TabItem, "this should not happen");
            const getRenderedItem = () => {
                if (myStaticData.type == EComponentTypeData.CopyCat) return getCopyCat();
                else if (myStaticData.type == EComponentTypeData.EndlessTable) {
                    /*let children = undefined;
                    if (Array.isArray(compChildren) && compChildren.length >= 1) {
                        children = compChildren[0];
                    } else if (compChildren != undefined) {
                        children = compChildren;
                    }
                    compProps["tableHeaderItem"] = children;*/
                    return React.createElement(compType, compProps);
                }
                return React.createElement(compType, compProps, compChildren);
            };
            const renderedPure = getRenderedItem();
            const applyThemeProviderIfSupported = () => {
                if (
                    compType != EComponentTypeLayout.TabItem &&
                    compType != EComponentTypeData.CopyCat
                ) {
                    return (
                        <AdvComponentThemer compProps={compProps}>
                            {renderedPure}
                        </AdvComponentThemer>
                    );
                }
                return renderedPure;
            };
            const rendered = applyThemeProviderIfSupported();

            const parentType = components.find((comp) => comp.key == parentKey)?.staticData.type;

            const buildStackItem = () => {
                const stackItemGrow = myProperties.find(
                    (prop) => prop.name == AdvStackItemDesignable.PropertyName.Grow,
                );
                const stackItemShrink = myProperties.find(
                    (prop) => prop.name == AdvStackItemDesignable.PropertyName.Shrink,
                );
                const stackItemAlign = myProperties.find(
                    (prop) => prop.name == AdvStackItemDesignable.PropertyName.Align,
                );
                const stackItemFlexBasis = myProperties.find(
                    (prop) => prop.name == AdvStackItemDesignable.PropertyName.FlexBasis,
                );
                const stackItemMinWidth = myProperties.find(
                    (prop) => prop.name == AdvStackItemDesignable.PropertyName.MinWidth,
                );
                const stackItemMaxWidth = myProperties.find(
                    (prop) => prop.name == AdvStackItemDesignable.PropertyName.MaxWidth,
                );
                const stackItemMinHeight = myProperties.find(
                    (prop) => prop.name == AdvStackItemDesignable.PropertyName.MinHeight,
                );
                const stackItemMaxHeight = myProperties.find(
                    (prop) => prop.name == AdvStackItemDesignable.PropertyName.MaxHeight,
                );
                const hidden = myProperties.find((prop) => prop.name == "advhide");
                return (
                    <AdvStackItem
                        key={`sia_${myComponentKey}`}
                        grow={stackItemGrow?.value ?? false}
                        shrink={stackItemShrink?.value ?? false}
                        align={stackItemAlign?.value ?? "auto"}
                        basis={stackItemFlexBasis?.value ?? "auto"}
                        minWidth={stackItemMinWidth?.value}
                        maxWidth={stackItemMaxWidth?.value}
                        minHeight={stackItemMinHeight?.value}
                        maxHeight={stackItemMaxHeight?.value}
                        advhide={hidden?.value}
                        growBindingParams={stackItemGrow?.bindingParams}
                        shrinkBindingParams={stackItemShrink?.bindingParams}
                        alignBindingParams={stackItemAlign?.bindingParams}
                        basisBindingParams={stackItemFlexBasis?.bindingParams}
                        minWidthBindingParams={stackItemMinWidth?.bindingParams}
                        maxWidthBindingParams={stackItemMaxWidth?.bindingParams}
                        minHeightBindingParams={stackItemMinHeight?.bindingParams}
                        maxHeightBindingParams={stackItemMaxHeight?.bindingParams}
                        advhideBindingParams={hidden?.bindingParams}
                    >
                        {rendered}
                    </AdvStackItem>
                );
            };

            // Wenn das ParentElement ein Stack ist, dann muss der Inhalt in einem StackItem verpackt sein.
            // Das machen wir hier und im ElementContainer :)
            if (
                parentType == EComponentTypeLayout.Stack &&
                myStaticData.type != EComponentTypeLayout.StackItem &&
                myStaticData.type != EComponentTypeData.CopyCat &&
                myStaticData.type != EComponentTypeLayout.Groupbox
            ) {
                return buildStackItem();
            } else if (
                parentType == EComponentTypeLayout.Grid &&
                myStaticData.type != EComponentTypeLayout.GridItem &&
                myStaticData.type != EComponentTypeData.CopyCat
            ) {
                const gridItemColumn = myProperties.find(
                    (prop) => prop.name == AdvGridItemDesignable.PropertyName.Column,
                );
                const gridItemRow = myProperties.find(
                    (prop) => prop.name == AdvGridItemDesignable.PropertyName.Row,
                );
                const gridItemAsFlexContainer = myProperties.find(
                    (prop) => prop.name == AdvGridItemDesignable.PropertyName.AsFlexContainer,
                );

                let gridItemInner = rendered;
                if (
                    gridItemAsFlexContainer != undefined &&
                    gridItemAsFlexContainer.value === true
                ) {
                    gridItemInner = buildStackItem();
                }
                return (
                    <AdvGridItem
                        key={`gia_${myComponentKey}`}
                        column={gridItemColumn?.value}
                        row={gridItemRow?.value}
                        columnBindingParams={gridItemColumn?.bindingParams}
                        rowBindingParams={gridItemRow?.bindingParams}
                        asFlexContainer={gridItemAsFlexContainer?.value}
                    >
                        {gridItemInner}
                    </AdvGridItem>
                );
            } else {
                return rendered;
            }
        } else {
            console.warn(myStaticData);
        }
        return <></>;
    }, [
        components,
        getChildren,
        getProps,
        myChildrenKeys,
        myComponentKey,
        myProperties,
        myStaticData,
        pageProps,
        parentKey,
    ]);

    useAdvEffect(() => {
        console.debug("RENDERED", myStaticData.type);
        // eslint-disable-next-line react-hooks/exhaustive-deps, react-hooks-addons/no-unused-deps
    }, [renderedComponent]);

    return renderedComponent;
};

const AdvDynamicComponent = React.memo(AdvDynamicComponentComp, deepCompareJSXProps);
export default AdvDynamicComponent;

import AdvText from "@components/data/text";
import { AdvButtonContextMenu } from "@components/inputs/button/button-contextmenu";
import AdvStack, { TAdvStackStyles } from "@components/layout/stack/stack";
import AdvStackItem from "@components/layout/stack/stack-item/stack-item";
import AdvIcon from "@components/other/icon/icon";
import { sessionAddInfosAtom } from "@data/session";
import {
    BaseButton,
    IComponentAs,
    IconButton,
    INavButtonProps,
    INavLink,
    IStyle,
} from "@fluentui/react";
import { useT } from "@hooks/language/useTranslation";
import { useAdvCallback } from "@hooks/react-overload/useAdvCallback";
import useAdvRecoilValue from "@hooks/recoil-overload/useAdvRecoilValue";
import useAdvComponent from "@hooks/useAdvComponent";
import useAdvTheme from "@hooks/useAdvTheme";
import { DownButton, UpButton } from "@themes/icons";
import { deepCompareJSXProps } from "@utils/deep-compare";
import { advcatch } from "@utils/logging";
import { RemoveIndex } from "@utils/remove-index";
import { mergeObjects } from "@utils/styling";
import Link, { LinkProps } from "next/link";
import React, { MouseEvent, useMemo, useRef, useState } from "react";
import { Url } from "url";

import { useAdvRouter } from "@hooks/page/useAdvRouter";
import { navButtonStyles } from "./styles";

// #region AdvNavLink
export type TAdvNavLink = Omit<RemoveIndex<INavLink>, "links"> & {
    actionName?: string;

    links: TAdvNavLink[];
    renderAs: "item" | "group" | "iconnizedItem" | "iconnizedGroup";
};

/** Wir müssen Query-Parameter aus der URL filtern und als "query" übergbeen anstatt "pathname" */
const getHref = (link?: string): Partial<Url> => {
    let pathname = link ?? "";
    let query: string | undefined = undefined;

    const index = pathname.indexOf("?");
    if (index >= 0) {
        query = pathname.substring(index + 1);
        pathname = pathname.substring(0, index);
    }

    return { pathname, query };
};

/**
 * Erweiterungskomponente eines NavButtons, mit dem das AdvNav erweitert wird.
 * Hiermit erreichen wir, dass prefetching wie gewohnt funktioniert (nutzen von NextJS-Link).
 */
export const AdvNavLink: IComponentAs<INavButtonProps> = ({ link, ...props }: INavButtonProps) => {
    const navLink = link as TAdvNavLink | undefined;
    const urlHref = useMemo(() => getHref(navLink?.url), [navLink?.url]);
    const shouldRenderAsGroup = useMemo(
        () => (navLink?.renderAs ?? "item") == "group",
        [navLink?.renderAs],
    );
    const isIconnizedGroup = useMemo(
        () => (navLink?.renderAs ?? "item") == "iconnizedGroup",
        [navLink?.renderAs],
    );
    const isIconnized = useMemo(
        () => (navLink?.renderAs ?? "item") == "iconnizedItem" || isIconnizedGroup,
        [isIconnizedGroup, navLink?.renderAs],
    );
    const isCollapsed = useMemo(() => link?.isExpanded ?? false, [link?.isExpanded]);

    const router = useAdvRouter();
    const onClickWrapper = useMemo<INavButtonProps["onClick"]>(() => {
        if (navLink != undefined && navLink.url != "")
            return () => {
                router.push(urlHref).catch(advcatch);
            };
        const onClickItem = navLink?.onClick as TAdvNavLink["onClick"];
        if (onClickItem != undefined)
            return (ev) => {
                onClickItem(ev as React.MouseEvent<HTMLDivElement>, navLink);
            };
    }, [navLink, router, urlHref]);

    const session = useAdvRecoilValue(sessionAddInfosAtom);

    const buttonRef = useRef<any>(null);
    const [isContextMenuHidden, setIsContextMenuHidden] = useState(true);
    const handleContextMenuClick = useAdvCallback(
        (
            e: MouseEvent<
                | HTMLAnchorElement
                | HTMLButtonElement
                | HTMLDivElement
                | BaseButton
                | HTMLSpanElement
            >,
        ) => {
            buttonRef.current = e.target;
            setIsContextMenuHidden(false);
            e.preventDefault();
        },
        [],
    );

    const navAction = useMemo(() => (link as TAdvNavLink).actionName ?? "", [link]);

    const contextMenu = useMemo(
        () => (
            <AdvButtonContextMenu
                buttonRef={buttonRef}
                isContextMenuHidden={isContextMenuHidden}
                setIsContextMenuHidden={setIsContextMenuHidden}
                webActionName={navAction}
            ></AdvButtonContextMenu>
        ),
        [isContextMenuHidden, navAction],
    );

    const propUrl = useMemo(() => {
        if (link != undefined && link.url != "") {
            return link.url;
        }
        return "";
    }, [link]);

    const { t: tTitle } = useT(props.title);

    const minifiedProps = useMemo(() => {
        const res: any = { ...props };
        if (propUrl != "") {
            res.href = "#";
        }
        res.onClick = onClickWrapper;
        res.title = tTitle;
        return res;
    }, [onClickWrapper, propUrl, props, tTitle]);

    const theme = useAdvTheme();
    // Styles
    const styles = useMemo(() => {
        const styles = mergeObjects(
            {
                icon: {
                    color: theme.palette.themePrimary,
                    minWidth: 49,
                    margin: 0,
                },
                rootHovered: {
                    color: theme.palette.neutralDark,
                },
            },
            navButtonStyles,
        );

        return styles;
    }, [theme.palette]);

    const stylesStack = useMemo<TAdvStackStyles>(() => {
        const res = {
            root: {
                position: "relative",
                backgroundColor:
                    shouldRenderAsGroup || isIconnizedGroup
                        ? undefined
                        : theme.palette.alternativeWhite,
            } as IStyle,
        };
        return res;
    }, [isIconnizedGroup, shouldRenderAsGroup, theme.palette.alternativeWhite]);

    const buttonComp = useMemo(() => {
        return (
            <AdvStack horizontal styles={stylesStack} tokens={{ childrenGap: 0 }}>
                {shouldRenderAsGroup && (
                    <AdvStackItem
                        styles={{
                            root: {
                                display: "flex",
                                alignItems: "center",
                                right: 5,
                                position: "absolute",
                                cursor: "pointer",
                                zIndex: 10,
                            },
                        }}
                        verticalFill
                        onClick={(ev) =>
                            navLink != undefined &&
                            navLink.onClick != undefined &&
                            navLink.onClick(ev, navLink)
                        }
                    >
                        <AdvIcon
                            iconName={(isCollapsed ? UpButton : DownButton).iconName}
                        ></AdvIcon>
                    </AdvStackItem>
                )}
                <IconButton
                    {...minifiedProps}
                    styles={styles}
                    onContextMenu={
                        navAction != "" && session.CanEditPermissions === true
                            ? handleContextMenuClick
                            : undefined
                    }
                    className={
                        isIconnized
                            ? (minifiedProps.className ?? "") + " ms-Button--icon-Extra"
                            : minifiedProps.className
                    }
                ></IconButton>
                {navAction != "" && contextMenu}
            </AdvStack>
        );
    }, [
        contextMenu,
        handleContextMenuClick,
        isCollapsed,
        isIconnized,
        minifiedProps,
        navAction,
        navLink,
        session.CanEditPermissions,
        shouldRenderAsGroup,
        styles,
        stylesStack,
    ]);

    return buttonComp;
};

/**
 * Wrapper für den Text innerhalb des nav links
 */
export const AdvNavLinkText = ({
    defaultRender,
    ...props
}: INavLink & { defaultRender?: (props?: INavLink) => React.JSX.Element | null }) => {
    const { t, hasErr } = useT(props.name);
    if (t === undefined || defaultRender === undefined || hasErr) return <AdvText>{t}</AdvText>;
    else return defaultRender({ ...props, name: t });
};

// #endregion

// #region AdvLink

export type TAdvLinkProps = LinkProps & {
    text: string;
};
/**
 * Ein Link, der prefetching unterstützt (NextJS).
 * @summary Wrapper für ``Next/Link``
 * @link https://nextjs.org/docs/api-reference/next/link
 */
const AdvLinkComp = ({ text, ...props }: TAdvLinkProps) => {
    useAdvComponent(AdvLinkComp, props);

    return (
        <Link {...props}>
            <a>{text}</a>
        </Link>
    );
};

// #endregion

const AdvLink = React.memo(AdvLinkComp, deepCompareJSXProps);
export default AdvLink;

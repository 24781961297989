import AdvChart from "@components/data/charts/charts";
import AdvText from "@components/data/text";
import AdvLabel from "@components/data/text/label";
import AdvCopyCat from "@components/dynamic/copycat/copycat";
import AdvDynamicPage from "@components/dynamic/dynamic-page";
import AdvButton from "@components/inputs/button-new";
import AdvCheckbox from "@components/inputs/checkbox";
import AdvComboBox from "@components/inputs/combobox";
import AdvRadioGroup from "@components/inputs/radio-group";
import AdvTextInput from "@components/inputs/text-input-new";
import AdvGrid from "@components/layout/grid";
import AdvGridItem from "@components/layout/grid/grid-item";
import AdvGroupbox from "@components/layout/groupbox";
import AdvReactGrid from "@components/layout/react-grid";
import AdvStack from "@components/layout/stack-new";
import AdvStackItem from "@components/layout/stack-new/stack-item";
import AdvTabControl from "@components/layout/tab-control";
import AdvIcon from "@components/other/icon/icon";
import { AdvNews } from "@components/other/news/news";
import AdvResource from "@components/other/resource/resource";
import { PivotItem } from "@fluentui/react";

import AdvEndlessTableNew from "@components/data/table-new/endless/endless";
import AdvBenchmark from "@components/dynamic/benchmark";
import AdvCalendar from "@components/inputs/calendar/calendar";
import AdvDatePicker from "@components/inputs/datepicker/datepicker";
import AdvDropdown from "@components/inputs/dropdown-new/dropdown";
import AdvContractActions from "@components/other/contract-actions-comp/contract-actions";
import AdvProgressBar from "@components/progress/progress-bar";
import AdvTraeAnprArtList, { AdvTraeArtiAnlageList } from "@feature/anprobe";
import AdvAddTraeArtis from "@feature/articles/article-list";
import AdvOrderCalendar from "@feature/order/order-calendar";
import AdvOrderEndlessAnf from "@feature/order/order-endless-anf";
import AdvOrderEndlessArticles from "@feature/order/order-endless-articles";
import AdvOrderEndlessVSA from "@feature/order/order-endless-vsa/order-endless-vsas";
import { EComponentType } from "./types/component-type";

/**
 * In eigener Datei, da ansonsten zirkuläre Referenzen entstehen würden.
 * Dadurch sind dann u.U. diverse Sachen undefined (z.B. Enums).
 */

/** Verknüpft die ComponentType's mit tatsächlichen React-Komponenten. */
export const keyToComponentMap: Record<EComponentType, any> = {
    button: AdvButton,
    textInput: AdvTextInput,
    label: AdvLabel,
    chart: AdvChart,
    resource: AdvResource,
    stack: AdvStack,
    stackItem: AdvStackItem,
    grid: AdvReactGrid,
    checkbox: AdvCheckbox,
    dropdown: AdvDropdown,
    text: AdvText,
    groupbox: AdvGroupbox,
    dynamicPage: AdvDynamicPage,
    radioGroup: AdvRadioGroup,
    endlessTable: AdvEndlessTableNew,
    tabControl: AdvTabControl,
    /** Der AdvStack wird beim rendern in ein TabItem einfügt */
    tabItem: PivotItem,
    combobox: AdvComboBox,
    icon: AdvIcon,
    fonticon: undefined,
    advgrid: AdvGrid,
    advgridItem: AdvGridItem,
    copyCat: AdvCopyCat,
    news: AdvNews,
    calender: AdvCalendar,
    orderCalendar: AdvOrderCalendar,
    orderEndlessArticles: AdvOrderEndlessArticles,
    orderEndlessAnf: AdvOrderEndlessAnf,
    orderEndlessVSA: AdvOrderEndlessVSA,
    benchmark: AdvBenchmark,
    traeArtiList: AdvAddTraeArtis,
    datePicker: AdvDatePicker,
    contractActions: AdvContractActions,
    traeAnprArtList: AdvTraeAnprArtList,
    traeArtiAnlageList: AdvTraeArtiAnlageList,
    progressbar: AdvProgressBar,
};

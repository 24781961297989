import { cacheGet, cacheSet } from "@data/cache/cache";
import { LAN_FALLBACK, LAN_GERMAN } from "@hooks/language/useLanguages";
import { advcatch } from "@utils/logging";
import { getHook } from "@utils/react-hooks-outside";
import { atomFamily } from "recoil";

type TGetTranslation = {
    SourceText: string;
    LanguageID: number;
};

type TSetTranslation = {
    TranslatedText: string;
    LanguageID: number;
    Success: boolean;
};
export type TTranslationItem = {
    /** Der übersetzte Text oder ein Fallback-Text */
    text: string | undefined;
    /** Konnte ein Text in der angegebenen Sprache ermittelt werden? */
    success: boolean;
};

type TTranslationSerialize = {
    langId: number;
    sourceText: string | undefined;
};

async function loadTranslationAsync(textToTranslate: string, languageId: number) {
    const socket = await getHook("useAdvSocketCallback");
    const input: TGetTranslation = { LanguageID: languageId, SourceText: textToTranslate };
    const res = await socket.sendCallbackRequest<TGetTranslation, TSetTranslation>(
        "Language",
        "GetTranslation",
        input,
        true,
    );

    const item: TTranslationItem = {
        text: res.TranslatedText ?? "",
        success: res.Success,
    };
    return item;
}

/** Ermittelt die Übersetzung zu {@link textToTranslate}.
 * Bevorzugt die angegebene {@link languageId} aber fällt auf {@link LAN_FALLBACK} zurück falls keine Übersetzung ermittelt werden konnte.
 * Cache > Server
 */
async function getTranslationAsync(textToTranslate: string, languageId: number) {
    const defaultItem = { success: false, text: textToTranslate } as TTranslationItem;
    const serialize = {
        sourceText: textToTranslate,
        langId: languageId,
    } as TTranslationSerialize;
    //if (textToTranslate == "Font-Anpassungen") debugger;
    const cacheKey = `advlan_${JSON.stringify(serialize)}`;

    if (languageId == LAN_GERMAN) {
        const germanItem = { success: true, text: textToTranslate } as TTranslationItem;
        // cacheSet(cacheKey, germanItem, 1000 * 60 * 60 * 24 * 31);
        return germanItem;
    }

    const { item: cachedItem } = await cacheGet<TTranslationItem>(cacheKey);
    if (cachedItem != null) return cachedItem;

    const translationItem = await loadTranslationAsync(textToTranslate, languageId);
    if (translationItem !== undefined && translationItem.success) {
        await cacheSet(cacheKey, translationItem, 1000 * 60 * 60 * 24 * 31);
        return translationItem;
    }

    // Wenn wir kein Fallback ermitteln können, weil diese Sprache bereits die Fallback-Sprache ist,
    // dann einfach den Wert abspeichern (kurz)
    if (languageId == LAN_FALLBACK) {
        await cacheSet(cacheKey, defaultItem, 1000 * 60 * 60 * 24 * 1);
        return defaultItem;
    }

    // Falls diese Sprache nicht die Fallback-Sprache ist, probieren
    // wir das ganze nochmal in der Fallback-Sprache.
    const fallbackSerialize = { ...serialize, langId: LAN_FALLBACK } as TTranslationSerialize;
    const fallbackCacheKey = `advlan_${JSON.stringify(fallbackSerialize)}`;

    // Prüfen: Gibt es im Cache für die FallbackSprache eine Übersetzung?
    const { item: cachedFallbackItem } = await cacheGet<TTranslationItem>(fallbackCacheKey);
    if (cachedFallbackItem != null) {
        cachedFallbackItem.success = false; // Fallback ist NICHT success
        await cacheSet(cacheKey, { ...cachedFallbackItem }, 1000 * 60 * 60 * 24 * 1);
        return cachedFallbackItem;
    }

    // Wenn alles nicht hilft: Server für Fallback anfragen
    const fallbackTranslationItem = await loadTranslationAsync(textToTranslate, LAN_FALLBACK);
    if (fallbackTranslationItem !== undefined && fallbackTranslationItem.success) {
        await cacheSet(fallbackCacheKey, fallbackTranslationItem, 1000 * 60 * 60 * 24 * 31);

        fallbackTranslationItem.success = false; // Fallback ist NICHT success
        await cacheSet(cacheKey, { ...fallbackTranslationItem }, 1000 * 60 * 60 * 24 * 1);
        return fallbackTranslationItem;
    }

    // Wenn wir überhaupt keine Übersetzung finden, dann nehmen wir einfach
    // den textToTranslate. Wichtig: Geringe Cache-Dauer
    await cacheSet(cacheKey, defaultItem, 1000 * 60 * 60 * 24 * 1);
    return defaultItem;
}

function removeFamilyNameJSON(key: string) {
    const index = key.indexOf("__");
    if (index >= 0) {
        let quotedKey = key.substring(index + 2);
        if (quotedKey[0] == '"')
            quotedKey = quotedKey.substring(
                1,
                quotedKey[quotedKey.length - 1] == '"' ? quotedKey.length - 2 : undefined,
            );
        return quotedKey;
    }
    return key;
}

export const gTranslationsAtom = atomFamily<TTranslationItem, TTranslationSerialize>({
    key: "global_translationsAtom",
    default: { text: undefined, success: false },
    effects: [
        ({ node, trigger, setSelf, getPromise }) => {
            if (trigger != "get") return;

            const loadTrans = async () => {
                const thisVal = await getPromise(node);

                // Wir müssen nur übersetzen, wenn dieses Atom noch keinen Wert besitzt.
                if (thisVal === undefined || thisVal.text === undefined) {
                    const { langId: id, sourceText: text = "" } = JSON.parse(
                        removeFamilyNameJSON(node.key),
                    ) as TTranslationSerialize;

                    // Leere und zu kurze Strings werden nicht übersetzt (ähnliche Logik in AdvanTex, s. Tools_String.Untranslatable)
                    const trimmedText = text.trim();
                    if (trimmedText.length < 2) {
                        setSelf({ text: trimmedText, success: true });
                        return;
                    }

                    getTranslationAsync(trimmedText, id)
                        .then((translationItem) => {
                            setSelf(translationItem);
                        })
                        .catch((r) => advcatch("Could not load translation: ", r, node.key));
                }
            };
            loadTrans().catch((r) => advcatch("Could not get translation: ", r, node.key));
        },
    ],
});

import { recoilAction } from "@data/action";
import { INavLink, Nav } from "@fluentui/react";
import { useAdvRouter } from "@hooks/page/useAdvRouter";
import { useAdvCallback } from "@hooks/react-overload/useAdvCallback";
import useAdvRecoilValue from "@hooks/recoil-overload/useAdvRecoilValue";
import { gAdvBreadcrumbIgnoreKey } from "@utils/page-parser";
import assert from "assert";
import { useMemo } from "react";
import { AdvNavLink, AdvNavLinkText, TAdvNavLink } from "../link";
import { IAdvNavItemGroup } from "./nav";
import { navStyles } from "./styles";

export const AdvNavLinks = ({
    navGroup,
    ignoreSelectedKey = false,
    renderLinkOverwrite,
}: {
    navGroup: IAdvNavItemGroup;
    ignoreSelectedKey?: boolean;
    renderLinkOverwrite?: (
        props?: INavLink,
        defaultRender?: (props?: INavLink) => React.JSX.Element | null,
    ) => React.JSX.Element;
}) => {
    const router = useAdvRouter();

    /** Abhängig von der (aktuellen) URL selektieren wir in der Nav den Eintrag der aktuelle Seite. */
    const selectedKey = useMemo(() => {
        const lastIndex = router.pathname.lastIndexOf("/");
        const cutUrl = router.pathname.substring(lastIndex + 1);
        const isDynamicSite = cutUrl == "dynamic";

        let siteName = cutUrl;
        if (isDynamicSite && Object.keys(router.query).includes(gAdvBreadcrumbIgnoreKey)) {
            const navKey = router.query[gAdvBreadcrumbIgnoreKey];
            if (navKey != undefined) {
                siteName = "dynamic_nav_item_" + (typeof navKey == "string" ? navKey : navKey[0]);
            }
        }

        return siteName;
    }, [router]);

    const actionNames = useMemo(() => {
        const actionNames: string[] = [];

        function addActionNames(links: TAdvNavLink[]) {
            links.forEach((val) => {
                if (val.actionName != undefined && val.actionName != "") {
                    actionNames.push(val.actionName);
                }
                if (val.links != undefined && val.links.length > 0) addActionNames(val.links);
            });
        }

        addActionNames(navGroup.links);

        return actionNames;
    }, [navGroup]);

    const navActions = useAdvRecoilValue(recoilAction.actionDictArrayByNames(actionNames));

    const group = useMemo(() => {
        function filterActionNames(links: TAdvNavLink[]): TAdvNavLink[] {
            const res = links.filter((valInner) => {
                const actIndex =
                    valInner.actionName != undefined && valInner.actionName != ""
                        ? actionNames.indexOf(valInner.actionName)
                        : -1;
                if (actIndex != -1) {
                    return !navActions[actIndex].HasError() && !navActions[actIndex].IsLoading();
                }
                return true;
            });

            res.forEach((val) => {
                if (val.links != undefined && val.links.length > 0)
                    val.links = filterActionNames(val.links);
            });
            return res;
        }

        return { ...navGroup, links: filterActionNames(navGroup.links) };
    }, [actionNames, navActions, navGroup]);

    const focusZoneStyle = useMemo(() => {
        return { style: { height: "100%" } };
    }, []);

    const navStyle = useMemo(() => {
        return navStyles();
    }, []);

    const renderLink = useAdvCallback(
        (props?: INavLink, defaultRender?: (props?: INavLink) => React.JSX.Element | null) => {
            assert(props);
            return <AdvNavLinkText {...props} defaultRender={defaultRender}></AdvNavLinkText>;
        },
        [],
    );

    return (
        <Nav
            styles={navStyle}
            groups={[group]}
            isOnTop={false}
            linkAs={AdvNavLink}
            onRenderLink={renderLinkOverwrite != undefined ? renderLinkOverwrite : renderLink}
            focusZoneProps={focusZoneStyle}
            selectedKey={ignoreSelectedKey ? "" : selectedKey}
        />
    );
};

import { TResourceStorageKey } from "@data/resource-storage";

export const gDatasourceFilePrefix = "advliveds_";
export const gPageFilePrefix = "advlivepg_"; // keep in sync with server
export const gActionFilePrefix = "advliveact_"; // keep in sync with server
export const gThemeFilePrefix = "advlivethm_"; // keep in sync with server
export const gNavFilePrefix = "advlivenav_"; // keep in sync with server
export const gResourceFilePrefix = "advliveres_";
export const gNewsFilePrefix = "";

export const gPageConstFilePrefix = "advlivecpg_"; // keep in sync with server
export const gActionConstFilePrefix = "advlivecact_"; // keep in sync with server

export const gDatasourceDesignerPrefix = "ds";
export const gPageDesignerPrefix = "pg";
export const gActionDesignerPrefix = "act";
export const gThemeDesignerPrefix = "thm";
export const gNavDesignerPrefix = "nav";
export const gResourceDesignerPrefix = (isClient: boolean) => (isClient ? "rs_cl" : "rs");
export const gNewsDesignerPrefix = "nws";

// hardcoded actions
export const gActionDesignerRead = gActionConstFilePrefix + "DesignerRead"; // keep in sync with server
export const gActionDesignerWrite = gActionConstFilePrefix + "DesignerWrite"; // keep in sync with server

export const gActionThemeDesignerRead = gActionConstFilePrefix + "ThemeDesignerRead"; // keep in sync with server
export const gActionThemeDesignerWrite = gActionConstFilePrefix + "ThemeDesignerWrite"; // keep in sync with server

export const gActionNavDesignerRead = gActionConstFilePrefix + "NavDesignerRead"; // keep in sync with server
export const gActionNavDesignerWrite = gActionConstFilePrefix + "NavDesignerWrite"; // keep in sync with server

export const gActionMenuDesignerRead = gActionConstFilePrefix + "MenuDesignerRead"; // keep in sync with server
export const gActionMenuDesignerWrite = gActionConstFilePrefix + "MenuDesignerWrite"; // keep in sync with server

export const gActionNewsDesignerRead = gActionConstFilePrefix + "NewsDesignerRead"; // keep in sync with server
export const gActionNewsDesignerWrite = gActionConstFilePrefix + "NewsDesignerWrite"; // keep in sync with server

export const gActionResourceDesignerRead = gActionConstFilePrefix + "ResDesignerRead"; // keep in sync with server
export const gActionResourceDesignerWrite = gActionConstFilePrefix + "ResDesignerWrite"; // keep in sync with server

export const gActionDesignerGroup = gActionConstFilePrefix + "DesignerGroup"; // keep in sync with server
export const gActionAdminSettings = gActionConstFilePrefix + "AdminSettings"; // keep in sync with server

// helper functions
export const replaceFilePrefix = (
    prefix: string,
    actName: string,
    splitChar: string | undefined,
) => {
    if (splitChar != undefined) return actName.replace(prefix, "").split(splitChar).slice(-1)[0];
    else return actName.replace(prefix, "");
};

export const replacePagePrefix = (pgName: string) => {
    return pgName.replace(gPageFilePrefix, "");
};

export const replaceActionPrefix = (actName: string) => {
    return replaceFilePrefix(gActionFilePrefix, actName, "|");
};

export const replaceDatasourcePrefix = (dsName: string) => {
    return replaceFilePrefix(gDatasourceFilePrefix, dsName, "|");
};

export const replaceResourcePrefix = (resName: TResourceStorageKey) => {
    return "[" + resName.DataType + "] " + resName.Name.replace(gResourceFilePrefix, "");
};

export const replaceNewsPrefix = (newsName: string) => {
    return replaceFilePrefix(gNewsFilePrefix, newsName, undefined);
};
export const replaceThemePrefix = (themeName: string) => {
    return replaceFilePrefix(gThemeFilePrefix, themeName, undefined);
};

import { useAdvCallback } from "@hooks/react-overload/useAdvCallback";
import { EVerbosityLevel, verbosityLevel } from "@hooks/shared";
// eslint-disable-next-line no-restricted-imports
import { useSetRecoilState } from "recoil";

type TSetRecoilStateParams<T> = Parameters<typeof useSetRecoilState<T>>;

const useAdvSetRecoilState = <T>(recoilState: TSetRecoilStateParams<T>[0]) => {
    const setter = useSetRecoilState(recoilState);

    if (verbosityLevel > EVerbosityLevel.None) {
        (setter as any).adv = 1;
    }

    const setterWrapper = useAdvCallback<typeof setter>(
        (param: Parameters<typeof setter>[0]) => {
            setTimeout(() => setter(param), 0);
        },
        [setter],
    );
    return setterWrapper;
};

export default useAdvSetRecoilState;

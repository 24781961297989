import { AdvHeadlineSmall } from "@components/data/text";
import { AdvButtonPure } from "@components/inputs/button/button-pure";
import AdvDropdown from "@components/inputs/dropdown";
import AdvModal from "@components/layout/modal";
import AdvStack from "@components/layout/stack";
import AdvStackItem from "@components/layout/stack/stack-item";
import AdvIcon from "@components/other/icon";
import AdvLoading, { EAdvLoadingMode } from "@components/other/loading";
import { recoilAction, TAdvActionBase } from "@data/action";
import {
    gActionConstFilePrefix,
    gActionFilePrefix,
    gPageConstFilePrefix,
    gPageFilePrefix,
    replaceActionPrefix,
} from "@data/designer/file";
import { LAN } from "@data/language/strings";
import { gPermissionNaN } from "@feature/actions/types";
import AdvDesignerFileListComponents from "@feature/filelist/filelist";
import useIsMounted from "@hooks/misc/useIsMounted";
import { useAdvCallback } from "@hooks/react-overload/useAdvCallback";
import { useAdvEffect } from "@hooks/react-overload/useAdvEffect";
import useAdvRecoilValue from "@hooks/recoil-overload/useAdvRecoilValue";
import {
    TAdvDBUserGroupsDB,
    TAdvWebActionPermission,
    useAdvDataFactory,
} from "@hooks/useAdvDataProviderFactory";
import useAdvTheme from "@hooks/useAdvTheme";
import { DeleteIcon, FileCodeIcon, SaveIcon } from "@themes/icons";
import deepCopy from "@utils/deep-copy";
import { advcatch, advlog } from "@utils/logging";
import { useMemo, useRef, useState } from "react";

type TPermissionConfigProps = {
    isOpen: boolean;
    onDismiss: () => void;
    webActionName: string;
};

let gDBUserGroups: Promise<TAdvDBUserGroupsDB> | undefined = undefined;

export const PermissionConfig = ({
    isOpen,
    onDismiss,
    webActionName: webActionNameParam,
}: TPermissionConfigProps) => {
    const [webActionName, setWebActionName] = useState(webActionNameParam);
    const action = useAdvRecoilValue(recoilAction.BaseActions(webActionName));
    const [isReady, setIsReady] = useState(false);
    const [permission, setPermission] = useState<TAdvWebActionPermission>({
        FormID: -1,
        PageName: "",
        Rights: [],
    });

    const { getDBUserGroups, getWebActionPermissions, setWebActionPermissions } =
        useAdvDataFactory();

    const onSave = useAdvCallback(() => {
        setWebActionPermissions({ ActionName: webActionName, Permission: permission }).catch((r) =>
            advlog("Could not save permission: ", r),
        );
    }, [permission, setWebActionPermissions, webActionName]);

    const onDismissWrapper = useAdvCallback(() => {
        setWebActionPermissions({ ActionName: webActionName, Permission: permission })
            .then(() => {
                onDismiss();
                setIsReady(false);
            })
            .catch((r) => advcatch("Could not save permission: ", r));
    }, [onDismiss, permission, setWebActionPermissions, webActionName]);

    const { isOrWillMount } = useIsMounted();

    const userGroupsDBList = useRef<TAdvDBUserGroupsDB>();
    const [hasDBUserGroups, setHasDBUserGroups] = useState(false);

    useAdvEffect(() => {
        if (isOpen) {
            advlog("Getting user groups from socket");
            if (gDBUserGroups == undefined) {
                gDBUserGroups = getDBUserGroups();
            }
            gDBUserGroups
                .then(
                    (value) => {
                        if (isOrWillMount()) {
                            if (userGroupsDBList.current == undefined) {
                                userGroupsDBList.current = value;
                                setHasDBUserGroups(true);
                            }
                        } else {
                            advlog("Could not load tables component was not mounted anymore.");
                        }
                    },
                    (r) => {
                        advlog("Could not load tables: ", r);
                    },
                )
                .catch((r) => {
                    advlog("Could not load tables: ", r);
                    gDBUserGroups = undefined;
                });
        }

        if (isOpen && !isReady) {
            getWebActionPermissions(webActionName)
                .then((res) => {
                    if (isOrWillMount()) {
                        const perm: TAdvWebActionPermission = {
                            FormID: -1,
                            PageName: action.IsLoaded() ? action.Get().PageName ?? "" : "",
                            Rights: [],
                        };
                        if (res.Success) {
                            perm.FormID = res.Permission.FormID;
                            perm.PageName =
                                perm.PageName == "" ? res.Permission.PageName : perm.PageName;
                            perm.Rights = deepCopy(res.Permission.Rights);
                        }
                        setPermission(perm);
                        setIsReady(true);
                    }
                })
                .catch((r) => advcatch("Could not load permission for action " + webActionName, r));
        }
    }, [
        action,
        getDBUserGroups,
        getWebActionPermissions,
        isOpen,
        isOrWillMount,
        isReady,
        webActionName,
    ]);

    const userRights = useMemo(() => {
        const res: number[] = [];
        if (hasDBUserGroups && userGroupsDBList.current != undefined) {
            userGroupsDBList.current.UserGroups.forEach((val) => {
                val.Permissions.forEach((permission) => {
                    if (!res.includes(permission.ID)) {
                        res.push(permission.ID);
                    }
                });
            });
        }
        return res;
    }, [hasDBUserGroups]);

    const userRightsAsOptions = useMemo(() => {
        const res: { key: string; text: string; data: number }[] = [];
        if (hasDBUserGroups && userGroupsDBList.current != undefined) {
            const handledRights: number[] = [];
            userGroupsDBList.current.UserGroups.forEach((val) => {
                val.Permissions.forEach((permission) => {
                    if (!handledRights.includes(permission.ID)) {
                        res.push({
                            key: permission.ID.toString(),
                            text: permission.Bez,
                            data: permission.ID,
                        });
                        handledRights.push(permission.ID);
                    }
                });
            });
        }
        return res;
    }, [hasDBUserGroups]);

    const theme = useAdvTheme();

    return (
        <AdvModal
            isOpen={isOpen}
            onDismiss={onDismissWrapper}
            headline={LAN.PERMISSIONS_CONFIGURE.text}
            styles={{ root: { main: { minWidth: 700, minHeight: "60%" } } }}
        >
            <AdvStack verticalFill>
                <AdvDesignerFileListComponents
                    label={LAN.PERMISSIONS_ACTIONS.text}
                    emptyFileName={""}
                    onFileClick={(newName: string) => {
                        onSave();
                        setWebActionName(newName);
                        setIsReady(false);
                    }}
                    onDeleteClick={() => {
                        // unused
                    }}
                    onEditFile={() => {
                        // unused
                    }}
                    onCopyFile={() => {
                        // unused
                    }}
                    checkRenameName={(): boolean => {
                        return false;
                    }}
                    onNewClick={() => {
                        // unused
                    }}
                    checkNewName={function (): boolean {
                        return false;
                    }}
                    prefix={""}
                    activeFileName={webActionName}
                    generalFilePrefix={gActionFilePrefix}
                    fileSplitChar={"|"}
                    illegalFileChar={"|"}
                    categoryIgnorePrefix={""}
                    canSaveAll={false}
                    canRedo={false}
                    canUndo={false}
                    canAddNew={false}
                    canCollapse={false}
                    fileList={recoilAction.actionBaseArrayFilteredPage({
                        pageName: undefined,
                        requiresConstActions: true,
                    })}
                    getFileProps={(aFile: TAdvActionBase) => {
                        return {
                            name: aFile.Name,
                            displayName: {
                                text:
                                    aFile.Name.replace(gActionConstFilePrefix, "") +
                                    (aFile.PageName !== undefined && aFile.PageName !== ""
                                        ? " [" +
                                          (aFile.PageName?.replace(gPageFilePrefix, "").replace(
                                              gPageConstFilePrefix,
                                              "",
                                          ) ?? "global") +
                                          "]"
                                        : ""),
                                ignoreTranslation: true,
                            },
                        };
                    }}
                    fileTypeName={"action"}
                    fileTypeIcon={FileCodeIcon.iconName}
                    hasUndoManager={false}
                    canEditOrDelete={() => {
                        return { canDelete: false, canEdit: false };
                    }}
                >
                    <AdvLoading
                        isLoading={!isReady}
                        mode={EAdvLoadingMode.HideContentWhenLoading}
                        spinnerProps={{
                            label:
                                "Fetching webaction data for: " +
                                replaceActionPrefix(webActionName),
                            withDots: true,
                        }}
                    >
                        <AdvStack verticalFill>
                            <AdvStackItem>
                                <AdvHeadlineSmall>
                                    {"Mindestens einer der Rechte..."}
                                </AdvHeadlineSmall>
                                <AdvStack horizontal styles={{ root: { justifyContent: "end" } }}>
                                    <AdvStackItem>
                                        <AdvButtonPure
                                            text={LAN.SAVE.text}
                                            iconProps={SaveIcon}
                                            primary
                                            onClick={() => {
                                                onSave();
                                            }}
                                        ></AdvButtonPure>
                                    </AdvStackItem>
                                </AdvStack>
                            </AdvStackItem>
                            {permission.Rights.map((val, index) => (
                                <AdvStackItem
                                    key={"permission-act-select" + index.toString()}
                                    styles={{
                                        root: {
                                            backgroundColor: theme.palette.themeLighterAlt,
                                            padding: 10,
                                        },
                                    }}
                                >
                                    <AdvStack horizontal>
                                        <AdvStackItem grow>
                                            <AdvDropdown
                                                key={
                                                    "permission-act-select-dropdown" +
                                                    index.toString()
                                                }
                                                ignoreTranslation
                                                label={""}
                                                value={
                                                    userRights.includes(val.RightID)
                                                        ? val.RightID.toString()
                                                        : undefined
                                                }
                                                options={userRightsAsOptions}
                                                onValueChanged={(opt) => {
                                                    if (opt != undefined) {
                                                        setPermission((old) => {
                                                            const newPerm = deepCopy(old);
                                                            newPerm.Rights[index].RightID =
                                                                opt.data;
                                                            return newPerm;
                                                        });
                                                    }
                                                }}
                                            ></AdvDropdown>
                                        </AdvStackItem>
                                        <AdvStackItem
                                            styles={{
                                                root: {
                                                    alignItems: "center",
                                                    display: "flex",
                                                    paddingLeft: 10,
                                                },
                                            }}
                                            shrink={0}
                                        >
                                            <AdvIcon
                                                iconName={DeleteIcon.iconName}
                                                onClick={() => {
                                                    setPermission((old) => {
                                                        const newPerm = deepCopy(old);
                                                        newPerm.Rights = newPerm.Rights.filter(
                                                            (val2, index2) => index2 != index,
                                                        );
                                                        return newPerm;
                                                    });
                                                }}
                                            ></AdvIcon>
                                        </AdvStackItem>
                                    </AdvStack>
                                </AdvStackItem>
                            ))}
                            <AdvStackItem>
                                <AdvButtonPure
                                    text={LAN.PERMISSIONS_ADD.text}
                                    primary
                                    onClick={() => {
                                        setPermission((old) => {
                                            const newPerm = deepCopy(old);
                                            newPerm.Rights.push({
                                                ModID: -1,
                                                RightID: gPermissionNaN,
                                            });
                                            return newPerm;
                                        });
                                    }}
                                ></AdvButtonPure>
                            </AdvStackItem>
                        </AdvStack>
                    </AdvLoading>
                </AdvDesignerFileListComponents>
            </AdvStack>
        </AdvModal>
    );
};

import { getConstPages } from "@components/navigation/defaultNavGroup";
import { dpClientAddOrRemEditDatGlobalTrans } from "@data/dataprovider/data-provider-client";
import { LAN } from "@data/language/strings";
import { useAdvCallback } from "@hooks/react-overload/useAdvCallback";
import { useAdvEffect } from "@hooks/react-overload/useAdvEffect";
import {
    CWebAction,
    RegisterWebAction,
    TActionParameters,
    TAdvExecWebActionRes,
    UnregisterWebAction,
} from "@logic/webactions/actions";
import { advcatch, advlog } from "@utils/logging";
import {
    buildPageParserParam,
    buildQueryAsStr,
    gAdvBreadcrumbIgnoreKey,
    gAdvDynPageName,
    gAdvPageAccessKey,
    gDefaultIgnoreParamKeys,
    getQueryValue,
    pageIsDynamic,
    parsePageName,
    parseQueryString,
    TPageParserParam,
} from "@utils/page-parser";
import { useRef } from "react";
import { useAdvRecoilTransaction } from "./recoil-overload/useAdvRecoilTransaction";

import {
    gPageParamResultAutoVar,
    setResultRegisterTrans,
} from "@components/dynamic/parameter-mapping/page-parameter";
import { popLastHistoryItemTrans } from "@components/navigation/history";
import { TPageIdent, TPageInfo } from "@pages/dynamic";
import { defaultPageInstanceIndex } from "@pages/dynamic/instanceIndexContext";
import { EActionLogic } from "../logic/webactions/types";
import useAdvError from "./dialogs/useAdvError";
import { useAdvRouter } from "./page/useAdvRouter";
import { useAdvEvent } from "./useAdvEvent";
import { TSuccessWithMsgClass, useAdvSocketCallback } from "./useAdvSocketCallback";

export const useWebActionManager = () => {
    const router = useAdvRouter();
    const { sendCallbackRequest } = useAdvSocketCallback();
    const { enableQueue, disableQueue } = useAdvEvent();

    const { showError, showPreparedError } = useAdvError();

    const lastPage = useRef<TPageInfo>({
        pathname: "",
        query: {},
        pageInstanceIndex: defaultPageInstanceIndex,
    });

    const setResultRegister = useAdvRecoilTransaction(setResultRegisterTrans, [
        setResultRegisterTrans,
    ]);

    useAdvEffect(() => {
        type TOpenPageInp = {
            Page: TPageIdent;
            ActionToken: string;
            QueryStr: string;
        };

        if (JSON.stringify(lastPage.current) != JSON.stringify(router.pageInfo)) {
            const pageName = parsePageName(router.pageInfo);
            if (pageName != "") {
                sendCallbackRequest<TOpenPageInp, TSuccessWithMsgClass>("page", "open", {
                    Page: {
                        Name: pageName,
                        PageIndex: router.pageInfo.pageInstanceIndex,
                    },
                    ActionToken: getQueryValue(router.pageInfo.query, gAdvPageAccessKey),
                    QueryStr: buildQueryAsStr(
                        buildPageParserParam(router.pageInfo.query, gDefaultIgnoreParamKeys),
                    ),
                })
                    .then((val) => {
                        if (!val.Success) showError(val.Msg, "Webaction");
                        else {
                            // disable queue if dyn page
                            if (pageIsDynamic(router.pageInfo.pathname)) {
                                // enableQueue(buildUniquePageID(router.pageInfo));
                            } else {
                                // disableQueue(buildUniquePageID(router.pageInfo));
                            }
                        }
                    })
                    .catch((r) => advcatch("Could not open page with URL ", router.asPath, r));
            }
            lastPage.current = router.pageInfo;
        }
        // eslint-disable-next-line react-hooks-addons/no-unused-deps
    }, [disableQueue, enableQueue, router, sendCallbackRequest, showError]);

    const popLastHistoryItem = useAdvRecoilTransaction(popLastHistoryItemTrans, [
        popLastHistoryItemTrans,
    ]);

    const webActionOpenPage = useAdvCallback(
        (actionParams: TActionParameters, successKey: string, successExtra: Array<any>) => {
            const pageName = successExtra[0] as string;
            const isDynPage = successExtra[1] as boolean;
            const isNavAction = successExtra[2] as boolean;
            const doesReplace = successExtra[3] as boolean;

            const paramList: TPageParserParam[] = [];
            if (isDynPage) {
                paramList.push({ MappingName: gAdvDynPageName, MappingVal: pageName });
            }
            paramList.push(
                ...actionParams.Parameters.map((v) => {
                    return {
                        MappingName: v.Name,
                        MappingVal: v.Value,
                    };
                }),
            );
            paramList.push({ MappingName: gAdvPageAccessKey, MappingVal: successKey });
            const queryStr = buildQueryAsStr(paramList);
            advlog(
                "Redirecting to page: " +
                    pageName +
                    " with following parameters: " +
                    JSON.stringify(actionParams),
            );
            let constPageName = "/404";
            if (!isDynPage) {
                const tmpPageName = getConstPages().find((val) => {
                    return val.name == pageName;
                });
                if (tmpPageName != undefined) constPageName = tmpPageName.url;
            }

            const navigateHref =
                (isDynPage ? "/dynamic/" : constPageName) +
                router.addInstanceToQuery(
                    queryStr +
                        (isNavAction
                            ? (queryStr == "" ? "?" : !queryStr.endsWith("&") ? "&" : "") +
                              gAdvBreadcrumbIgnoreKey +
                              "=" +
                              actionParams.User
                            : ""),
                    // Wenn ein neuer Reiter aufgemacht werden soll, dann muss
                    // hier der nächste Index verwendet werden (siehe auskommentierten
                    // Code, da wird bei einer Navigation über die Navbar immer eine
                    // neue Page geöffnet) TODO: 157870
                    undefined, // isNavAction ? router.nextPageIndex : undefined,
                );
            let curPath = router.asPath;
            // add a slash if missing
            if (curPath.startsWith("/dynamic?"))
                curPath = curPath.replace("/dynamic?", "/dynamic/?");
            const queryPath = parseQueryString(curPath);
            if (Object.keys(queryPath).includes(gAdvBreadcrumbIgnoreKey)) {
                const param =
                    gAdvBreadcrumbIgnoreKey +
                    "=" +
                    encodeURIComponent((queryPath[gAdvBreadcrumbIgnoreKey] as string) ?? "");
                // try with "&" and without
                curPath = curPath.replace("&" + param, "");
                curPath = curPath.replace(param, "");
            }

            (doesReplace ? router.replace : router.push)(navigateHref)
                .then(() => {
                    if (doesReplace) {
                        popLastHistoryItem(curPath);
                    }
                })
                .catch(() => {
                    advlog("Cannot redirect to page: " + pageName);
                });
        },
        [popLastHistoryItem, router],
    );

    useAdvEffect(() => {
        RegisterWebAction(
            EActionLogic.OpenPage,
            new CWebAction(
                sendCallbackRequest,
                showPreparedError(undefined, undefined, "OpenPage"),
                webActionOpenPage,
                LAN.ACTION_LOGIC_OPEN_PAGE,
            ),
        );
        return () => {
            UnregisterWebAction(EActionLogic.OpenPage);
        };
    }, [sendCallbackRequest, showPreparedError, webActionOpenPage]);

    const addEditData = useAdvRecoilTransaction(dpClientAddOrRemEditDatGlobalTrans, [
        dpClientAddOrRemEditDatGlobalTrans,
    ]);

    const webActionEditProvider = useAdvCallback(
        (actionParams: TActionParameters, successKey: string, successExtra: Array<any>) => {
            advlog("Tried to make dataprovider: " + actionParams.User + " editable.", successExtra);
        },
        [],
    );
    const webActionSaveProvider = useAdvCallback(
        (actionParams: TActionParameters, successKey: string, successExtra: Array<any>) => {
            advlog("Tried to save dataprovider: " + actionParams.User + ".", successExtra);
        },
        [],
    );
    const webActionCallContractFunc = useAdvCallback(
        (actionParams: TActionParameters, successKey: string, successExtra: Array<any>) => {
            advlog("Called contract func: ", successExtra);
        },
        [],
    );
    const cancelContract = useAdvCallback(
        (actionParams: TActionParameters, successKey: string, successExtra: Array<any>) => {
            advlog("Tried to cancel contract. ", actionParams.User, successExtra);
        },
        [],
    );
    const webActionAddContractEditRecord = useAdvCallback(
        (actionParams: TActionParameters, successKey: string, successExtra: Array<any>) => {
            advlog("Called add edit record", successExtra[1]);
            addEditData(router.pageInfo, successExtra[1], true);
        },
        [addEditData, router.pageInfo],
    );
    const webActionRemContractEditRecord = useAdvCallback(
        (actionParams: TActionParameters, successKey: string, successExtra: Array<any>) => {
            advlog("Called rem edit record", successExtra[1]);
            addEditData(router.pageInfo, successExtra[1], false);
        },
        [addEditData, router.pageInfo],
    );
    const browserBack = useAdvCallback(() => {
        router.back();
    }, [router]);
    const browserReturn = useAdvCallback(
        (actionParams: TActionParameters) => {
            advlog("Called return", actionParams);
            setResultRegister(
                actionParams.Parameters.find((p) => p.Name == gPageParamResultAutoVar)?.Value,
            );
            browserBack();
        },
        [browserBack, setResultRegister],
    );

    const { registerGlobalEventHandler } = useAdvEvent();
    const registerBereich = "exec_action";
    type TRegisterExecActionClass = {
        actionParams: TActionParameters;
        successKey: string;
        successExtra: Array<any>;
    };

    // register all web actions
    registerGlobalEventHandler<TRegisterExecActionClass>(
        registerBereich,
        EActionLogic.OpenPage,
        (data) => {
            webActionOpenPage(data.actionParams, data.successKey, data.successExtra);
        },
    );
    registerGlobalEventHandler<TRegisterExecActionClass>(
        registerBereich,
        EActionLogic.TryInitContract,
        (data) => {
            webActionEditProvider(data.actionParams, data.successKey, data.successExtra);
        },
    );
    registerGlobalEventHandler<TRegisterExecActionClass>(
        registerBereich,
        EActionLogic.TryFulfillContract,
        (data) => {
            webActionSaveProvider(data.actionParams, data.successKey, data.successExtra);
        },
    );
    registerGlobalEventHandler<TRegisterExecActionClass>(
        registerBereich,
        EActionLogic.CancelContract,
        (data) => {
            cancelContract(data.actionParams, data.successKey, data.successExtra);
        },
    );
    registerGlobalEventHandler<TRegisterExecActionClass>(
        registerBereich,
        EActionLogic.TryCancelContract,
        (data) => {
            cancelContract(data.actionParams, data.successKey, data.successExtra);
        },
    );
    registerGlobalEventHandler<TRegisterExecActionClass>(
        registerBereich,
        EActionLogic.ExecContract,
        (data) => {
            webActionSaveProvider(data.actionParams, data.successKey, data.successExtra);
        },
    );
    registerGlobalEventHandler<TRegisterExecActionClass>(
        registerBereich,
        EActionLogic.CallContractFunction,
        (data) => {
            webActionCallContractFunc(data.actionParams, data.successKey, data.successExtra);
        },
    );
    registerGlobalEventHandler<TRegisterExecActionClass>(
        registerBereich,
        EActionLogic.AddContractEditRecord,
        (data) => {
            webActionAddContractEditRecord(data.actionParams, data.successKey, data.successExtra);
        },
    );
    registerGlobalEventHandler<TRegisterExecActionClass>(
        registerBereich,
        EActionLogic.RemContractEditRecord,
        (data) => {
            webActionRemContractEditRecord(data.actionParams, data.successKey, data.successExtra);
        },
    );
    registerGlobalEventHandler<TRegisterExecActionClass>(
        registerBereich,
        EActionLogic.BrowserBack,
        () => {
            browserBack();
        },
    );
    registerGlobalEventHandler<TRegisterExecActionClass>(
        registerBereich,
        EActionLogic.Return,
        (data) => {
            browserReturn(data.actionParams);
        },
    );

    useAdvEffect(() => {
        RegisterWebAction(
            EActionLogic.TryInitContract,
            new CWebAction(
                sendCallbackRequest,
                showPreparedError(undefined, undefined, "TryInitContract"),
                webActionEditProvider,
                LAN.ACTION_LOGIC_TRY_INIT_CONTRACT,
            ),
        );
        RegisterWebAction(
            EActionLogic.TryFulfillContract,
            new CWebAction(
                sendCallbackRequest,
                showPreparedError(undefined, undefined, "TryFulfillContract"),
                webActionSaveProvider,
                LAN.ACTION_LOGIC_SAVE_PROVIDER,
            ),
        );
        RegisterWebAction(
            EActionLogic.CancelContract,
            new CWebAction(
                sendCallbackRequest,
                showPreparedError(undefined, undefined, "CancelContract"),
                cancelContract,
                LAN.ACTION_LOGIC_CANCEL_CONTRACT,
            ),
        );
        RegisterWebAction(
            EActionLogic.TryCancelContract,
            new CWebAction(
                sendCallbackRequest,
                showPreparedError(undefined, undefined, "TryCancelContract"),
                cancelContract,
                LAN.ACTION_LOGIC_TRY_CANCEL_CONTRACT,
            ),
        );
        RegisterWebAction(
            EActionLogic.ExecContract,
            new CWebAction(
                sendCallbackRequest,
                showPreparedError(undefined, undefined, "ExecContract"),
                webActionSaveProvider,
                LAN.ACTION_LOGIC_EXEC_CONTRACT,
            ),
        );
        RegisterWebAction(
            EActionLogic.CallContractFunction,
            new CWebAction(
                sendCallbackRequest,
                showPreparedError(undefined, undefined, "CallContractFunction"),
                webActionCallContractFunc,
                LAN.ACTION_LOGIC_CALL_CONTRACT_FUNC,
            ),
        );
        RegisterWebAction(
            EActionLogic.AddContractEditRecord,
            new CWebAction(
                sendCallbackRequest,
                showPreparedError(undefined, undefined, "AddContractEditRecord"),
                webActionAddContractEditRecord,
                LAN.ACTION_LOGIC_ADD_CONTRACT_EDIT_RECORD,
            ),
        );
        RegisterWebAction(
            EActionLogic.RemContractEditRecord,
            new CWebAction(
                sendCallbackRequest,
                showPreparedError(undefined, undefined, "RemContractEditRecord"),
                webActionRemContractEditRecord,
                LAN.ACTION_LOGIC_REM_CONTRACT_EDIT_RECORD,
            ),
        );

        return () => {
            UnregisterWebAction(EActionLogic.TryInitContract);
            UnregisterWebAction(EActionLogic.TryFulfillContract);
            UnregisterWebAction(EActionLogic.CancelContract);
            UnregisterWebAction(EActionLogic.TryCancelContract);
            UnregisterWebAction(EActionLogic.ExecContract);
            UnregisterWebAction(EActionLogic.CallContractFunction);
            UnregisterWebAction(EActionLogic.AddContractEditRecord);
            UnregisterWebAction(EActionLogic.RemContractEditRecord);
        };
    }, [
        cancelContract,
        sendCallbackRequest,
        showPreparedError,
        webActionAddContractEditRecord,
        webActionCallContractFunc,
        webActionEditProvider,
        webActionRemContractEditRecord,
        webActionSaveProvider,
    ]);

    const sendDummy = useAdvCallback(() => {
        return new Promise(function (
            resolve: (value: TAdvExecWebActionRes | PromiseLike<TAdvExecWebActionRes>) => void,
        ) {
            resolve({
                ActionToken: "",
                Extra: [],
                Msg: "",
                Success: true,
            } as TAdvExecWebActionRes);
        });
    }, []);

    useAdvEffect(() => {
        RegisterWebAction(
            EActionLogic.BrowserBack,
            new CWebAction(
                sendDummy,
                showPreparedError(undefined, undefined, "BrowserBack"),
                browserBack,
                LAN.ACTION_LOGIC_BROWSER_BACK,
            ),
        );
        return () => {
            UnregisterWebAction(EActionLogic.BrowserBack);
        };
    }, [browserBack, sendDummy, showPreparedError]);

    useAdvEffect(() => {
        RegisterWebAction(
            EActionLogic.Return,
            new CWebAction(
                sendDummy,
                showPreparedError(undefined, undefined, "Return"),
                browserReturn,
                LAN.ACTION_LOGIC_RETURN,
            ),
        );
        return () => {
            UnregisterWebAction(EActionLogic.Return);
        };
    }, [browserReturn, sendDummy, showPreparedError]);
};

import { useWebActionManager } from "@hooks/useWebActionsManager";

export const NavActionManager = () => {
    // TODO: 157870 Prüfen, ob wir das wirklich mehrfach brauchen oder es doch anders
    // lösen können. Wir haben den hook aktuell ein mehrere Stellen (dynamisc page und hier)
    useWebActionManager();

    return <></>;
};

export default NavActionManager;

export enum EAdvWebActionType { // the values of this enum is used in the server code. Keep it in sync
    // Bindet eine vom user gewünschten Funktion, diese Eigenschaft kann genutzt werden, um keinen besondere Funktion zu binden
    WebActionTypeUser,
    // Bindet eine Web-Action.
    WebActionTypeNormal,
}

export const gAdvWebActionParamKey = "__ActionParam__ADV"; // this value is used in the server code. Keep it in sync
export const gAdvWebActionParamAsKey = "__As_ActionParam__ADV"; // this value is used in the server code. Keep it in sync
export const gAdvWebActionParamTypeKey = "__Type_ActionParam__ADV"; // this value is used in the server code. Keep it in sync
export const gAdvWebActionParamTypeValueKey = "__Type_Value_ActionParam__ADV"; // this value is used in the server code. Keep it in sync

export const gAdvWebActionParamContractProvKey = "__Contract_Prov_ActionParam__ADV"; // this value is used in the server code. Keep it in sync
export const gAdvWebActionParamContractContractKey = "__Contract_Cont_ActionParam__ADV"; // this value is used in the server code. Keep it in sync

export const gAdvWebActionParamOpenPageMode = "__Replace_Page__ADV"; // this value is used in the server code. Keep it in sync

export type TAdvWebActionParams = {
    actionName: string; // the name of this field is used in the server code, in case of rename, also do it in the server code
    actionType: string | EAdvWebActionType; // the name of this field is used in the server code, in case of rename, also do it in the server code
    actionParams: Record<string, { value: string; options: Record<string, any> }>; // the name of this field is used in the server code, in case of rename, also do it in the server code
};
export const gAdvWebActionDefaultValue: TAdvWebActionParams = {
    actionName: "",
    actionType: EAdvWebActionType.WebActionTypeNormal,
    actionParams: {},
};

import {
    CUndoAction,
    CUndoManager,
    gDesignerFileListUndoManagerKey,
    gDesignerUndoManagerKey,
    recoilUndoManagers,
    recoilUndoManagersState,
    TUndoAction,
} from "@data/designer/undomanager";
import { useAdvCallback } from "@hooks/react-overload/useAdvCallback";
import {
    TAdvTransactionInterface,
    useAdvRecoilTransaction,
} from "@hooks/recoil-overload/useAdvRecoilTransaction";
import useAdvRecoilValue from "@hooks/recoil-overload/useAdvRecoilValue";
import { RecoilState, selectorFamily } from "recoil";

const useUndoManagerImpl = (
    aManager: RecoilState<CUndoManager>,
    aManagerState: RecoilState<{
        canRedo: boolean;
        canUndo: boolean;
        shouldSave: boolean;
        redoText: string;
        undoText: string;
    }>,
) => {
    const undoManager = useAdvRecoilValue(aManager);
    const undoManagerState = useAdvRecoilValue(aManagerState);

    const changeStateTrans = useAdvCallback(
        (tb: TAdvTransactionInterface) =>
            (shouldSave: boolean, respecOldSaveState: boolean = true) => {
                const old = tb.get(aManagerState);
                tb.set(aManagerState, {
                    canRedo: undoManager.CanRedo(),
                    canUndo: undoManager.CanUndo(),
                    shouldSave: shouldSave || (respecOldSaveState && old.shouldSave),
                    redoText: undoManager.redoText(),
                    undoText: undoManager.undoText(),
                });
            },
        [aManagerState, undoManager],
    );
    const changeState = useAdvRecoilTransaction(changeStateTrans, [changeStateTrans]);

    const umDoAction = useAdvCallback(
        (undoAction: CUndoAction) => {
            changeState(undoManager.DoAction(undoAction));
        },
        [changeState, undoManager],
    );

    const umDoGroupAction = useAdvCallback(
        (groupName: string, undoAction: CUndoAction) => {
            changeState(undoManager.DoGroupAction(groupName, undoAction));
        },
        [changeState, undoManager],
    );

    const umRegister = useAdvCallback(
        (registerName: string, undoFunc: TUndoAction, doFunc: TUndoAction) => {
            undoManager.Register(registerName, undoFunc, doFunc);
        },
        [undoManager],
    );

    const umUnregister = useAdvCallback(
        (registerName: string) => {
            undoManager.Unregister(registerName);
        },
        [undoManager],
    );

    const umUndo = useAdvCallback(() => {
        changeState(undoManager.UndoAction());
    }, [undoManager, changeState]);

    const umRedo = useAdvCallback(() => {
        changeState(undoManager.RedoAction());
    }, [undoManager, changeState]);

    const umSaved = useAdvCallback(() => {
        changeState(false, false);
    }, [changeState]);

    const forceShouldSaveUM = useAdvCallback(() => {
        changeState(true, false);
    }, [changeState]);

    return {
        undoManager,
        umDoAction,
        umDoGroupAction,
        umRegister,
        umUnregister,

        umUndo,
        umRedo,

        umSaved,

        canRedoUM: undoManagerState.canRedo,
        canUndoUM: undoManagerState.canUndo,
        shouldSaveUM: undoManagerState.shouldSave,

        redoTextUM: undoManagerState.redoText,
        undoTextUM: undoManagerState.undoText,

        forceShouldSaveUM,
    };
};

export const useUndoManagerFileList = (prefix: string) => {
    const res = useUndoManagerImpl(
        recoilUndoManagers(prefix + gDesignerFileListUndoManagerKey),
        recoilUndoManagersState(prefix + gDesignerFileListUndoManagerKey),
    );
    return res;
};

export const useUndoManagerDesigner = (prefix: string, designerFileName: string) => {
    const res = useUndoManagerImpl(
        recoilUndoManagers(prefix + gDesignerUndoManagerKey + designerFileName),
        recoilUndoManagersState(prefix + gDesignerUndoManagerKey + designerFileName),
    );
    return res;
};

export const designerSaveAllSelector = selectorFamily({
    key: "designerSaveAllSelector",
    get:
        (param: { prefix: string; names: any[] }) =>
        ({ get }) => {
            const shouldSaveNameList: any[] = [];
            for (const fileName of param.names) {
                const state = get(
                    recoilUndoManagersState(
                        param.prefix + gDesignerUndoManagerKey + (fileName as string), // TODO!2
                    ),
                );
                if (state.shouldSave) {
                    shouldSaveNameList.push(fileName);
                }
            }
            return shouldSaveNameList;
        },
});

import { recoilAction } from "@data/action";
import { recoilDatasource } from "@data/datasource";
import { gNavFilePrefix, gThemeFilePrefix } from "@data/designer/file";
import { recoilDynamicPage } from "@data/dynamic-page";
import { recoilKeystorage } from "@data/key-storage";
import { gTranslationsAtom } from "@data/language/language";
import { recoilNewsStorage } from "@data/news";
import { TResourceStorageKey, getRecoilResourceStorage } from "@data/resource-storage";
import { EDictionaryAddOptions, TDictionaryAddOptions } from "@data/utils/recoil-dictionary";
import { useAdvCallback } from "@hooks/react-overload/useAdvCallback";
import {
    TAdvTransactionInterface,
    useAdvRecoilTransaction,
} from "@hooks/recoil-overload/useAdvRecoilTransaction";
import { useAdvEvent } from "@hooks/useAdvEvent";
import useAdvRecoilDictionary from "@hooks/useAdvRecoilDictionary";
import { SerializableParam } from "recoil";

export type TSetItem<K> = {
    Name: K;
    Value: string;
    Err: string;
};

export type TSetItems<K> = {
    Values: TSetItem<K>[];
};

type TSetTranslation = {
    SourceText: string;
    TranslatedText: string;
    LanguageID: number;
};

type TSetTranslations = {
    Items: Array<TSetTranslation>;
};

type TDictReturnType<T, K extends SerializableParam> = ReturnType<
    typeof useAdvRecoilDictionary<T, K>
>;

export const useStorageListener = () => {
    const { registerGlobalEventHandler } = useAdvEvent();
    const pageDictionary = useAdvRecoilDictionary(recoilDynamicPage.dictionary);
    const actionDictionary = useAdvRecoilDictionary(recoilAction.dictionary);
    const datasourceDictionary = useAdvRecoilDictionary(recoilDatasource.dictionary);
    const keysDictionary = useAdvRecoilDictionary(recoilKeystorage(false).dictionary);
    const keysDictionaryClient = useAdvRecoilDictionary(recoilKeystorage(true).dictionary);
    const resourceDictionary = useAdvRecoilDictionary(getRecoilResourceStorage(false).dictionary);
    const resourceDictionaryClient = useAdvRecoilDictionary(
        getRecoilResourceStorage(true).dictionary,
    );
    const newsDictionary = useAdvRecoilDictionary(recoilNewsStorage.dictionary);

    const pageBaseDictionary = useAdvRecoilDictionary(recoilDynamicPage.baseDictionary);
    const actionBaseDictionary = useAdvRecoilDictionary(recoilAction.baseDictionary);
    const datasourceBaseDictionary = useAdvRecoilDictionary(recoilDatasource.baseDictionary);
    const keysBaseDictionary = useAdvRecoilDictionary(recoilKeystorage(false).baseDictionary);
    const keysBaseDictionaryClient = useAdvRecoilDictionary(recoilKeystorage(true).baseDictionary);
    const resourceBaseDictionary = useAdvRecoilDictionary(
        getRecoilResourceStorage(false).baseDictionary,
    );
    const resourceBaseDictionaryClient = useAdvRecoilDictionary(
        getRecoilResourceStorage(true).baseDictionary,
    );
    const newsBaseDictionary = useAdvRecoilDictionary(recoilNewsStorage.baseDictionary);

    const processItems = useAdvCallback(function <T, K extends SerializableParam>(
        data: TSetItems<K>,
        dict: TDictReturnType<T, K>,
        addOptionsCB?: (key: string) => Array<TDictionaryAddOptions> | undefined,
    ) {
        const addItems = data.Values.filter((val) => val.Value != "" && val.Err == "");
        if (addItems.length > 0) {
            dict.addItemsSilentlyInternal(
                ...addItems.map((val) => {
                    const pagePayload = JSON.parse(val.Value) as T;
                    return {
                        key: val.Name,
                        itemToAdd: pagePayload,
                        addOptions:
                            addOptionsCB != undefined
                                ? addOptionsCB(val.Name?.toString() ?? "")
                                : undefined,
                    };
                }),
            );
        }
        const remItems = data.Values.filter((val) => val.Value == "" && val.Err == "");
        if (remItems.length > 0) {
            dict.removeItemsSilentlyInternal(
                ...remItems.map((val) => {
                    return val.Name;
                }),
            );
        }
        const addErrItems = data.Values.filter((val) => val.Err != "");
        if (addErrItems.length > 0) {
            dict.addItemsSilentlyInternal(
                ...addErrItems.map((val) => {
                    const pagePayload = undefined as unknown as T;
                    return {
                        key: val.Name,
                        itemToAdd: pagePayload,
                        addOptions: [{ type: EDictionaryAddOptions.AddErr, extraValue: val.Err }],
                    };
                }),
            );
        }
    }, []);

    registerGlobalEventHandler<TSetItems<string>>("pagestorage", "setitems", (data) => {
        processItems(data, pageDictionary);
    });

    registerGlobalEventHandler<TSetItems<string>>("actionstorage", "setitems", (data) => {
        processItems(data, actionDictionary);
    });

    registerGlobalEventHandler<TSetItems<string>>("datasourcestorage", "setitems", (data) => {
        processItems(data, datasourceDictionary);
    });

    registerGlobalEventHandler<TSetItems<string>>("keystorage", "setitems", (data) => {
        processItems(data, keysDictionary, (key) => {
            if (key.startsWith(gNavFilePrefix))
                return [
                    {
                        type: EDictionaryAddOptions.AddLifeTime,
                        extraValue: 1000 * 60 * 60 * 24 * 31 /* 31 days cache */,
                    },
                ];
            return undefined;
        });
    });

    registerGlobalEventHandler<TSetItems<string>>("keystorage_client", "setitems", (data) => {
        processItems(data, keysDictionaryClient, (key) => {
            if (key.startsWith(gThemeFilePrefix) || key.startsWith(gNavFilePrefix))
                return [
                    {
                        type: EDictionaryAddOptions.AddLifeTime,
                        extraValue: 1000 * 60 * 60 * 24 * 31 /* 31 days cache */,
                    },
                ];
            return undefined;
        });
    });

    registerGlobalEventHandler<TSetItems<TResourceStorageKey>>(
        "resourcestorage",
        "setitems",
        (data) => {
            processItems(
                {
                    Values: data.Values.map((v) => {
                        return { ...v, Name: JSON.parse(v.Name as unknown as string) };
                    }),
                },
                resourceDictionary,
            );
        },
    );

    registerGlobalEventHandler<TSetItems<TResourceStorageKey>>(
        "resourcestorage_client",
        "setitems",
        (data) => {
            processItems(
                {
                    Values: data.Values.map((v) => {
                        return { ...v, Name: JSON.parse(v.Name as unknown as string) };
                    }),
                },
                resourceDictionaryClient,
            );
        },
    );

    registerGlobalEventHandler<TSetItems<string>>("newsstorage", "setitems", (data) => {
        processItems(data, newsDictionary);
    });

    registerGlobalEventHandler<TSetItems<string>>("pagestorage", "setbaseitems", (data) => {
        processItems(data, pageBaseDictionary);
    });

    registerGlobalEventHandler<TSetItems<string>>("actionstorage", "setbaseitems", (data) => {
        processItems(data, actionBaseDictionary);
    });

    registerGlobalEventHandler<TSetItems<string>>("datasourcestorage", "setbaseitems", (data) => {
        processItems(data, datasourceBaseDictionary);
    });

    registerGlobalEventHandler<TSetItems<string>>("keystorage", "setbaseitems", (data) => {
        processItems(data, keysBaseDictionary, (key) => {
            if (key.startsWith(gNavFilePrefix))
                return [
                    {
                        type: EDictionaryAddOptions.AddLifeTime,
                        extraValue: 1000 * 60 * 60 * 24 * 31 /* 31 days cache */,
                    },
                ];
            return undefined;
        });
    });

    registerGlobalEventHandler<TSetItems<string>>("keystorage_client", "setbaseitems", (data) => {
        processItems(data, keysBaseDictionaryClient, (key) => {
            if (key.startsWith(gThemeFilePrefix) || key.startsWith(gNavFilePrefix))
                return [
                    {
                        type: EDictionaryAddOptions.AddLifeTime,
                        extraValue: 1000 * 60 * 60 * 24 * 31 /* 31 days cache */,
                    },
                ];
            return undefined;
        });
    });

    registerGlobalEventHandler<TSetItems<TResourceStorageKey>>(
        "resourcestorage",
        "setbaseitems",
        (data) => {
            processItems(
                {
                    Values: data.Values.map((v) => {
                        return { ...v, Name: JSON.parse(v.Name as unknown as string) };
                    }),
                },
                resourceBaseDictionary,
            );
        },
    );

    registerGlobalEventHandler<TSetItems<TResourceStorageKey>>(
        "resourcestorage_client",
        "setbaseitems",
        (data) => {
            processItems(
                {
                    Values: data.Values.map((v) => {
                        return { ...v, Name: JSON.parse(v.Name as unknown as string) };
                    }),
                },
                resourceBaseDictionaryClient,
            );
        },
    );

    registerGlobalEventHandler<TSetItems<string>>("newsstorage", "setbaseitems", (data) => {
        processItems(data, newsBaseDictionary);
    });

    const translationSetItemsTrans = useAdvCallback(
        (tb: TAdvTransactionInterface) => (data: TSetTranslations) => {
            for (const translation of data.Items) {
                tb.set(
                    gTranslationsAtom({
                        langId: translation.LanguageID,
                        sourceText: translation.SourceText,
                    }),
                    { success: true, text: translation.TranslatedText },
                );
            }
        },
        [],
    );
    const translationSetItems = useAdvRecoilTransaction(translationSetItemsTrans, [
        translationSetItemsTrans,
    ]);
    registerGlobalEventHandler<TSetTranslations>("Language", "setitems", (data) => {
        translationSetItems(data);
    });
};

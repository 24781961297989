import { useAdvCallback } from "@hooks/react-overload/useAdvCallback";

const events: Record<string, (AValue: any, aExtraDataInfo: string) => any> = {};

const createUniqueEventKey = function (aBereich: string, aName: string) {
    return aBereich + "_" + aName;
};

const queuedEvents: Array<{
    aBereich: string;
    aName: string;
    aData: any;
    aExtraDataInfo: string;
}> = [];

const queueState = {
    isEnabled: false,
    pageID: "",
};

export function useAdvEvent() {
    const executeEventReal = useAdvCallback(function (
        aBereich: string,
        aName: string,
        aData: any,
        aExtraDataInfo: string,
    ) {
        const UUID = createUniqueEventKey(aBereich, aName);
        const handler = events[UUID];

        if (typeof handler == "undefined") {
            console.warn("Unknown Event fired!: " + UUID);
        } else {
            handler(aData, aExtraDataInfo);
        }
    }, []);

    const executeEvent = useAdvCallback(
        function (aBereich: string, aName: string, aData: any, aExtraDataInfo: string) {
            if (queueState.isEnabled) {
                queuedEvents.push({ aBereich, aName, aData, aExtraDataInfo });
            } else {
                executeEventReal(aBereich, aName, aData, aExtraDataInfo);
            }
        },
        [executeEventReal],
    );

    const enableQueue = useAdvCallback(
        (pageID: string) => {
            // flush previous events
            if (queueState.pageID != pageID) {
                queuedEvents.forEach((ev) => {
                    const { aBereich, aName, aData, aExtraDataInfo } = ev;
                    executeEventReal(aBereich, aName, aData, aExtraDataInfo);
                });
                queuedEvents.length = 0;

                queueState.isEnabled = true;
                queueState.pageID = pageID;
            }
        },
        [executeEventReal],
    );
    const disableQueue = useAdvCallback(
        (pageID: string) => {
            if (queueState.isEnabled) {
                // handle queued events
                queuedEvents.forEach((ev) => {
                    const { aBereich, aName, aData, aExtraDataInfo } = ev;
                    executeEventReal(aBereich, aName, aData, aExtraDataInfo);
                });
                queuedEvents.length = 0;
            }
            if (queueState.pageID == pageID) queueState.isEnabled = false;
            if (!queueState.isEnabled) queueState.pageID = pageID;
        },
        [executeEventReal],
    );

    const registerGlobalEventHandler = useAdvCallback(function <TData>(
        aBereich: string,
        aName: string,
        aCallback: (AData: TData, aExtraDataInfo: string) => void,
    ): void {
        events[createUniqueEventKey(aBereich, aName)] = aCallback;
    }, []);

    const deRegisterGlobalEventHandler = useAdvCallback(function (aBereich: string, aName: string) {
        delete events[createUniqueEventKey(aBereich, aName)];
    }, []);

    return {
        registerGlobalEventHandler,
        deRegisterGlobalEventHandler,
        executeEvent,
        enableQueue,
        disableQueue,
    };
}

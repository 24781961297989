import {
    AdvCommonComponentAttributes,
    AdvThemeProviderProperties,
} from "@components/other/common-properties";
import { LAN } from "@data/language/strings";
import { DefaultComponentCategory } from "@feature/Designer/types/category";
import { EComponentTypeLayout } from "@feature/Designer/types/component-type";
import { EDesignablePropertyBuilderTypes } from "@feature/Designer/types/designable";
import { AdvProperty, registerDesignableComponent } from "@feature/Designer/utils";
import { toAdvText } from "@hooks/language/useTranslation";
import { GridIcon } from "@themes/icons";
import AdvStackItemDesignable from "../stack/stack-item/designable";
import AdvGridItemDesignable from "./grid-item/designable";

enum EPropertyName {
    Horizontal = "horizontal",
    Columns = "columns",
    Rows = "rows",
    VerticalFill = "verticalFill",
    HorizontalFill = "horizontalFill",
    alignItems = "alignItems",
    rowGap = "rowGap",
    colGap = "colGap",
}

enum EPropertyCategory {
    Allgemeines = "Allgemeines",
    Fortgeschritten = "Fortgeschritten",
}

enum EPresetName {}
// Stack ist die Basis des Ui-Designers, deshalb hier als Objekt merken.
const registeredComponent = registerDesignableComponent({
    staticData: {
        name: LAN.GRID.text,

        type: EComponentTypeLayout.Grid,
        supportsChildren: true,
        category: DefaultComponentCategory.Layout,
        icon: GridIcon,
    },
    properties: [
        AdvProperty.Text.create(
            toAdvText(LAN.COLUMNS),
            EPropertyName.Columns,
            toAdvText(LAN.ADVANCED),
            toAdvText(LAN.GRID_COLUMNS_DESCR),
            "1fr 1fr",
        ),
        AdvProperty.Text.create(
            toAdvText(LAN.ROWS),
            EPropertyName.Rows,
            toAdvText(LAN.ADVANCED),
            toAdvText(LAN.GRID_ROWS_DESCR),
            "1fr 1fr",
        ),
        AdvProperty.Boolean.create(
            toAdvText(LAN.VERTICAL_FILL),
            EPropertyName.VerticalFill,
            toAdvText(LAN.ADVANCED),
            toAdvText(LAN.GRID_VERTICAL_FILL_DESCR),
            true,
        ),
        AdvProperty.Boolean.create(
            toAdvText(LAN.HORIZONTAL_FILL),
            EPropertyName.HorizontalFill,
            toAdvText(LAN.ADVANCED),
            toAdvText(LAN.GRID_HORIZONTAL_FILL_DESCR),
            true,
        ),
        AdvProperty.Text.createSelect(
            toAdvText(LAN.GRID_ALIGN_ITEMS),
            EPropertyName.alignItems,
            toAdvText(LAN.ADVANCED),
            toAdvText(LAN.GRID_ALIGN_ITEMS_DESCR),
            0,
            false,
            "",
            "center",
            "start",
            "end",
            "baseline",
            "stretch",
        ),
        AdvProperty.Number.create(
            toAdvText(LAN.GRID_COL_GAP),
            EPropertyName.colGap,
            toAdvText(LAN.ADVANCED),
            toAdvText(LAN.GRID_COL_GAP_DESCR),
            4,
            0,
            undefined,
            false,
        ),
        AdvProperty.Number.create(
            toAdvText(LAN.GRID_ROW_GAP),
            EPropertyName.rowGap,
            toAdvText(LAN.ADVANCED),
            toAdvText(LAN.GRID_ROW_GAP_DESCR),
            4,
            0,
            undefined,
            false,
        ),
        ...AdvCommonComponentAttributes,
        ...AdvThemeProviderProperties,
        ...AdvStackItemDesignable.CommonProperties,
        ...AdvGridItemDesignable.CommonProperties,
    ],
    propertiesBuilders: [EDesignablePropertyBuilderTypes.Grid],
    presets: [],
});

const AdvGridDesignable = {
    PropertyName: EPropertyName,
    PropertyCategory: EPropertyCategory,
    PresetName: EPresetName,
    Registered: registeredComponent,
};

export default AdvGridDesignable;

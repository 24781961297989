import useAdvComponent from "@hooks/useAdvComponent";
import { deepCompareJSXProps } from "@utils/deep-compare";
import React from "react";
import AdvGenericDialog from "../generic/generic";

export type TAdvWeiterDialogProps = {
    hidden: boolean;
    title: string;
    text: string;

    continueText?: string;
    ignoreTranslation?: boolean;

    minWidth?: string | number;
    maxWidth?: string | number;

    canContinue?: boolean;

    /** Wird aufgerufen, wenn der Dialog sich schließen soll. Diese Methode muss die ``hidden``-Property setzen */
    onClosed: (weiter: boolean) => void;
};

const AdvWarteDialogComp = ({
    title,
    text,
    onClosed,
    hidden,
    continueText = "Ok",
    ignoreTranslation,
    minWidth,
    maxWidth,
    canContinue = true,
    ...props
}: TAdvWeiterDialogProps) => {
    useAdvComponent(AdvWarteDialogComp, props);

    return (
        <AdvGenericDialog
            title={title}
            text={text}
            onClosed={onClosed}
            hidden={hidden}
            continueText={continueText}
            ignoreTranslation={ignoreTranslation}
            canCancel={false}
            canContinue={canContinue}
            hideCancel
            minWidth={minWidth}
            maxWidth={maxWidth}
        ></AdvGenericDialog>
    );
};

const AdvWarteDialog = React.memo(AdvWarteDialogComp, deepCompareJSXProps);
export default AdvWarteDialog;

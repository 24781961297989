import {
    CreateTableColumnOptions,
    DataGridCellProps,
    DataGridHeaderCellProps,
    DataGridHeaderProps,
    DataGridProps,
    DataGridRowProps,
    TableColumnDefinition,
    TableRowId,
    // eslint-disable-next-line no-restricted-imports
    createTableColumn,
} from "@fluentui/react-components";

export type TAdvTableHeaderProps = DataGridHeaderProps;
export type TAdvTableHeaderCellProps = DataGridHeaderCellProps;
export type TAdvTableRowProps<TItem> = DataGridRowProps<TItem> & { item?: TItem };
export type TAdvTableCellProps = DataGridCellProps;

export interface IAdvCreateTableColumnOptions<TItem> extends CreateTableColumnOptions<TItem> {
    contains: (item: TItem, text: string) => boolean;
    searchtermCorrespondence?: (item: TItem, text: string) => number;
}
export type TAdvTableColumn<TItem> = TableColumnDefinition<TItem> & {
    /** Enthält die Zelle den Text? */
    contains: (item: TItem, text: string) => boolean;
    /** wie stark uebereinstimmt der Wert mit einem Suchterm, je hoeher desto relevanter fuer die Suche */
    searchtermCorrespondence?: (item: TItem, text: string) => number;
};
export function createAdvTableColumn<TItem>({
    contains,
    searchtermCorrespondence,
    ...generalOptions
}: IAdvCreateTableColumnOptions<TItem>): TAdvTableColumn<TItem> {
    return { ...createTableColumn<TItem>(generalOptions), contains, searchtermCorrespondence };
}

export type TAdvTableProps<TItem extends object> = Omit<
    DataGridProps,
    | "items"
    | "columns"
    | "selectedItems"
    | "defaultSelectedItems"
    | "onSelectionChange"
    | "getRowId"
> & {
    columns: TAdvTableColumn<TItem>[];
    items: TItem[];
    selectedItems?: TItem[];
    onSelectionChange?: (items: TItem[]) => void;
    actionbar?: React.JSX.Element;
    /** Default: FALSE */
    canFilter?: boolean;
    /** @deprecated Use sortable */
    canSort?: boolean;
    /** @deprecated Use onSelectionChange */
    onActiveItemChanged?: (items: TItem[]) => void;
    /** Standardmäßig wird der Index als ID genommen. */
    getRowId?: (item: TItem) => TableRowId;

    headerAs?: React.ComponentType<TAdvTableHeaderProps>;
    headerCellAs?: React.ComponentType<TAdvTableHeaderCellProps>;
    headerRowAs?: React.ComponentType<TAdvTableRowProps<TItem>>;
    rowAs?: React.ComponentType<TAdvTableRowProps<TItem>>;
    cellAs?: React.ComponentType<TAdvTableCellProps>;
};

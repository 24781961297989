import AllIcons from "./icons_base";

export const KundenIcon = AllIcons["AddressBook"];
export const TraegerIcon = AllIcons["Shirt"];
export const LoginIcon = AllIcons["RightToBracket"];
export const LogoutIcon = AllIcons["RightFromBracket"];
export const SettingsIcon = AllIcons["Gear"];
export const SettingsUserIcon = AllIcons["UserCog"];
export const LanguagesIcon = AllIcons["Language"];
export const AdminIcon = AllIcons["ScrewdriverWrench"];

export const UiIcon = AllIcons["PenToSquare"]; // UiDesigner
export const DataSourceIcon = AllIcons["TableList"]; // DataSourceDesigner
export const ActionsIcon = AllIcons["Code"]; // ActionDesigner
export const ThemesIcon = AllIcons["PaintRoller"]; // ActionDesigner
export const VariableIcon = AllIcons["SquareRootVariable"];

export const BracketsCurlyIcon = AllIcons["BracketsCurly"]; // ActionDesigner

export const AddIcon = AllIcons["Plus"];
export const PageAddIcon = AllIcons["FileCirclePlus"];
export const CopyIcon = AllIcons["Copy"];
export const EditIcon = AllIcons["Pen"];
export const DeleteIcon = AllIcons["Trash"];
export const DownToLineIcon = AllIcons["ArrowsDownToLine"];
export const RefreshIcon = AllIcons["RotateRight"];

export const DragObject = AllIcons["GripVertical"];

export const TemplateIcon = AllIcons["ToolBox"];
export const FilterIcon = AllIcons["Filter"];
export const FilterResetIcon = AllIcons["FilterCircleXmark"];
export const BarsIcon = AllIcons["Bars"];

export const DownButton = AllIcons["ChevronDown"];
export const UpButton = AllIcons["ChevronUp"];
export const LeftButton = AllIcons["ChevronLeft"];
export const RightButton = AllIcons["ChevronRight"];

export const SaveIcon = AllIcons["FloppyDisk"];
export const SaveAll = AllIcons["FloppyDisks"];
export const OpenIcon = AllIcons["FileImport"];
export const RenameIcon = AllIcons["Edit"];

export const CompassDraftingIcon = AllIcons["CompassDrafting"];
export const LanguageIcon = AllIcons["Language"];
export const ExternalLinkIcon = AllIcons["ExternalLink"];

export const OpenFileIcon = AllIcons["FileImport"];

export const FileCodeIcon = AllIcons["FileCode"];
export const FileNavIcon = AllIcons["Bars"];
export const FileUIIcon = AllIcons["WindowMaximize"];

export const CircleInfoIcon = AllIcons["CircleInfo"];
export const CirclePlusIcon = AllIcons["CirclePlus"];
export const LocationDotIcon = AllIcons["LocationDot"];
export const ScrubberIcon = AllIcons["Scrubber"];

export const DropIcon = AllIcons["ArrowDownToLine"];

export const EllipsisIcon = AllIcons["Ellipsis"];

export const UpIcon = AllIcons["Up"];
export const DownIcon = AllIcons["Down"];

export const LinkIcon = AllIcons["Link"];
export const TableLinkIcon = AllIcons["ArrowUpRightFromSquare"];
export const UndoIcon = AllIcons["Undo"];
export const RedoIcon = AllIcons["Redo"];

export const EyeIcon = AllIcons["Eye"];
export const EyeHideIcon = AllIcons["EyeSlash"];
export const SearchIcon = AllIcons["MagnifyingGlass"];
export const CheckIcon = AllIcons["Check"];
export const CheckCircleIcon = AllIcons["CircleCheck"];

export const PageIcon = AllIcons["File"];
export const CloseIcon = AllIcons["Times"];

export const PaletteIcon = AllIcons["Palette"];

export const CloudImportExport = AllIcons["FileDownload"];

export const SetActionIcon = AllIcons["Code"];

export const FileSQLIcon = AllIcons["Database"];
export const FileExcel = AllIcons["FileExcel"];
export const FileResource = AllIcons["PhotoFilm"];
export const FileAppend = AllIcons["FileCirclePlus"];

export const ToggleLeftIcon = AllIcons["ToggleOff"];
export const ToggleRightIcon = AllIcons["ToggleOn"];

export const LabelIcon = AllIcons["Font"];
export const ChartIcon = AllIcons["ChartSimple"];
export const TextBoxIcon = AllIcons["Bold"];
export const ButtonIcon = AllIcons["Stop"];
export const CheckboxIcon = AllIcons["CheckSquare"];
export const ProgressbarIcon = AllIcons["BarsProgress"];
export const IconIcon = AllIcons["Icons"];
export const ImageIcon = AllIcons["Image"];
export const ComboboxIcon = AllIcons["CaretDown"];
export const CalendarIcon = AllIcons["Calendar"];
export const OrderArticlesIcon = AllIcons["CartShopping"];
export const OrderAnfIcon = AllIcons["CartShopping"];
export const OrderVSAIcon = AllIcons["CartShopping"];
export const OrderIcon = AllIcons["CartShopping"];
export const BenchmarkIcon = AllIcons["Calculator"];
export const DropdownIcon = AllIcons["CaretSquareDown"];
export const RadioBtnIcon = AllIcons["CircleDot"];
export const TextInputIcon = AllIcons["Keyboard"];
export const GridIcon = AllIcons["Th"];
export const GridItemIcon = AllIcons["Grid2Plus"];
export const GroupboxIcon = AllIcons["ObjectGroup"];
export const StackIcon = AllIcons["LayerGroup"];
export const StackItemIcon = AllIcons["TableRow"];
export const TabControlIcon = AllIcons["Browsers"];
export const TabItemIcon = AllIcons["Browser"];
export const TableIcon = AllIcons["Table"];
export const NewsIcon = AllIcons["Newspaper"];
export const KeyIcon = AllIcons["Key"];
export const WarningIcon = AllIcons["TriangleExclamation"];
export const WarningRoundIcon = AllIcons["CircleExclamation"];
export const WarningTextRoundIcon = AllIcons["Exclamation"];

export const ValueAbsoluteIcon = AllIcons["ValueAbsolute"];
export const SquareIcon = AllIcons["SquareFull"];

export const NamenschildIcon = AllIcons["IdCard"];
export const EmblemIcon = AllIcons["Shield"];
export const AenderungIcon = AllIcons["Ruler"];
export const ApplIcon = AllIcons["RulerTriangle"];

export const QuestionIcon = AllIcons["Question"];
export const SendIcon = AllIcons["PaperPlaneTop"];

export const DollarIcon = AllIcons["DollarSign"];
export const CalendarTodayIcon = AllIcons["ClockRotateLeft"];

export const MailIcon = AllIcons["Envelope"];
export const SpinnerIcon = AllIcons["Spinner"];

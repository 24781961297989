import { IDetailsHeaderStyles, IDetailsListStyles } from "@fluentui/react";

// Alternative zu ScrollablePane + Sticky
// https://codesandbox.io/embed/18413-detailslist-scrollable-with-fixed-headers-fdepm
const detailsListStyles = (
    width: string | number,
    height: string | number,
): Partial<IDetailsListStyles> => {
    return {
        root: {
            width: width,
            height: "100%",
        },
    };
};

const detailsHeaderStyles: Partial<IDetailsHeaderStyles> = {
    root: {
        paddingTop: 0,
    },
    check: {
        display: "none",
    },
};

export const defaultTableStyles = {
    list: detailsListStyles,
    header: detailsHeaderStyles,
};

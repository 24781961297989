/* eslint-disable @typescript-eslint/naming-convention */
import {
    EAdvValueBinderLogicType,
    EAdvValueBinderType,
    TAdvValueBindingParams,
} from "@hooks/dynamic/useAdvValueBinder";

/** */
export enum EAnprobeKdArApplChoice {
    /* Benutzer muss keine Auswahl treffen (weil kein KdArAppl) */
    NoChoiceRequired = 0,
    /* Benutzer muss eine Auswahl treffen (intermediate state) */
    ChoiceRequired = 1,
    /* Benutzer möchte die KdArAppl anwenden */
    ChoiceApply = 2,
    /* Benutzer möchte die KdArAppl nicht anwenden */
    ChoiceDiscard = 3,
}

export enum EAdvArtiType {
    TEXTIL = 1, // Textile Artikel
    NSARTI = 2, // Namenschilder
    EMBLEM = 3, // Firemnembleme
    SONSTIGE = 4, // Sonstige Artikel (#eingeführt mit 43739)
    REPARATUR = 5, // Reparaturen
    SONSTIGE_FAKTURIERUNG = 6, // Sonstige Fakturierung
    VERRECHNUNGSARTIKEL = 7, // Verrechnungsartikel (Kundendaten-SAP-Schnittstelle)
    AENDERUNGEN = 8, // Änderungen
}

export enum EAdvKdArApplAutoModus {
    Unknown = 0,
    /** KdArAppl wird automatisch und ohne Rückfrage in TraeAppl überführt und kann dort auch nicht gelöscht werden
    (entspricht heutigen Namenschildern und Emblemen).
    Neu angelegte KdArAppl werden sogar bei bestehenden Trägern rückwirkend in die TraeAppl aufgenommen,
    was ein nachträgliches Anbringen der Applikationen nach sich ziehen wird. */
    AutoNotDelRetro = 1,
    /** KdArAppl wird automatisch und ohne Rückfrage in TraeAppl überführt und kann dort auch nicht gelöscht werden
    (entspricht heutigen Namenschildern und Emblemen).
    Neu angelegte KdArAppl werden bei bestehenden Trägern nicht rückwirkend in die TraeAppl aufgenommen. */
    AutoNotDelNotRetro = 2,
    /** KdArAppl wird automatisch und ohne Rückfrage in TraeAppl überführt,
    kann dann dort aber gelöscht werden (entspricht KdArMass mit Auto=TRUE) */
    AutoDel = 3,
    /** KdArAppl wird nur nach Rückfrage in TraeAppl überführt
    (und kann dann auch wieder gelöscht werden; entspricht KdArMass mit Auto=FALSE), Rückfrage Default JA */
    QueYesDel = 4,
    /** KdArAppl wird nur nach Rückfrage in TraeAppl überführt
    (und kann dann auch wieder gelöscht werden; entspricht KdArMass mit Auto=FALSE), Rückfrage Default NEIN */
    QueNoDel = 5,
    /** KdArAppl ist zwar angelegt, wird aber nicht aktiv vorgeschlagen.
    Man muss wissentlich in den Trägerartikel gehen und über eine neue Funktion „Applikation aus Vorlage übernehmen“ z.B. das Ersthelfer-Emblem
    in den Trägerartikel (TraeAppl) übernehmen. */
    Manual = 6,
}

/** Hartkodierte Page-Variablen, die VON HAND angelegt werden müssen! */
export const ANPROBE_PAGE_VARS = {
    /** -1 = Zurück, 0 = Speichern, 1 = Abschließen */
    NextStep: "NextStep",
    /** JSON-Liste TAnprobeTraeArti[]  */
    AnprArti: "LIST_AnprArti",
    /** Neues Memo der Träger-Anprobe */
    Memo: "ENTRY_Memo",
};

export const ANPROBE_PAGE_BINDINGS = {
    NextStep: {
        bindingLogic: EAdvValueBinderLogicType.Value,
        bindingLogicValue: "",
        bindingTypeName: EAdvValueBinderType.BinderTypePageVariable,
        bindingParams: {
            getPageVar: { value: ANPROBE_PAGE_VARS.NextStep, options: {} },
        },
    } as TAdvValueBindingParams,
    AnprArtiList: {
        bindingLogic: EAdvValueBinderLogicType.Value,
        bindingLogicValue: "",
        bindingTypeName: EAdvValueBinderType.BinderTypePageVariable,
        bindingParams: {
            getPageVar: { value: ANPROBE_PAGE_VARS.AnprArti, options: {} },
        },
    } as TAdvValueBindingParams,
    Memo: {
        bindingLogic: EAdvValueBinderLogicType.Value,
        bindingLogicValue: "",
        bindingTypeName: EAdvValueBinderType.BinderTypePageVariable,
        bindingParams: {
            getPageVar: { value: ANPROBE_PAGE_VARS.Memo, options: {} },
        },
    } as TAdvValueBindingParams,
};

import { Theme } from "@fluentui/react";

export const nestingBorderColors = (theme: Theme): string[] => [
    theme.palette.blueLight,
    theme.palette.orangeLighter,
    theme.palette.magenta,
    theme.palette.tealLight,
    theme.palette.themeDark,
    theme.palette.neutralSecondaryAlt,
    // old "#5caeef", "#dfb976", "#c172d9", "#4fb1bc", "#97c26c", "#abb2c0",
];

export const nestingBackgroundColors = (theme: Theme): string[] => [
    "transparent", // Nicht änderbar, da DropArea keine DesignerProps bekommt (dessen Stack)
    theme.palette.neutralLight,
    theme.palette.neutralLight,
    theme.palette.neutralQuaternaryAlt,
    theme.palette.neutralQuaternary,
    theme.palette.neutralTertiaryAlt,
    theme.palette.neutralTertiary,
    theme.palette.neutralSecondary,
    theme.palette.neutralPrimaryAlt,
    theme.palette.neutralPrimary,
    theme.palette.neutralDark,
];

import AdvReactGridDesignable from "@components/layout/react-grid/designable";
import { LAN } from "@data/language/strings";
import { DefaultComponentCategory } from "@feature/Designer/types/category";
import { EComponentTypeLayout } from "@feature/Designer/types/component-type";
import { AdvProperty, registerDesignableComponent } from "@feature/Designer/utils";
import { toAdvText } from "@hooks/language/useTranslation";
import { PageIcon } from "@themes/icons";

export const DYNAMIC_PAGE_DEFAULT_NAME = "NewPage";
export const DYNAMIC_PAGE_DEFAULT_TITLE = "";
export const DYNAMIC_PAGE_DEFAULT_SECONDARY_TITLE = "";
export const DYNAMIC_PAGE_DEFAULT_CATEGORY = "Default";
export const DYNAMIC_PAGE_DEFAULT_ICON = PageIcon.iconName;

enum EPropertyName {
    Name = "name",
    Title = "title",
    SecondaryTitle = "secondarytitle",
}

enum EPropertyCategory {
    Allgemeines = "Allgemeines",
}

enum EPresetName {
    Standard = "Standard Layout",
    StandardGrid = "Standard Grid-Layout",
    OneTwoOne = "1-2-1 Grid-Layout",
}

const registeredComponent = registerDesignableComponent({
    staticData: {
        name: LAN.DYNAMIC_PAGE.text,
        type: EComponentTypeLayout.DynamicPage,
        supportsChildren: true,
        hideDropAreas: true, // Die DynamicPage an sich soll KEINE DropAreas bereitstellen. Das macht das AdvGrid bzw. dessen Kind-Komponenten.
        hidden: true, // Nicht pflegbar im Ui-Designer (vorerst)
        category: DefaultComponentCategory.Misc,
        icon: PageIcon,
    },
    properties: [
        // TODO! wird eig nicht mehr gebraucht AdvProperty.Text.createConst(EPropertyName.Name, DYNAMIC_PAGE_DEFAULT_NAME),
        AdvProperty.Text.create(
            toAdvText(LAN.TITLE),
            EPropertyName.Title,
            toAdvText(LAN.GENERAL),
            toAdvText(LAN.DYNAMIC_PAGE_TITLE_DESCR),
            DYNAMIC_PAGE_DEFAULT_TITLE,
        ),
        AdvProperty.Text.create(
            toAdvText(LAN.SECONDARY_TITLE),
            EPropertyName.SecondaryTitle,
            toAdvText(LAN.GENERAL),
            toAdvText(LAN.DYNAMIC_PAGE_SECONDARY_TITLE_DESCR),
            DYNAMIC_PAGE_DEFAULT_SECONDARY_TITLE,
        ),
        AdvProperty.Action.createSimplified(
            toAdvText(LAN.EVENTS_SHOW),
            "onShowEventActionParams",
            toAdvText(LAN.EVENTS),
            toAdvText(LAN.EVENTS_SHOW_DESCR),
        ),
        AdvProperty.Action.create(
            toAdvText(LAN.EVENTS_HIDE),
            "onHideEventActionParams",
            toAdvText(LAN.EVENTS),
            toAdvText(LAN.EVENTS_HIDE_DESCR),
        ),
        AdvProperty.Action.createSimplified(
            toAdvText(LAN.EVENTS_INIT),
            "onInitEventActionParams",
            toAdvText(LAN.EVENTS),
            toAdvText(LAN.EVENTS_INIT_DESCR),
        ),
        AdvProperty.Action.createSimplified(
            toAdvText(LAN.EVENTS_DESTROY),
            "onDestroyEventActionParams",
            toAdvText(LAN.EVENTS),
            toAdvText(LAN.EVENTS_DESTROY_DESCR),
        ),
        AdvProperty.Boolean.create(
            toAdvText(LAN.PAGE_IS_REPORT),
            "isReporting",
            toAdvText(LAN.GENERAL),
            toAdvText(LAN.PAGE_IS_REPORT_DESCR),
            false,
        ),
        AdvProperty.Boolean.create(
            toAdvText(LAN.NEVER_CACHE),
            "NeverUseCached",
            toAdvText(LAN.GENERAL),
            toAdvText(LAN.NEVER_CACHE_DESCR),
            false,
        ),
    ],
    propertiesBuilders: [],
    presets: [
        {
            name: LAN.STANDARD_LAYOUT.text,
            default: true,
            children: [
                {
                    type: EComponentTypeLayout.Stack,
                },
            ],
        },
        {
            name: LAN.STANDARD_GRID_LAYOUT.text,
            children: [
                {
                    type: EComponentTypeLayout.ReactGrid,
                    usePreset: AdvReactGridDesignable.PresetName.Standard,
                },
            ],
        },
        {
            name: LAN.ONE_TWO_ONE_GRID_LAYOUT.text,
            children: [
                {
                    type: EComponentTypeLayout.ReactGrid,
                    usePreset: AdvReactGridDesignable.PresetName.OneTwoOne,
                },
            ],
        },
    ],
});

const AdvDynamicPageDesignable = {
    PropertyName: EPropertyName,
    PropertyCategory: EPropertyCategory,
    PresetName: EPresetName,
    Registered: registeredComponent,
};

export default AdvDynamicPageDesignable;

import { useAdvCallback } from "@hooks/react-overload/useAdvCallback";
import { useAdvEffect } from "@hooks/react-overload/useAdvEffect";
import { useRef } from "react";

/**
 * In React, once a component is unmounted, it is deleted from memory and will
 * never be mounted again. That's why we don't define a state in a disassembled component.
 * The right way to solve this is cleaning effect like the above message said.
 * For example, see useInterval or useEventListener.
 * But, there are some cases like Promise or API calls where it's impossible to know if the component is still mounted at the resolve time.
 * This React hook help you to avoid this error with a function that return a boolean, isMounted.
 * @link https://usehooks-ts.com/react-hook/use-is-mounted
 */
function useIsMounted() {
    const isMounted = useRef(false);
    const isOrWillMount = useRef(true);

    useAdvEffect(() => {
        isMounted.current = true;
        isOrWillMount.current = true;

        return () => {
            isMounted.current = false;
            isOrWillMount.current = false;
        };
    }, []);

    return {
        isMounted: useAdvCallback(() => isMounted.current, []),
        isOrWillMount: useAdvCallback(() => isOrWillMount.current, []),
    };
}

export default useIsMounted;

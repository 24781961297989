import recoilSettings from "@data/settings";
import { DefaultThemeIcon, TAdvPalette, TAdvThemeIcon } from "@feature/settings/theme-types";
// eslint-disable-next-line no-restricted-imports
import { Theme, useTheme } from "@fluentui/react";
import deepCopy from "@utils/deep-copy";
import { useMemo } from "react";
import useAdvRecoilValue from "./recoil-overload/useAdvRecoilValue";

type TCustomProps = {
    textNotTranslated:
        | {
              textDecoration: string | undefined;
              textDecorationStyle: "dashed" | "dotted" | "double" | "solid" | "wavy" | undefined;
          }
        | undefined;
};
export type TAdvAppliedTheme = Omit<Theme, "palette"> & {
    custom: TCustomProps;
    icon: TAdvThemeIcon;
    palette: TAdvPalette;
};

const useAdvTheme = () => {
    const fluentTheme = useTheme();

    const underlineUntranslated = useAdvRecoilValue(recoilSettings.Local.underlineUntranslated);

    const advTheme = useMemo(() => {
        const customProps: TCustomProps = {
            textNotTranslated:
                underlineUntranslated.IsLoaded() == false ||
                underlineUntranslated.Get().Value == false
                    ? undefined
                    : {
                          textDecoration: "underline " + fluentTheme.palette.red,
                          textDecorationStyle: "wavy",
                      },
        };

        const icon = (fluentTheme as TAdvAppliedTheme).icon ?? deepCopy(DefaultThemeIcon);

        return { ...fluentTheme, custom: customProps, icon: icon } as TAdvAppliedTheme;
    }, [fluentTheme, underlineUntranslated]);

    return advTheme;
};

export default useAdvTheme;

import {
    AdvCommonComponentAttributes,
    AdvThemeProviderProperties,
} from "@components/other/common-properties";
import { LAN } from "@data/language/strings";
import { DefaultComponentCategory } from "@feature/Designer/types/category";
import { EComponentTypeLayout } from "@feature/Designer/types/component-type";
import { AdvProperty, registerDesignableComponent } from "@feature/Designer/utils";
import { toAdvText } from "@hooks/language/useTranslation";
import { GroupboxIcon } from "@themes/icons";
import AdvGridItemDesignable from "../grid/grid-item/designable";
import AdvStackItemDesignable from "../stack/stack-item/designable";
import { EAdvGroupboxCollapseDir } from "./types";

enum EPropertyName {
    Heading = "heading",
    AdditionalHeading = "additionalHeading",
    Opened = "isOpen",
    ContentAsFlexContainer = "contentAsFlexContainer",
    OnClickAction = "onClickAction",
}

enum EPropertyCategory {
    Allgemeines = "Allgemeines",
    Fortgeschritten = "Fortgeschritten",
}

const registeredComponent = registerDesignableComponent({
    staticData: {
        name: LAN.GROUPBOX.text,

        type: EComponentTypeLayout.Groupbox,
        supportsChildren: true,
        resetNestingDepth: true,
        category: DefaultComponentCategory.Layout,
        icon: GroupboxIcon,
    },
    properties: [
        AdvProperty.Text.create(
            toAdvText(LAN.HEADING),
            EPropertyName.Heading,
            toAdvText(LAN.GENERAL),
            toAdvText(LAN.GROUPBOX_HEADER_DESCR),
            "Überschrift",
        ),
        AdvProperty.Text.create(
            toAdvText(LAN.ADDITIONAL_HEADING),
            EPropertyName.AdditionalHeading,
            toAdvText(LAN.GENERAL),
            toAdvText(LAN.GROUPBOX_ADDITIONAL_HEADER_DESCR),
            "",
        ),
        AdvProperty.Boolean.create(
            toAdvText(LAN.OPENED),
            EPropertyName.Opened,
            toAdvText(LAN.GENERAL),
            toAdvText(LAN.GROUPBOX_OPENED_DESCR),
            true,
        ),
        AdvProperty.Boolean.create(
            toAdvText(LAN.CONTENT_AS_FLEX_CONTAINER),
            EPropertyName.ContentAsFlexContainer,
            toAdvText(LAN.GENERAL),
            toAdvText(LAN.GROUPBOX_CONTENT_AS_FLEX_CONTAINER_DESCR),
            false,
            false,
        ),
        AdvProperty.Boolean.create(
            toAdvText(LAN.IGNORE_TRANSLATION),
            "ignoreTranslation",
            toAdvText(LAN.GENERAL),
            toAdvText(LAN.IGNORE_TRANSLATION_DESCR),
            false,
        ),
        AdvProperty.Boolean.create(
            toAdvText(LAN.IGNORE_CACHE),
            "ignoreCache",
            toAdvText(LAN.GENERAL),
            toAdvText(LAN.IGNORE_CACHE_DESCR),
            false,
        ),
        AdvProperty.Boolean.create(
            toAdvText(LAN.CAN_COLLAPSE),
            "canCollapse",
            toAdvText(LAN.GENERAL),
            toAdvText(LAN.CAN_COLLAPSE_DESCR),
            true,
        ),
        AdvProperty.Number.createSelect(
            toAdvText(LAN.COLLAPSE_DIR),
            "collapseDir",
            toAdvText(LAN.GENERAL),
            toAdvText(LAN.COLLAPSE_DIR_DESCR),
            EAdvGroupboxCollapseDir.Top,
            false,
            { text: "normal", value: EAdvGroupboxCollapseDir.Top },
            { text: "unten", value: EAdvGroupboxCollapseDir.Bottom },
            { text: "links", value: EAdvGroupboxCollapseDir.Left },
            { text: "rechts", value: EAdvGroupboxCollapseDir.Right },
        ),
        AdvProperty.Action.create(
            toAdvText(LAN.ON_CLICK_ACTION),
            EPropertyName.OnClickAction,
            toAdvText(LAN.GENERAL),
            toAdvText(LAN.ON_CLICK_ACTION_DESCR),
        ),
        ...AdvCommonComponentAttributes,
        ...AdvThemeProviderProperties,
        ...AdvStackItemDesignable.CommonProperties,
        ...AdvGridItemDesignable.CommonProperties,
    ],
    propertiesBuilders: [],
    presets: [
        {
            name: LAN.WITH_STACK.text,

            default: true,
            children: [{ type: EComponentTypeLayout.Stack, properties: [] }],
            properties: [],
        },
    ],
});

const AdvGroupboxDesignable = {
    PropertyName: EPropertyName,
    PropertyCategory: EPropertyCategory,
    Registered: registeredComponent,
};

export default AdvGroupboxDesignable;

import {
    AdvCommonComponentAttributes,
    AdvThemeProviderProperties,
} from "@components/other/common-properties";
import { LAN } from "@data/language/strings";
import { DefaultComponentCategory } from "@feature/Designer/types/category";
import { EComponentTypeLayout } from "@feature/Designer/types/component-type";
import { IDesignableComponentPresetChildren } from "@feature/Designer/types/preset";
import { AdvProperty, registerDesignableComponent } from "@feature/Designer/utils";
import { toAdvText } from "@hooks/language/useTranslation";
import { GridIcon } from "@themes/icons";
import { deepCopyTimes } from "@utils/deep-copy";

enum EPropertyName {
    Columns = "cols",
    RowHeight = "rowHeight",
    Layout = "layout",
}

enum EPropertyCategory {
    Allgemeines = "Allgemeines",
}

enum EPresetName {
    Standard = "Standard Layout",
    OneTwoOne = "1-2-1 Layout",
}

const registeredComponent = registerDesignableComponent({
    staticData: {
        name: LAN.REACT_GRID.text,

        type: EComponentTypeLayout.ReactGrid,
        supportsChildren: true,
        hidden: true, // Nicht im Ui-Designer zur Auswahl stellen. Wird nur von der DynamicPage genutzt
        category: DefaultComponentCategory.Layout,
        icon: GridIcon,
    },
    properties: [
        AdvProperty.Number.create(
            toAdvText(LAN.COLUMN_COUNT),
            EPropertyName.Columns,
            toAdvText(LAN.GENERAL),
            toAdvText(LAN.REACT_GRID_COLUMNS_DESCR),
            12,
            6,
        ),
        AdvProperty.Number.create(
            toAdvText(LAN.ROWHEIGHT),
            EPropertyName.RowHeight,
            toAdvText(LAN.GENERAL),
            toAdvText(LAN.REACT_GRID_ROWHEIGHT_DESCR),
            30,
            6,
        ),
        AdvProperty.Object.create(
            toAdvText(LAN.LAYOUT),
            EPropertyName.Layout,
            toAdvText(LAN.GENERAL),
            toAdvText(LAN.REACT_GRID_LAYOUT_DESCR),
            [],
        ),
        ...AdvCommonComponentAttributes,
        ...AdvThemeProviderProperties,
    ],
    propertiesBuilders: [],
    presets: [
        {
            default: true,
            name: LAN.STANDARD_LAYOUT.text,

            properties: [
                {
                    name: EPropertyName.Layout,
                    value: [
                        {
                            i: "griditem_1",
                            w: 12,
                            h: 10,
                            x: 0,
                            y: 0,
                            isResizable: false,
                            isDraggable: false,
                        },
                    ] as ReactGridLayout.Layout[],
                },
                { name: EPropertyName.Columns, value: 12 },
            ],
            children: [{ type: EComponentTypeLayout.Stack }],
        },
        {
            name: LAN.ONE_TWO_ONE_LAYOUT.text,

            properties: [
                {
                    name: EPropertyName.Layout,
                    value: [
                        {
                            i: "griditem_121_1",
                            w: 3,
                            h: 10,
                            x: 0,
                            y: 0,
                            isResizable: false,
                            isDraggable: false,
                        },
                        {
                            i: "griditem_121_2",
                            w: 6,
                            h: 10,
                            x: 3,
                            y: 0,
                            isResizable: false,
                            isDraggable: false,
                        },
                        {
                            i: "griditem_121_3",
                            w: 3,
                            h: 10,
                            x: 9,
                            y: 0,
                            isResizable: false,
                            isDraggable: false,
                        },
                    ] as ReactGridLayout.Layout[],
                },
                { name: EPropertyName.Columns, value: 12 },
            ],
            children: deepCopyTimes<IDesignableComponentPresetChildren>(
                { type: EComponentTypeLayout.Stack },
                3,
            ),
        },
    ],
});

const AdvReactGridDesignable = {
    PropertyName: EPropertyName,
    PropertyCategory: EPropertyCategory,
    PresetName: EPresetName,
    Registered: registeredComponent,
};

export default AdvReactGridDesignable;

import { IDesignerComponent } from "@feature/Designer/types/designer";
import { DefaultValue, selectorFamily } from "recoil";

import { atomSelectedComponentKey, componentsAtomFamily, designerGetUniqueName } from "./atoms";

/**
 * Aktuell ausgewählte ``DesignerComponent``, abhängig von ``atomSelectedComponentKey``
 * @supports GET & SET
 */
const selectedComponentSelector = selectorFamily<IDesignerComponent, string>({
    key: "uiDesignerSelectedComponent",
    get:
        (activeFileName: string) =>
        ({ get }) => {
            const selectedComponentKey = get(atomSelectedComponentKey(activeFileName));
            const uniqueName = designerGetUniqueName(activeFileName, selectedComponentKey);
            const selectedComponentState = componentsAtomFamily(uniqueName);
            const selectedComponent = get(selectedComponentState);
            return selectedComponent;
        },
    set:
        (activeFileName: string) =>
        ({ set }, newValue) => {
            if (newValue instanceof DefaultValue) return;
            set(atomSelectedComponentKey(activeFileName), newValue.key);

            const uniqueName = designerGetUniqueName(activeFileName, newValue.key);
            const componentState = componentsAtomFamily(uniqueName);
            set(componentState, newValue);
        },
});

export { selectedComponentSelector };

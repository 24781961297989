import { TAdvDesignerComponentProps } from "@feature/Designer/types/component-props";
import {
    IPivotProps,
    IPivotStyleProps,
    IPivotStyles,
    IStyleFunctionOrObject,
    Pivot,
} from "@fluentui/react";
import useAdvComponent from "@hooks/useAdvComponent";
import { deepCompareJSXProps } from "@utils/deep-compare";
import React from "react";
import AdvStack from "../stack";

export type TAdvTabControlStyles = IPivotStyles; /* do not change */
export type TAdvTabControlStyleProps = IPivotStyleProps; /* do not change */

export type TAdvTabControlProps = Omit<IPivotProps, "styles"> &
    TAdvDesignerComponentProps & {
        styles?: IStyleFunctionOrObject<TAdvTabControlStyleProps, TAdvTabControlStyles>;
    };
/**
 * @summary Wrapper für ``Pivot``
 * @important AdvTab
 * @link https://developer.microsoft.com/en-us/fluentui#/controls/web/pirvot
 */
const AdvTabControlComp = ({
    headersOnly,
    styles,
    designerProps,
    ...props
}: TAdvTabControlProps) => {
    useAdvComponent(AdvTabControlComp, props);

    // workaround bcs the fluent ui's control boxes use ::after ::before which increases the elements size, without increasing the parent's size
    // => scrollbars get visible
    if (headersOnly === true) {
        return (
            <AdvStack styles={{ root: { padding: 3 } }}>
                <Pivot {...props} {...designerProps} styles={styles} headersOnly={headersOnly} />
            </AdvStack>
        );
    } else {
        return <Pivot {...props} {...designerProps} styles={styles} headersOnly={headersOnly} />;
    }
};

const AdvTabControl = React.memo(AdvTabControlComp, deepCompareJSXProps);
export default AdvTabControl;

import "./designable";

import { IStackItemStyles, IStackItemTokens } from "@fluentui/react";

const stackItemTokens: IStackItemTokens = {};

const stackItemStyles: IStackItemStyles = {
    root: {},
};

const stackItemDesignerStyles: IStackItemStyles = {
    root: {
        minHeight: 24,
    },
};

export const defaultStackItemTokens = {
    normal: stackItemTokens,
};

export const defaultStackItemStyles = {
    // Eingebunden in themes/default.ts
    normal: stackItemStyles,
    designer: stackItemDesignerStyles,
};

import {
    IStyleFunctionOrObject,
    IToggleProps,
    IToggleStyleProps,
    IToggleStyles,
    Toggle,
} from "@fluentui/react";
import { TAdvTranslationText, useT } from "@hooks/language/useTranslation";
import useAdvComponent from "@hooks/useAdvComponent";
import useAdvTheme from "@hooks/useAdvTheme";
import { useAdvValue } from "@hooks/useAdvValue";
import { deepCompareJSXProps } from "@utils/deep-compare";
import { combineStyles } from "@utils/styling";
import React, { useMemo } from "react";

import { TCommonValueProps } from "..";

export type TAdvToggleStyles = IToggleStyles; /* do not change */
export type TAdvToggleStyleProps = IToggleStyleProps; /* do not change */

export type TAdvToggleProps = Omit<
    IToggleProps,
    "styles" | "label" | "onChange" | "checked" | "defaultValue" | "onText" | "offText"
> &
    TCommonValueProps<boolean> & {
        styles?: IStyleFunctionOrObject<TAdvToggleStyleProps, TAdvToggleStyles>;
        label: string;
        ignoreTranslation?: boolean;

        onText?: string;
        onTextIgnoreTranslation?: boolean;
        offText?: string;
        offTextIgnoreTranslation?: boolean;

        /**
         * Zeigt ein Infoicon, welches detalierte Informationen gibt, sobald der User mit dem Cursor drüber geht
         */
        info?: TAdvTranslationText;
    };
/**
 * @summary Wrapper für ``Toggle``
 * @link https://developer.microsoft.com/en-us/fluentui#/controls/web/toggle
 */
const AdvToggleComp = ({
    styles,
    label,
    value,
    onValueChanged,
    inlineLabel = true,
    ignoreTranslation,
    onTextIgnoreTranslation,
    offTextIgnoreTranslation,
    onText,
    offText,
    ...props
}: TAdvToggleProps) => {
    useAdvComponent(AdvToggleComp, props);

    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { internalValue, onInternalChange } = useAdvValue(value, onValueChanged);

    const { t, hasErr } = useT(label, ignoreTranslation);
    const { t: translatedOnText } = useT(onText, onTextIgnoreTranslation);
    const { t: translatedOffText } = useT(offText, offTextIgnoreTranslation);

    const theme = useAdvTheme();

    const myStyles = useMemo(() => {
        if (hasErr == false) return styles;
        return combineStyles(styles, { label: { ...theme.custom.textNotTranslated } });
    }, [hasErr, styles, theme.custom.textNotTranslated]);

    return (
        <Toggle
            {...props}
            label={t}
            inlineLabel={inlineLabel}
            checked={internalValue}
            onChange={onInternalChange}
            styles={myStyles}
            onText={translatedOnText}
            offText={translatedOffText}
        />
    );
};

const AdvToggle = React.memo(AdvToggleComp, deepCompareJSXProps);
export default AdvToggle;

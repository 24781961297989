import { gThemeFilePrefix } from "@data/designer/file";
import { recoilKeystorage, TKeyStorageBaseItem } from "@data/key-storage";
import { TDictionaryValue } from "@data/persist/dictionary-value";
import { ResourceStorageDefault, TResourceStorageKey } from "@data/resource-storage";
import { IPalette, ISpacing } from "@fluentui/react";
import deepCopy from "@utils/deep-copy";
import { selector, selectorFamily } from "recoil";

export type TAdvThemeIconType = "solid" | "thin" | "duotone" | "regular" | "light";
export type TAdvThemeIcon = {
    type: TAdvThemeIconType;
    color: keyof IPalette;
    colorSecondary: keyof IPalette;
    hoverColor: keyof IPalette;
    hoverColorSecondary: keyof IPalette;
    disabledColor: keyof IPalette;
    disabledColorSecondary: keyof IPalette;
};

export const DefaultThemeIcon: TAdvThemeIcon = {
    type: "solid",
    color: "neutralPrimary",
    colorSecondary: "neutralSecondary",
    hoverColor: "neutralPrimary",
    hoverColorSecondary: "neutralSecondary",
    disabledColor: "neutralPrimary",
    disabledColorSecondary: "neutralSecondary",
};

export type TAdvPaletteExt = {
    alternativeWhite: string;
};

export type TAdvPalette = IPalette & TAdvPaletteExt;

export type TAdvTheme = {
    palette: TAdvPalette;
    spacing: ISpacing;
    isInverted: boolean;

    icon: TAdvThemeIcon;
};

export type TAdvThemeLogo = {
    backgroundLogin: TResourceStorageKey;
    logoLogin: TResourceStorageKey;
    logoTopBar: TResourceStorageKey;
};

export const DefaultThemeLogo = {
    backgroundLogin: deepCopy(ResourceStorageDefault),
    logoLogin: deepCopy(ResourceStorageDefault),
    logoTopBar: deepCopy(ResourceStorageDefault),
};

export type TAdvThemeStorage = {
    topBar: TAdvTheme;
    main: TAdvTheme;
    nav: TAdvTheme;
    logo: TAdvThemeLogo;
};

export const gThemeFileListSelector = selector<TDictionaryValue<TKeyStorageBaseItem>[]>({
    key: "designer_themeFileListSelector",
    get: ({ get }) => {
        const temp: TDictionaryValue<TKeyStorageBaseItem>[] = [];
        const keys = deepCopy(get(recoilKeystorage(true).Keys)).filter((val) =>
            val.startsWith(gThemeFilePrefix),
        );
        if (!keys.includes(gThemeFilePrefix + "dark"))
            temp.push(
                new TDictionaryValue({
                    aVal: {
                        Name: gThemeFilePrefix + "dark",
                        Additional: [],
                        Value: "",
                    },
                }),
            );
        if (!keys.includes(gThemeFilePrefix + "light"))
            temp.push(
                new TDictionaryValue({
                    aVal: {
                        Name: gThemeFilePrefix + "light",
                        Additional: [],
                        Value: "",
                    },
                }),
            );
        for (const key of keys) {
            const theme = get(recoilKeystorage(true).baseDictionary.values(key));
            if (theme.IsLoaded()) temp.push(theme);
        }
        return temp.sort((val1, val2) => val1.Get().Name.localeCompare(val2.Get().Name));
    },
});

export const gThemeFileKeysSelector = selectorFamily<string[], boolean>({
    key: "designer_themeFileKeysSelector",
    get:
        (filterDisabledThemes) =>
        ({ get }) => {
            const keys = deepCopy(get(recoilKeystorage(true).Keys)).filter((val) =>
                val.startsWith(gThemeFilePrefix),
            );
            if (!keys.includes(gThemeFilePrefix + "dark")) keys.push(gThemeFilePrefix + "dark");
            if (!keys.includes(gThemeFilePrefix + "light")) keys.push(gThemeFilePrefix + "light");
            if (!filterDisabledThemes) {
                return keys;
            } else {
                return keys.filter((val) => {
                    const valBase = get(recoilKeystorage(true).baseDictionary.values(val));
                    return (
                        (valBase.IsLoaded() && !valBase.Get().Additional.includes("disabled")) ||
                        (!valBase.IsLoaded() && val == gThemeFilePrefix + "dark") ||
                        (!valBase.IsLoaded() && val == gThemeFilePrefix + "light")
                    );
                });
            }
        },
});

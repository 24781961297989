import { useAdvCallback } from "@hooks/react-overload/useAdvCallback";
import { DependencyList, useMemo, useRef, useState } from "react";

// Wurde eingebaut wg. useMemo + useState = 2 Render oder so

export type TSetterFunc<T> = (old: T) => T;
/**
 * Ein Hook, der wie useState funktioniert, nur kein useEffect benutzt, und damit eher ausgeführt wird als nach dem Rendern.
 * @param factory Die Factory, die den Wert erstellt
 * @param deps Die optionalen Dependencies
 */
export function useAdvMemoWithUpdater<
    TValue extends object | number | boolean | string | undefined,
>(
    factory: (version: number) => TValue,
    deps: DependencyList,
): [TValue, () => void, (newVal: TValue | TSetterFunc<TValue>) => void, number] {
    const [isUseInternalValue, setIsUseInternalValue] = useState(-1);
    const customOverwrite = useRef<TValue>();
    const lastKnownVal = useRef<TValue>();

    // use don't need the value, we just need another memo that executes before our actual memo
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const internValChanged = useMemo(() => {
        customOverwrite.current = undefined;
        // intentionally disable eslint here, we want the rehook because of the dependencies
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [...deps]);

    const internalValue = useMemo<TValue>(() => {
        const ret =
            customOverwrite.current == undefined
                ? factory(isUseInternalValue)
                : customOverwrite.current;
        lastKnownVal.current = ret;
        return ret;
        // intentionally disable eslint here, we want the rehook because of the dependencies
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [...deps, isUseInternalValue]);

    const updateInternalValue = useAdvCallback(() => {
        setIsUseInternalValue((oldval) => oldval + 1);
    }, []);

    const setInternalValue = useAdvCallback((newVal: TValue | TSetterFunc<TValue>) => {
        if (typeof newVal == "function") {
            if (lastKnownVal.current != undefined)
                customOverwrite.current = newVal(lastKnownVal.current);
        } else {
            customOverwrite.current = newVal;
        }
        lastKnownVal.current = customOverwrite.current;
        setIsUseInternalValue((oldval) => oldval + 1);
    }, []);

    return [internalValue, updateInternalValue, setInternalValue, isUseInternalValue];
}

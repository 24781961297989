import { cacheGet, cacheSet } from "@data/cache/cache";
import { sessionAddInfosAtom } from "@data/session";
import { setLanguage } from "@fluentui/react";
import { useAdvCallback } from "@hooks/react-overload/useAdvCallback";
import { useAdvEffect } from "@hooks/react-overload/useAdvEffect";
import useAdvRecoilState from "@hooks/recoil-overload/useAdvRecoilState";
import useAdvRecoilValue from "@hooks/recoil-overload/useAdvRecoilValue";
import useAdvSetRecoilState from "@hooks/recoil-overload/useAdvSetRecoilState";
import { useAdvSocketCallback } from "@hooks/useAdvSocketCallback";
import { useWaitForLogin } from "@hooks/useWaitForLogin";
import { advcatch } from "@utils/logging";
import { getHook } from "@utils/react-hooks-outside";
import { useMemo, useState } from "react";
import { atom, selector } from "recoil";

export const LAN_GERMAN = -1;
export const LAN_ENGLISH = 1;
// export const LAN_DEFAULT = { ID: LAN_GERMAN, ISO: "de_DE", Language: "German (Fallback)" };
export const LAN_DEFAULT = { ID: LAN_ENGLISH, ISO: "en_GB", Language: "English (Fallback)" };
export const LAN_FALLBACK = LAN_DEFAULT.ID;

type TLanguage = {
    ID: number;
    Language: string;
    ISO: string;
};

type TLanguages = {
    Languages: TLanguage[];
};

/** Die vom Benutzer ausgewählte Sprache */
const gLanguageAtom = atom<TLanguage | undefined>({
    key: "global_language",
    default: undefined,
});

/** Alle verfügbaren Sprachen (enthält mindestens die LAN_DEFAULT) */
const gLanguagesAtom = atom<TLanguage[]>({
    key: "global_languagesAtom",
    default: undefined,
    effects: [
        ({ node, trigger, setSelf, getPromise }) => {
            const cacheKey = "advlan_languages";
            async function loadTranslation() {
                const thisVal = await getPromise(node);
                if (thisVal === undefined || thisVal.length == 0) {
                    const socket = await getHook("useAdvSocketCallback");
                    const res = await socket.sendCallbackRequest<{}, TLanguages>(
                        "Language",
                        "GetLanguages",
                        {},
                        true,
                    );
                    const item: TLanguage[] = res.Languages ?? [];
                    setSelf(item);
                    await cacheSet(cacheKey, item, 1000 * 60 * 60 * 24 * 31 /* 31 days cache */);
                }
            }

            if (trigger == "get") {
                const loadItem = async () => {
                    const { item: itemLang } = await cacheGet<TLanguage[]>(cacheKey);
                    if (itemLang == null || itemLang.length == 0) {
                        await loadTranslation();
                    } else {
                        setSelf(itemLang);
                    }
                };
                loadItem().catch((r) => advcatch("Could not load languages: ", r));
            }
        },
    ],
});

/** Selector für die aktuelle Sprache, die abhängig vom gLanguageAtom oder gLanguagesAtom + Browsersprache ist */
const gLanguageSelector = selector<TLanguage>({
    key: "gCurrentLangauge",
    get: ({ get }) => {
        const currentLanguageValue = get(gLanguageAtom);
        if (currentLanguageValue === undefined) {
            const currentLanguagesValue = get(gLanguagesAtom);
            if (currentLanguagesValue === undefined || currentLanguagesValue.length == 0) {
                return LAN_DEFAULT;
            } else {
                return getBrowserLanguage(currentLanguagesValue) ?? LAN_DEFAULT;
            }
        } else {
            return currentLanguageValue;
        }
    },
});

/** de_DE -> de-de (BCP 47) */
export function normalizeISO(iso: string) {
    return iso.replace("_", "-").toLowerCase();
}

/**
 * Ermittelt die zum ISO-Code passende Sprache aus den Languages.
 */
function getLanguageByISO(languages: TLanguage[], iso: string) {
    // Zuerst prüfen, ob ein exakt identischer ISO-Code vorhanden ist
    const exact = languages.find((lang) => normalizeISO(lang.ISO) == normalizeISO(iso));
    if (exact !== undefined) return exact;

    // Alternativ nur den ersten Teil des ISO Codes prüfen ("fr", "de", ...)
    const partial = languages.find(
        (lang) => normalizeISO(lang.ISO).substring(0, 2) == normalizeISO(iso).substring(0, 2),
    );
    if (partial !== undefined) return partial;

    return undefined;
}

function getBrowserLanguage(languages: TLanguage[]) {
    return getLanguageByISO(languages, navigator.language);
}

type TAdvLanguages = {
    languages: TLanguage[];

    /** Browser-Sprache, sofern sie ermittelt werden konnte */
    browserLanguage: TLanguage | undefined;
    /** Aktuell genutzte Sprache. CurrentLanguage > BrowserLanguage > Default-Language (LAN_DEFAULT) */
    currentLanguage: TLanguage;

    /** Setzt die aktuelle Sprache und lädt ggf. die Seite neu */
    setCurrentLanguageById: (langId: number, reload?: boolean) => void;

    /** Setzt die aktuelle Sprache zurück. Siehe currentLanguage */
    resetCurrentLanguage: () => void;
    formatInteger: (value: any) => string;
};

export const useLanguage = (): TLanguage => {
    const currentLanguage = useAdvRecoilValue(gLanguageSelector);
    return currentLanguage ?? LAN_DEFAULT;
};

export const useLanguages = (): TAdvLanguages => {
    const internalLanguages: TLanguage[] | undefined = useAdvRecoilValue(gLanguagesAtom);
    const [session, setSession] = useAdvRecoilState(sessionAddInfosAtom);

    const languages: TLanguage[] = useMemo(() => {
        // Solange wir noch keine Sprachen vom Server geladen haben,
        // geben wir nur die Default-Sprache zur Auswahl
        if (internalLanguages === undefined || internalLanguages.length == 0) return [LAN_DEFAULT];
        else {
            return internalLanguages
                .filter((lang) => lang.ID >= LAN_GERMAN)
                .sort((a, b) => a.Language.localeCompare(b.Language));
        }
    }, [internalLanguages]);

    const browserLanguage: TLanguage | undefined = useMemo(() => {
        if (internalLanguages !== undefined) {
            const language = getBrowserLanguage(internalLanguages);
            return language;
        }
        return undefined;
    }, [internalLanguages]);

    const { isLoggedIn } = useWaitForLogin();

    const { sendCallbackRequest } = useAdvSocketCallback();

    const [curSetLangID, setCurSetLangID] = useState<undefined | number>(undefined);

    const setSelectedLanguage = useAdvSetRecoilState(gLanguageAtom);
    const setCurrentLanguageById = useAdvCallback((langId: number) => {
        setCurSetLangID(langId);
    }, []);

    const formatInteger = (value: any): string => {
        let isNumeric = false;
        const numberValue = value;
        if (value != "") {
            if (typeof value == "number") {
                isNumeric = true;
            }
        }

        if (typeof value != "undefined" && isNumeric) {
            const currentLocales = normalizeISO(currentLanguage.ISO); // => de-de, en-gb, nl-nl
            const formatter = new Intl.NumberFormat(currentLocales);
            value = formatter.format(Number(numberValue));
        }

        return value;
    };

    useAdvEffect(() => {
        if (curSetLangID != undefined) {
            const language = languages.find((lang) => lang.ID == curSetLangID);
            if (language !== undefined) {
                setCurSetLangID(undefined);
                setSelectedLanguage(language);
                setLanguage(language.ISO.replace("_", "-"), "none"); // Sprache der Seite setzen

                // SetLanguage brauchen wir nur aufrufen, wenn wir schon eingeloggt sind.
                // Wir schicken beim Login bereits die aktuelle Sprache mit.
                if (isLoggedIn()) {
                    sendCallbackRequest<{}, TLanguages>("Language", "SetLanguage", {
                        ID: language.ID,
                    })
                        .then(() => {
                            setSession((oldSession) => {
                                return { ...oldSession, LanguageID: language.ID };
                            });
                        })
                        .catch((r) => advcatch("Could not set language: ", r));
                }
            }
        }
    }, [curSetLangID, isLoggedIn, languages, sendCallbackRequest, setSelectedLanguage, setSession]);

    // Session.LanguageID mit CurrentLanguage syncrhon halten (wenn angemeldet)
    useAdvEffect(() => {
        if (isLoggedIn()) {
            const language = languages.find((lang) => lang.ID == session.LanguageID);
            if (language) setSelectedLanguage(language);
        }
    }, [isLoggedIn, languages, session.LanguageID, setSelectedLanguage]);

    const resetCurrentLanguage = useAdvCallback(() => {
        setSelectedLanguage(undefined);
    }, [setSelectedLanguage]);

    const currentLanguage = useAdvRecoilValue(gLanguageSelector);
    return {
        languages,
        browserLanguage,
        currentLanguage,
        setCurrentLanguageById,
        resetCurrentLanguage,
        formatInteger,
    };
};

import AdvText from "@components/data/text/text";
import AdvCalendar, {
    designableCalendarProps,
    TAdvCalendarProps,
} from "@components/inputs/calendar";
import AdvStack from "@components/layout/stack";
import AdvLoading, { EAdvLoadingMode } from "@components/other/loading";
import { LAN } from "@data/language/strings";
import { DefaultComponentCategory } from "@feature/Designer/types/category";
import { EComponentTypeCustom } from "@feature/Designer/types/component-type";
import { AdvProperty, registerDesignableComponent } from "@feature/Designer/utils";
import { DayOfWeek } from "@fluentui/react";
import {
    TAdvValueBindingParams,
    useAdvValueBinderAsArrayNoDataType,
} from "@hooks/dynamic/useAdvValueBinder";
import { toAdvText } from "@hooks/language/useTranslation";
import { useAdvObjMemo } from "@hooks/useAdvObjMemo";
import useAdvTheme from "@hooks/useAdvTheme";
import { CalendarIcon } from "@themes/icons";
import { EAdvValueDataTypes } from "@utils/data-types";
import { CompareDatesUTC, UnixToDateTime } from "@utils/date";
import { deepCompareJSXProps } from "@utils/deep-compare";
import React, { useMemo } from "react";

require("datejs");

export type TAdvOrderCalenderProps = TAdvCalendarProps & {
    // intentionally unused, only bindings are used
    dateList?: number[];
    dateListBindingParams?: TAdvValueBindingParams;
};
const AdvOrderCalendarImpl = ({
    dateListBindingParams,
    designerProps,
    ...props
}: TAdvOrderCalenderProps) => {
    const [unixDateList] = useAdvValueBinderAsArrayNoDataType(
        dateListBindingParams,
        [0],
        EAdvValueDataTypes.Number,
        false,
        0,
    );

    const validDays = useMemo(
        () => unixDateList.filter((u) => u > 0).map((v) => UnixToDateTime(v)),
        [unixDateList],
    );

    const minDate = useMemo(() => new Date(), []);
    const maxDate = useMemo(() => {
        if (validDays.length == 0) {
            // Standard: die nächste 3 Wochen
            return new Date(Date.today().next().week().next().week());
        } else {
            return validDays[validDays.length - 1];
        }
    }, [validDays]);

    const invalidDays = useMemo(() => {
        const res: Array<Date> = [];
        let currentDate = minDate;
        while (currentDate <= maxDate.clone().addDays(1)) {
            const validDay = validDays.find((d) => {
                return CompareDatesUTC(currentDate, d);
            });
            if (validDay === undefined) {
                res.push(currentDate);
            }
            currentDate = new Date(currentDate.clone().next().day());
        }
        return res;
    }, [minDate, maxDate, validDays]);

    const isLoading = useAdvObjMemo(() => {
        if (unixDateList.length == 1 && unixDateList[0] == 0 && designerProps === undefined)
            return true;
        return false;
    }, [designerProps, unixDateList]);

    const theme = useAdvTheme();

    return (
        <AdvLoading
            isLoading={isLoading}
            mode={EAdvLoadingMode.ShowLoadingAsOverlay}
            spinnerProps={{ label: "Warte auf Server..." }}
        >
            <AdvStack>
                {unixDateList.length > 0 ? null : (
                    <AdvText
                        label="Keine Bestellung möglich"
                        styles={{ root: { fontWeight: "bold", color: theme.palette.red } }}
                    />
                )}
                <AdvCalendar
                    {...props}
                    isMonthPickerVisible={false}
                    isDayPickerVisible={unixDateList.length > 0}
                    firstDayOfWeek={DayOfWeek.Monday}
                    showWeekNumbers={true}
                    showGoToToday={false}
                    minDate={minDate}
                    maxDate={maxDate}
                    restrictedDates={invalidDays}
                    validDays={validDays}
                ></AdvCalendar>
            </AdvStack>
        </AdvLoading>
    );
};
const AdvOrderCalendar = React.memo(AdvOrderCalendarImpl, deepCompareJSXProps);
export default AdvOrderCalendar;

const calendarProps = [
    ...designableCalendarProps,
    AdvProperty.Text.create(
        toAdvText(LAN.ORDER_DATE_LIST),
        "dateList",
        toAdvText(LAN.GENERAL),
        toAdvText(LAN.ORDER_DATE_LIST_DESCR),
        "",
    ),
];

registerDesignableComponent({
    staticData: {
        name: LAN.CALENDAR_ORDERS.text,

        type: EComponentTypeCustom.OrderCalendar,
        supportsChildren: false,
        category: DefaultComponentCategory.Misc,
        icon: CalendarIcon,
    },
    properties: calendarProps,
    propertiesBuilders: [],
    presets: [],
});

import AdvText from "@components/data/text";
import AdvLabel from "@components/data/text/label";
import AdvGenericDialog from "@components/dialogs/generic/generic";
import AdvActionBarPure from "@components/inputs/action-bar/action-bar-pure";
import { TAdvActionBarItemProps, TAdvActionBarProps } from "@components/inputs/action-bar/types";
import { AdvButtonPure } from "@components/inputs/button/button-pure";
import { TAdvButtonContextMenuProps } from "@components/inputs/button/types";
import AdvDropdown, { TAdvDropdownItem } from "@components/inputs/dropdown/dropdown";
import { AdvFileInput } from "@components/inputs/file/file-input";
import AdvTextInput from "@components/inputs/text-input";
import AdvCallout from "@components/layout/callout/callout";
import AdvGroupbox from "@components/layout/groupbox";
import { EAdvGroupboxCollapseDir } from "@components/layout/groupbox/types";
import AdvModal from "@components/layout/modal";
import AdvStack from "@components/layout/stack";
import AdvStackItem, { TAdvStackItemStyles } from "@components/layout/stack/stack-item";
import AdvIcon from "@components/other/icon";
import AdvMessageBar from "@components/other/message-bar";
import AdvSpinner, { EAdvSpinnerSize } from "@components/other/spinner";
import { recoilAction } from "@data/action";
import { replaceFilePrefix } from "@data/designer/file";
import { LAN } from "@data/language/strings";
import { TDictionaryValue } from "@data/persist/dictionary-value";
import { sessionAddInfosAtom } from "@data/session";
import recoilSettings from "@data/settings";
import {
    BaseButton,
    DirectionalHint,
    IButtonStyles,
    ITextProps,
    ITheme,
    MessageBarType,
} from "@fluentui/react";
import { useBoolean, useId } from "@fluentui/react-hooks";
import {
    designerSaveAllSelector,
    useUndoManagerDesigner,
    useUndoManagerFileList,
} from "@hooks/designer/useUndoManager";
import useAdvToast from "@hooks/dialogs/useAdvToast";
import { TAdvTranslationText, useT } from "@hooks/language/useTranslation";
import { useAdvCallback } from "@hooks/react-overload/useAdvCallback";
import { useAdvEffect } from "@hooks/react-overload/useAdvEffect";
import useAdvRecoilValue from "@hooks/recoil-overload/useAdvRecoilValue";
import { useAdvObjMemo } from "@hooks/useAdvObjMemo";
import useAdvTheme from "@hooks/useAdvTheme";
import {
    CloudImportExport,
    DeleteIcon,
    EditIcon,
    PageAddIcon,
    RedoIcon,
    SaveAll,
    ScrubberIcon,
    UndoIcon,
} from "@themes/icons";
import { deepCompareJSXProps } from "@utils/deep-compare";
import { advlog } from "@utils/logging";
import { str_format } from "@utils/string";
import React, { MemoExoticComponent, MouseEvent, useMemo, useRef, useState } from "react";
import { RecoilValueReadOnly, SerializableParam } from "recoil";

const IconWithActionLogic = ({
    actionName,
    iconName,
    onClick,
    contextMenuAs,
}: {
    actionName: string;
    iconName: string;
    onClick: () => void;
    contextMenuAs: (props: TAdvButtonContextMenuProps) => React.JSX.Element;
}) => {
    const session = useAdvRecoilValue(sessionAddInfosAtom);

    const buttonRef = useRef<any>(null);
    const [isContextMenuHidden, setIsContextMenuHidden] = useState(true);
    const handleContextMenuClick = useAdvCallback(
        (
            e: MouseEvent<
                | HTMLAnchorElement
                | HTMLButtonElement
                | HTMLDivElement
                | BaseButton
                | HTMLSpanElement
            >,
        ) => {
            buttonRef.current = e.target;
            setIsContextMenuHidden(false);
            e.preventDefault();
        },
        [],
    );
    const actionVal = useAdvRecoilValue(recoilAction.BaseActions(actionName));

    // eslint-disable-next-line @typescript-eslint/naming-convention
    const ContextMenuComp = contextMenuAs;

    return actionName == "" || actionVal.IsLoaded() ? (
        <>
            <AdvIcon
                style={{ marginLeft: "5px", cursor: "pointer", minWidth: 12, minHeight: 12 }}
                iconName={iconName}
                onClick={(e) => {
                    onClick();
                    e.stopPropagation();
                }}
                onContextMenu={
                    actionName != "" && session.CanEditPermissions === true
                        ? handleContextMenuClick
                        : undefined
                }
            ></AdvIcon>
            <ContextMenuComp
                buttonRef={buttonRef}
                isContextMenuHidden={isContextMenuHidden}
                setIsContextMenuHidden={setIsContextMenuHidden}
                webActionName={actionName}
            ></ContextMenuComp>
        </>
    ) : (
        <></>
    );
};

const stackItemStyle = (
    active: boolean,
    first: boolean,
    last: boolean,
    props: ITextProps,
    theme: ITheme,
): TAdvStackItemStyles => {
    return {
        root: {
            borderBottom: last ? "" : "1px solid " + theme.palette.black,
            borderTop: first ? "" : "1px solid " + theme.palette.black,
            backgroundColor: active ? theme.palette.themeLight : "",
            padding: 4,
            margin: 0,
            textAlign: "left",
            userSelect: "none",
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontSize: theme.fonts.large.fontSize,
        },
    };
};

type TFileListComponentWrapperProps<K extends SerializableParam> = {
    onClickFile: (newFileName: K) => void;
    onDeleteFile: (newFileName: K) => void;
    onEditFileName: (editFileName: K) => void;
    ActiveFile: boolean;
    IsLastFile: boolean;
    IsFirstFile: boolean;
    isSaving: boolean;
    fileName: { displayName: TAdvTranslationText; name: K };
    categoryName: string | undefined;
    prefix: string;
    fileIgnorePrefix: string;
    categoryIgnorePrefix: string;
    fileSplitChar?: string;
    fileTypeIcon: string;
    canEditOrDelete?: (aFileName: K) => { canEdit: boolean; canDelete: boolean };
    shouldSave?: boolean;

    requiresWritePermission?: boolean;
    writePermissionName?: string;
    contextMenuAs: (props: TAdvButtonContextMenuProps) => React.JSX.Element;
};

const FileListComponentWrapperComp = <K extends SerializableParam>({
    requiresWritePermission = false,
    writePermissionName,
    contextMenuAs,
    fileIgnorePrefix,
    categoryIgnorePrefix,
    fileSplitChar,
    isSaving,
    onEditFileName,
    fileName,
    canEditOrDelete,
    fileTypeIcon,
    shouldSave = false,
    ...props
}: TFileListComponentWrapperProps<K>) => {
    const theme = useAdvTheme();
    const { canEdit, canDelete } =
        canEditOrDelete == undefined
            ? { canEdit: true, canDelete: true }
            : canEditOrDelete(fileName.name as K);

    return (
        <AdvStackItem
            onClick={() => {
                props.onClickFile(fileName.name as K);
            }}
            styles={stackItemStyle.bind(
                null,
                props.ActiveFile,
                props.IsFirstFile,
                props.IsLastFile,
            )}
        >
            <AdvIcon
                style={{
                    marginRight: "5px",
                    width: 16,
                    height: 16,
                    color: theme.palette.themeSecondary,
                }}
                iconName={fileTypeIcon}
            ></AdvIcon>
            {shouldSave && (
                <AdvIcon
                    iconName={ScrubberIcon.iconName}
                    styles={{ root: { marginRight: 3 } }}
                ></AdvIcon>
            )}
            <AdvText
                style={{ flexGrow: 1 }}
                ignoreTranslation={fileName.displayName.ignoreTranslation}
            >
                {replaceFilePrefix(fileIgnorePrefix, fileName.displayName.text, fileSplitChar) +
                    (props.categoryName === undefined || props.categoryName == ""
                        ? ""
                        : ` [${props.categoryName?.replace(categoryIgnorePrefix, "")}]`)}
            </AdvText>
            {isSaving && (
                <AdvSpinner
                    style={{ marginLeft: "5px", cursor: "pointer" }}
                    size={EAdvSpinnerSize.xSmall}
                ></AdvSpinner>
            )}
            {canEdit && (
                <IconWithActionLogic
                    iconName={EditIcon.iconName}
                    actionName={
                        requiresWritePermission
                            ? writePermissionName ?? "writePermissionIsMissing"
                            : ""
                    }
                    onClick={() => {
                        onEditFileName(fileName.name as K);
                    }}
                    contextMenuAs={contextMenuAs}
                ></IconWithActionLogic>
            )}
            {canDelete && (
                <IconWithActionLogic
                    iconName={DeleteIcon.iconName}
                    actionName={
                        requiresWritePermission
                            ? writePermissionName ?? "writePermissionIsMissing"
                            : ""
                    }
                    onClick={() => {
                        props.onDeleteFile(fileName.name as K);
                    }}
                    contextMenuAs={contextMenuAs}
                ></IconWithActionLogic>
            )}
        </AdvStackItem>
    );
};
const FileListComponentWrapper = React.memo(
    FileListComponentWrapperComp,
    deepCompareJSXProps,
) as typeof FileListComponentWrapperComp;

const FileListComponentWrapperSimple = <K extends SerializableParam>({
    shouldSave = false,
    ...props
}: TFileListComponentWrapperProps<K>) => {
    return <FileListComponentWrapper shouldSave={shouldSave} {...props}></FileListComponentWrapper>;
};

const FileListComponentWrapperComplex = <K extends SerializableParam>({
    shouldSave = false,
    prefix,
    fileName,
    ...props
}: TFileListComponentWrapperProps<K>) => {
    const { shouldSaveUM } = useUndoManagerDesigner(prefix, fileName.name as string);
    return (
        <FileListComponentWrapper
            {...props}
            shouldSave={shouldSaveUM || shouldSave}
            prefix={prefix}
            fileName={fileName}
        ></FileListComponentWrapper>
    );
};

type TFileListComponentProps<T, K extends SerializableParam> = {
    onFileClick: (newFileName: K) => void;
    onDeleteClick: (newFileName: K) => void;
    onNewClick: (aNewName: K) => void;
    checkNewName: (aNewName: K) => boolean;
    newExtraText?: string;
    onEditFile: (oldName: K, aRenameName: K) => void;
    onCopyFile: (fileName: K) => void;
    checkRenameName: (aNewName: K) => boolean;
    label: string;
    prefix: string;
    activeFileName: K;
    generalFilePrefix: string;
    categoryIgnorePrefix: string;
    fileSplitChar?: string;
    illegalFileChar?: string;
    fileBeforeSplitStr?: string;
    canUndo?: boolean;
    canRedo?: boolean;
    canSaveAll?: boolean;
    // default false
    canExportImport?: boolean;
    onExportImport?: (
        importJSONString?: string,
        shouldDownload?: boolean,
        shouldDelete?: boolean,
        downloadSpecific?: string,
    ) => void;

    emptyFileName: K;
    canAddNew?: boolean;
    addNewDialogOverload?: {
        addNewDialogContent: (
            setError: React.Dispatch<React.SetStateAction<number>>,
            setName: React.Dispatch<React.SetStateAction<K>>,
        ) => React.JSX.Element;
        onClose: (wasContinue: boolean, create: (aNewName: K | undefined) => void) => void;
    };

    canCollapse?: boolean;
    onSaveAllFile?: (aNameList: string[]) => void;
    fileList: RecoilValueReadOnly<TDictionaryValue<T>[]>;
    getFileProps: (aFile: T) => {
        name: K;
        category?: string;
        displayName: TAdvTranslationText;
    };
    fileTypeName: string;
    fileTypeIcon: string;
    onRenderRename?: () => React.JSX.Element;
    canEditOrDelete?: (aFileName: K) => { canEdit: boolean; canDelete: boolean };
    hasUndoManager: boolean;

    canUndoUM?: boolean;
    canRedoUM?: boolean;
    umUndo?: () => void;
    umRedo?: () => void;

    children: React.JSX.Element | React.JSX.Element[] | undefined;

    // permission related, if you provide one, provide all
    requiresWritePermission?: boolean;
    writePermissionName?: string;
    actionBarAs?:
        | ((props: TAdvActionBarProps) => React.JSX.Element)
        | MemoExoticComponent<({ ...props }: TAdvActionBarProps) => React.JSX.Element>;
    contextMenuAs?: (props: TAdvButtonContextMenuProps) => React.JSX.Element;
};

const AdvDesignerFileListComponentsImpl = function <T, K extends SerializableParam>({
    prefix,
    generalFilePrefix,
    categoryIgnorePrefix,
    fileSplitChar,
    illegalFileChar,
    fileBeforeSplitStr,
    onNewClick,
    checkNewName,
    newExtraText,
    onEditFile,
    onCopyFile,
    checkRenameName,
    activeFileName,
    fileList,
    canUndo = true,
    canRedo = true,
    canSaveAll = true,
    canExportImport = false,
    onExportImport,

    canAddNew = true,
    addNewDialogOverload,
    emptyFileName,

    canCollapse = true,
    label,
    onSaveAllFile,
    getFileProps,
    fileTypeName,
    fileTypeIcon,
    onRenderRename,
    hasUndoManager,
    canEditOrDelete,
    canUndoUM = false,
    canRedoUM = false,
    umRedo = () => {
        /**/
    },
    umUndo = () => {
        /**/
    },
    children,
    requiresWritePermission = false,
    writePermissionName,
    actionBarAs = AdvActionBarPure,
    contextMenuAs = () => {
        return <></>;
    },
    ...props
}: TFileListComponentProps<T, K>) {
    const theme = useAdvTheme();

    const designerFileBases = useAdvRecoilValue(fileList);
    const selectorNames = useMemo(
        () => designerFileBases.map((val) => getFileProps(val.Get()).name),
        [designerFileBases, getFileProps],
    );

    const [isNewDialogOpen, setIsNewDialogOpen] = useState(false);
    const [isNewDialogErr, setIsNewDialogErr] = useState(0);
    const [newDialogName, setNewDialogName] = useState<K>(emptyFileName);

    const [isRenameDialogOpen, setIsRenameDialogOpen] = useState(false);
    const [isRenameDialogErr, setIsRenameDialogErr] = useState(0);
    const [renameDialogFileName, setRenameDialogFileName] = useState<K>(emptyFileName);
    const [renameDialogName, setRenameDialogName] = useState<K>(emptyFileName);

    const shouldSaveAllNameListReal = useAdvRecoilValue(
        designerSaveAllSelector({
            prefix: prefix,
            names: selectorNames,
        }),
    );

    const shouldSaveAllNameList: any[] = useAdvObjMemo(
        () => shouldSaveAllNameListReal,
        [shouldSaveAllNameListReal],
    );

    const [isExportImportModalOpen, setExportImportModalOpen] = useState(false);

    // auto save
    const { showInfo } = useAdvToast();
    const { t: textAutoSave } = useT(LAN.AUTOSAVED.text);
    const autoSaveInterval = useAdvRecoilValue(recoilSettings.Designer.autoSaveIntervalTimeInMS);
    const autoSaveTime = useRef(Date.now());
    useAdvEffect(() => {
        const saveInterval = autoSaveInterval.IsLoaded() ? autoSaveInterval.Get().Value : 60000;
        const timeNow = Date.now();
        let timeDiff = timeNow - autoSaveTime.current;
        autoSaveTime.current = timeNow;
        if (timeDiff >= saveInterval && shouldSaveAllNameList.length > 0) {
            advlog("Auto-Save");
            if (onSaveAllFile != undefined) onSaveAllFile(shouldSaveAllNameList);
            showInfo(textAutoSave);
            timeDiff = 0;
        }
        const aTimeout: { val: any } = { val: undefined };
        const autoSave = () => {
            aTimeout.val = setTimeout(() => autoSave(), saveInterval);
            autoSaveTime.current = Date.now();
            if (shouldSaveAllNameList.length > 0) {
                if (onSaveAllFile != undefined) onSaveAllFile(shouldSaveAllNameList);
                showInfo(textAutoSave);
            }
        };
        aTimeout.val = window.setTimeout(() => autoSave(), saveInterval - timeDiff);
        return () => {
            clearTimeout(aTimeout.val);
        };
    }, [onSaveAllFile, autoSaveInterval, shouldSaveAllNameList, showInfo, textAutoSave]);

    const buttonStyles = useMemo<IButtonStyles>(() => {
        return {
            root: { padding: 0, margin: 0, minWidth: 32, minHeight: 32 },
            flexContainer: { padding: 0, margin: 0 },
            menuIcon: { margin: 0, padding: 0 },
            icon: { margin: 0, padding: 0 },
        };
    }, []);

    const actionBarItems = useAdvObjMemo<TAdvActionBarItemProps[]>(() => {
        const res: TAdvActionBarItemProps[] = [];
        if (canAddNew) {
            res.push({
                key: "new",
                iconProps: PageAddIcon,
                disabled: false,
                iconOnly: true,
                buttonStyles: buttonStyles,
                onClick: () => {
                    setIsNewDialogOpen(true);
                    setIsNewDialogErr(0);
                    setNewDialogName(emptyFileName);
                },
                actionName: requiresWritePermission ? writePermissionName : undefined,
            });
        }
        if (canUndo) {
            res.push({
                key: "undo",
                iconProps: UndoIcon,
                disabled: !canUndoUM,
                iconOnly: true,
                buttonStyles: buttonStyles,
                onClick: () => {
                    umUndo();
                },
                actionName: requiresWritePermission ? writePermissionName : undefined,
            });
        }
        if (canRedo) {
            res.push({
                key: "redo",
                iconProps: RedoIcon,
                disabled: !canRedoUM,
                iconOnly: true,
                buttonStyles: buttonStyles,
                onClick: () => {
                    umRedo();
                },
                actionName: requiresWritePermission ? writePermissionName : undefined,
            });
        }
        if (canSaveAll) {
            res.push({
                key: "saveall",
                iconProps: SaveAll,
                disabled: shouldSaveAllNameList.length == 0,
                iconOnly: true,
                buttonStyles: buttonStyles,
                onClick: () => {
                    autoSaveTime.current = Date.now();
                    if (shouldSaveAllNameList.length > 0 && onSaveAllFile != undefined)
                        onSaveAllFile(shouldSaveAllNameList);
                },
                actionName: requiresWritePermission ? writePermissionName : undefined,
            });
        }
        if (canExportImport) {
            res.push({
                key: "exportimport",
                iconProps: CloudImportExport,
                disabled: false,
                iconOnly: true,
                buttonStyles: buttonStyles,
                onClick: () => {
                    setExportImportModalOpen(true);
                },
                actionName: writePermissionName,
            });
        }
        return res;
    }, [
        buttonStyles,
        canAddNew,
        canExportImport,
        canRedo,
        canRedoUM,
        canSaveAll,
        canUndo,
        canUndoUM,
        emptyFileName,
        onSaveAllFile,
        requiresWritePermission,
        shouldSaveAllNameList,
        umRedo,
        umUndo,
        writePermissionName,
    ]);

    // eslint-disable-next-line @typescript-eslint/naming-convention
    const FileListItemWrapper = hasUndoManager
        ? FileListComponentWrapperComplex
        : FileListComponentWrapperSimple;

    // eslint-disable-next-line @typescript-eslint/naming-convention
    const ActionBarComp = actionBarAs;

    const [isGroupBoxOpen, setIsGroupBoxOpen] = useState(true);

    const { t: createNew } = useT(LAN.CREATE_FILE.text);
    const { t: renameNew } = useT(LAN.RENAME_FILE.text);

    const newFileDialogContent = useMemo(() => {
        if (addNewDialogOverload != undefined) {
            return addNewDialogOverload.addNewDialogContent(setIsNewDialogErr, setNewDialogName);
        }
        return (
            <AdvStack grow>
                {newExtraText != undefined ? (
                    <AdvStackItem>
                        <AdvLabel>{newExtraText}</AdvLabel>
                    </AdvStackItem>
                ) : (
                    <></>
                )}
                <AdvStackItem>
                    <AdvTextInput
                        label={LAN.INPUT_A_NAME.text}
                        key={"adv-filelist-newnamekey"}
                        onValueChanged={(aNewName?: string) => {
                            if (aNewName != undefined) {
                                const nameToCheck =
                                    generalFilePrefix +
                                    (fileSplitChar != undefined
                                        ? (fileBeforeSplitStr ?? "") + fileSplitChar
                                        : "") +
                                    aNewName;
                                const hasIllegalChar =
                                    illegalFileChar != undefined &&
                                    aNewName.includes(illegalFileChar);
                                if (!checkNewName(nameToCheck as K) || hasIllegalChar) {
                                    setIsNewDialogErr(hasIllegalChar ? 2 : 1);
                                } else {
                                    setIsNewDialogErr(0);
                                }
                                setNewDialogName(aNewName as K);
                            }
                        }}
                    ></AdvTextInput>
                    <>
                        {isNewDialogErr == 1 && (
                            <AdvMessageBar type={MessageBarType.blocked}>
                                {newDialogName == ""
                                    ? "Empty names are not allowed"
                                    : "A " + fileTypeName + " with that name already exists"}
                            </AdvMessageBar>
                        )}
                        {isNewDialogErr == 2 && (
                            <AdvMessageBar type={MessageBarType.blocked}>
                                {'Name contains illegal character "' +
                                    (illegalFileChar ?? "") +
                                    '"'}
                            </AdvMessageBar>
                        )}
                    </>
                </AdvStackItem>
            </AdvStack>
        );
    }, [
        addNewDialogOverload,
        checkNewName,
        fileBeforeSplitStr,
        fileSplitChar,
        fileTypeName,
        generalFilePrefix,
        illegalFileChar,
        isNewDialogErr,
        newDialogName,
        newExtraText,
    ]);

    const [
        isExportSingleOpen,
        { toggle: toggleIsExportSingleOpen, setFalse: closeIsExportSingleOpen },
    ] = useBoolean(false);
    const id = useId("callout");
    const [curExportSingleItem, setCurExportSingleItem] = useState();
    const exportSingleOptions = useMemo(() => {
        return designerFileBases.map<TAdvDropdownItem<any>>((o, oIndex) => {
            return {
                key: oIndex.toString(),
                text: o.IsLoaded() ? getFileProps(o.Get()).displayName.text : "Loading...",
                data: o.IsLoaded() ? getFileProps(o.Get()).name?.toString() : "",
            };
        });
    }, [designerFileBases, getFileProps]);

    const onCloseNewDialog = useAdvCallback(
        (wasContinue: boolean) => {
            if (addNewDialogOverload != undefined)
                addNewDialogOverload.onClose(wasContinue, (aNewName: K | undefined) => {
                    if (aNewName != undefined) {
                        onNewClick(aNewName);
                    }
                    setIsNewDialogOpen(false);
                });
            else {
                const newDSName =
                    generalFilePrefix +
                    (fileSplitChar != undefined ? (fileBeforeSplitStr ?? "") + fileSplitChar : "") +
                    (newDialogName as string);
                const hasIllegalChar =
                    illegalFileChar != undefined &&
                    (newDialogName as string).includes(illegalFileChar);
                const hasErr = !checkNewName(newDSName as K) || hasIllegalChar;
                if (hasErr) {
                    if (wasContinue) setIsNewDialogErr(hasIllegalChar ? 2 : 1);
                } else {
                    if (wasContinue && !isNewDialogErr) onNewClick(newDSName as K);
                }
                if (!wasContinue || (!isNewDialogErr && !hasErr)) setIsNewDialogOpen(false);
            }
        },
        [
            addNewDialogOverload,
            checkNewName,
            fileBeforeSplitStr,
            fileSplitChar,
            generalFilePrefix,
            illegalFileChar,
            isNewDialogErr,
            newDialogName,
            onNewClick,
        ],
    );

    const onCloseRenameDialog = useAdvCallback(
        (wasContinue: boolean) => {
            const renameDSName =
                generalFilePrefix +
                (fileSplitChar != undefined ? (fileBeforeSplitStr ?? "") + fileSplitChar : "") +
                (renameDialogName as string);
            const hasIllegalChar =
                illegalFileChar != undefined &&
                (renameDialogName as string).includes(illegalFileChar);
            const hasErr = !checkRenameName(renameDSName as K) || hasIllegalChar;
            if (hasErr) {
                if (wasContinue) setIsRenameDialogErr(hasIllegalChar ? 2 : 1);
            } else {
                if (wasContinue && !isRenameDialogErr)
                    onEditFile(renameDialogFileName as K, renameDSName as K);
            }
            if (!wasContinue || (!isRenameDialogErr && !hasErr)) setIsRenameDialogOpen(false);
        },
        [
            checkRenameName,
            fileBeforeSplitStr,
            fileSplitChar,
            generalFilePrefix,
            illegalFileChar,
            isRenameDialogErr,
            onEditFile,
            renameDialogFileName,
            renameDialogName,
        ],
    );

    const onCloseExportImportModal = useAdvCallback(() => setExportImportModalOpen(false), []);

    const downloadExportClick = useAdvCallback(() => {
        if (onExportImport != undefined) onExportImport(undefined, true, undefined);
    }, [onExportImport]);

    const onFileImport = useAdvCallback(
        (fileBuffer: ArrayBuffer, fileType: string) => {
            if (fileType == "application/json") {
                const jsonStr = new TextDecoder().decode(new Uint8Array(fileBuffer));
                if (onExportImport != undefined) onExportImport(jsonStr);
            } else {
                alert("File type must be JSON, but was " + fileType + " instead.");
            }
        },
        [onExportImport],
    );

    const downloadExportDeleteClick = useAdvCallback(() => {
        if (onExportImport != undefined) onExportImport(undefined, true, true);
    }, [onExportImport]);

    const onEditFileName = useAdvCallback((aFileName: K) => {
        setRenameDialogName(aFileName);
        setRenameDialogFileName(aFileName);
        setIsRenameDialogErr(0);
        setIsRenameDialogOpen(true);
    }, []);

    const outerStackStyles = useMemo(() => {
        return { root: { padding: 3 } };
    }, []);

    const groupboxStackItemStyles = useMemo(() => {
        return {
            root: {
                minWidth: isGroupBoxOpen ? 200 : 0,
                borderRight: "1px solid " + theme.palette.neutralLight,
            },
        };
    }, [isGroupBoxOpen, theme.palette.neutralLight]);

    const onRenameChangeValue = useAdvCallback(
        (aRenameName?: string) => {
            if (aRenameName != undefined) {
                const nameToCheck =
                    generalFilePrefix +
                    (fileSplitChar != undefined ? (fileBeforeSplitStr ?? "") + fileSplitChar : "") +
                    aRenameName;
                const hasIllegalChar =
                    illegalFileChar != undefined && aRenameName.includes(illegalFileChar);
                if (!checkRenameName(nameToCheck as K) || hasIllegalChar) {
                    setIsRenameDialogErr(hasIllegalChar ? 2 : 1);
                } else {
                    setIsRenameDialogErr(0);
                }
                setRenameDialogName(aRenameName as K);
            }
        },
        [checkRenameName, fileBeforeSplitStr, fileSplitChar, generalFilePrefix, illegalFileChar],
    );

    const fileBaseTokens = useMemo(() => {
        return { childrenGap: -1 };
    }, []);

    const designerFileList = useMemo(() => {
        return designerFileBases.map((value, index: number) => {
            return (
                <FileListItemWrapper
                    key={`file_id_` + index.toString()}
                    onClickFile={props.onFileClick}
                    onDeleteFile={props.onDeleteClick}
                    onEditFileName={onEditFileName}
                    fileName={{ ...getFileProps(value.Get()) }}
                    prefix={prefix}
                    categoryName={getFileProps(value.Get()).category}
                    ActiveFile={activeFileName == getFileProps(value.Get()).name}
                    IsLastFile={index == designerFileBases.length - 1}
                    IsFirstFile={index == 0}
                    isSaving={value.__internalIsSaving}
                    fileIgnorePrefix={generalFilePrefix}
                    categoryIgnorePrefix={categoryIgnorePrefix}
                    fileSplitChar={fileSplitChar}
                    fileTypeIcon={fileTypeIcon}
                    canEditOrDelete={canEditOrDelete}
                    requiresWritePermission={requiresWritePermission}
                    writePermissionName={writePermissionName}
                    contextMenuAs={contextMenuAs}
                />
            );
        });
    }, [
        FileListItemWrapper,
        activeFileName,
        canEditOrDelete,
        categoryIgnorePrefix,
        contextMenuAs,
        designerFileBases,
        fileSplitChar,
        fileTypeIcon,
        generalFilePrefix,
        getFileProps,
        onEditFileName,
        prefix,
        props.onDeleteClick,
        props.onFileClick,
        requiresWritePermission,
        writePermissionName,
    ]);

    const childrenStackItemStyles = useMemo(() => {
        return { root: { overflowY: "auto" } };
    }, []);

    const list = useMemo(
        () => (
            <AdvStack verticalFill styles={outerStackStyles}>
                <AdvStackItem verticalFill grow>
                    <AdvStack verticalFill horizontal>
                        <AdvStackItem verticalFill shrink={0} styles={groupboxStackItemStyles}>
                            <AdvGroupbox
                                heading={label}
                                canCollapse={canCollapse}
                                onStateChange={setIsGroupBoxOpen}
                                collapseDir={EAdvGroupboxCollapseDir.Left}
                                ignoreDefaultStyle
                            >
                                <AdvGenericDialog
                                    hidden={!isNewDialogOpen}
                                    onClosed={onCloseNewDialog}
                                    text={""}
                                    title={str_format(
                                        createNew ?? LAN.CREATE_FILE.text,
                                        fileTypeName,
                                    )}
                                >
                                    {newFileDialogContent}
                                </AdvGenericDialog>
                                <AdvGenericDialog
                                    hidden={!isRenameDialogOpen}
                                    onClosed={onCloseRenameDialog}
                                    text={""}
                                    title={str_format(
                                        renameNew ?? LAN.CREATE_FILE.text,
                                        fileTypeName,
                                    )}
                                >
                                    <AdvStack>
                                        <AdvTextInput
                                            label={LAN.INSERT_NEW_NAME.text}
                                            ignoreTranslation
                                            key={"adv-filelist-renamenamekey"}
                                            onValueChanged={onRenameChangeValue}
                                            defaultValue={replaceFilePrefix(
                                                generalFilePrefix,
                                                typeof renameDialogFileName == "string"
                                                    ? renameDialogFileName
                                                    : "",
                                                fileSplitChar,
                                            )}
                                        ></AdvTextInput>
                                        <>
                                            {isRenameDialogErr == 1 && (
                                                <AdvMessageBar type={MessageBarType.blocked}>
                                                    {renameDialogName == ""
                                                        ? "Empty names are not allowed"
                                                        : "A " +
                                                          fileTypeName +
                                                          " with that name already exists"}
                                                </AdvMessageBar>
                                            )}
                                            {isRenameDialogErr == 2 && (
                                                <AdvMessageBar type={MessageBarType.blocked}>
                                                    {'Name contains illegal character "' +
                                                        (illegalFileChar ?? "") +
                                                        '"'}
                                                </AdvMessageBar>
                                            )}
                                        </>
                                        <AdvStackItem shrink={0}>
                                            {onRenderRename != undefined ? onRenderRename() : <></>}
                                        </AdvStackItem>
                                        <AdvStackItem shrink={0}>
                                            <AdvStack horizontal>
                                                <AdvButtonPure
                                                    text={LAN.COPY.text}
                                                    onClick={() => {
                                                        onCopyFile(renameDialogFileName as K);
                                                        setIsRenameDialogOpen(false);
                                                    }}
                                                    styles={{ root: { flexGrow: 1 } }}
                                                ></AdvButtonPure>
                                            </AdvStack>
                                        </AdvStackItem>
                                    </AdvStack>
                                </AdvGenericDialog>
                                {actionBarItems.length > 0 && (
                                    <ActionBarComp
                                        items={actionBarItems}
                                        styles={{
                                            root: { margin: 0, padding: 0, height: 32 },
                                            primarySet: { margin: 0, padding: 0 },
                                        }}
                                    ></ActionBarComp>
                                )}
                                {canExportImport === true && (
                                    <AdvModal
                                        headline="Export & Import"
                                        isOpen={isExportImportModalOpen}
                                        onDismiss={onCloseExportImportModal}
                                    >
                                        <AdvStack>
                                            <AdvLabel>{"Import JSON file"}</AdvLabel>
                                            <AdvFileInput
                                                autoClearFile
                                                onFile={onFileImport}
                                            ></AdvFileInput>
                                            <AdvButtonPure
                                                text="download / export all items"
                                                onClick={downloadExportClick}
                                            ></AdvButtonPure>
                                            <AdvButtonPure
                                                text="download / export a specific item"
                                                onClick={toggleIsExportSingleOpen}
                                                id={id}
                                            ></AdvButtonPure>
                                            {isExportSingleOpen && (
                                                <AdvCallout
                                                    target={`#${id}`}
                                                    styles={{ root: { padding: 10 } }}
                                                    onDismiss={closeIsExportSingleOpen}
                                                    directionalHint={
                                                        DirectionalHint.bottomRightEdge
                                                    }
                                                >
                                                    <AdvDropdown
                                                        options={exportSingleOptions}
                                                        label="item list"
                                                        value={exportSingleOptions.find(
                                                            (d) => d.data === curExportSingleItem,
                                                        )}
                                                        onValueChanged={(newOpt) => {
                                                            if (newOpt != undefined) {
                                                                setCurExportSingleItem(newOpt.data);
                                                            }
                                                        }}
                                                    ></AdvDropdown>
                                                    <AdvButtonPure
                                                        text="download / export now"
                                                        onClick={() => {
                                                            if (onExportImport != undefined)
                                                                onExportImport(
                                                                    undefined,
                                                                    true,
                                                                    undefined,
                                                                    curExportSingleItem,
                                                                );
                                                        }}
                                                    ></AdvButtonPure>
                                                </AdvCallout>
                                            )}
                                            <AdvButtonPure
                                                text="download / export & delete all items"
                                                onClick={downloadExportDeleteClick}
                                            ></AdvButtonPure>
                                        </AdvStack>
                                    </AdvModal>
                                )}
                                <AdvStack tokens={fileBaseTokens}>{designerFileList}</AdvStack>
                            </AdvGroupbox>
                        </AdvStackItem>
                        <AdvStackItem grow verticalFill styles={childrenStackItemStyles}>
                            {children}
                        </AdvStackItem>
                    </AdvStack>
                </AdvStackItem>
            </AdvStack>
        ),
        [
            ActionBarComp,
            actionBarItems,
            canCollapse,
            canExportImport,
            children,
            childrenStackItemStyles,
            closeIsExportSingleOpen,
            createNew,
            curExportSingleItem,
            designerFileList,
            downloadExportClick,
            downloadExportDeleteClick,
            exportSingleOptions,
            fileBaseTokens,
            fileSplitChar,
            fileTypeName,
            generalFilePrefix,
            groupboxStackItemStyles,
            id,
            illegalFileChar,
            isExportImportModalOpen,
            isExportSingleOpen,
            isNewDialogOpen,
            isRenameDialogErr,
            isRenameDialogOpen,
            label,
            newFileDialogContent,
            onCloseExportImportModal,
            onCloseNewDialog,
            onCloseRenameDialog,
            onCopyFile,
            onExportImport,
            onFileImport,
            onRenameChangeValue,
            onRenderRename,
            outerStackStyles,
            renameDialogFileName,
            renameDialogName,
            renameNew,
            toggleIsExportSingleOpen,
        ],
    );

    return list;
};

const AdvDesignerFileListComponentsSimple = function <T, K extends SerializableParam>(
    props: TFileListComponentProps<T, K>,
) {
    return <AdvDesignerFileListComponentsImpl {...props}></AdvDesignerFileListComponentsImpl>;
};

const AdvDesignerFileListComponentsComplex = function <T, K extends SerializableParam>({
    prefix,
    ...props
}: TFileListComponentProps<T, K>) {
    const { canRedoUM, canUndoUM, umUndo, umRedo } = useUndoManagerFileList(prefix);
    return (
        <AdvDesignerFileListComponentsImpl
            {...props}
            prefix={prefix}
            canUndoUM={canUndoUM}
            canRedoUM={canRedoUM}
            umUndo={umUndo}
            umRedo={umRedo}
        ></AdvDesignerFileListComponentsImpl>
    );
};

export const AdvDesignerFileListComponentsComp = function <T, K extends SerializableParam>({
    hasUndoManager,
    ...props
}: TFileListComponentProps<T, K>) {
    if (hasUndoManager)
        return (
            <AdvDesignerFileListComponentsComplex
                {...props}
                hasUndoManager={hasUndoManager}
            ></AdvDesignerFileListComponentsComplex>
        );
    else
        return (
            <AdvDesignerFileListComponentsSimple
                {...props}
                hasUndoManager={hasUndoManager}
            ></AdvDesignerFileListComponentsSimple>
        );
};

const AdvDesignerFileListComponents = React.memo(
    AdvDesignerFileListComponentsComp,
    deepCompareJSXProps,
) as typeof AdvDesignerFileListComponentsComp;
export default AdvDesignerFileListComponents;

import { TAdvDropdownItem } from "@components/inputs/dropdown";
import { TDictionaryValue } from "@data/persist/dictionary-value";
import { recoilPersistServerDictionary } from "@data/persist/server-dictionary";
import { selector, selectorFamily } from "recoil";

import { gActionConstFilePrefix, replaceActionPrefix } from "./designer/file";
import { LAN } from "./language/strings";
import { TSyncDicts } from "./persist/sync-dictionary";
import { CreateRecoilDictionary } from "./utils/recoil-dictionary";

export const gActionParameterName = "__ActionMappedParam__ADV";

export enum EAdvActionParameterDataTypes {
    Int = 0,
    Float,
    Bool,
    String,
    Array,
    Object,
    Unknown,
}

export type TAdvActionParameter = {
    Name: string;
    DataType: string;
    DefaultValue: any;
    Description: string;
    Optional: boolean;
};
export type TAdvActionParameters = Record<string, TAdvActionParameter>;

export type TAdvActionBase = {
    Name: string;
    IconName?: string;
    ActionLabel: string;
    LogicName: string;
    Parameters: TAdvActionParameters;
    PageName?: string;
    Category: string;
};

export type TAdvAction = TAdvActionBase & {
    ActionHint: string;
    Attributes: Record<string, string>;
    AreYouSureQuestion: string;
};

const dictSyncer: TSyncDicts = new Map();
// TODO BaseValues
const { valuesEffect, baseValuesEffect } = recoilPersistServerDictionary<
    TAdvActionBase,
    TAdvAction
>("actionstorage", dictSyncer);

// Key: ActionName, Value: TAction
const actionDictionary = CreateRecoilDictionary<TAdvAction>("actions", {
    ValuesEffects: [valuesEffect],
});
const actionBaseDictionary = CreateRecoilDictionary<TAdvActionBase>("baseactions", {
    ValuesEffects: [baseValuesEffect],
});

export const recoilAction = {
    dictionary: actionDictionary,
    baseDictionary: actionBaseDictionary,

    /** @example useAdvRecoilValue(recoilAction.Actions("OpenPage")); */
    Actions: actionDictionary.values,
    ActionKeys: actionDictionary.valueKeys,
    BaseActions: actionBaseDictionary.values,
    ActionNames: actionBaseDictionary.valueKeys,

    /** @example useAdvRecoilTransaction((transactionInterface) => recoilAction.setAction(transactionInterface)(<ActionObject>)); */
    clearAndSetActions: actionDictionary.setItems,
    addOrSetAction: actionDictionary.addOrSetItem,
    addOrSetBaseAction: actionBaseDictionary.addOrSetItem,
    removeAction: actionDictionary.removeItem,
    removeBaseAction: actionBaseDictionary.removeItem,
    replaceAction: actionDictionary.replaceItem,

    actionArrayFromNames: selector({
        key: "actionArray",
        get: ({ get }) => {
            const temp: TAdvActionBase[] = [];
            const actionKeyNames = get(actionBaseDictionary.valueKeys);
            for (const actionName of actionKeyNames) {
                const action = get(actionBaseDictionary.values(actionName));
                if (action.IsLoaded()) temp.push(action.Get());
            }
            return temp;
        },
    }),

    /** Base-Actions aus Action-Names-Array */
    actionArrayByNames: selectorFamily({
        key: "actionArrayByNames",
        get:
            (actionNames: string[]) =>
            ({ get }) => {
                const temp: TAdvActionBase[] = [];
                for (const actionName of actionNames) {
                    const action = get(actionBaseDictionary.values(actionName));
                    if (action.IsLoaded()) temp.push(action.Get());
                }
                return temp;
            },
    }),

    /** Base-Actions aus Action-Names-Array */
    actionDictArrayByNames: selectorFamily({
        key: "actionDictArrayByNames",
        get:
            (actionNames: string[]) =>
            ({ get }) => {
                const temp: TDictionaryValue<TAdvActionBase>[] = [];
                for (const actionName of actionNames) {
                    const action = get(actionBaseDictionary.values(actionName));
                    temp.push(action);
                }
                return temp;
            },
    }),

    /** Dieser RecoilState stellt alle Auswahlmöglichkeiten für die Web-Actions als Dropdown im UI-Designer zur Verfügung, gefiltert durch die Kategorie */
    actionKeyArray: selectorFamily({
        key: "actionKeyArray",
        get:
            ({
                pageName,
                categoryName,
                requiresDefault,
            }: {
                pageName?: string;
                categoryName: string;
                requiresDefault: boolean;
            }) =>
            ({ get }) => {
                const temp: TAdvDropdownItem<any>[] = [];
                const actions = get(recoilAction.actionArrayFromNames);
                const categoryFilter = categoryName != undefined ? categoryName.toString() : "";
                const currentPageName = pageName;
                for (const action of actions) {
                    if (
                        (action.PageName !== undefined && action.PageName !== currentPageName) ||
                        (action.PageName == undefined && currentPageName != undefined)
                    )
                        continue;

                    const category = action.Category == undefined ? "" : action.Category;
                    if (categoryFilter == category || categoryFilter == "") {
                        const dropdownItem = {
                            key: `ddi_${action.Name}`,
                            text: replaceActionPrefix(action.Name) + " [" + action.LogicName + "]",
                            data: action.Name,
                        } as TAdvDropdownItem<any>;
                        temp.push(dropdownItem);
                    }
                }
                // Items in falscher Reihenfolge sortieren, später reverse()
                const sortedTemp = temp.sort((a, b) => b.text.localeCompare(a.text));
                if (requiresDefault) {
                    const dropdownItem = {
                        key: `_ddi__sel_None`,
                        text: LAN.NO_SELECTION.text,

                        data: "",
                    } as TAdvDropdownItem<any>;
                    sortedTemp.push(dropdownItem);
                }
                return sortedTemp.reverse();
            },
    }),

    actionCategoryArray: selector<TAdvDropdownItem<any>[]>({
        key: "actionCategoryArray",
        get: ({ get }) => {
            const categories = new Array<string>();
            const temp = [];
            const actions = get(recoilAction.actionArrayFromNames);

            if (!categories.includes("")) {
                categories.push("");
                const dropdownItem = {
                    key: `ddicat_all`,
                    text: LAN.ALL_CATEGORIES.text,

                    data: "",
                } as TAdvDropdownItem<any>;
                temp.push(dropdownItem);
            }

            for (const action of actions) {
                const category = action.Category == undefined ? "" : action.Category;

                if (category != "" && category != undefined && !categories.includes(category)) {
                    categories.push(category);
                    const dropdownItem = {
                        key: `ddicat_${category}`,
                        text: category,
                        data: category,
                    } as TAdvDropdownItem<any>;
                    temp.push(dropdownItem);
                }
            }
            return temp;
        },
    }),

    actionBaseArrayFilteredPage: selectorFamily<
        TDictionaryValue<TAdvActionBase>[],
        { pageName: string | undefined; requiresConstActions: boolean }
    >({
        key: "actionBaseArray",
        get:
            ({ pageName, requiresConstActions }) =>
            ({ get }) => {
                let temp: TDictionaryValue<TAdvActionBase>[] = [];
                const actionKeyNames = get(recoilAction.ActionNames).filter(
                    (val) => requiresConstActions || !val.startsWith(gActionConstFilePrefix),
                );
                for (const actionName of actionKeyNames) {
                    const action = get(recoilAction.BaseActions(actionName));
                    if (action.IsLoaded()) temp.push(action);
                }
                temp = temp
                    .filter(
                        (val) =>
                            ((val.Get().PageName == "" || val.Get().PageName == undefined) &&
                                pageName == "") ||
                            val.Get().PageName == pageName ||
                            pageName == undefined,
                    )
                    .sort((val1, val2) =>
                        replaceActionPrefix(val1.Get().Name).localeCompare(
                            replaceActionPrefix(val2.Get().Name),
                        ),
                    ); // TODO! do we want sorting?
                return temp;
            },
    }),

    baseActionLengthFiltered: selectorFamily<number, boolean>({
        key: "baseActionLength",
        get:
            (requiresConstActions) =>
            ({ get }) => {
                const actionKeyNames: string[] = get(recoilAction.ActionNames).filter(
                    (val) => requiresConstActions || !val.startsWith(gActionConstFilePrefix),
                );
                return actionKeyNames.length;
            },
    }),

    actionArrayFilteredPage: selectorFamily<
        TAdvAction[],
        { pageName: string | undefined; requiresConstActions: boolean }
    >({
        key: "actionArrayFiltered",
        get:
            ({ pageName, requiresConstActions }) =>
            ({ get }) => {
                let temp: TAdvAction[] = [];
                const actionKeyNames = get(recoilAction.ActionNames).filter(
                    (val) => requiresConstActions || !val.startsWith(gActionConstFilePrefix),
                );
                for (const actionName of actionKeyNames) {
                    const action = get(recoilAction.Actions(actionName));
                    if (action.IsLoaded()) temp.push(action.Get());
                }
                temp = temp.filter(
                    (val) =>
                        ((val.PageName == "" || val.PageName == undefined) && pageName == "") ||
                        val.PageName == pageName ||
                        pageName == undefined,
                );
                return temp;
            },
    }),
};

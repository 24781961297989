import { buildUniqueContractID, gContracts } from "@components/dynamic/contracts/contracts";
import { checkDataProviderCorrectness } from "@components/dynamic/data-provider/types";
import { TDynamicPage, TDynamicPagePayload } from "@components/dynamic/dynamic-page/types";
import { payloadToPage } from "@components/dynamic/dynamic-utils";
import { recoilDataProvider } from "@data/dataprovider";
import { buildUniqueProviderID } from "@data/dataprovider/data-provider-server";
import recoilDesigner from "@data/designer";
import { recoilDynamicPage } from "@data/dynamic-page";
import { buildPageIDForVariableID, recoilParameters } from "@data/parameters";
import { IDesignerComponent, IDesignerProperty } from "@feature/Designer/types/designer";
import { getDesignableComponentByType } from "@feature/Designer/utils";
import { useAdvRouter } from "@hooks/page/useAdvRouter";
import { useAdvCallback } from "@hooks/react-overload/useAdvCallback";
import {
    TAdvTransactionInterface,
    useAdvRecoilTransaction,
} from "@hooks/recoil-overload/useAdvRecoilTransaction";
import useAdvRecoilValue from "@hooks/recoil-overload/useAdvRecoilValue";
import { TPageInfo } from "@pages/dynamic";
import { trans_assert } from "@utils/assert-trans";
import deepCopy from "@utils/deep-copy";
import { advcatch } from "@utils/logging";
import { nanoid } from "nanoid";
import { useMemo } from "react";

export enum EPageLoaderMode {
    Normal,
    Designer,
}

export type TCanLoadPageCallback = (data: TDynamicPagePayload | string) => Promise<boolean>;

/**
 * Initialisiert die übergebene Page:
 * - Bereitet DataProvider vor
 * - Fügt fehlende Properties zu vorhandenen Komponenten hinzu
 * - Setzt States des UI-Designers (falls im Designer-Mode)
 * @returns Eine nutzbare DynamicPage (u.a. um fehlende Properties ergänzt)
 */
export const initPageInternal =
    (pageInfo: TPageInfo) =>
    (transactionInterface: TAdvTransactionInterface) =>
    (
        pageName: string,
        dynamicPagePayload: TDynamicPagePayload,
        mode: EPageLoaderMode,
        finishedFunc: (tb: TAdvTransactionInterface) => (page: TDynamicPage) => void,
    ) => {
        const { get, set, reset } = transactionInterface;
        /**
         * Fehlende Properties zu Komponenten hinzufügen. Prüft entsprechende DesignableComponent.
         * Notwendig wenn eine Komponente, die bereits in einige Pages genutzt wird, zusätzliche Properties zur Pflege bekommt.
         * @param designerComponentsOrKeys Die zu überprüfenden Komponenten bzw. deren Keys. Mindestens eine Komponente nötig
         * @param mode Aufgerufen aus dem Ui-Designer oder einer DynamicPage?
         * @returns Eine *Kopie* aller Komponenten mit aktuellen Properties.
         */
        function _addMissingPropertiesInternal(
            designerComponentsOrKeys: IDesignerComponent[] | string[],
            mode: EPageLoaderMode,
        ): IDesignerComponent[] {
            trans_assert(
                designerComponentsOrKeys != undefined,
                "designerComponentsOrKeys undefined",
            );
            trans_assert(designerComponentsOrKeys.length > 0, "designerComponentsOrKeys empty");

            let designerComponents: IDesignerComponent[] | undefined = undefined;
            if (typeof designerComponentsOrKeys[0] == "string") {
                trans_assert(
                    mode != EPageLoaderMode.Normal,
                    `Atoms/Selectors nur im Designer verfügbar`,
                );

                const designerKeys = designerComponentsOrKeys as string[];
                // designerComponents = deepCopy(Promise.all(designerKeys.map(key => get(designer.component(key).self)))); // TODO: Testen
                designerComponents = designerKeys.map((key) =>
                    get(recoilDesigner.component(pageName, key).self),
                );
            } else {
                designerComponents = deepCopy(designerComponentsOrKeys as IDesignerComponent[]);
            }

            trans_assert(
                designerComponents != undefined && designerComponents.length > 0,
                `DesignerComponents not found: ${JSON.stringify(designerComponentsOrKeys)}`,
            );

            for (const designerComponent of designerComponents) {
                const designableComponent = getDesignableComponentByType(
                    designerComponent.staticData.type,
                );
                trans_assert(
                    designableComponent != undefined,
                    `DesignableComponent not found: ${JSON.stringify(
                        designerComponent.staticData,
                    )}`,
                );

                const properties = [...designerComponent.properties];

                // Properties der DesignableComponent und DesignerComponent vergleichen
                const designablePropertyNames = designableComponent.properties.map(
                    (prop) => prop.name,
                );
                const designerPropertyNames = properties.map((prop) => prop.name);

                const missingPropertyNames = designablePropertyNames.filter(
                    (name) => designerPropertyNames.indexOf(name) < 0,
                );
                if (missingPropertyNames.length > 0) {
                    console.debug(
                        designerComponent.key,
                        "missing properties added:",
                        missingPropertyNames.join(", "),
                    );

                    // ... und fehlende Properties einfach aus der DesignableComponent übernehmen (Default-Wert)
                    const missingProperties = designableComponent.properties.filter(
                        (prop) => missingPropertyNames.indexOf(prop.name) >= 0,
                    );
                    for (const missingProperty of missingProperties) {
                        const property = { ...missingProperty, key: nanoid() } as IDesignerProperty;
                        properties.push(property);
                    }

                    // Wenn im DesignerMode, dann entsprechendes Atom der Komponente anpassen
                    if (mode == EPageLoaderMode.Designer) {
                        set(recoilDesigner.component(pageName, designerComponent.key).self, {
                            ...designerComponent,
                            properties,
                        });
                    }

                    // Kopie der Komponenten wird zurückgegeben, also hier auch die Properties des Objekts ändern
                    designerComponent.properties = properties;
                }
            }

            return designerComponents;
        }

        trans_assert(dynamicPagePayload != undefined, "LoadPage keine DynamicPage gefunden");

        const dynamicPage = payloadToPage(dynamicPagePayload);

        // DataProvider der Seite laden bzw. in das Dictionary hinzufügen
        recoilDataProvider.resetAndRemoveAllDataprovider(transactionInterface)(pageInfo);
        for (const provider of dynamicPage.dataproviders) {
            const providerToAdd = checkDataProviderCorrectness(provider);
            const provID = buildUniqueProviderID(pageInfo, provider.providerKey);
            recoilDataProvider.addOrSetDataproviderDefinition(transactionInterface)(
                provID,
                providerToAdd,
            );
        }

        // Parameters der Page laden
        reset(recoilParameters(buildPageIDForVariableID(pageInfo)));
        if (dynamicPage.parameterMapping != undefined)
            set(recoilParameters(buildPageIDForVariableID(pageInfo)), dynamicPage.parameterMapping);

        // Wenn der PageLoader im Designer genutzt wird, dann müssen wir die geladenen Daten
        // in die Recoil Atoms laden (designer. ...)
        if (mode == EPageLoaderMode.Designer) {
            // Alle alten Komponenten aus dem Dictionary resetten (entfernen)
            const oldComponentKeys = get(recoilDesigner.components.keys(pageName));
            for (const componentKey of oldComponentKeys) {
                reset(recoilDesigner.component(pageName, componentKey).self);
            }

            // set contracts
            set(recoilDesigner.contracts(pageName), dynamicPage.contracts ?? []);

            // Component-Keys setzen
            set(recoilDesigner.components.keys(pageName), dynamicPage.componentKeys);

            // Komponenten setzen
            const newComponents = dynamicPage.components;
            for (const component of newComponents) {
                set(recoilDesigner.component(pageName, component.key).self, component);
            }
            // Ausgewählte Komponente setzen
            set(recoilDesigner.selected.componentKey(pageName), dynamicPage.baseComponentKey);
            // Neuen BaseKey setzen
            set(recoilDesigner.components.baseKey(pageName), dynamicPage.baseComponentKey);
        } else {
            const curContracts = deepCopy(get(gContracts));
            // add all contracts that aren't added
            for (const contract of dynamicPage.contracts ?? []) {
                const contractID = buildUniqueContractID(pageInfo, contract.Name);
                if (
                    curContracts.find(
                        (c) => JSON.stringify(c.contractID) == JSON.stringify(contractID),
                    ) == undefined
                ) {
                    curContracts.push({
                        contractName: contract.Name,
                        pageInfo: pageInfo,
                        // Falls es hier Probleme gibt, siehe nächste kommentierte Zeile. Evtl. müssen wir PageInfo clonen?
                        // query: deepCopy(query),
                        contractID,
                    });
                }
            }
            set(gContracts, curContracts);
        }

        const components = _addMissingPropertiesInternal(dynamicPage.components, mode);
        if (components != undefined) dynamicPage.components = components;

        // => Fertig
        finishedFunc(transactionInterface)(dynamicPage);
    };

/**
 * Ein Hook, mit dem eine DynamicPage initialisiert werden kann (RecoilStates).
 * Ermittelt den PageName aus den GET-Parametern und lädt bereits alle DynamicPageKeys sowie
 * die angegebene DynamicPage (anhand des PageNames).
 * @param getParamName GET-Parameter der den zu ladenen PageName enthält
 * @param fallbackPageName Wird genutzt, wenn kein PageName vorhanden ist (optional)
 */
export function useAdvPageLoader(getParamName: string, fallbackPageName?: string) {
    const router = useAdvRouter();
    const pageName = useMemo(() => {
        if (router.isReady == false) return; // Erst weiter wenn der Router ready ist (erst dann ist u.a. der Query gesetzt)
        const paramValue = router.query[getParamName] as string | undefined;

        if (paramValue === undefined) {
            // Wenn wir auf eine FallbackPage zurückgreifen, dann sollten wir auch
            // auf dessen URL navigieren (shallow)
            if (getParamName != "" && fallbackPageName != undefined)
                router
                    .replace(
                        router.asPath +
                            `${
                                Object.keys(router.query).length == 0 ? "?" : "&"
                            }${router.addInstanceToQuery(getParamName + "=" + fallbackPageName)}`,
                        undefined,
                        { shallow: true },
                    )
                    .catch(advcatch);
            return fallbackPageName;
        } else return paramValue;
    }, [router, getParamName, fallbackPageName]);

    const loadablePage = useAdvRecoilValue(recoilDynamicPage.Pages(pageName ?? ""));

    const initTransInternal = useAdvCallback(
        (tb: TAdvTransactionInterface) => {
            return initPageInternal(router.pageInfo)(tb);
        },
        [router.pageInfo],
    );
    /** @see {@link initPageInternal} */
    const initTransaction = useAdvRecoilTransaction(initTransInternal, [initTransInternal]);

    return {
        /** @see {@link initPageInternal} */
        initPage: initTransaction,
        initPageTrans: initTransInternal,
        pageName,
        loadablePage,
    };
}

/**
 * Wrapper für @see useAdvPageLoader für den Designer
 */
export function useAdvPageLoaderDesigner(getParamName: string, fallbackPageName?: string) {
    const pageNames = useAdvRecoilValue(recoilDynamicPage.PageNames); // Keys laden

    const {
        /** @see {@link initPageInternal} */
        initPage: initTransaction,
        initPageTrans,
        pageName,
        loadablePage,
    } = useAdvPageLoader(getParamName, fallbackPageName);

    return {
        initPage: initTransaction,
        initPageTrans,
        pageName,
        pageNames,
        loadablePage,
    };
}

// https://stackoverflow.com/a/1144249
// slightly changed
/**
 * It's important to note that if @param compareFunctionsStrict is false, functions may return true, even if they are not strictly equal (e.g. different captures)
 * This can make sense e.g. for functions created in a constructor, but generally is not desired
 */
export const deepCompare = (obj1: any, obj2: any, compareFunctionsStrict: boolean = true) => {
    const leftChain: any[] = [],
        rightChain: any[] = [];

    function compare2Objects(x: any, y: any) {
        // remember that NaN === NaN returns false
        // and isNaN(undefined) returns true
        if (typeof x === "number" && typeof y === "number" && isNaN(x) && isNaN(y)) {
            return true;
        }

        // Compare primitives and functions.
        // Check if both arguments link to the same object.
        // Especially useful on the step where we compare prototypes
        if (x === y) {
            return true;
        }

        // Works in case when functions are created in constructor.
        // Comparing dates is a common scenario. Another built-ins?
        // We can even handle functions passed across iframes
        if (
            (typeof x === "function" &&
                typeof y === "function" &&
                compareFunctionsStrict !== true) ||
            (x instanceof Date && y instanceof Date) ||
            (x instanceof RegExp && y instanceof RegExp) ||
            (x instanceof String && y instanceof String) ||
            (x instanceof Number && y instanceof Number)
        ) {
            return x.toString() === y.toString();
        }
        // if objects were functions make sure to never return true accidentialy, only === should work
        else if (typeof x === "function" && typeof y === "function") {
            return false;
        }

        // At last checking prototypes as good as we can
        if (!(x instanceof Object && y instanceof Object)) {
            return false;
        }

        // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
        if (x.isPrototypeOf(y) || y.isPrototypeOf(x)) {
            return false;
        }

        if (x.constructor !== y.constructor) {
            return false;
        }

        if (x.prototype !== y.prototype) {
            return false;
        }

        // Check for infinitive linking loops
        if (leftChain.indexOf(x) > -1 || rightChain.indexOf(y) > -1) {
            return false;
        }

        // Quick checking of one object being a subset of another.
        // todo: cache the structure of arguments[0] for performance
        for (const p in y) {
            if (y.hasOwnProperty(p) !== x.hasOwnProperty(p)) {
                return false;
            } else if (typeof y[p] !== typeof x[p]) {
                return false;
            }
        }

        for (const p in x) {
            if (y.hasOwnProperty(p) !== x.hasOwnProperty(p)) {
                return false;
            } else if (typeof y[p] !== typeof x[p]) {
                return false;
            }

            leftChain.push(x);
            rightChain.push(y);

            if (!compare2Objects(x[p], y[p])) {
                return false;
            }

            leftChain.pop();
            rightChain.pop();
        }

        return true;
    }

    if (!compare2Objects(obj1, obj2)) {
        return false;
    }

    return true;
};

export const deepCompareJSXProps = (obj1: any, obj2: any) => {
    return deepCompare(obj1, obj2, true);
};

import { useAdvCallback } from "@hooks/react-overload/useAdvCallback";
import useAdvSetRecoilState from "@hooks/recoil-overload/useAdvSetRecoilState";
import { atom } from "recoil";

/**
 * Teil des Fix für NextJS in Kombination mit recoil-persist.
 * @see ttps://github.com/polemius/recoil-persist/issues/43
 * @see https://stackoverflow.com/a/70459889
 */
export const ssrCompletedState = atom({
    key: "SsrCompleted",
    default: false,
});

/**
 * Teil des Fix für NextJS in Kombination mit recoil-persist.
 * @see https://github.com/polemius/recoil-persist/issues/43
 * @see https://stackoverflow.com/a/70459889
 */
export const useSsrCompletedState = () => {
    const setSsrCompleted = useAdvSetRecoilState(ssrCompletedState);
    const isComplete = useAdvCallback(() => setSsrCompleted(true), [setSsrCompleted]);
    return isComplete;
};

/* eslint-disable @typescript-eslint/naming-convention */
import { useAdvEvent } from "@hooks/useAdvEvent";
import { useAdvSocketCallback } from "@hooks/useAdvSocketCallback";
import React, { Fragment } from "react";

class HooksExecuter {
    __hooks: Record<string, { name: string; hook: Function }> = {};
    __temp: Record<string, unknown> = {};

    constructor() {
        this.setHook = this.setHook.bind(this);
        this.getHook = this.getHook.bind(this);
        this.putHooks = this.putHooks.bind(this);
    }

    /**
     * Add hook
     * @param {string} name hook name
     * @param {function} hook hook function
     */
    setHook(name: string, hook: Function) {
        [
            { value: name, id: "name", type: "string" },
            { value: hook, id: "hook", type: "function" },
        ].forEach(({ value, id, type }) => {
            if (typeof value !== type)
                throw new TypeError(`"${id}" expected to be of type ${type}`);
        });

        this.__hooks[name] = { name, hook };
        return this;
    }

    /**
     *
     * @param {string} name hook name
     * @param {Function} hook hook function
     */
    putHooks(name: string, result: Function) {
        this.__temp[name] = result;
    }

    /**
     * Empty component
     */
    component() {
        const EmptyComponent = () => {
            Object.values(this.__hooks).forEach(({ name, hook }) => this.putHooks(name, hook()));
            return <Fragment />;
        };

        return EmptyComponent;
    }

    getHook(name: "useAdvSocketCallback"): Promise<ReturnType<typeof useAdvSocketCallback>>;
    getHook(name: "useAdvEvent"): Promise<ReturnType<typeof useAdvEvent>>;
    /**
     * Get hook
     * @param {string} name hook name
     * @returns {unknown}
     */
    getHook(name: string): Promise<unknown> {
        return new Promise<unknown>((resolve) => {
            let result = this.__temp[name];

            if (typeof result == "undefined") {
                const intv = setInterval(() => {
                    result = this.__temp[name];
                    if (typeof result != "undefined") {
                        clearInterval(intv);
                        resolve(result);
                    }
                }, 10);
            } else resolve(result);
        });
    }
}

// new instance
const instance = new HooksExecuter();

// component
export const ReactHooksWrapper = instance.component();

// methods
export const { getHook, setHook } = instance;

import { recoilDataProvider } from "@data/dataprovider";
import { useDataprovider } from "@data/dataprovider/data-provider-client";
import {
    buildPageIDForProviderID,
    buildPageIDForUIDesigner,
    buildUniqueProviderID,
    buildUniqueProviderIDForUIDesigner,
    gDataproviderConstFilterValueMappingKey,
    gDataproviderConstFilterValueMappingPageKey,
    gDataproviderDynFilterValueMappingKey,
    useDataproviderServer,
} from "@data/dataprovider/data-provider-server";
import { TAdvDatasource, recoilDatasource } from "@data/datasource";
import { buildPageIDForVariableID, recoilParameterListOfCurrentPage } from "@data/parameters";
import { TPropertyList } from "@feature/Designer/types/property";
import {
    EAdvValueBinderLogicType,
    EAdvValueBinderType,
    TAdvValueBindingParams,
    useAdvValueBinderMulti,
} from "@hooks/dynamic/useAdvValueBinder";
import { useAdvEffect } from "@hooks/react-overload/useAdvEffect";
import {
    TAdvTransactionInterface,
    useAdvRecoilTransaction,
} from "@hooks/recoil-overload/useAdvRecoilTransaction";
import useAdvRecoilValue from "@hooks/recoil-overload/useAdvRecoilValue";
import useAdvComponent from "@hooks/useAdvComponent";
import { useAdvEvent } from "@hooks/useAdvEvent";
import { EAdvValueDataTypes } from "@utils/data-types";
import { deepCompareJSXProps } from "@utils/deep-compare";
import { convertToOriginalType } from "@utils/to-type";
import React, { useMemo, useRef } from "react";

import { useDebounce } from "@hooks/misc/useDebounce";
import { useAdvRouter } from "@hooks/page/useAdvRouter";
import { TPageInfo } from "@pages/dynamic";
import { IAdvDataProvider } from "../data-provider/types";

const SingleDataProviderDesignerHost = ({
    providerName,
    activeFileName,
}: {
    activeFileName: string;
    providerName: string;
}) => {
    const dataprovDef = useAdvRecoilValue(
        recoilDataProvider.getDataproviderDefinition(
            buildUniqueProviderIDForUIDesigner(activeFileName, providerName),
        ),
    );
    const dataprovDatasourceDef = useAdvRecoilValue(
        recoilDatasource.Datasources(
            dataprovDef.IsLoaded() ? dataprovDef.Get().datasourceName : "",
        ),
    );
    const { reset, getData } = useDataprovider(providerName);

    const curDataprovDef = useRef<IAdvDataProvider>();
    const curDataprovDatasourceDef = useRef<TAdvDatasource>();

    useAdvEffect(() => {
        if (
            (curDataprovDef.current == undefined ||
                JSON.stringify(curDataprovDef.current) != JSON.stringify(dataprovDef)) &&
            !dataprovDef.__internalIsSaving
        ) {
            const shouldReset = curDataprovDef.current != undefined;
            curDataprovDef.current = JSON.parse(JSON.stringify(dataprovDef));
            if (shouldReset) reset();
        }
    }, [reset, dataprovDef]);

    useAdvEffect(() => {
        if (
            dataprovDatasourceDef != undefined &&
            (curDataprovDatasourceDef.current == undefined ||
                JSON.stringify(curDataprovDatasourceDef.current) !=
                    JSON.stringify(dataprovDatasourceDef)) &&
            !dataprovDatasourceDef.__internalIsSaving
        ) {
            const shouldReset = curDataprovDatasourceDef.current != undefined;
            curDataprovDatasourceDef.current = JSON.parse(JSON.stringify(dataprovDatasourceDef));
            if (shouldReset) reset();
        }
    }, [reset, dataprovDatasourceDef]);

    useAdvEffect(() => {
        getData();
    }, [getData]);

    return <></>;
};

const DataProviderDesignerHost = ({ activeFileName }: { activeFileName: string }) => {
    const dataProviderKeys = useAdvRecoilValue(
        recoilDataProvider.dataproviderNamesOfPage(buildPageIDForUIDesigner(activeFileName)),
    );

    return (
        <>
            {dataProviderKeys.map((val) => (
                <SingleDataProviderDesignerHost
                    key={val}
                    providerName={val}
                    activeFileName={activeFileName}
                ></SingleDataProviderDesignerHost>
            ))}
        </>
    );
};

const SingleDataproviderDynFilter = ({
    providerName,
    filterName,
    filterBinding,
}: {
    pageInfo: TPageInfo;
    providerName: string;
    filterName: string;
    filterBinding: TAdvValueBindingParams;
}) => {
    const startProp = useMemo<TPropertyList>(() => [], []);
    const [curFilterValBinding] = useAdvValueBinderMulti(
        filterBinding,
        startProp,
        EAdvValueDataTypes.Any,
    );
    //verhindern von zu hauefigem Laden bei Filteraenderungen zB bei Textfeldeingabe oder auch schnelle automatischen Filterwechseln
    const curFilterVal = useDebounce(curFilterValBinding, 250);

    const { setFilterValue } = useDataprovider(providerName);

    useAdvEffect(() => {
        //wird ueber die dependecies immer aufgerufen, wenn sich curFilterVal ändert und das passiert via binding
        const getFilter = () => {
            if (curFilterVal.length == 1) {
                let val = convertToOriginalType<any>(curFilterVal[0].val, curFilterVal[0].dataType);
                if (
                    curFilterVal[0].dataType == EAdvValueDataTypes.ArrayAsCommaSeperatedString &&
                    curFilterVal[0].val == ""
                )
                    val = [];
                return Array.isArray(val)
                    ? val.map((v) => {
                          return v.toString();
                      })
                    : [val.toString()];
            }
            return curFilterVal.map((v) => {
                return convertToOriginalType<any>(v.val, v.dataType).toString();
            });
        };
        const newFilter = getFilter();
        setFilterValue(filterName, newFilter);
    }, [curFilterVal, filterName, setFilterValue]);

    return <></>;
};

const useDataproviderListener = ({
    providerName,
    pageInfo,
}: {
    pageInfo: TPageInfo;
    providerName: string;
}) => {
    const { registerGlobalEventHandler, deRegisterGlobalEventHandler } = useAdvEvent();

    const addOrSetProviderData = useAdvRecoilTransaction(
        (tb: TAdvTransactionInterface) => recoilDataProvider.addOrSetDataproviderData(tb),
        [],
    );
    const removeProviderData = useAdvRecoilTransaction(
        (tb: TAdvTransactionInterface) => recoilDataProvider.removeDataproviderData(tb),
        [],
    );

    useAdvEffect(() => {
        registerGlobalEventHandler<{ data: Record<string, any>; compareField: string }>(
            "dataprovider_" + providerName,
            "add_or_set",
            (aAddOrSetData) => {
                const providerID = buildUniqueProviderID(pageInfo, providerName);
                addOrSetProviderData(providerID, aAddOrSetData);
            },
        );
        registerGlobalEventHandler<{ compareData: any; compareField: string }>(
            "dataprovider_" + providerName,
            "delete",
            (aDeleteData) => {
                const providerID = buildUniqueProviderID(pageInfo, providerName);
                removeProviderData(providerID, aDeleteData);
            },
        );
        return () => {
            deRegisterGlobalEventHandler("dataprovider_" + providerName, "add_or_set");
            deRegisterGlobalEventHandler("dataprovider_" + providerName, "delete");
        };
    }, [
        addOrSetProviderData,
        deRegisterGlobalEventHandler,
        pageInfo,
        providerName,
        registerGlobalEventHandler,
        removeProviderData,
    ]);
};

const SingleDataProviderFilterHost = ({
    providerName,
    pageInfo,
}: {
    pageInfo: TPageInfo;
    providerName: string;
}) => {
    const dataprovDef = useAdvRecoilValue(
        recoilDataProvider.getDataproviderDefinition(buildUniqueProviderID(pageInfo, providerName)),
    );

    useDataproviderListener({ providerName, pageInfo });

    const variableID = useMemo(() => buildPageIDForVariableID(pageInfo), [pageInfo]);
    const pageVariables = useAdvRecoilValue(recoilParameterListOfCurrentPage(variableID));

    return (
        <>
            {Object.keys(dataprovDef.Get().dynFilterValueMapping)
                .filter((val) => val.startsWith(gDataproviderDynFilterValueMappingKey))
                .map((val) => (
                    <SingleDataproviderDynFilter
                        key={val}
                        providerName={providerName}
                        pageInfo={pageInfo}
                        filterName={dataprovDef.Get().dynFilterValueMapping[val].filterName}
                        filterBinding={dataprovDef.Get().dynFilterValueMapping[val].filterBinding}
                    ></SingleDataproviderDynFilter>
                ))}
            {Object.keys(dataprovDef.Get().constFilterValueMapping)
                .filter((val) => {
                    if (val.startsWith(gDataproviderConstFilterValueMappingPageKey)) {
                        const pageVar = pageVariables.get(
                            dataprovDef.Get().constFilterValueMapping[val],
                        );
                        if (pageVar != null && !pageVar.isRequired && !pageVar.isConstant) {
                            return true;
                        }
                    }
                    return false;
                })
                .map((val) => {
                    const index = parseInt(
                        val.replace(gDataproviderConstFilterValueMappingPageKey, ""),
                    );
                    return (
                        <SingleDataproviderDynFilter
                            key={val}
                            providerName={providerName}
                            pageInfo={pageInfo}
                            filterName={
                                dataprovDef.Get().constFilterValueMapping[
                                    gDataproviderConstFilterValueMappingKey + index.toString()
                                ]
                            }
                            filterBinding={{
                                bindingLogic: EAdvValueBinderLogicType.Value,
                                bindingLogicValue: "",
                                bindingTypeName: EAdvValueBinderType.BinderTypePageVariable,
                                bindingParams: {
                                    ["getPageVar"]: {
                                        value: dataprovDef.Get().constFilterValueMapping[val],
                                        options: {},
                                    },
                                },
                            }}
                        ></SingleDataproviderDynFilter>
                    );
                })}
        </>
    );
};

const DataProviderFilterHost = () => {
    const { pageInfo } = useAdvRouter();
    const dataProviderKeys = useAdvRecoilValue(
        recoilDataProvider.dataproviderNamesOfPage(buildPageIDForProviderID(pageInfo)),
    );

    return (
        <>
            {dataProviderKeys.map((val) => (
                <SingleDataProviderFilterHost
                    key={val}
                    providerName={val}
                    pageInfo={pageInfo}
                ></SingleDataProviderFilterHost>
            ))}
        </>
    );
};

const DataProviderHostComp = ({
    isDesigner = false,
    activeFileName = "",
    ...props
}: {
    isDesigner?: boolean;
    activeFileName?: string;
}) => {
    useAdvComponent(DataProviderHostComp, props);

    useDataproviderServer(isDesigner);

    return (
        <>
            {isDesigner ? (
                <DataProviderDesignerHost
                    key={"designerProviderHost"}
                    activeFileName={activeFileName}
                ></DataProviderDesignerHost>
            ) : (
                <></>
            )}
            <DataProviderFilterHost></DataProviderFilterHost>
        </>
    );
};

const DataProviderHost = React.memo(DataProviderHostComp, deepCompareJSXProps);
export default DataProviderHost;

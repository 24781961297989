import AdvText from "@components/data/text";
import AdvStack, { TAdvStackStyles } from "@components/layout/stack";
import AdvStackItem, { TAdvStackItemStyles } from "@components/layout/stack/stack-item";
import AdvResource from "@components/other/resource/resource";
import { TResourceStorageKey } from "@data/resource-storage";
import { themeAuto } from "@data/theme/atoms";
import { DefaultThemeLogo } from "@feature/settings/theme-types";
import { ImageFit } from "@fluentui/react";
import useAdvRecoilValue from "@hooks/recoil-overload/useAdvRecoilValue";
import deepCopy from "@utils/deep-copy";
import { useMemo } from "react";
import { selector } from "recoil";

const themeValSelector = selector({
    key: "navtitle_themeValSelector",
    get: ({ get }) => {
        const themeVal = get(themeAuto);
        if (themeVal != undefined) {
            return themeVal.Value;
        }
        return undefined;
    },
});

export const AdvNavTitleInner = ({
    logo,
    onClick,
    logoFull = false,
}: {
    logo: TResourceStorageKey;
    onClick?: () => void;
    logoFull?: boolean;
}) => {
    const logoStyleStack = useMemo<TAdvStackStyles>(() => {
        if (logoFull)
            return {
                root: {
                    cursor: onClick == undefined ? undefined : "pointer",
                },
            };
        else
            return {
                root: {
                    position: "relative",
                    height: 40,
                    display: "flex",
                    justifyContent: "center",
                    cursor: onClick == undefined ? undefined : "pointer",
                },
            };
    }, [logoFull, onClick]);

    const logoStyleStackItem = useMemo<TAdvStackItemStyles>(() => {
        if (logoFull)
            return {
                root: {},
            };
        else
            return {
                root: { position: "absolute" },
            };
    }, [logoFull]);

    const logoFit = logoFull ? ImageFit.cover : ImageFit.contain;

    return (logo.Name ?? "") == "" ? (
        <AdvText
            variant="xxLarge"
            styles={{
                root: {
                    paddingLeft: 5,
                    paddingRight: 5,
                    cursor: onClick == undefined ? undefined : "pointer",
                },
            }}
            ignoreTranslation
            onClick={onClick}
        >
            AdvanTex Online
        </AdvText>
    ) : (
        <AdvStack styles={logoStyleStack} onClick={onClick} horizontalAlign="center">
            <AdvStackItem styles={logoStyleStackItem}>
                <AdvResource
                    forceFitType={logoFit}
                    resourceMaxHeight={logoFull ? undefined : "40px"}
                    resourceProps={{
                        resourceDataType: logo.DataType,
                        resourceName: logo.Name,
                    }}
                ></AdvResource>
            </AdvStackItem>
        </AdvStack>
    );
};

export const AdvNavTitle = ({
    onClick,
    logoFull = false,
}: {
    onClick?: () => void;
    logoFull?: boolean;
}) => {
    const curTheme = useAdvRecoilValue(themeValSelector);

    return (
        <AdvNavTitleInner
            onClick={onClick}
            logoFull={logoFull}
            logo={
                (logoFull ? curTheme?.logo.logoLogin : curTheme?.logo.logoTopBar) ??
                deepCopy(logoFull ? DefaultThemeLogo.logoLogin : DefaultThemeLogo.logoTopBar)
            }
        ></AdvNavTitleInner>
    );
};

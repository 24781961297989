import { useFluent, useScrollbarWidth } from "@fluentui/react-components";
import React from "react";
import { useResizeDetector } from "react-resize-detector";

export const ResizerFlex = ({ children }: { children: (height: number) => React.JSX.Element }) => {
    const { ref: refTabBody, height: tabBodyHeight } = useResizeDetector<HTMLDivElement>({
        handleHeight: true,
    });

    return (
        <div
            style={{
                width: "100%",
                flexGrow: 1,
                flexShrink: 0,
                minHeight: 200,
                position: "relative",
            }}
            ref={refTabBody}
        >
            <div
                style={{
                    width: "100%",
                    height: "100%",
                    position: "absolute",
                }}
            >
                {children(tabBodyHeight ?? 200)}
            </div>
        </div>
    );
};

export const ContentBoxCreator = ({
    children,
    calculatedColumnSize,
}: {
    children: () => React.JSX.Element;
    calculatedColumnSize: number;
}) => {
    const { targetDocument } = useFluent();
    const scrollbarWidth = useScrollbarWidth({ targetDocument });

    return (
        <div
            style={{
                width: "100%",
                flexGrow: 1,
                flexShrink: 0,
                minHeight: 250,
                position: "relative",
                overflowX: "auto",
            }}
        >
            <div
                style={{
                    display: "inline-block",
                    //minWidth: "max-content",
                    width: "100%",
                    //minWidth: "calc(100% - " + (scrollbarWidth ?? 0).toString() + "px)",
                    //minWidth: "calc(" + calculatedColumnSize.toString() + "px + 100px)",
                    minWidth:
                        "calc(" +
                        calculatedColumnSize.toString() +
                        "px + " +
                        (scrollbarWidth ?? 0).toString() +
                        "px)",
                    //maxWidth: calculatedColumnSize.toString() + "px",
                    //minWidth: "calc(100% - 2px)",
                    //minWidth: "100%",
                    height: "100%",
                    position: "absolute",
                    overflow: "visible",
                    //marginRight: scrollbarWidth,
                    //paddingRight: scrollbarWidth,
                }}
            >
                {children()}
            </div>
        </div>
    );
};

import { ReactNode } from "react";

type TPageLayoutSimpleProps = {
    children: ReactNode;
};

const PageLayoutSimple = ({ children }: TPageLayoutSimpleProps) => {
    return <>{children}</>;
};

export default PageLayoutSimple;

import AdvGrid from "@components/layout/grid/grid";
import AdvGridItem from "@components/layout/grid/grid-item/grid-item";
import { TAdvGridItemStyles } from "@components/layout/grid/grid-item/types";
import AdvStack, { TAdvStackStyles } from "@components/layout/stack/stack";
import { EPageComponentSizeType } from "@components/page-component";
import { Theme, ThemeProvider } from "@fluentui/react";
import useAdvTheme from "@hooks/useAdvTheme";
import React, { useMemo, useState } from "react";
import { AdvCredits } from "../credits/info";
import { TAdvNavLink } from "../link";
import { IAdvNavItemGroup } from "./nav";
import { AdvNavLinks } from "./nav-links";

export const NavElNav = ({
    navGroup,
    themeNav,
    setMenuMode,
    pageLayout = EPageComponentSizeType.DesktopWide,
}: {
    navGroup: IAdvNavItemGroup;
    themeNav: Theme;
    pageLayout?: EPageComponentSizeType;
    setMenuMode: React.Dispatch<React.SetStateAction<"iconnized" | "open" | "hidden">>;
}) => {
    const navStyle = useMemo<TAdvStackStyles>(() => {
        return {
            root: {
                width: "100%",
                position: "absolute",
                backgroundColor: themeNav.palette.white,
            },
        };
    }, [themeNav.palette.white]);

    const alignedNavGroups = useMemo(() => {
        function deepLinks(links: TAdvNavLink[]): TAdvNavLink[] {
            return links.map((l) => {
                return {
                    ...l,
                    onClick: (ev) => {
                        if (l.renderAs != "group") setMenuMode("hidden");
                        if (l.onClick != undefined) l.onClick(ev, l);
                    },
                    links: deepLinks(l.links),
                };
            });
        }

        if (pageLayout <= EPageComponentSizeType.Mobile) {
            return {
                ...navGroup,
                links: deepLinks(navGroup.links),
            } as IAdvNavItemGroup;
        } else {
            return navGroup;
        }
    }, [navGroup, pageLayout, setMenuMode]);

    const navGridStyle = useMemo<TAdvGridItemStyles>(() => {
        return {
            root: {
                overflowX: "hidden",
                padding: 0,
                boxSizing: "border-box",
                position: "relative",
                display: "flex",
                justifyContent: "center",
            },
        };
    }, []);
    return (
        <AdvGrid
            rows="[content] auto [footer] 44px"
            columns="1fr"
            styles={{ root: { gridGap: "0px" } }}
            verticalFill
        >
            <AdvGridItem column={1} row="content" styles={navGridStyle}>
                <ThemeProvider theme={themeNav} as={React.Fragment}>
                    <AdvStack grow styles={navStyle}>
                        <AdvNavLinks navGroup={alignedNavGroups}></AdvNavLinks>
                    </AdvStack>
                </ThemeProvider>
            </AdvGridItem>
            <AdvGridItem column={1} row="footer" styles={navGridStyle}>
                <AdvCredits fullHeight></AdvCredits>
            </AdvGridItem>
        </AdvGrid>
    );
};

export const NavElIconizedNav = ({
    navGroup,
    themeNav,
}: {
    navGroup: IAdvNavItemGroup;
    themeNav: Theme;
}) => {
    const [openGroup, setOpenGroup] = useState("");
    const theme = useAdvTheme();
    const iconnizedGroups = useMemo(() => {
        function deepLinks(links: TAdvNavLink[]): TAdvNavLink[] {
            return links.map((l) => {
                return {
                    ...l,
                    onClick: (ev) => {
                        setOpenGroup("");
                        if (l.onClick != undefined && l.renderAs == "item") l.onClick(ev, l);
                    },
                    // always expand all
                    isExpanded: l.renderAs != "item",
                    links: deepLinks(l.links),
                };
            });
        }

        return {
            links: navGroup.links.map((g) => {
                return {
                    ...g,
                    name: g.name,
                    isExpanded: openGroup == g.key,
                    onClick: (ev) => {
                        if (g.onClick != undefined && g.renderAs == "item") g.onClick(ev, g);
                        else setOpenGroup(g.key ?? "");
                    },
                    links: openGroup == g.key ? deepLinks(g.links) : [],
                    renderAs:
                        g.renderAs == "group"
                            ? "iconnizedGroup"
                            : g.renderAs == "item"
                            ? "iconnizedItem"
                            : g.renderAs,
                } as TAdvNavLink;
            }),
        } as IAdvNavItemGroup;
    }, [navGroup.links, openGroup]);
    const navEl = useMemo(
        () => <AdvNavLinks navGroup={iconnizedGroups}></AdvNavLinks>,
        [iconnizedGroups],
    );
    const navStyle = useMemo<TAdvStackStyles>(() => {
        return {
            root: {
                width: "100%",
                position: "absolute",
                backgroundColor: theme.palette.white,
            },
        };
    }, [theme.palette.white]);
    const navGridStyle = useMemo<TAdvGridItemStyles>(() => {
        return {
            root: {
                overflowX: "hidden",
                padding: 0,
                boxSizing: "border-box",
                position: "relative",
                display: "flex",
                justifyContent: "center",
            },
        };
    }, []);
    return (
        <AdvGrid
            rows="[content] 1fr [footer] 44px"
            columns={openGroup == "" ? "1fr" : "minmax(200px, 1fr)"}
            styles={{ root: { gridGap: "0px" } }}
            verticalFill
        >
            <AdvGridItem column={1} row="content" styles={navGridStyle}>
                <ThemeProvider theme={themeNav} as={React.Fragment}>
                    <AdvStack grow styles={navStyle}>
                        {navEl}
                    </AdvStack>
                </ThemeProvider>
            </AdvGridItem>
            <AdvGridItem column={1} row="footer" styles={navGridStyle}>
                <AdvCredits fullHeight minimized={openGroup == ""}></AdvCredits>
            </AdvGridItem>
        </AdvGrid>
    );
};

import "./designable";

import { TAdvDesignerComponentProps } from "@feature/Designer/types/component-props";
import {
    IsValueBindingTrivial,
    TAdvValueBindingParams,
    useAdvValueBinderNoDataType,
} from "@hooks/dynamic/useAdvValueBinder";
import { EAdvValueDataTypes } from "@utils/data-types";
import assert from "assert";
import React, { CSSProperties, ReactElement, useMemo } from "react";
import ReactGridLayout, { ReactGridLayoutProps } from "react-grid-layout";

import { TAdvCommonProperties } from "@components/other/common-properties";
import useAdvComponent from "@hooks/useAdvComponent";
import { deepCompareJSXProps } from "@utils/deep-compare";
import WidthAndHeightProvider from "./height-provider";

// const savedLayout = GetFromLS("myTestLayout2");
const GridLayout = WidthAndHeightProvider(ReactGridLayout);

export type TAdvReactGridProps = Omit<ReactGridLayoutProps, "children" | "layout"> &
    TAdvDesignerComponentProps &
    TAdvCommonProperties & {
        children: ReactElement[] | undefined;
        /** Layout, welches hartkodiert gesetzt wird */
        defaultLayout: ReactGridLayout.Layout[];
        /** Layout, welches durch den Designer gesetzt wird */
        layout?: ReactGridLayout.Layout[];

        saveInLocalStorage?: boolean;

        colsBindingParams?: TAdvValueBindingParams;
        rowHeightBindingParams?: TAdvValueBindingParams;
        layoutBindingParams?: TAdvValueBindingParams;
    };

const AdvReactGridImplComp = ({
    layout,
    advhide,
    defaultLayout,
    designerData,
    designerProps,
    ...props
}: TAdvReactGridProps) => {
    useAdvComponent(AdvReactGridImplComp, props);

    // TODO: LocalStorage Anbindung wieder implementieren
    // Dabei beachte: AdvGrid kann im Designer aber auch hartkodiert (z.B. MainMenu) genutzt werden, eventuell brauchen wir hier beim
    // hartkodierten AdvGrid ebenfalls zwingend einen Key?

    // Default-Wert: Entweder die defaultLayouts oder, wenn möglicherweise im LocalStorage ein Layout liegt, das Layout aus dem LocalStorage nutzen
    //const [layout, setLayout] = useState<ReactGridLayout.Layout[]>([]);
    // Layout NICHT als DefaultWert vom State, sondern verzögert setzen (wenn alles ready?):
    // useAdvEffect(() =>
    // {
    //     setLayout(saveInLocalStorage ? (savedLayout || defaultLayout) : defaultLayout);
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, []);
    // function resetLayout()
    // {
    //     setLayout([]);
    // }
    // function onLayoutChange(layout: Layout[]): void
    // {
    // if (saveInLocalStorage)
    // {
    //     SaveToLS("myTestLayout2", layout);
    // }
    // setLayout(layout);
    // }

    const children = useMemo(() => {
        assert(props.children !== undefined && props.children.length >= 0, "Grid: Keine Children?");

        if (layout != undefined) {
            // => Dieses Grid befindet sich in einer DynamicPage
            // Dann müssen wir erstmal herausfinden, welche ElementContainer / DynamicComponents hier im Grid sind
            let actualComponents: ReactElement[] = [];
            if (designerData) {
                actualComponents = props.children.filter(
                    (child: any) => child.key?.toString().startsWith("ec_"),
                );
                // Alternativ: Object.is(child.type, ElementContainer), Aber: Zirkuläre Referenz
            } else {
                actualComponents = props.children.filter(
                    (child: any) => child.key?.toString().startsWith("dc_"),
                );
            }

            if (actualComponents === undefined || actualComponents.length == 0) return;
            assert(
                actualComponents != undefined && actualComponents.length > 0,
                "Keine tatsächlichen Komponenten im Grid? Möglicherweise fehlen GridItems im Preset",
            );

            const normalStyle: CSSProperties = {};

            // ... diese Komponente müssen wir nämlich jeweils in ein DIV packen.
            // Dieses DIV wird vom ReactGridLayout vorausgesetzt und es stellt quasi ein GridItem dar.
            return layout.map((layout: ReactGridLayout.Layout, index: number) => {
                const renderAsDesignerStyle: CSSProperties = {
                    borderWidth: 1,
                    borderStyle: "solid",
                    borderColor: ["red", "blue", "green", "yellow", "purple"][index],
                };
                const divStyle: CSSProperties =
                    designerData?.renderAsDesigner ?? false ? renderAsDesignerStyle : normalStyle;

                return (
                    <div key={layout.i} {...designerProps} style={divStyle}>
                        {actualComponents[index]}
                    </div>
                );
            });
        } else {
            // => Hartkodiertes Grid, also einfach die Children ausgeben
            return props.children;
        }
    }, [designerData, layout, designerProps, props.children]);

    //const myStyles: CSSProperties = useMemo(() => { return { /*height: "100%"*/ }; }, []);

    if (advhide === true && designerProps === undefined) return <></>;
    return (
        <GridLayout
            {...props}
            className={designerData ? "designer" : undefined}
            layout={layout ?? defaultLayout}
            //style={myStyles}
            // onLayoutChange={onLayoutChange}
        >
            {children}
        </GridLayout>
    );
};
const AdvReactGridImpl = React.memo(AdvReactGridImplComp, deepCompareJSXProps);

const AdvReactGridSimple = (props: TAdvReactGridProps) => {
    return <AdvReactGridImpl {...props}></AdvReactGridImpl>;
};

const AdvReactGridComplex = ({
    colsBindingParams,
    rowHeightBindingParams,
    layoutBindingParams,
    advhide,
    advhideBindingParams,
    cols,
    rowHeight,
    layout,
    dataArrayIndex = 0,
    ...props
}: TAdvReactGridProps) => {
    const [colsValue] = useAdvValueBinderNoDataType(
        colsBindingParams,
        cols,
        EAdvValueDataTypes.Any,
        dataArrayIndex,
    );
    const [rowHeightValue] = useAdvValueBinderNoDataType(
        rowHeightBindingParams,
        rowHeight,
        EAdvValueDataTypes.Any,
        dataArrayIndex,
    );
    const [layoutValue] = useAdvValueBinderNoDataType(
        layoutBindingParams,
        layout,
        EAdvValueDataTypes.Any,
        dataArrayIndex,
    );
    const [shouldHide] = useAdvValueBinderNoDataType(
        advhideBindingParams,
        advhide,
        EAdvValueDataTypes.Any,
        dataArrayIndex,
    );
    return (
        <AdvReactGridImpl
            {...props}
            colsBindingParams={colsBindingParams}
            rowHeightBindingParams={rowHeightBindingParams}
            layoutBindingParams={layoutBindingParams}
            advhide={shouldHide}
            advhideBindingParams={advhideBindingParams}
            cols={colsValue}
            rowHeight={rowHeightValue}
            layout={layoutValue}
        ></AdvReactGridImpl>
    );
};

/**
 * @summary Wrapper für ReactGridLayout.
 * @link https://github.com/react-grid-layout/react-grid-layout
 */
const AdvReactGridComp = ({
    colsBindingParams,
    rowHeightBindingParams,
    layoutBindingParams,
    advhideBindingParams,
    ...props
}: TAdvReactGridProps) => {
    if (
        IsValueBindingTrivial(colsBindingParams) &&
        IsValueBindingTrivial(rowHeightBindingParams) &&
        IsValueBindingTrivial(layoutBindingParams) &&
        IsValueBindingTrivial(advhideBindingParams)
    )
        return (
            <AdvReactGridSimple
                {...props}
                colsBindingParams={colsBindingParams}
                rowHeightBindingParams={rowHeightBindingParams}
                layoutBindingParams={layoutBindingParams}
                advhideBindingParams={advhideBindingParams}
            ></AdvReactGridSimple>
        );
    else
        return (
            <AdvReactGridComplex
                {...props}
                colsBindingParams={colsBindingParams}
                rowHeightBindingParams={rowHeightBindingParams}
                layoutBindingParams={layoutBindingParams}
                advhideBindingParams={advhideBindingParams}
            ></AdvReactGridComplex>
        );
};

const AdvReactGrid = React.memo(AdvReactGridComp, deepCompareJSXProps);
export default AdvReactGrid;

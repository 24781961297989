import { IButtonStyles, IIconStyles } from "@fluentui/react";
import { TAdvAppliedTheme } from "@hooks/useAdvTheme";

const defaultStyles: IButtonStyles = {
    root: {
        // width: "100%"
    },
    textContainer: {},
};

const primaryStyles = (theme: TAdvAppliedTheme): IButtonStyles => {
    return {
        root: {
            // width: "100%"
        },
        textContainer: {},
        rootHovered: {
            backgroundColor: theme.palette.neutralDark,
            borderColor: theme.palette.neutralDark,
        },
    };
};

const bigIconButtonStyles: IButtonStyles = {
    flexContainer: {
        display: "inline",
    },
    icon: {
        display: "block",
        marginButtom: 8,
    },
    root: {
        minHeight: 50,
    },
};

const bigIconIconStyles: IIconStyles = {
    root: {
        fontSize: 32,
        marginBottom: 0,
    },
};

export const defaultButtonStyles = {
    default: defaultStyles,
    primary: primaryStyles,
    bigIconButton: bigIconButtonStyles,
    bigIconIcon: bigIconIconStyles,
};

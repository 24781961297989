import assert from "assert";
import { advcatch } from "./logging";

/**
 * Erstellt eine vollständige Kopie des Objekts.
 * Wird benötigt, da Objekte kopiert werden müssen, in denen wiederrum andere Objekte enthalten sind (z.B. ``DesignProperties``).
 * @important Es gehen unter Umständen Daten verloren! Zum Beispiel Funktionen.
 *
 * @example
 * let x = { id: 1, name: { first: "hans", last: "peter" } }
 * let y = { ...x }
 * x.id === y.id // false
 * x.name === y.name // true (gleiches Objekt)
 *
 * @example
 * let y = deepCopy(x)
 * x.id === y.id // false
 * x.name === y.name // false (ungleiche Objekte)
 *
 * @example Alternative
 * let y = { ...x, name: { ...(x.name) } }
 * x.id === y.id // false
 * x.name === y.name // false
 *
 * Falls irgendwann Performance-Probleme:
 * https://bobbyhadz.com/blog/react-deep-clone-object#create-a-deep-copy-of-an-object-using-lodash-in-react
 */
export default function deepCopy<T>(object: T, allowFunctionCopy?: boolean): T {
    if (process.env.NEXT_PUBLIC_ENV == "dev" && allowFunctionCopy !== true) {
        const checkObjHasFunc = (obj: any) => {
            for (const key in obj) {
                const objMember = obj[key];
                if (typeof objMember == "function") {
                    advcatch("Tried to deepCopy an object that holds a function", obj, object);
                } else if (typeof objMember == "object") checkObjHasFunc(objMember);
            }
        };
        checkObjHasFunc(object);
    }
    let res: T | undefined = undefined;
    if (typeof window === "undefined" || typeof structuredClone === "undefined") {
        res = JSON.parse(JSON.stringify(object));
    } else {
        try {
            // Wenn structuredClone vorhanden ist (und wir uns im Browser befinden), dann dies immer zuerst probieren.
            // Falls das jedoch einen Fehler wirft (ist etwas "strenger" als die andere Methode)
            // dann geben wir diesen Fehler in der Konsole aus und nehmen die JSON-Methode.
            res = structuredClone(object);
        } catch (ex) {
            // TODO: this is too annoying console.warn(ex);
            res = JSON.parse(JSON.stringify(object));
        }
    }

    if (res != undefined && allowFunctionCopy === true) {
        const copyFunctions = (objOld: any, objNew: any) => {
            for (const key in objOld) {
                const objMember = objOld[key];
                const objMemberNew = objNew[key];

                if (typeof objMember == "function") objNew[key] = objOld[key];
                // some special cases
                else if (objMember instanceof Date) {
                    objNew[key] = new Date(objMember);
                } else if (objMember instanceof RegExp) {
                    objNew[key] = new RegExp(objMember);
                } else if (objMember instanceof String) {
                    objNew[key] = new String(objMember);
                } else if (objMember instanceof Number) {
                    objNew[key] = new Number(objMember);
                } else if (typeof objMember == "object") {
                    // make sure the new obj is also of type object
                    if (typeof objMemberNew != "object") objNew[key] = {};
                    copyFunctions(objMember, objMemberNew);
                }
            }
        };
        copyFunctions(object, res);
    }
    assert(
        res != undefined,
        "This should never happen, deepCopy failed to execute, maybe you tried to copy undefined? (object: " +
            JSON.stringify(object) +
            ")",
    );
    return res;
}

/**
 * Das Objekt X-mal kopieren
 * @returns Einen Array mit X Kopien des Objekts. */
export function deepCopyTimes<T>(object: T, count: number): T[] {
    const arr: T[] = [];
    for (let i = 0; i < count; i++) {
        arr.push(deepCopy(object));
    }
    return arr;
}

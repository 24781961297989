import useAdvComponent from "@hooks/useAdvComponent";
import { deepCompareJSXProps } from "@utils/deep-compare";
import { combineStyles } from "@utils/styling";
import React, { ReactNode } from "react";

import AdvSpinner, { TAdvSpinnerProps, TAdvSpinnerStyleProps, TAdvSpinnerStyles } from "../spinner";
import { defaultLoadingStyles, loadingOverlayStyles } from "./styles";

export type TAdvLoadingStyles = TAdvSpinnerStyles; /* do not change */
export type TAdvLoadingStyleProps = TAdvSpinnerStyleProps; /* do not change */

export enum EAdvLoadingPosition {
    BeforeContent,
    AfterContent,
}
export enum EAdvLoadingMode {
    /**
     * Zeigt entweder den {@link AdvSpinner} ODER den Content an.
     * @important Verursacht re-renders!
     */
    HideContentWhenLoading,
    /** Zeigt den Content und, falls ``isLoading = TRUE``, den {@link AdvSpinner}. */
    ShowLoadingAtPosition,
    ShowLoadingAsOverlay,
}

export type TAdvLoadingProps = {
    isLoading: boolean;
    spinnerProps?: TAdvSpinnerProps;
    children?: ReactNode;

    /** Standard: {@link EAdvLoadingMode.HideContentWhenLoading} */
    mode?: EAdvLoadingMode;

    /**
     * Soll der Spinner VOR oder NACH dem Content angezeigt werden?
     *
     * Betrifft den {@link EAdvLoadingMode.ShowLoadingAtPosition}-Modus
     *
     * Standard: {@link EAdvLoadingPosition.AfterContent}
     */
    position?: EAdvLoadingPosition;

    /**
     * Click on the spinner
     */
    onClick?: () => void;
};
/**
 * Zeigt einen {@link AdvSpinner} an, solange {@link isLoading}
 *
 * Verhalten kann über {@link mode} und {@link position} gesteuert werden.
 *
 * @link https://developer.microsoft.com/en-us/fluentui#/controls/web/spinner
 */
const AdvLoadingComp = ({
    onClick,
    children,
    isLoading,
    spinnerProps,
    mode = EAdvLoadingMode.ShowLoadingAsOverlay,
    position = EAdvLoadingPosition.AfterContent,
    ...props
}: TAdvLoadingProps): React.JSX.Element => {
    useAdvComponent(AdvLoadingComp, props);

    switch (mode) {
        case EAdvLoadingMode.HideContentWhenLoading: {
            const loadingComponent = (
                <AdvSpinner
                    onClick={onClick}
                    {...spinnerProps}
                    styles={combineStyles(defaultLoadingStyles, spinnerProps?.styles)}
                />
            );
            return isLoading ? loadingComponent : <>{children}</>;
        }
        case EAdvLoadingMode.ShowLoadingAtPosition: {
            const loadingComponent = (
                <AdvSpinner
                    onClick={onClick}
                    {...spinnerProps}
                    styles={combineStyles(defaultLoadingStyles, spinnerProps?.styles)}
                />
            );
            if (isLoading) {
                switch (position) {
                    case EAdvLoadingPosition.BeforeContent:
                        return (
                            <>
                                {loadingComponent}
                                {children}
                            </>
                        );
                    case EAdvLoadingPosition.AfterContent:
                        return (
                            <>
                                {children}
                                {loadingComponent}
                            </>
                        );
                }
            }
        }
        case EAdvLoadingMode.ShowLoadingAsOverlay: {
            const loadingComponent = (
                <AdvSpinner
                    onClick={onClick}
                    {...spinnerProps}
                    styles={combineStyles(loadingOverlayStyles, spinnerProps?.styles)}
                />
            );
            if (isLoading)
                return (
                    <>
                        {children}
                        {loadingComponent}
                    </>
                );
        }
    }
    return <>{children}</>;
};

const AdvLoading = React.memo(AdvLoadingComp, deepCompareJSXProps);
export default AdvLoading;

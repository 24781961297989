export function parseToBoolean<T>(val: T): boolean {
    if (typeof val == "string")
        return val.toLowerCase() == "true" ||
            val.toLowerCase() == "wahr" ||
            (!isNaN(parseInt(val)) ? Boolean(parseInt(val)) : false) ||
            val.toLowerCase() == "yes" ||
            val.toLowerCase() == "on"
            ? true
            : false;
    return Boolean(val).valueOf();
}

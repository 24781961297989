import { ITextStyles } from "@fluentui/react";

// Eingebunden in themes/default.ts
export const defaultTextStyles: ITextStyles = {
    root: {
        whiteSpace: "nowrap",
        overflowX: "hidden",
        textOverflow: "ellipsis",
    },
};

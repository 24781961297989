//eine Methode "createExcelFile" die Tabellendaten und so bekommt und dann eine Excel-Datei daraus baut
//die Logik dafür kam urspruenglich aus dem dataprovider-server, wurde aber hierhin ausgelagert, damit sie auch von anderen Komponenten verwendet werden kann

import filenamify from "react-native-filenamify";
import { utils, writeFile } from "xlsx";

export const createExcelFile = (
    fileName: string,
    sheetName: string,
    data: any[][],
    colSize: number[],
) => {
    if (data.length == 0) return;
    const dataTranspose = data[0].map((_, colIndex) => data.map((row) => row[colIndex]));
    const sheet = utils.aoa_to_sheet(dataTranspose);
    sheet["!cols"] = colSize.map((c) => {
        return { wch: c };
    });

    //Zahlen sollen auch als Zahlen exportiert werden
    if (sheet["!ref"] != undefined) {
        const range = utils.decode_range(sheet["!ref"]);
        for (let j = range.s.c; j <= range.e.c; ++j) {
            for (let i = range.s.r; i <= range.e.r; ++i) {
                const ref = utils.encode_cell({ r: i, c: j });
                if (sheet[ref] == undefined || isNaN(sheet[ref].v)) {
                    //Nicht-Zahlen werden uebersprungen
                    continue;
                }
                sheet[ref].t = "n"; //Typ wird auf n (number) gesetzt
            }
        }
    }

    const book = utils.book_new();

    function osFriendlyName(name: string): string {
        return filenamify(name, { replacement: "_" }).slice(0, 31);
    }
    function sheetFriendlyName(name: string): string {
        return osFriendlyName(name).replaceAll("]", "_").replaceAll("[", "_");
    }
    utils.book_append_sheet(book, sheet, sheetFriendlyName(sheetName));

    writeFile(book, osFriendlyName(fileName) + ".xlsx");
};

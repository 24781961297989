export const advlog = (...logItems: any[]) => {
    const nowTime = new Date();
    const millis =
        nowTime.getMilliseconds().toString() +
        (nowTime.getMilliseconds() < 10 ? "00" : nowTime.getMilliseconds() < 100 ? "0" : "");
    console.log(
        "[" +
            nowTime.toLocaleDateString() +
            " " +
            nowTime.toLocaleTimeString() +
            "." +
            millis +
            "] " +
            (typeof logItems[0] == "string" ? logItems[0] : ""),
        ...logItems.slice(typeof logItems[0] == "string" ? 1 : 0),
    );
};

export const advcatch = (...err: any[]) => {
    console.error(...err);
    debugger;
};

import useAdvComponent from "@hooks/useAdvComponent";
import { deepCompareJSXProps } from "@utils/deep-compare";
import React from "react";
import AdvGenericDialog from "../generic/generic";

export type TAdvWeiterDialogProps = {
    hidden: boolean;
    title: string;
    text: string;

    continueText?: string;
    cancelText?: string;

    disableContinue?: boolean;
    disableCancel?: boolean;

    hideContinue?: boolean;
    hideCancel?: boolean;

    /** Wird aufgerufen, wenn der Dialog sich schließen soll. Diese Methode muss die ``hidden``-Property setzen */
    onClosed: (weiter: boolean) => void;

    ignoreTranslation?: boolean;
};

const AdvWeiterDialogComp = ({
    title,
    text,
    onClosed,
    hidden,
    continueText = "Weiter",
    cancelText = "Abbrechen",
    ignoreTranslation = false,
    disableCancel = false,
    disableContinue = false,
    hideCancel = false,
    hideContinue = false,
    ...props
}: TAdvWeiterDialogProps) => {
    useAdvComponent(AdvWeiterDialogComp, props);

    return (
        <AdvGenericDialog
            title={title}
            text={text}
            onClosed={onClosed}
            hidden={hidden}
            continueText={continueText}
            cancelText={cancelText}
            ignoreTranslation={ignoreTranslation}
            canCancel={!disableCancel}
            canContinue={!disableContinue}
            hideCancel={hideCancel}
            hideContinue={hideContinue}
        ></AdvGenericDialog>
    );
};

const AdvWeiterDialog = React.memo(AdvWeiterDialogComp, deepCompareJSXProps);
export default AdvWeiterDialog;

import AdvText from "@components/data/text";
import AdvButton from "@components/inputs/button";
import AdvModal from "@components/layout/modal";
import AdvStack from "@components/layout/stack";
import AdvStackItem from "@components/layout/stack/stack-item";
import AdvIcon from "@components/other/icon";
import AdvLoading, { EAdvLoadingMode } from "@components/other/loading";
import { LAN } from "@data/language/strings";
import useIsMounted from "@hooks/misc/useIsMounted";
import { useAdvCallback } from "@hooks/react-overload/useAdvCallback";
import { useAdvEffect } from "@hooks/react-overload/useAdvEffect";
import { TAdvPermission, useAdvDataFactory } from "@hooks/useAdvDataProviderFactory";
import useAdvTheme from "@hooks/useAdvTheme";
import { DeleteIcon, SaveIcon } from "@themes/icons";
import deepCopy from "@utils/deep-copy";
import { advcatch } from "@utils/logging";
import { useState } from "react";

type TPermissionConfigProps = {
    isOpen: boolean;
    onDismiss: () => void;
};

export const SessionPermissionConfig = ({ isOpen, onDismiss }: TPermissionConfigProps) => {
    const [isReady, setIsReady] = useState(false);
    const [permission, setPermission] = useState<Array<TAdvPermission>>([]);

    const { getSessionPermissions, setSessionPermissions } = useAdvDataFactory();

    const onSave = useAdvCallback(
        (doReset = false) => {
            setSessionPermissions({ IsReset: doReset, Permissions: permission })
                .then(() => {
                    setIsReady(false);
                })
                .catch((r) => advcatch("Could not save session permission: ", r));
        },
        [permission, setSessionPermissions],
    );

    const onDismissWrapper = useAdvCallback(() => {
        setSessionPermissions({ IsReset: false, Permissions: permission })
            .then(() => {
                onDismiss();
                setIsReady(false);
            })
            .catch((r) => advcatch("Could not save session permission: ", r));
    }, [onDismiss, permission, setSessionPermissions]);

    const { isOrWillMount } = useIsMounted();

    useAdvEffect(() => {
        if (isOpen && !isReady) {
            getSessionPermissions()
                .then((res) => {
                    if (isOrWillMount()) {
                        setPermission(deepCopy(res.Permissions));
                        setIsReady(true);
                    }
                })
                .catch((r) => advcatch("Could not load session permissions: ", r));
        }
    }, [getSessionPermissions, isOpen, isOrWillMount, isReady]);

    const theme = useAdvTheme();

    return (
        <AdvModal
            isOpen={isOpen}
            onDismiss={onDismissWrapper}
            headline={LAN.SES_PERMISSIONS_CONFIGURE.text}
            styles={{ root: { main: { minWidth: 500, minHeight: "60%" } } }}
        >
            <AdvStack verticalFill>
                <AdvLoading
                    isLoading={!isReady}
                    mode={EAdvLoadingMode.HideContentWhenLoading}
                    spinnerProps={{ label: "Fetching session permissions", withDots: true }}
                >
                    <AdvStack verticalFill>
                        <AdvStackItem>
                            <AdvStack horizontal styles={{ root: { justifyContent: "end" } }}>
                                <AdvStackItem>
                                    <AdvButton
                                        text={LAN.SAVE.text}
                                        iconName={SaveIcon.iconName}
                                        primary
                                        onClick={() => {
                                            onSave(false);
                                        }}
                                    ></AdvButton>
                                </AdvStackItem>
                                <AdvStackItem>
                                    <AdvButton
                                        text={LAN.RESET.text}
                                        iconName={"Backward"}
                                        primary
                                        onClick={() => {
                                            onSave(true);
                                        }}
                                    ></AdvButton>
                                </AdvStackItem>
                            </AdvStack>
                        </AdvStackItem>
                        {permission.map((val, index) => (
                            <AdvStackItem
                                key={"permission-act-select" + index.toString()}
                                styles={{
                                    root: {
                                        backgroundColor: theme.palette.themeLighterAlt,
                                        padding: 10,
                                    },
                                }}
                            >
                                <AdvStack horizontal>
                                    <AdvStackItem grow>
                                        <AdvText
                                            ignoreTranslation
                                            key={"permission-act-select-text" + index.toString()}
                                        >
                                            {val.Bez}
                                        </AdvText>
                                    </AdvStackItem>
                                    <AdvStackItem
                                        styles={{
                                            root: {
                                                alignItems: "center",
                                                display: "flex",
                                                paddingLeft: 10,
                                            },
                                        }}
                                        shrink={0}
                                    >
                                        <AdvIcon
                                            iconName={DeleteIcon.iconName}
                                            onClick={() => {
                                                setPermission((old) => {
                                                    let newPerm = deepCopy(old);
                                                    newPerm = newPerm.filter(
                                                        (val2, index2) => index2 != index,
                                                    );
                                                    return newPerm;
                                                });
                                            }}
                                        ></AdvIcon>
                                    </AdvStackItem>
                                </AdvStack>
                            </AdvStackItem>
                        ))}
                    </AdvStack>
                </AdvLoading>
            </AdvStack>
        </AdvModal>
    );
};

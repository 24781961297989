import {
    gAdvParameterMappingKey,
    IAdvParameterMapping,
} from "@components/dynamic/parameter-mapping/types";
import { TAdvDropdownItem } from "@components/inputs/dropdown";
import { EAdvValueDataTypes } from "@utils/data-types";
import { buildUniquePageID, gAdvDesignerPageName, getQueryValue } from "@utils/page-parser";
import { atomFamily, selectorFamily } from "recoil";

import { gPageParamResultAutoVar } from "@components/dynamic/parameter-mapping/page-parameter";
import { EActionLogic } from "@logic/webactions/types";
import { TPageInfo } from "@pages/dynamic";
import { defaultPageInstanceIndex } from "@pages/dynamic/instanceIndexContext";
import { TAdvAction } from "./action";

export type TParameterID = { id: string };
export function buildPageIDForParameterUIDesigner(activeFileName: string): TParameterID {
    const pageInfo: TPageInfo = {
        pageInstanceIndex: defaultPageInstanceIndex,
        pathname: "/designer/ui",
        query: { [gAdvDesignerPageName]: activeFileName },
    };

    return {
        id: buildUniquePageID(pageInfo),
    };
}

export function buildPageIDForVariableID(pageInfo: TPageInfo): TParameterID {
    const zwPageInfo = JSON.parse(JSON.stringify(pageInfo));
    zwPageInfo.query = pageInfo.pathname.startsWith("/designer/")
        ? { [gAdvDesignerPageName]: getQueryValue(pageInfo.query, gAdvDesignerPageName) }
        : pageInfo.query;

    return {
        id: buildUniquePageID(zwPageInfo),
    };
}

export const recoilParameters = atomFamily<IAdvParameterMapping, TParameterID>({
    key: "recoilParameters",
    default: { params: {} },
});

export type TPageParameterValue = {
    pageParamName: string;
    value: any;
    dataType: EAdvValueDataTypes;
};

export const recoilPageVariables = atomFamily<TPageParameterValue[], TParameterID>({
    key: "recoilPageVariables",
    default: [],
});

export const recoilParameterList = selectorFamily<
    TAdvDropdownItem<any>[],
    { action: TAdvAction; exclude: string[] }
>({
    key: "recoilParameterList",
    get: (param) => () => {
        const ret: TAdvDropdownItem<any>[] = [];
        const params = param.action.Parameters;
        const paramKeys = Object.keys(params);
        const paramList: string[] = [];
        for (const paramKey of paramKeys) {
            if (
                !param.exclude.includes(params[paramKey].Name) &&
                !paramList.includes(params[paramKey].Name)
            ) {
                ret.push({
                    key: params[paramKey].Name,
                    text: params[paramKey].Name,
                    data: params[paramKey].Name,
                });
                paramList.push(params[paramKey].Name);
            }
        }
        if (param.action.LogicName == EActionLogic.Return) {
            ret.push({
                key: gPageParamResultAutoVar,
                text: "[Auto] Page-Result",
                data: gPageParamResultAutoVar,
            });
        }
        return ret;
    },
});

export const recoilParameterListOfCurrentPageDropDown = selectorFamily<
    TAdvDropdownItem<any>[],
    { paramID: TParameterID; filterConst?: boolean }
>({
    key: "recoilParameterListOfCurrentPageDropDown",
    get:
        ({ paramID, filterConst }) =>
        ({ get }) => {
            const ret: TAdvDropdownItem<any>[] = [];
            const pageParams = get(recoilParameters(paramID));
            if (pageParams != undefined) {
                const paramKeys = Object.keys(pageParams.params);
                const paramList: string[] = [];
                for (const paramKey of paramKeys) {
                    if (
                        !paramList.includes(pageParams.params[paramKey].value) &&
                        paramKey.startsWith(gAdvParameterMappingKey) &&
                        (filterConst !== true ||
                            !Object.keys(pageParams.params[paramKey].options).includes(
                                "constant",
                            ) ||
                            pageParams.params[paramKey].options["constant"] !== true)
                    ) {
                        ret.push({
                            key: pageParams.params[paramKey].value,
                            text: pageParams.params[paramKey].value,
                            data: pageParams.params[paramKey].value,
                        });
                        paramList.push(pageParams.params[paramKey].value);
                    }
                }
            }
            return ret;
        },
});

export const recoilParameterListOfCurrentPage = selectorFamily<
    Map<
        string,
        {
            val: any;
            dataType: EAdvValueDataTypes;
            isRequired: boolean;
            isConstant: boolean;
        }
    >,
    TParameterID
>({
    key: "recoilParameterAsListOfCurrentPage",
    get:
        (param: TParameterID) =>
        ({ get }) => {
            const ret = new Map<
                string,
                {
                    val: any;
                    dataType: EAdvValueDataTypes;
                    isRequired: boolean;
                    isConstant: boolean;
                }
            >();
            const pageParams = get(recoilParameters(param));
            const pageParamValues = get(recoilPageVariables(param));
            if (pageParams != undefined) {
                const paramKeys = Object.keys(pageParams.params);
                const paramList: string[] = [];
                for (const paramKey of paramKeys) {
                    const pageParamName = pageParams.params[paramKey].value;
                    const pageParamOptions = pageParams.params[paramKey].options;
                    const pageParamOptionKeys = Object.keys(pageParams.params[paramKey].options);
                    const pageParamVal = pageParamValues.find(
                        (val) => val.pageParamName == pageParamName,
                    );
                    if (
                        !paramList.includes(pageParamName) &&
                        paramKey.startsWith(gAdvParameterMappingKey)
                    ) {
                        const isRequired = pageParamOptionKeys.includes("required")
                            ? pageParamOptions["required"] === true
                            : false;
                        const isConstant = pageParamOptionKeys.includes("constant")
                            ? pageParamOptions["constant"] === true
                            : false;
                        if (pageParamVal != undefined)
                            ret.set(pageParamName, {
                                val: pageParamVal.value,
                                dataType: pageParamVal.dataType,
                                isRequired: isRequired,
                                isConstant: isConstant,
                            });
                        else
                            ret.set(pageParamName, {
                                val: undefined,
                                dataType: EAdvValueDataTypes.Any,
                                isRequired: isRequired,
                                isConstant: isConstant,
                            });
                        paramList.push(pageParamName);
                    }
                }
            }
            const foundRes = pageParamValues.find(
                (p) => p.pageParamName == gPageParamResultAutoVar,
            );
            if (foundRes != undefined) {
                ret.set(gPageParamResultAutoVar, {
                    val: foundRes.value,
                    dataType: EAdvValueDataTypes.Any,
                    isRequired: false,
                    isConstant: false,
                });
            }
            return ret;
        },
});

import AdvGridItemDesignable from "@components/layout/grid/grid-item/designable";
import AdvStackItemDesignable from "@components/layout/stack/stack-item/designable";
import {
    AdvCommonComponentAttributes,
    AdvThemeProviderProperties,
    TAdvCommonProperties,
} from "@components/other/common-properties";
import { LAN } from "@data/language/strings";
import { DefaultComponentCategory } from "@feature/Designer/types/category";
import { TAdvDesignerComponentProps } from "@feature/Designer/types/component-props";
import { EComponentTypeInput } from "@feature/Designer/types/component-type";
import {
    AdvProperty,
    getDesignerModeComponentStyle,
    getSelectedComponentStyle,
    registerDesignableComponent,
} from "@feature/Designer/utils";
import { Calendar, ICalendarPickerStyles, ICalendarProps, ICalendarStrings } from "@fluentui/react";
import {
    TAdvValueBindingParams,
    useAdvValueBinderNoDataType,
} from "@hooks/dynamic/useAdvValueBinder";
import { useAdvWebAction } from "@hooks/dynamic/useAdvWebAction";
import { TAdvWebActionParams } from "@hooks/dynamic/useAdvWebAction.types";
import { toAdvText } from "@hooks/language/useTranslation";
import { useAdvUnmount } from "@hooks/misc/useMounts";
import { useAdvCallback } from "@hooks/react-overload/useAdvCallback";
import { useAdvEffect } from "@hooks/react-overload/useAdvEffect";
import useAdvComponent from "@hooks/useAdvComponent";
import useAdvTheme from "@hooks/useAdvTheme";
import { CalendarIcon } from "@themes/icons";
import { EAdvValueDataTypes } from "@utils/data-types";
import { DateTimeToUnix, NumberRange, UnixToDateTime } from "@utils/date";
import { deepCompareJSXProps } from "@utils/deep-compare";
import { combineStyles, mergeObjects } from "@utils/styling";
import React, { createRef, useMemo, useRef } from "react";
import { TCommonValueProps } from "..";
import { defaultCalenderStyles } from "./styles";

require("datejs");

export const localizationCalendarStrings: ICalendarStrings | undefined =
    typeof window === "undefined"
        ? undefined
        : {
              // Tage / Monate direkt aus dem Browser nutzen, so müssen wir nicht alles manuell übersetzen.
              days: NumberRange(-1, 5).map((int) => {
                  const date = new Date(0, 0, 1); // Montag 1 Januar 1900
                  date.addDays(int);
                  return date.toLocaleString(window.navigator.language, { weekday: "long" });
              }),
              months: NumberRange(0, 11).map((int) => {
                  const date = new Date(0, 0, 1); // Montag 1 Januar 1900
                  date.addMonths(int);
                  return date.toLocaleString(window.navigator.language, { month: "long" });
              }),
              shortDays: NumberRange(-1, 5).map((int) => {
                  const date = new Date(0, 0, 1); // Montag 1 Januar 1900
                  date.addDays(int);
                  return date.toLocaleString(window.navigator.language, { weekday: "short" });
              }),
              shortMonths: NumberRange(0, 11).map((int) => {
                  const date = new Date(0, 0, 1); // Montag 1 Januar 1900
                  date.addMonths(int);
                  return date.toLocaleString(window.navigator.language, { month: "short" });
              }),
              // TODO /** String to render for button to direct the user to today's date.*/
              goToToday: "gehe zu heute",
              // /** Aria-label for the "previous month" button in day picker.*/
              // prevMonthAriaLabel: "",
              // /** Aria-label for the "next month" button in day picker.*/
              // nextMonthAriaLabel: "",
              // /** Aria-label for the "previous year" button in month picker.*/
              // prevYearAriaLabel: "",
              // /** Aria-label for the "next year" button in month picker.*/
              // nextYearAriaLabel: "",
              // /** Aria-label for the "previous year range" button in year picker.*/
              // prevYearRangeAriaLabel: "",
              // /** Aria-label for the "next year range" button in year picker.*/
              // nextYearRangeAriaLabel: "",
              // /** Aria-label format string for the header button in the month picker. Should have 1 string param, e.g. "`{0}`,
              //  * select to change the year". This aria-label will only be applied if the year picker is enabled; otherwise
              //  * the label will default to the header string, e.g. "2019".*/
              // monthPickerHeaderAriaLabel: "",
              // /** Aria-label format string for the header button in the year picker.
              //  * Should have 1 string param, e.g. "`{0}`, select to change the month"*/
              // yearPickerHeaderAriaLabel: "",
              // /** Aria-label for the "close" button.*/
              // closeButtonAriaLabel: "",
              // /** Aria-label format string for the week number header. Should have 1 string param, e.g. "week number `{0}`"*/
              // weekNumberFormatString: "",
              // /** Aria-label format string for the currently selected date. Should have 1 string param, e.g. "Selected date `{0}`"*/
              // selectedDateFormatString: "",
              // /** Aria-label format string for today's date. Should have 1 string param, e.g. "Today's date `{0}`"*/
              // todayDateFormatString: "",
              // /** Aria-label for when a date is marked*/
              // dayMarkedAriaLabel: "",
          };

export type TAdvCalendarStyles = ICalendarPickerStyles; /* do not change */
export type TAdvCalendarProps = ICalendarProps &
    TAdvDesignerComponentProps &
    TAdvCommonProperties &
    TCommonValueProps<Date> & {
        keyRef: string;
        onHideEventActionParams?: TAdvWebActionParams;
        onChangedEventActionParams?: TAdvWebActionParams;
        onBeforeChangedEventActionParams?: TAdvWebActionParams;
        selectedDateBindingParams?: TAdvValueBindingParams;
        readonlyBindingParams?: TAdvValueBindingParams;
        dateWriterPrettyBindingParams?: TAdvValueBindingParams;
        validDays?: Date[];
    };
const now = new Date();
const AdvCalendarComp = ({
    advhide,
    styles: propStyles,
    designerData,
    designerProps,
    onSelectDate,
    selectedDateBindingParams,
    readonlyBindingParams,
    dateWriterPrettyBindingParams,
    dataArrayIndex = 0,
    value,
    keyRef,
    onHideEventActionParams,
    onChangedEventActionParams,
    onBeforeChangedEventActionParams,
    validDays,
    ...props
}: TAdvCalendarProps) => {
    useAdvComponent(AdvCalendarComp, props);
    const calendarRef = createRef<HTMLDivElement>();

    const [selectedUnixDate, setSelectedUnixDate] = useAdvValueBinderNoDataType(
        selectedDateBindingParams,
        value === undefined ? DateTimeToUnix(now) : DateTimeToUnix(value),
        EAdvValueDataTypes.Number,
        dataArrayIndex,
    );
    const [, setDateWriterPretty] = useAdvValueBinderNoDataType(
        dateWriterPrettyBindingParams,
        "",
        EAdvValueDataTypes.String,
        dataArrayIndex,
    );
    const [isReadonly] = useAdvValueBinderNoDataType(
        readonlyBindingParams,
        false,
        EAdvValueDataTypes.Boolean,
        dataArrayIndex,
    );

    const [, , onHideEventActionFunction, , ,] = useAdvWebAction(
        keyRef,
        dataArrayIndex,
        onHideEventActionParams,
    );
    const [, , onChangedActionFunction, , ,] = useAdvWebAction(
        keyRef,
        dataArrayIndex,
        onChangedEventActionParams,
    );
    const [, , onBeforeChangedActionFunction, , ,] = useAdvWebAction(
        keyRef,
        dataArrayIndex,
        onBeforeChangedEventActionParams,
    );

    const handleSelectDate = useAdvCallback(
        (newDate: Date, selectedDateRangeArray?: Date[] | undefined) => {
            if (designerData !== undefined) return; // Im Designer nichts an den Server schicken
            if (isReadonly) return;

            const newUnixDate = DateTimeToUnix(newDate);
            setSelectedUnixDate(newUnixDate);
            // setDateWriter(newUnixDate);
            setDateWriterPretty(
                newDate.toLocaleDateString(undefined, {
                    day: "2-digit",
                    month: "2-digit",
                    year: "numeric",
                }),
            );
            if (onSelectDate != undefined) onSelectDate(newDate, selectedDateRangeArray);
        },
        [designerData, isReadonly, onSelectDate, setDateWriterPretty, setSelectedUnixDate],
    );

    const previousSelectedUnixDate = useRef<number | undefined>(selectedUnixDate);
    const forceReselect = useRef<boolean>(false);
    useAdvEffect(() => {
        if (designerData !== undefined) return; // Im Designer nichts an den Server schicken

        // OnChanged-Action ausführen (nachdem Komponente gerendet und alle States gesetzt sind)
        if (selectedUnixDate === previousSelectedUnixDate.current && !forceReselect.current) return;
        forceReselect.current = false;
        previousSelectedUnixDate.current = selectedUnixDate;
        (onChangedActionFunction as Function)();
    }, [designerData, onChangedActionFunction, selectedUnixDate]);

    useAdvUnmount(() => {
        // Wenn Komponente unmounted, beim nächsten mal das Date wieder setzen (Anfrage an Server schicken)
        forceReselect.current = true;
    });

    useAdvEffect(() => {
        return () => {
            (onHideEventActionFunction as Function)();
        };
    }, [onHideEventActionFunction]);

    const initialDateSelected = useRef<boolean>(false);
    useAdvEffect(() => {
        // Das erste Datum auswählen (nur 1x)
        if (validDays !== undefined && validDays.length > 0) {
            if (!initialDateSelected.current) {
                initialDateSelected.current = true;
                handleSelectDate(validDays[0]);
            }
        }
    }, [handleSelectDate, props.maxDate, props.minDate, validDays]);

    const theme = useAdvTheme();
    const styles = useMemo(() => {
        let styles = propStyles;
        if ((designerData?.isSelected ?? false) && (designerData?.renderAsDesigner ?? false))
            styles = mergeObjects(styles ?? {}, { root: getSelectedComponentStyle(theme, true) });
        if (designerData?.renderAsDesigner ?? false)
            styles = mergeObjects(styles ?? {}, { root: getDesignerModeComponentStyle(theme) });
        styles = combineStyles(styles, defaultCalenderStyles);
        return styles;
    }, [designerData?.isSelected, designerData?.renderAsDesigner, propStyles, theme]);

    if (advhide === true && designerProps === undefined) return <></>;
    return (
        <Calendar
            {...props}
            {...designerProps}
            ref={calendarRef}
            styles={styles}
            onSelectDate={(newDate, arr) => {
                // OnBeforeChanged-Action ausführen, bevor die Komponente neu gerendet und die States geändert werden
                (onBeforeChangedActionFunction as Function)();
                // newDate = 00:00:00 (GMT+2) des ausgewählten Tages.
                // Problem: UTC (GMT+0) wäre 22:00:00 des vorherigen Tages.
                // Da uns nur das Datum interessiert, addieren wir einfach 12 Stunden,
                // sodass wir immer auf dem gleichen Tag landen.
                newDate.setHours(12);
                handleSelectDate(newDate, arr);
            }}
            value={UnixToDateTime(selectedUnixDate)}
            strings={localizationCalendarStrings}
        />
    );
};

const AdvCalendar = React.memo(AdvCalendarComp, deepCompareJSXProps);
export default AdvCalendar;

export const designableCalendarProps = [
    AdvProperty.Number.create(
        toAdvText(LAN.ORDER_DATE_SELECTED),
        "selectedDate",
        toAdvText(LAN.GENERAL),
        toAdvText(LAN.ORDER_DATE_SELECTED_DESCR),
        -1,
    ),
    AdvProperty.Action.create(
        toAdvText(LAN.EVENTS_BEFORE_CHANGED),
        "onBeforeChangedEventActionParams",
        toAdvText(LAN.EVENTS),
        toAdvText(LAN.EVENTS_BEFORE_CHANGED_DESCR),
    ),
    AdvProperty.Action.create(
        toAdvText(LAN.EVENTS_CHANGED),
        "onChangedEventActionParams",
        toAdvText(LAN.EVENTS),
        toAdvText(LAN.EVENTS_CHANGED_DESCR),
    ),
    AdvProperty.Action.create(
        toAdvText(LAN.EVENTS_HIDE),
        "onHideEventActionParams",
        toAdvText(LAN.EVENTS),
        toAdvText(LAN.EVENTS_HIDE_DESCR),
    ),
    // DateReader = Bei Änderung des Wertes wird das Date übernommen
    AdvProperty.Boolean.create(
        toAdvText(LAN.READ_ONLY),
        "readonly",
        toAdvText(LAN.GENERAL),
        toAdvText(LAN.READ_ONLY_DESCR),
        false,
    ),
    // Wie der DateWriter, bloß lesbar
    AdvProperty.Text.create(
        toAdvText(LAN.WRITE_DATE_PRETTY),
        "dateWriterPretty",
        toAdvText(LAN.GENERAL),
        toAdvText(LAN.WRITE_DATE_PRETTY_DESCR),
        "",
    ),
    ...AdvCommonComponentAttributes,
    ...AdvThemeProviderProperties,
    ...AdvStackItemDesignable.CommonProperties,
    ...AdvGridItemDesignable.CommonProperties,
];

registerDesignableComponent({
    staticData: {
        name: LAN.CALENDAR.text,
        type: EComponentTypeInput.Calendar,
        supportsChildren: false,
        category: DefaultComponentCategory.Input,
        icon: CalendarIcon,
    },
    properties: designableCalendarProps,
    propertiesBuilders: [],
    presets: [],
});

import { LAN } from "@data/language/strings";
import { AdvProperty } from "@feature/Designer/utils";
import { toAdvText } from "@hooks/language/useTranslation";

enum EPropertyName {
    Row = "row",
    Column = "column",
    AsFlexContainer = "asFlexContainer",
}

enum EPropertyCategory {
    Allgemeines = "Allgemeines",
    GridItem = "Grid-Item",
}

enum EPresetName {}

const commonProperties = [
    AdvProperty.Text.create(
        toAdvText(LAN.COLUMN),
        EPropertyName.Column,
        toAdvText(LAN.GRID_ITEM),
        toAdvText(LAN.GRID_ITEM_COLUMN_DESCR),
        "1",
    ),
    AdvProperty.Text.create(
        toAdvText(LAN.ROW),
        EPropertyName.Row,
        toAdvText(LAN.GRID_ITEM),
        toAdvText(LAN.GRID_ITEM_ROW_DESCR),
        "1",
    ),
    AdvProperty.Boolean.create(
        toAdvText(LAN.AS_FLEX_CONTAINER),
        EPropertyName.AsFlexContainer,
        toAdvText(LAN.GRID_ITEM),
        toAdvText(LAN.GRID_ITEM_AS_FLEX_CONTAINER_DESCR),
        false,
        false,
    ),
];

// Stack ist die Basis des Ui-Designers, deshalb hier als Objekt merken.
/*const registeredComponent = registerDesignableComponent({
    staticData: {
        name: LAN.GRID_ITEM.text,

        type: EComponentTypeLayout.GridItem,
        supportsChildren: true,
        category: DefaultComponentCategory.Layout,
        icon: GridItemIcon,
    },
    properties: commonProperties,
    propertiesBuilders: [],
    presets: [],
});*/

const AdvGridItemDesignable = {
    PropertyName: EPropertyName,
    PropertyCategory: EPropertyCategory,
    PresetName: EPresetName,
    //Registered: registeredComponent,
    CommonProperties: commonProperties,
};

export default AdvGridItemDesignable;

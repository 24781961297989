import { AdvTable } from "@components/data/table-new";
import { TAdvTableColumn, createAdvTableColumn } from "@components/data/table-new/types";
import AdvText from "@components/data/text/text";
import AdvButton from "@components/inputs/button/button";
import { TAdvModalProps } from "@components/layout/modal";
import AdvModal from "@components/layout/modal/modal";
import AdvStack from "@components/layout/stack/stack";
import AdvStackItem from "@components/layout/stack/stack-item/stack-item";
import { LAN } from "@data/language/strings";
import { Dropdown, IDropdownOption, SelectableOptionMenuItemType } from "@fluentui/react";
import { useSessionStorage } from "@hooks/misc/useSessionStorage";
import { useAdvRouter } from "@hooks/page/useAdvRouter";
import { useAdvCallback } from "@hooks/react-overload/useAdvCallback";
import { useAdvEffect, useAdvEffectOnce } from "@hooks/react-overload/useAdvEffect";
import useAdvComponent from "@hooks/useAdvComponent";
import { useAdvSocketCallback } from "@hooks/useAdvSocketCallback";
import { SearchIcon } from "@themes/icons";
import { deepCompare, deepCompareJSXProps } from "@utils/deep-compare";
import React, { useMemo, useRef, useState } from "react";
import { TAdvDropdownProps } from "../dropdown";

export type TCustomer = {
    ID: number;
    Nr: string;
    Name: string;
    FirmaID: number;
};
type TCustomersResponse = { Data: TCustomer[] };

const SELECT_ALL = -999;
const SELECT_DIVIDER = -888;
const SELECT_ALL_KEY = "dd_cst_all";
const SELECT_DIVIDER_KEY = "dd_divider";
export const SESSIONSTORAGE_CUSTOMERS = "session_customers";

/**
 * Hook kümmert sich um die aktuell zu sehenen Kunden.
 * Kommuniziert mit dem Webportal Server und speichert die Auswahl im SessionStorage ab.
 */
export const useCustomers = () => {
    const { sendCallbackRequest } = useAdvSocketCallback();
    const [myCustomers, setMyCustomers] = useState<TCustomer[]>([]);
    const [currentCustomerIDs, setSelectedCustomerIDs] = useSessionStorage(
        SESSIONSTORAGE_CUSTOMERS,
        [] as number[],
    );
    const router = useAdvRouter();

    useAdvEffectOnce(() => {
        async function LoadCustomersAsync() {
            const res: TCustomersResponse = await sendCallbackRequest(
                "session",
                "get_customers",
                {},
            );
            setMyCustomers(res.Data);
            if (currentCustomerIDs.length == 0) {
                const arrIds = res.Data.map((k) => k.ID);
                lastSelectedCustomerIDs.current = arrIds;
                setSelectedCustomerIDs(arrIds); // Initial alle auswählen
            }
        }

        void LoadCustomersAsync();
    });

    const currentCustomers = useMemo(() => {
        return currentCustomerIDs
            .map((id) => myCustomers.find((k) => k.ID == id))
            .filter((k) => k !== undefined) as TCustomer[];
    }, [myCustomers, currentCustomerIDs]);

    const setCurrentCustomers = useAdvCallback(
        (
            customersOrFunction: TCustomer[] | ((old: TCustomer[]) => TCustomer[]),
            deselectOthers: boolean = true,
        ) => {
            setSelectedCustomerIDs((oldKeys) => {
                const customers =
                    typeof customersOrFunction == "function"
                        ? customersOrFunction(currentCustomers)
                        : customersOrFunction;
                if (deselectOthers) return [...customers.map((k) => k.ID)];
                else return [...oldKeys, ...customers.map((k) => k.ID)];
            });
        },
        [currentCustomers, setSelectedCustomerIDs],
    );

    const lastSelectedCustomerIDs = useRef<number[] | undefined>(undefined);
    useAdvEffect(() => {
        // Sicherstellen, dass wir nu eine Anfrage an den Server schicken wenn nötig!
        if (lastSelectedCustomerIDs.current === undefined) {
            // Undefined => Initiale Daten. Anfangs sind immer alle Kunden ausgewählt, deshalb keine Anfrage nötig
            lastSelectedCustomerIDs.current = currentCustomerIDs;
        } else {
            // IDs vergleichen und nur bei Unterschied die Anfrage verschicken
            if (deepCompare(lastSelectedCustomerIDs.current, currentCustomerIDs)) return;
            lastSelectedCustomerIDs.current = currentCustomerIDs;
            router.reload();
        }
    }, [currentCustomerIDs, router]);

    return {
        customers: myCustomers,
        currentCustomers,
        setCurrentCustomers,
    };
};

const customerSearchColumns: TAdvTableColumn<TCustomer>[] = [
    createAdvTableColumn<TCustomer>({
        columnId: "nr",
        renderHeaderCell: () => LAN.CUSTOMER_NR.text,
        renderCell: ({ Nr }) => Nr,
        contains: ({ Nr }, text) => Nr.toLowerCase().includes(text.toLowerCase()),
    }),
    createAdvTableColumn<TCustomer>({
        columnId: "name",
        renderHeaderCell: () => LAN.CUSTOMER_NAME.text,
        renderCell: ({ Name }) => Name,
        contains: ({ Name }, text) => Name.toLowerCase().includes(text.toLowerCase()),
    }),
];

type TAdvCustomerSearchProps = Omit<
    TAdvModalProps,
    "headline" | "children" | "onDismiss" | "onDismissed"
> & {
    onDismiss: (selectedCustomers: TCustomer[]) => void;
    customers: TCustomer[];
    selectionmode: "single" | "multiselect";
    text: string;
    closeOnSelect?: boolean;
};
export const AdvCustomerSearchComp = ({
    onDismiss,
    customers,
    selectionmode,
    text,
    closeOnSelect,
    ...props
}: TAdvCustomerSearchProps) => {
    const selectedCustomers = useRef<TCustomer[]>([]);
    useAdvEffect(() => {
        selectedCustomers.current = [];
    }, [props.isOpen]);

    const handleDismiss = useAdvCallback(() => {
        if (onDismiss !== undefined) onDismiss(selectedCustomers.current);
    }, [onDismiss]);

    return (
        <AdvModal {...props} headline="Kundensuche" onDismiss={handleDismiss}>
            <AdvStack>
                <AdvText>{text}</AdvText>
                <AdvTable<TCustomer>
                    columns={customerSearchColumns}
                    items={customers}
                    selectionMode={selectionmode}
                    onSelectionChange={(items) => {
                        selectedCustomers.current = items;
                        if (closeOnSelect == true) handleDismiss();
                    }}
                ></AdvTable>
            </AdvStack>
        </AdvModal>
    );
};

export type TAdvCustomerDropdownProps = Omit<TAdvDropdownProps, "options" | "label" | "value"> & {};
const AdvCustomerDropdownComp = ({ ...props }: TAdvCustomerDropdownProps) => {
    useAdvComponent(AdvCustomerDropdownComp, props);

    const [isSearchOpen, setSearchOpen] = useState<boolean>(false);
    const { customers, currentCustomers, setCurrentCustomers } = useCustomers();

    const customerDropdownItems: IDropdownOption[] = useMemo(() => {
        if (customers.length == 0) return [];
        // if (customers.length == 1) {
        //     let i = 1;
        //     for (let x = 0; x < 100; x++)
        //         customers.push({ ID: i, Name: "Test-lang " + i, Nr: String(i++) });
        // }
        const customerItems = customers
            .sort((a, b) => a.Name.localeCompare(b.Nr))
            .map(({ ID, Name, Nr }) => {
                return {
                    key: `dd_cst_${ID}`,
                    text: `${Nr} ${Name}`,
                    selected: true,
                    data: ID,
                } as IDropdownOption;
            });

        if (customers.length == 1) return customerItems;
        else {
            const allCustomersItem = {
                key: SELECT_ALL_KEY,
                text: LAN.ALL_CUSTOMERS.text,
                data: SELECT_ALL,
            } as IDropdownOption;
            const dividerItem = {
                key: SELECT_DIVIDER_KEY,
                data: SELECT_DIVIDER,
                itemType: SelectableOptionMenuItemType.Divider,
                selected: false,
            } as IDropdownOption;
            return [allCustomersItem, dividerItem, ...customerItems];
        }
    }, [customers]);

    const [selectedCustomers, setSelectedCustomer] = useState<TCustomer[]>([]);
    const selectedCustomerKeys = useMemo(() => {
        // Wenn alle ausgewählt, dann auch zwingend "(alle Kunden)" auswählen (nur im Dropdown)
        if (selectedCustomers.length == customers.length) {
            return [SELECT_ALL_KEY, ...selectedCustomers.map((k) => `dd_cst_${k.ID}`)];
        }
        return selectedCustomers.map((k) => `dd_cst_${k.ID}`);
    }, [customers.length, selectedCustomers]);

    useAdvEffect(() => {
        // Falls sich die CurrentCustomers (die Kunden, die lt. Session ausgewählt sind) ändern,
        // dann ebenfalls die Auswahl in der Dropdown aktualisieren (u.a. sobald der Server uns die schickt)
        setSelectedCustomer(currentCustomers);
    }, [currentCustomers]);

    const handleChange = useAdvCallback(
        (event: React.FormEvent<HTMLDivElement>, item: IDropdownOption | undefined): void => {
            if (item === undefined) return;
            const { selected: isThisItemSelected = false, data: thisItemData } = item;
            const thisItemID = Number(thisItemData);

            if (thisItemID == SELECT_ALL) {
                if (isThisItemSelected) {
                    setSelectedCustomer(customers);
                } else {
                    setSelectedCustomer([customers[0]]);
                }
            } else {
                if (isThisItemSelected) {
                    setSelectedCustomer((oldCustomers) => [
                        ...oldCustomers,
                        customers.find((k) => k.ID == thisItemID)!,
                    ]);
                } else {
                    setSelectedCustomer((oldCustomers) => {
                        const newCustomers = [...oldCustomers.filter((k) => k.ID != thisItemID)];
                        if (newCustomers.length > 0) return newCustomers;
                        else return oldCustomers;
                    });
                }
            }
        },
        [customers],
    );
    const handleRenderTitle = useAdvCallback(
        (
            selectedItems?: IDropdownOption<any>[],
            defaultRender?:
                | ((props?: IDropdownOption<any>[] | undefined) => React.JSX.Element | null)
                | undefined,
        ) => {
            if (selectedItems === undefined || defaultRender === undefined) return null;
            const selectedCustomerItems = selectedItems.filter((item) => Number(item.data) > 0);
            if (selectedCustomerItems.length == customers.length) {
                return <AdvText>(alle Kunden)</AdvText>;
            }
            return defaultRender(selectedItems);
        },
        [customers.length],
    );
    const handleDropdownDismissed = useAdvCallback(() => {
        // Wenn sich nichts geändert hat, dann raus hier
        if (deepCompare(selectedCustomers, currentCustomers)) return;
        // Wenn die Dropdown geschlossen wird: Fragen ob Änderung übernommen werden soll.
        if (confirm(LAN.CONFIRM_CUSTOMERS.text)) {
            setCurrentCustomers(selectedCustomers);
        } else {
            // Änderungen rückgängig
            setSelectedCustomer(currentCustomers);
        }
    }, [currentCustomers, selectedCustomers, setCurrentCustomers]);

    const handleSearchDismissed = useAdvCallback(
        (selectedCustomers: TCustomer[]) => {
            // Wenn sich die Suche schließt
            setSearchOpen(false);
            if (selectedCustomers.length > 0 && confirm(LAN.CONFIRM_CUSTOMERS.text)) {
                setCurrentCustomers((old) => [...old, ...selectedCustomers]);
            }
        },
        [setCurrentCustomers],
    );

    const customersForSearch = useMemo(() => {
        return customers.filter((customer) => selectedCustomers.indexOf(customer) == -1);
    }, [customers, selectedCustomers]);

    if (customers.length <= 1) return <></>;
    return (
        <AdvStack horizontal horizontalAlign="stretch" styles={{ root: { width: "100%" } }}>
            <AdvStackItem align="stretch" grow styles={{ root: { overflow: "hidden" } }}>
                <Dropdown
                    onDismiss={handleDropdownDismissed}
                    multiSelect
                    label={""}
                    disabled={customers.length <= 1}
                    options={customerDropdownItems}
                    onChange={customers.length == 0 ? undefined : handleChange}
                    placeholder={customers.length == 0 ? LAN.LOADING_CUSTOMERS.text : undefined}
                    selectedKeys={selectedCustomerKeys}
                    title={LAN.LOADING_CUSTOMERS.text}
                    onRenderTitle={handleRenderTitle}
                    dropdownWidth="auto"
                    {...props}
                />
            </AdvStackItem>
            {customersForSearch.length == 0 ? null : (
                <AdvStackItem>
                    <AdvButton
                        iconName={SearchIcon.iconName}
                        simplified
                        styles={{ root: { paddingLeft: 2, paddingRight: 2, minWidth: "auto" } }}
                        onClick={() => {
                            setSearchOpen(true);
                        }}
                    />
                    <AdvCustomerSearchComp
                        customers={customersForSearch}
                        isOpen={isSearchOpen}
                        onDismiss={handleSearchDismissed}
                        selectionmode="multiselect"
                        text="Verfügbare Kunden (bereits ausgewählte Kunden werden nicht angezeigt)"
                    />
                </AdvStackItem>
            )}
        </AdvStack>
    );
};

const AdvCustomerDropdown = React.memo(AdvCustomerDropdownComp, deepCompareJSXProps);
export default AdvCustomerDropdown;

import { useAdvCallback } from "@hooks/react-overload/useAdvCallback";
import { EAdvValueDataTypes } from "@utils/data-types";
import { useMemo } from "react";
import {
    EValueBindingMode,
    TAdvValueBinderAttributes,
    TAdvValueBindingParams,
    useAdvValueBinderImplProvider,
} from "./useAdvValueBinder";

/**
 * Bindet ein Wert eines Providers
 * @param bindings die bindings, die genutzt werden sollen, der Binding-Typ muss vom Typ @see EAdvValueBinderType.BinderTypePageVariable sein
 * @returns ein Array bestehend aus:
 *      den aktuellen Werten,
 *      einem Setter um die aktuellen Werte zu bearbeiten,
 *      einem Boolean, das aussagt, ob der Setter neue Werte akzeptiert,
 *      einem Boolean, das aussagt, ob der aktuelle Wert benutzt werden sollte,
 */
export const useAdvValueBinderProvider = function <T>(
    bindings: TAdvValueBindingParams | undefined,
    userVal: T,
    expectedDataType: EAdvValueDataTypes,
    dataArrayIndex: number,
): [T, (newVal: T) => boolean, TAdvValueBinderAttributes] {
    const useValAsArr = useMemo(() => [userVal], [userVal]);

    const [res0, res1, res2] = useAdvValueBinderImplProvider(
        bindings,
        useValAsArr,
        expectedDataType,
        EValueBindingMode.SingleRecordOrData,
        true,
        dataArrayIndex,
    );

    const setValueWrapper = useAdvCallback(
        (newVal: T): boolean => {
            return res1([newVal]);
        },
        [res1],
    );

    return [res0[0]?.val, setValueWrapper, res2];
};

import { TDictionaryValue } from "@data/persist/dictionary-value";
import { TAdvTransactionInterface } from "@hooks/recoil-overload/useAdvRecoilTransaction";
import { trans_assert } from "@utils/assert-trans";
import {
    atom,
    AtomEffect,
    atomFamily,
    DefaultValue,
    RecoilValueReadOnly,
    SerializableParam,
} from "recoil";

export enum EDictionaryAddOptions {
    DesignerAddAsSaving,
    AddErr,
    AddLifeTime,
}

export type TDictionaryAddOptions = {
    type: EDictionaryAddOptions;
    extraValue: any;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export type TRecoilDictionary<TValue, TKey> = {
    /** Die tatsächlichen Werte / Daten. Jedes Value ist ein eigenes Atom */
    values: (key: TKey) => RecoilValueReadOnly<TDictionaryValue<TValue>>;
    /** Alle Keys zu den ``values`` */
    valueKeys: RecoilValueReadOnly<Array<TKey>>;

    /** Entfernt das Item */
    removeItem: (
        tb: Pick<TAdvTransactionInterface, "get" | "set" | "reset">,
    ) => (keyToRemove: TKey) => void;
    /**
     * Fügt das Item hinzu. Doppelte Keys werden vermieden.
     * Bereits vorhandene Items werden überschrieben.
     */
    addOrSetItem: (
        tb: Pick<TAdvTransactionInterface, "get" | "set">,
    ) => (key: TKey, itemToAdd: TValue, addOptions?: TDictionaryAddOptions[]) => void;
    addItems: (tb: TAdvTransactionInterface) => (
        ...items: Array<{
            key: TKey;
            itemToAdd: TValue;
        }>
    ) => void;
    removeItemsSilentlyInternal: (
        tb: Pick<TAdvTransactionInterface, "get" | "set" | "reset">,
    ) => (...keysToRemove: Array<TKey>) => void;
    addItemsSilentlyInternal: (tb: TAdvTransactionInterface) => (
        ...items: Array<{
            key: TKey;
            itemToAdd: TValue;
            addOptions?: TDictionaryAddOptions[];
        }>
    ) => void;
    /**
     * Ersetzt ein Item durch ein neues. Der key kann dabei geändert werden.
     */
    replaceItem: (
        tb: TAdvTransactionInterface,
    ) => (oldKey: TKey, newkey: TKey, itemThatReplaces: TValue) => void;
    /** Entfernt alle Items aus dem Dictionary */
    clear: (tb: TAdvTransactionInterface) => () => void;

    /** Leert das Dictionary und fügt im Anschluss alle angegebenen Items hinzu */
    setItems: (tb: TAdvTransactionInterface) => (
        ...items: Array<{
            key: TKey;
            itemToAdd: TValue;
        }>
    ) => void;
};

/**
 * Optionale Einstellungsmöglichkeiten.
 * - Effekte: Effekte für die entsprechende Atom(family), beispielsweise um Daten vom Server zu laden.
 * - Prefix: Präfix für den AtomKey (node.Key)
 */
export type TRecoilDictionaryOptions<TValue> = {
    ValuesEffects?: ReadonlyArray<AtomEffect<TDictionaryValue<TValue>>>;

    /** Hat u.a. Einfluss darauf, wie die Daten gespeichert werden (LocalStorage / Server) */
    ValuesPrefix?: string;
};

/**
 * Eine Hilfsfunktion, die eine Art Dictionary mit diversen Recoil-States erstellt.
 * Wird benötigt, weil man nicht über eine ``AtomFamily`` iterieren kann.
 *
 * Zum modifizieren: {@link addItem}, {@link removeItem}, {@link clear}
 * Alternativ (nutzung in Komponenten): {@link useAdvRecoilDictionary}.
 *
 * @important
 * KANN in Kombination mit {@link recoilPersistServerDictionary} genutzt werden, um Daten serverseitig zu speichern.
 * Aber es ist KEIN muss (siehe z.B. {@link recoilDataProvider}).
 */
export function CreateRecoilDictionary<TValue, TKey extends SerializableParam = string>(
    uniqueName: string,
    { ValuesEffects, ValuesPrefix = "values_" }: TRecoilDictionaryOptions<TValue> = {},
): TRecoilDictionary<TValue, TKey> {
    const defaultValue = new TDictionaryValue<TValue>({ aIsLoading: true });
    const values = atomFamily<TDictionaryValue<TValue>, TKey>({
        key: `${ValuesPrefix}${uniqueName}`,
        default: defaultValue,
        effects: ValuesEffects,
    });

    const valueKeys = atom<Array<TKey>>({
        key: `keys_${ValuesPrefix}${uniqueName}`,
        default: [],
    });

    const setValueKeys =
        (transactionInterface: Pick<TAdvTransactionInterface, "get" | "set">) =>
        (newKeys: Array<TKey>) => {
            const { set, get } = transactionInterface;
            const oldKeys = get(valueKeys);

            // sort keys so two arrays with the same keys are actually the same unique array
            const realNewKeys = newKeys.sort((a, b) => {
                if (typeof a == "string") {
                    return a.localeCompare(b as string);
                } else {
                    return JSON.stringify(a).localeCompare(JSON.stringify(b));
                }
            });

            if (JSON.stringify(oldKeys) != JSON.stringify(realNewKeys)) set(valueKeys, realNewKeys);
        };

    const removeItemImpl =
        (transactionInterface: Pick<TAdvTransactionInterface, "get" | "set" | "reset">) =>
        (keyToRemove: TKey, removeSilent: boolean) => {
            // console.debug("Dictionary", "REMOVE", { keyToRemove });
            trans_assert(keyToRemove != undefined);

            const { set, get } = transactionInterface;
            const oldKeys = get(valueKeys);
            setValueKeys(transactionInterface)(
                oldKeys.filter((key) => JSON.stringify(key) != JSON.stringify(keyToRemove)),
            );

            if (removeSilent)
                set(
                    values(keyToRemove),
                    new TDictionaryValue<TValue>({ __internalSilentSet: true }),
                );
            else set(values(keyToRemove), new TDictionaryValue<TValue>({}));
        };

    const removeItem =
        (transactionInterface: Pick<TAdvTransactionInterface, "get" | "set" | "reset">) =>
        (keyToRemove: TKey) => {
            removeItemImpl(transactionInterface)(keyToRemove, false);
        };

    const removeItemsSilentlyInternal =
        (transactionInterface: Pick<TAdvTransactionInterface, "get" | "set" | "reset">) =>
        (...keysToRemove: Array<TKey>) => {
            for (const keyToRemove of keysToRemove)
                removeItemImpl(transactionInterface)(keyToRemove, true);
        };

    const addOrSetItem =
        (transactionInterface: Pick<TAdvTransactionInterface, "get" | "set">) =>
        (key: TKey, itemToAdd: TValue, addOptions?: TDictionaryAddOptions[]) => {
            // console.debug("Dictionary", "ADD", { key, itemToAdd });
            trans_assert(key != undefined && itemToAdd != undefined);

            const { set, get } = transactionInterface;
            const oldKeys = get(valueKeys);
            if (oldKeys instanceof DefaultValue) {
                setValueKeys(transactionInterface)([key]);
            } else if (
                oldKeys.findIndex((val) => JSON.stringify(val) == JSON.stringify(key)) == -1
            ) {
                setValueKeys(transactionInterface)([...oldKeys, key]);
            }

            const addEl = new TDictionaryValue({ aVal: itemToAdd });
            if (
                addOptions != undefined &&
                addOptions.find((val) => val.type == EDictionaryAddOptions.DesignerAddAsSaving) !=
                    undefined
            ) {
                addEl.__internalIsSaving = true;
            }
            set(values(key), addEl);
        };

    const addItemsImpl =
        (transactionInterface: TAdvTransactionInterface) =>
        (
            isSilent: boolean,
            ...items: Array<{ key: TKey; itemToAdd: TValue; addOptions?: TDictionaryAddOptions[] }>
        ) => {
            const { set, get } = transactionInterface;
            let currentKeys = get(valueKeys);
            for (const item of items) {
                const { key, itemToAdd } = item;
                if (
                    item.addOptions != undefined &&
                    item.addOptions.find((val) => val.type == EDictionaryAddOptions.AddErr) !=
                        undefined
                ) {
                    const addErr = item.addOptions.find(
                        (val) => val.type == EDictionaryAddOptions.AddErr,
                    );
                    if (addErr != undefined) {
                        if (!isSilent)
                            set(
                                values(key),
                                new TDictionaryValue<TValue>({
                                    aCannotLoad: addErr.extraValue as string,
                                }),
                            );
                        else
                            set(
                                values(key),
                                new TDictionaryValue<TValue>({
                                    aCannotLoad: addErr.extraValue as string,
                                    __internalSilentSet: true,
                                }),
                            );
                    }
                } else {
                    const lifeTimeOpt = item.addOptions?.find(
                        (val) => val.type == EDictionaryAddOptions.AddLifeTime,
                    );
                    const lifeTime = lifeTimeOpt?.extraValue as number | undefined;
                    trans_assert(key != undefined && itemToAdd != undefined);

                    if (currentKeys instanceof DefaultValue) {
                        currentKeys = [key];
                        setValueKeys(transactionInterface)(currentKeys);
                    } else if (
                        currentKeys.findIndex(
                            (val) => JSON.stringify(val) == JSON.stringify(key),
                        ) == -1
                    ) {
                        currentKeys = [...currentKeys, key];
                        setValueKeys(transactionInterface)(currentKeys);
                    }

                    if (!isSilent) {
                        set(
                            values(key),
                            new TDictionaryValue({ aVal: itemToAdd, __internalLifeTime: lifeTime }),
                        );
                    } else {
                        set(
                            values(key),
                            new TDictionaryValue({
                                aVal: itemToAdd,
                                __internalSilentSet: true,
                                __internalLifeTime: lifeTime,
                            }),
                        );
                    }
                }
            }
        };

    const addItems =
        (transactionInterface: TAdvTransactionInterface) =>
        (...items: Array<{ key: TKey; itemToAdd: TValue }>) => {
            addItemsImpl(transactionInterface)(false, ...items);
        };

    const addItemsSilentlyInternal =
        (transactionInterface: TAdvTransactionInterface) =>
        (
            ...items: Array<{ key: TKey; itemToAdd: TValue; addOptions?: TDictionaryAddOptions[] }>
        ) => {
            addItemsImpl(transactionInterface)(true, ...items);
        };

    const replaceItem =
        (transactionInterface: TAdvTransactionInterface) =>
        (oldKey: TKey, newKey: TKey, itemThatReplaces: TValue) => {
            trans_assert(oldKey != undefined && newKey != "" && itemThatReplaces != undefined);

            const { set, get } = transactionInterface;
            const currentKeys = get(valueKeys);
            const oldKeyIndex = currentKeys.findIndex(
                (val) => JSON.stringify(val) == JSON.stringify(oldKey),
            );
            if (oldKeyIndex == -1) {
                trans_assert(
                    false,
                    "Cannot replace key " + JSON.stringify(oldKey) + ", not found.",
                );
            } else {
                const tmpKeys = [...currentKeys];
                tmpKeys[oldKeyIndex] = newKey;
                setValueKeys(transactionInterface)([...tmpKeys]);
                set(values(oldKey), new TDictionaryValue<TValue>({}));
                set(values(newKey), new TDictionaryValue({ aVal: itemThatReplaces }));
            }
        };

    const clear =
        (transactionInterface: Pick<TAdvTransactionInterface, "reset" | "get" | "set">) => () => {
            const { reset, get, set } = transactionInterface;

            const oldKeys = get(valueKeys);
            // console.debug("Dictionary", "CLEAR", { oldKeys });

            if (oldKeys instanceof DefaultValue) return;

            reset(valueKeys);
            for (const key of oldKeys) {
                set(values(key), new TDictionaryValue<TValue>({}));
            }
        };

    const setItems =
        (transactionInterface: TAdvTransactionInterface) =>
        (...items: Array<{ key: TKey; itemToAdd: TValue }>) => {
            clear(transactionInterface)();
            addItems(transactionInterface)(...items);
        };

    return {
        values: values as (key: TKey) => RecoilValueReadOnly<TDictionaryValue<TValue>>,
        valueKeys: valueKeys as RecoilValueReadOnly<Array<TKey>>,
        //keyNames: (keyNames as RecoilValueReadOnly<Array<TKey>>),

        removeItem,

        addOrSetItem,
        addItems,

        removeItemsSilentlyInternal,
        addItemsSilentlyInternal,
        replaceItem,

        clear,

        setItems,
    };
}

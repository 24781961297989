import { useEffectDebugger } from "@hooks/misc/useEffectDebugger";
import { EVerbosityLevel, verbosityLevel } from "@hooks/shared";
// eslint-disable-next-line no-restricted-imports
import { useCallback } from "react";

type TCallbackParams<T extends Function> = Parameters<typeof useCallback<T>>;

// allow unused since this hook as runtime overhead it must be explicitly enabled
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const useAdvCallback =
    verbosityLevel > EVerbosityLevel.None
        ? <T extends Function>(
              cb: TCallbackParams<T>[0],
              deps: TCallbackParams<T>[1],
          ): TCallbackParams<T>[0] => {
              useEffectDebugger(
                  "verbose-useAdvCallback-" + cb.name + "-" + cb.toString(),
                  () => {},
                  deps,
              );

              // eslint-disable-next-line react-hooks/exhaustive-deps
              const res = useCallback(cb, deps);
              (res as any).adv = 1;
              return res;
          }
        : <T extends Function>(
              cb: TCallbackParams<T>[0],
              deps: TCallbackParams<T>[1],
          ): TCallbackParams<T>[0] => {
              // eslint-disable-next-line react-hooks/exhaustive-deps
              return useCallback(cb, deps);
          };
